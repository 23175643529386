import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  seo,
  sendTrackingData,
  notAllowWhiteSpaceAtStart,
  history,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Tooltip,
} from "@material-ui/core";
import {
  setTitle,
  basicInfoActions,
  sharedActions,
  basicInfoPage,
  setLocationDetail,
} from "../../../../actions";
import validator from "validator";
import { toast } from "react-toastify";
import classNames from "classnames";
import ReactTranslation from "../../../core/iReact18";
import translationEN from "../../../../locales/en/translation.json";
import { GLocation } from "../../../core/glocation.component";
import PhoneNumber from "../../../core/phoneNumber";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: theme.spacing(6),
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  title: {
    fontSize: 14,
  },
  smallBottomMargin: {
    marginBottom: "20px",
  },
  smallTopMargin: {
    marginTop: "20px",
  },
  smallMarginLeft: {
    marginLeft: "8px",
  },
  verySmallBottomMargin: {
    marginBottom: "10px",
  },
  verySmallTopMargin: {
    marginTop: "10px",
  },
  mediumTopMargin: {
    marginTop: "24px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customDivider: {
    color: "#E8E8E8",
    width: "100%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  customAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    textAlign: "left",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginTop: "32px",
  },
  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkedInUrlHelpText: {
    padding: "8px",
    fontWeight: 500,
    fontSize: "18px",
  },
  linkedInUrlHelpTextContent: {
    padding: "8px",
  },
  basicButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  cardAnimation: {
    marginTop: "-10px",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  flagAdornment: {
    height: "2em !important",
    alignItems: "initial !important",
  },
});

class BasicInfoStep2 extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    phoneNumber: this.props?.basicInfo?.basicInfoData?.contact
      ? this.props?.basicInfo?.basicInfoData?.contact
      : this.props?.basicInfo?.basicInfoParsedData?.contact
      ? this.props?.basicInfo?.basicInfoParsedData?.contact
      : this.props?.authentication?.loginInfo?.phone
      ? this.props?.authentication?.loginInfo?.phone
      : "",
    linkedInUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
      ? this.props?.basicInfo?.basicInfoData?.linkedin_url
      : this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
      ? this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
      : "",
    isPhoneNumberRequired: false,
    isLinkedInUrlRequired: false,
    isValidPhoneNumber: true,
    isValidLinkedInUrl: true,
    isAddressRequired: false,
    linkedInUrlHelpTextShow: false,
    baseUrlDev: false,
    basicInfoStep: 2,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setTitle(`${translationEN.PageTitle.Basic_Info_Step_2_Title}`));
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
    if (
      prevProps?.basicInfo?.basicInfoData !==
      this.props?.basicInfo?.basicInfoData
    ) {
      let { phoneNumber, linkedInUrl, address } = this.state;
      let userInfo = {
        userId: this.props?.basicInfo?.basicInfoData?.candidate_id,
        phoneNumber: phoneNumber,
        linkedInUrl: linkedInUrl,
        address: address,
        onboardingStatus: this.props?.onboarding?.onboardingStatus,
      };
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.BASIC_INFO_STEP_2_PAGE_LOAD,
        SCREENS.BASIC_INFO_STEP_2_SCREEN,
        ACTIONS.BASIC_INFO_STEP_2_PAGE_LOADED,
        userInfo
      );
    }
    if (
      prevProps?.basicInfo?.basicInfoParsedData?.contact !==
      this.props?.basicInfo?.basicInfoParsedData?.contact
    ) {
      this.setState({
        phoneNumber: this.props?.basicInfo?.basicInfoData?.contact
          ? this.props?.basicInfo?.basicInfoData?.contact
          : this.props?.basicInfo?.basicInfoParsedData?.contact
          ? this.props?.basicInfo?.basicInfoParsedData?.contact
          : this.props?.authentication?.loginInfo?.phone
          ? this.props?.authentication?.loginInfo?.phone
          : "",
      });
    }
    if (
      prevProps?.basicInfo?.basicInfoParsedData?.linkedin_url !==
      this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
    ) {
      this.setState({
        linkedInUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
          ? this.props?.basicInfo?.basicInfoData?.linkedin_url
          : this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
          ? this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
          : "",
      });
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "linkedInUrl":
          this.setState({
            isValidLinkedInUrl:
              inputValue.length > 0
                ? validator.matches(
                    inputValue,
                    /^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/gim
                  )
                : true,
            isLinkedInUrlRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          break;
      }
    });
  };

  handleSaveBasicInfo2 = () => {
    const { dispatch } = this.props;
    this.setState(
      {
        address:
          Object.keys(this.props?.authentication?.locationData).length !== 0
            ? this.props?.authentication?.locationData
            : null,
      },
      () => {
        const {
          phoneNumber,
          linkedInUrl,
          isValidLinkedInUrl,
          isValidPhoneNumber,
          address,
        } = this.state;
        let progressBarStepValue = 25;
        const utmData = this.props?.authentication?.utmData
          ? this.props?.authentication?.utmData
          : {};
        if (
          phoneNumber &&
          linkedInUrl &&
          isValidLinkedInUrl &&
          isValidPhoneNumber &&
          address
        ) {
          let payload = {
            step: this.state.basicInfoStep,
            contact: phoneNumber,
            linkedin_url: linkedInUrl.toLowerCase(),
            location: address,
            invite_code: this.props?.authentication?.inviteCode
              ? this.props?.authentication?.inviteCode
              : "REAC22",
          };
          if (Object.keys(utmData)?.length > 0) {
            for (var key in utmData) {
              payload[key] = utmData[key];
            }
          }
          dispatch(
            basicInfoActions.basicInformationStep2(
              payload,
              this.props?.shared?.activeStep,
              progressBarStepValue
            )
          );
          let userInfo = {
            userId: this.props?.basicInfo?.basicInfoData?.candidate_id,
            linkedinUrl: linkedInUrl,
            phone: phoneNumber,
            address: address,
            onboardingStatus: this.props?.onboarding?.onboardingStatus,
          };
          sendTrackingData(
            this.props.shared.decryptedMixPanelKey,
            EVENT_TITLES.BASIC_INFO_STEP_2_NEXT_CLICK,
            SCREENS.BASIC_INFO_STEP_2_SCREEN,
            ACTIONS.BASIC_INFO_STEP_2_NEXT_CLICKED,
            userInfo
          );
        } else {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Required_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={"Toast_Message.Required_Message"}
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
            )
          );
          this.setState({
            isPhoneNumberRequired: validator.isEmpty(phoneNumber)
              ? true
              : false,
            isLinkedInUrlRequired: validator.isEmpty(linkedInUrl)
              ? true
              : false,
            isAddressRequired: address
              ? Object.keys(address).length === 0
              : true,
          });
        }
      }
    );
  };

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setLocationDetail({}));
  }

  handleOnShowLinkedInUrlText = () => {
    this.setState({ linkedInUrlHelpTextShow: true });
  };

  handleOnHideLinkedInUrlText = () => {
    this.setState({ linkedInUrlHelpTextShow: false });
  };

  updateLocationRequireState = (value) => {
    this.setState({ isAddressRequired: value });
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(sharedActions.setProgressbarValue(0));
    dispatch(basicInfoPage(1));
    dispatch(basicInfoActions.getBasicInfo());
    history.push("/onboarding/basic-info-step-1");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.BASIC_INFO_STEP_2_PREVIOUS_CLICK,
      SCREENS.BASIC_INFO_STEP_2_SCREEN,
      ACTIONS.BASIC_INFO_STEP_2_PREVIOUS_CLICKED
    );
  };

  onChangePhoneValue = (value, validate, required) => {
    this.setState({
      phoneNumber: value,
      isPhoneNumberRequired: required,
      isValidPhoneNumber: validate,
    });
  };
  render() {
    const { classes } = this.props;
    const { phoneNumber, linkedInUrl, baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          className={classNames(classes.mainGrid, classes.smallTopMargin)}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <PhoneNumber
                  onChangeValue={this.onChangePhoneValue}
                  value={phoneNumber}
                  baseUrlDev={baseUrlDev}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  autoComplete={false}
                  id="linkedInUrl"
                  name="linkedInUrl"
                  fullWidth
                  label={
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Step_2.Form.LinkedIn_URL"
                        placement="left-end"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_2.Form.LinkedIn_URL"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={
                            "Basic_Information_Step_2.Form.LinkedIn_URL"
                          }
                        />
                      </Typography>
                    )
                  }
                  placeholder={`${translationEN.Placeholder.Basic_Info_Page.LinkedIn_Placeholder}`}
                  variant="standard"
                  required
                  value={linkedInUrl}
                  onChange={this.handleInputChange("linkedInUrl")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.textField,
                      input: classes.notchedInput,
                      root: classes.fontFamily,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      contained: classes.leftMarginZero,
                      root: classes.fontFamily,
                    },
                  }}
                  error={
                    !this.state.isValidLinkedInUrl ||
                    this.state.isLinkedInUrlRequired
                  }
                  helperText={
                    this.state.isLinkedInUrlRequired ? (
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )
                    ) : this.state.isValidLinkedInUrl ? (
                      ""
                    ) : baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Validation_Messages.Field_Invalid"
                        placement="left-end"
                      >
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Invalid"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className={classes.fontSize12px}
                      >
                        <ReactTranslation
                          contentKey={"Validation_Messages.Field_Invalid"}
                        />
                      </Typography>
                    )
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <GLocation
                  {...this.props}
                  isRequire={this.state.isAddressRequired}
                  updateRequire={this.updateLocationRequireState}
                ></GLocation>
              </Grid>
            </Grid>
            <Grid container spacing={3} className={classes.marginTop}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.mediumTopMargin}
              >
                <div
                  className={classNames(classes.TopMargin, classes.basicButton)}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="basicInfoPage2PreviousButton"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        {" "}
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="basicInfoPage2PreviousButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      {" "}
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next">
                      <Button
                        id="basicInfoPage2NextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveBasicInfo2();
                        }}
                      >
                        {" "}
                        <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="basicInfoPage2NextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveBasicInfo2();
                      }}
                    >
                      {" "}
                      <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

BasicInfoStep2.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedBasicInfoPage2 = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(BasicInfoStep2))
);
export { connectedBasicInfoPage2 as BasicInfoStep2 };
