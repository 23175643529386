const initialState = {
  basicInfoPage: null,
  professionalData: {},
  professionalParsedData: {},
  careerPage: null,
  suggestedAccomplishments: {},
  existingAccomplishments: {},
  existingCompetencies: {},
  suggestedCompetencies: {},
  memberDiversityData: {},
  videoInfoData: {},
  coffeeQuestionData: {},
  onboardingStatus: null,
};

export function onboarding(state = initialState, action) {
  switch (action.type) {
    case "BASIC_INFO_PAGE":
      return {
        ...state,
        basicInfoPage: action.pageNo,
      };
    case "BASIC_INFO_DATA":
      return {
        ...state,
        basicInfoData: action.basicInfo,
      };
    case "PROFESSIONAL_INFO_DATA":
      return {
        ...state,
        professionalData: action.professionalInfo,
        professionalParsedData: action.professionalParsedData,
      };
    case "ACCOMPLISHMENTS_DATA":
      return {
        ...state,
        suggestedAccomplishments: action.suggested_accomplishments,
        existingAccomplishments: action.existing_accomplishments,
      };
    case "COMPETENCIES_DATA":
      return {
        ...state,
        existingCompetencies: action.existing_competencies,
        suggestedCompetencies: action.suggested_competencies,
      };
    case "CAREER_PAGE":
      return {
        ...state,
        careerPage: action.pageNo,
      };
    case "SAVE_VIDEO":
      return {
        ...state,
        videoInfoData: action.videoData,
      };
    case "SAVE_COFFEQUESTION":
      return {
        ...state,
        coffeeQuestionData: action.coffeeQuestion,
      };
    case "ONBOARDING_STATUS":
      return {
        ...state,
        onboardingStatus: action.onboardingStatus,
      };
    default:
      return state;
  }
}
