import axios from "axios";
import config from "../config/config";
import store from "../store/store";
import { authActions } from "../actions";
import { toast } from "react-toastify";
import { VERSION } from "../constants/constants";
import secureLocalStorage from "react-secure-storage";
import { history } from "../helpers";

export const apiService = {
  get,
  post,
  put,
  deleteDetail,
  thirdPartyApiGet,
  thirdPartyApiPost,
};

function get(apiEndpoint) {
  return axios
    .get(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("token")
      ) {
        return axios
          .post(config.baseUrl + "api/v1/recruiter/refresh_token/", {
            refresh_token: JSON.parse(secureLocalStorage.getItem("token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return get(apiEndpoint).then((response) => {
                return response;
              });
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signOut());
            }
          });
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("token")
      ) {
        store.dispatch(authActions.signOut());
      } else if (error.response.status === 429) {
        history.push("/bad-request");
      }
      return error.response;
    });
}

function post(apiEndpoint, payload) {
  return axios
    .post(config.baseUrl + apiEndpoint, payload, getOptions()) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      // console.log(err);
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("token")
      ) {
        return axios
          .post(config.baseUrl + "api/v1/recruiter/refresh_token/", {
            refresh_token: JSON.parse(secureLocalStorage.getItem("token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return post(apiEndpoint, payload).then((response) => {
                return response;
              });
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signOut());
            }
          });
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("token")
      ) {
        store.dispatch(authActions.signOut());
      } else if (error.response.status === 429) {
        toast.dark(error.response.data.message);
        history.push("/bad-request");
      }
      console.log(error.response);
      return error.response;
    });
}
function put(apiEndpoint, payload) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("token")
      ) {
        return axios
          .post(config.baseUrl + "api/v1/recruiter/refresh_token/", {
            refresh_token: JSON.parse(secureLocalStorage.getItem("token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return put(apiEndpoint, payload).then((response) => {
                return response;
              });
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signOut());
            }
          });
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("token")
      ) {
        store.dispatch(authActions.signOut());
      } else if (error.response.status === 429) {
        history.push("/bad-request");
      }
      console.log(error);
      return error.response;
    });
}
function deleteDetail(apiEndpoint) {
  return axios
    .delete(config.baseUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_EXPIRED" &&
        secureLocalStorage.getItem("token")
      ) {
        return axios
          .post(config.baseUrl + "api/v1/recruiter/refresh_token/", {
            refresh_token: JSON.parse(secureLocalStorage.getItem("token")),
          }) // return axios.post(config.baseUrl+apiEndpoint, payload, getOptions())
          .then((response) => {
            if (response.data && response.data.access_token) {
              secureLocalStorage.setItem(
                "a_token",
                JSON.stringify(response.data.access_token)
              );
              return deleteDetail(apiEndpoint).then((response) => {
                return response;
              });
            }
          })
          .catch((error) => {
            if (
              error.response.status === 401 &&
              (error.response.data.message === "REFRESH_TOKEN_EXPIRED" ||
                error.response.data.message === "REFRESH_TOKEN_INVALID")
            ) {
              store.dispatch(authActions.signOut());
            }
          });
      } else if (
        error.response.status === 401 &&
        error.response.data.message === "TOKEN_INVALID" &&
        secureLocalStorage.getItem("token")
      ) {
        store.dispatch(authActions.signOut());
      } else if (error.response.status === 429) {
        history.push("/bad-request");
      }
      console.log(error);
      return error.response;
    });
}

// function getOptions() {
//     let options = {};

//     if (secureLocalStorage.getItem('a_token')) {
//         options.headers = { 'Authorization': 'Bearer ' + JSON.parse(secureLocalStorage.getItem('a_token')) };
//     }
//     return options;
// }

function thirdPartyApiGet(apiEndpoint) {
  return axios
    .get(apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error.response.status !== 412) {
        handelErrorResponse(error);
      }
    });
}

function thirdPartyApiPost(apiEndpoint, payload) {
  return axios
    .post(apiEndpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      handelErrorResponse(error);
    });
}
function getOptions() {
  let options = {};
  if (secureLocalStorage.getItem("token")) {
    options.headers = {
      Authorization:
        "Bearer " + JSON.parse(secureLocalStorage.getItem("token")),
      "ss-mem-source": `OPTIMUS_${VERSION}`,
    };
  } else {
    options.headers = { "ss-mem-source": `OPTIMUS_${VERSION}` };
  }
  return options;
}

function handelErrorResponse(error) {
  if (error.response) {
    toast.dark(
      error.response.data.message
        ? error.response.data.message
        : "Something went haywire"
    );
  }
}
