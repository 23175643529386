import React, { Component } from "react";
// import Loader from 'react-loader-spinner';
import customLoader from "../../assets/loader.gif";
// import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "rgba(0,0,0,0.5)",
  zIndex: "9999",
  width: "100%",
  padding: "100%",
};
const imageStyle = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: "9999",
};
// const style = { position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: '9999', width: '100%', padding: '100vh'};

export default class AppLoader extends Component {
  render() {
    return (
      // <Loader
      //   type='Oval'
      //   color='#FBC112'
      //   height={70}
      //   width={70}
      //   style={style}
      // />
      <div style={style}>
        <img
          style={imageStyle}
          height="100px"
          width="100px"
          src={customLoader}
          alt="loading..."
        />
      </div>
    );
  }
}
