import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Button, Tooltip } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import StopIcon from "@material-ui/icons/Stop";
import ReactTranslation from "../../../core/iReact18";
import { showHideToolTip } from "../../../../helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "0 !important",
  },

  countDownButton: {
    position: "absolute",
    top: "50%",
    bottom: "50%",
    left: "50%",
    right: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "100px",
  },
  timer: {
    position: "absolute",
    top: "50",
    bottom: "0",
    left: "0",
    right: "50",
    fontSize: "30px",
  },
  newRecordButton: {
    backgroundColor: "rgba(227, 73, 28, 0.8)",
    color: "#FFFFFF",
    cursor: "pointer",
    position: "absolute",
    top: "85%",
    right: "35%",
    left: "35%",
    [theme.breakpoints.between("xs", "sm")]: {
      right: "20%",
      left: "20%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "15%",
      left: "15%",
    },
    "&:hover": {
      backgroundColor: "rgba(227, 73, 28, 0.9)",
      color: "#FFFFFF",
    },
  },
  newStopRecordButton: {
    backgroundColor: "rgba(227, 73, 28, 0.8)",
    color: "#FFFFFF",
    cursor: "pointer",
    position: "absolute",
    top: "85%",
    right: "35%",
    left: "35%",
    [theme.breakpoints.between("xs", "sm")]: {
      right: "20%",
      left: "20%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "15%",
      left: "15%",
    },
    "&:hover": {
      backgroundColor: "rgba(227, 73, 28, 0.9)",
      color: "#FFFFFF",
    },
  },
  recordButton: {
    position: "absolute",
    width: "fit-content",
    // top: '78%',
    right: "0%",
    left: "41.5%",
    bottom: "0%",
    cursor: "pointer",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "40%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "36%",
    },
  },
  stopButton: {
    position: "absolute",
    width: "fit-content",
    top: "72%",
    right: "0%",
    left: "42%",
    bottom: "0%",
    cursor: "pointer",
    [theme.breakpoints.between("xs", "sm")]: {
      left: "45%",
    },
    [theme.breakpoints.down("xs")]: {
      left: "30%",
    },
  },
  turnOnButton: {
    cursor: "pointer",
    position: "absolute",
    top: "85%",
    right: "35%",
    left: "35%",
    backgroundColor: "#FFFFFF",
    color: "#020203",
    [theme.breakpoints.between("xs", "sm")]: {
      right: "20%",
      left: "20%",
    },
    [theme.breakpoints.down("xs")]: {
      right: "15%",
      left: "15%",
    },
  },
});

class VideoRecorderComponent extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    countDownTime: this.props.countdownTime / 1000,
    countDown: false,
    timeLimit: this.props.timeLimit ? this.props.timeLimit / 1000 : 0,
    isTimer: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  handleOnStartVideoRecording = () => {
    this.props.onTurnOnCamera();
  };
  handleOnRecord = () => {
    const { isCameraOn, streamIsReady } = this.props;
    if (isCameraOn && streamIsReady) {
      this.setState({
        countDown: true,
      });

      this.timeout = setTimeout(this.updateNumber, 1000);
    }
  };

  updateNumber = () => {
    const nextNumber = this.state.countDownTime - 1;
    this.setState({
      countDownTime: nextNumber,
    });
    if (nextNumber !== 0) {
      this.timeout = setTimeout(this.updateNumber, 1000);
    }
    if (this.state.countDownTime === 0) {
      this.props.onStartRecording();
      this.setState({
        isTimer: true,
      });
    }
  };

  handleOnStopRecording = () => {
    this.setState(
      {
        isTimer: false,
      },
      () => {
        this.props.onStopRecording();
      }
    );
  };

  componentWillUnmount() {
    clearInterval(this.timeout, this.timer);
    this.turnOffCamera();
  }

  turnOffCamera = () => {
    if (this.props.onTurnOffCamera) {
      this.props.onTurnOffCamera();
    }
  };

  render() {
    const { classes } = this.props;
    const {
      t,
      isCameraOn,
      streamIsReady,
      isVideoInputSupported,
      isInlineRecordingSupported,
      thereWasAnError,
      isConnecting,
      isRunningCountdown,
      isRecording,
      isReplayingVideo,
      onOpenVideoInput,
    } = this.props;
    const { baseUrlDev } = this.state;

    let shouldUseVideoInput =
      !isInlineRecordingSupported && isVideoInputSupported;

    if (
      (!isInlineRecordingSupported && !isVideoInputSupported) ||
      thereWasAnError ||
      isConnecting ||
      isRunningCountdown
    ) {
      return null;
    }
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {shouldUseVideoInput !== null ? (
                !isCameraOn &&
                !isReplayingVideo && (
                  <Button
                    t={t}
                    onClick={() => this.handleOnStartVideoRecording()}
                    className={classes.turnOnButton}
                    title={
                      baseUrlDev
                        ? "Dialog.Video_Page.Turn_on_camera"
                        : "Turn on camera"
                    }
                    data-qa="start-recording"
                    variant="contained"
                    color="primary"
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Turn_on_camera"}
                    />
                  </Button>
                )
              ) : baseUrlDev ? (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    baseUrlDev
                      ? "Dialog.Video_Page.Record_a_Video"
                      : "Record video"
                  }
                >
                  <Button
                    t={t}
                    onClick={onOpenVideoInput}
                    className={classes.recordButton}
                    data-qa="start-recording"
                    variant="contained"
                    color="primary"
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Record_a_Video"}
                    />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  t={t}
                  onClick={onOpenVideoInput}
                  className={classes.recordButton}
                  data-qa="start-recording"
                  variant="contained"
                  color="primary"
                >
                  <ReactTranslation
                    contentKey={"Dialog.Video_Page.Record_a_Video"}
                  />
                </Button>
              )}
              {isCameraOn && streamIsReady && !isRecording && (
                <>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={
                      baseUrlDev
                        ? "Dialog.Video_Page.Start_Recording"
                        : "Record video"
                    }
                  >
                    <Button
                      startIcon={<VideocamIcon />}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleOnRecord();
                      }}
                      className={classes.newRecordButton}
                      data-qa="start-recording"
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Start_Recording"}
                      />
                    </Button>
                  </Tooltip>
                </>
              )}
              {this.state.countDown && (
                <div className={classes.countDownButton}>
                  {this.state.countDownTime !== 0
                    ? this.state.countDownTime
                    : null}
                </div>
              )}
              {this.state.isTimer && !isReplayingVideo && isRecording && (
                <Timer
                  className={classes.timer}
                  timeLimit={this.props.timeLimit}
                />
              )}
              {isRecording && (
                <Tooltip
                  arrow
                  title={
                    baseUrlDev
                      ? "Dialog.Video_Page.Stop_Recording"
                      : "Stop recording"
                  }
                  placement="bottom"
                >
                  <Button
                    startIcon={<StopIcon />}
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleOnStopRecording();
                    }}
                    className={classes.newStopRecordButton}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Stop_Recording"}
                    />
                  </Button>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

VideoRecorderComponent.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedVideoRecorder = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(VideoRecorderComponent))
);
export { connectedVideoRecorder as VideoRecorderComponent };

class Timer extends Component {
  static propTypes = {
    timeLimit: PropTypes.number,
    defaultText: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const nextSeconds = props.timeLimit ? props.timeLimit / 1000 : 0;
    this.state = this.getState(nextSeconds);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  componentDidMount() {
    const { timeLimit } = this.props;
    this.timer = setInterval(() => {
      const { seconds } = this.state;
      const nextSeconds = timeLimit ? seconds - 1 : seconds + 1;
      const nextState = this.getState(nextSeconds);
      this.setState(nextState);
    }, 1000);
  }

  pad(unit) {
    var str = "" + unit;
    var pad = "00";
    return pad.substring(0, pad.length - str.length) + str;
  }

  getState(seconds) {
    const minutes = Math.floor(seconds);

    const humanTime =
      minutes !== 0 ? `${this.pad(seconds)}sec` : `${seconds}sec`;

    return {
      seconds: seconds,
      timeLimitInSeconds: humanTime,
    };
  }

  render() {
    const defaultText = this.props.defaultText || "0:00";
    return (
      <div
        style={{
          position: "absolute",
          top: "50px",
          right: "50px",
          fontSize: "36px",
          color: "red",
        }}
      >
        {this.state.timeLimitInSeconds || defaultText}
      </div>
    );
  }
}

export default Timer;
