const initialState = {
  loginInfo: {},
  jodDetailInfo: {},
  vipJobDetailInfo: {},
  companyNames: [],
  jobTitles: [],
  constants: {},
  locationData: {},
};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case "JOB_DATA":
      return {
        ...state,
        jodDetailInfo: action.jobDetail,
      };
    case "VIP_JOB_DATA":
      return {
        ...state,
        vipJobDetailInfo: action.vipJobDetail,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        loginInfo: action.loginInfo,
        environment: action.environment,
        key: action.key,
      };
    case "COMPANY_NAME_DATA":
      return {
        ...state,
        companyNames: action.companyNames,
      };
    case "JOB_TITLE_DATA":
      return {
        ...state,
        jobTitles: action.jobTitles,
      };
    case "CONSTANTS":
      return {
        ...state,
        constants: action.constant,
      };
    case "SAVE_PARAM_INFO":
      return {
        ...state,
        utmData: action.utmData,
        inviteCode: action.inviteCode,
        originalUrlPath: action.originalUrlPath,
      };
    case "LOCATION_DATA":
      return {
        ...state,
        locationData: action.locationData,
      };
    case "SIGN_OUT_DELETE":
      return {};
    default:
      return state;
  }
}
