import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Box,
  Tooltip,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { connect } from "react-redux";
import {
  authActions,
  getMixPanelkey,
  loading,
  saveJobDetail,
  saveVipJobDetail,
} from "../../../actions";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { seo, sendTrackingData, showHideToolTip } from "../../../helpers";
import { EVENT_TITLES, SCREENS, ACTIONS } from "../../../constants/constants";
import Footer from "../../core/footer";
import { setTitle } from "../../../actions";
import config from "../../../config/config";
import BackgroundImage from "../../../assets/new-img-left.png";
import BOAModule from "../../core/boa-module";
import ReactTranslation from "../../core/iReact18";
import translationEN from "../../../locales/en/translation.json";
import { NeedHelp } from "../../core/needHelp.component";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    height: "calc(100vh - 150px)",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customPaper: {
    borderRadius: "4px",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  spacing: {
    [theme.breakpoints.down("xs")]: {
      padding: "5% 5% 25% 5%",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5% 5% 25% 5%",
    },
    backgroundPosition: "right bottom",
  },
  customLink: {
    color: "#FBC112 !important",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  loginForm: {
    backgroundColor: "#14131f",
    borderRadius: "0 !important",
    color: "rgba(255, 255, 255, 0.6)",
    paddingRight: "48px !important",
    paddingTop: "48px !important ",
    paddingLeft: "48px !important ",
    [theme.breakpoints.down("md")]: {
      width: "75%",
      paddingRight: "32px !important",
      paddingLeft: "32px !important",
      paddingBottom: "32px !important",
    },
  },
  smallBottomMargin: {
    marginBottom: "1em",
  },
  linkedInButton: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #020203",
    color: "#020203 !important",
    fontWeight: "700 !important",
    borderRadius: "4px",
    fontSize: "16px",

    "&:hover": {
      color: "#000000 !important",
      backgroundColor: "#FFFFFF",
    },
  },
  disabledLinkedInButton: {
    width: "100%",
    fontSize: "16px",
    backgroundColor: "#FFFFFF !important",
  },
  linkedInSignInButton: {
    width: "100%",
    fontWeight: "700 !important",
    backgroundColor: "white",
    color: "#020203 !important",
    cursor: "pointer",
    border: "1px solid #020203",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#000000 !important",
    },
  },
  linkedinIcon: {
    color: "#0A66C2",
  },
  versionPosition: {
    fontSize: "small",
    bottom: 85,
    left: "2.6%",
    position: "fixed",
    [theme.breakpoints.between("xs", "sm")]: {
      position: "relative",
      bottom: 120,
    },
  },
  leftSideBackgroundImage: {
    borderRadius: "0 !important",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  textColor: {
    color: "#FFFFFF",
  },
  customcheckBox: {
    padding: "2px 9px",
    color: "#FFFFFF !important",
  },
  textColorTerm: {
    color: "rgba(255, 255, 255, 0.6)",
  },
  responsiveLoginText: {
    textAlign: "center",
  },
  loginText: {
    textAlign: "right",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "0px !important",
      textAlign: "center",
    },
  },
});

const CustomCheckBox = withStyles({
  root: {
    color: "#FFFFFF",
    "&$checked": {
      color: "#FFFFFF",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class LogIn extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    acceptTermAndConditions: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    dispatch(getMixPanelkey());
    dispatch(setTitle(`${translationEN.PageTitle.Default_Login_Page_Title}`));
    dispatch(loading(false));
    // const { hostname } = new URL(window.location.href);
    let url = new URL(window.location.href);
    const params = new URLSearchParams(window.location.search);
    let utmData = {};
    for (var urlKey of params.keys()) {
      if (urlKey.startsWith("utm_")) {
        utmData[urlKey] = params.get(urlKey);
      }
    }
    if (!url.searchParams.get("key")) {
      utmData["utm_linkurl"] = window.location.href; // hostname;
    }
    if (url.searchParams.get("invite_code")) {
      const inviteCode = url.searchParams.get("invite_code")
        ? url.searchParams.get("invite_code")
        : "REAC22";
      const originalUrlPath = window.location.pathname + window.location.search;
      dispatch(authActions.saveParamInfo(utmData, inviteCode, originalUrlPath));
    } else {
      if (!url.searchParams.get("key")) {
        dispatch(
          authActions.saveParamInfo(
            utmData
              ? utmData
              : this.props?.authentication?.utmData
              ? this.props?.authentication?.utmData
              : {},
            this.props?.authentication?.inviteCode
              ? this.props?.authentication?.inviteCode
              : "REAC22",
            window.location.pathname
          )
        );
      }
    }
    if (secureLocalStorage.getItem("jobName")) {
      secureLocalStorage.removeItem("jobName");
    }
    if (secureLocalStorage.getItem("vipJobName")) {
      secureLocalStorage.removeItem("vipJobName");
    }
    dispatch(saveJobDetail({}));
    dispatch(saveVipJobDetail({}));
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });

    if (
      prevProps.shared.decryptedMixPanelKey !==
      this.props.shared.decryptedMixPanelKey
    ) {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.DEFAULT_LOGIN_PAGE_LOAD,
        SCREENS.DEFAULT_LOGIN_SCREEN,
        ACTIONS.DEFAULT_LOGIN_PAGE_LOADED
      );
    }
  }

  handleTermsAndConditionAccept = (prop) => (event) => {
    this.setState({ [prop]: event.target.checked }, () => {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.TERMS_AND_CONDITIONS_CHECKBOX_CLICK,
        SCREENS.DEFAULT_LOGIN_SCREEN,
        `${
          event.target.checked
            ? ACTIONS.T_N_C_CHECKBOX_CHECKED
            : ACTIONS.T_N_C_CHECKBOX_UNCHECKED
        }`
      );
    });
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <Grid container className={classes.root}>
        <Box
          component={Grid}
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={8}
          display={{ xs: "block", md: "block", lg: "block", xl: "block" }}
        >
          <img
            src={BackgroundImage}
            alt="loginImage"
            style={{ width: "100%", height: "100%", display: "block" }}
          />
        </Box>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={4}
          component={Paper}
          elevation={0}
          className={classNames(classes.loginForm)}
        >
          <Typography
            id="rightSideSection"
            variant="h4"
            className={classNames(
              classes.boldFont,
              classes.smallBottomMargin,
              classes.textColor,
              classes.textAlignLeft
            )}
          >
            {baseUrlDev ? (
              <Tooltip arrow title="Login_Page.heading" placement="top">
                <Typography variant="h4">
                  <ReactTranslation contentKey={"Login_Page.heading"} />
                </Typography>
              </Tooltip>
            ) : (
              <Typography variant="h4">
                <ReactTranslation contentKey={"Login_Page.heading"} />
              </Typography>
            )}
          </Typography>
          <Typography
            className={classNames(
              classes.smallTopMargin,
              classes.smallBottomMargin,
              classes.textAlignLeft
            )}
          >
            {baseUrlDev ? (
              <Tooltip arrow title="Login_Page.content_01" placement="left">
                <Typography>
                  {" "}
                  <ReactTranslation
                    contentKey={"Login_Page.content_01"}
                  ></ReactTranslation>
                </Typography>
              </Tooltip>
            ) : (
              <Typography>
                {" "}
                <ReactTranslation
                  contentKey={"Login_Page.content_01"}
                ></ReactTranslation>
              </Typography>
            )}
            <br />
            {baseUrlDev ? (
              <Tooltip arrow title="Login_Page.content_02" placement="left">
                <Typography>
                  {" "}
                  <ReactTranslation contentKey={"Login_Page.content_02"} />
                </Typography>
              </Tooltip>
            ) : (
              <Typography>
                {" "}
                <ReactTranslation contentKey={"Login_Page.content_02"} />
              </Typography>
            )}
          </Typography>
          <br />
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            id="termsAndConditions"
          >
            <FormControlLabel
              style={{ placeItems: "flex-start" }}
              control={
                <CustomCheckBox
                  checked={this.state.acceptTermAndConditions}
                  onChange={this.handleTermsAndConditionAccept(
                    "acceptTermAndConditions"
                  )}
                  name="acceptTermAndConditions"
                  style={{ padding: "2px 9px" }}
                />
              }
              label={
                <Typography
                  color="textSecondary"
                  className={classes.textColorTerm}
                >
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Login_Page.content_03"
                      placement="left"
                    >
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Login_Page.content_03"}
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography component={"span"}>
                      <ReactTranslation contentKey={"Login_Page.content_03"} />
                    </Typography>
                  )}
                  <a
                    href="https://succeedsmart.com/termsofuse"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.customLink}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Login_Page.terms_of_use"
                        placement="left"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Login_Page.terms_of_use"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <ReactTranslation
                        contentKey={"Login_Page.terms_of_use"}
                      />
                    )}
                  </a>
                  &nbsp;
                  {baseUrlDev ? (
                    <Tooltip arrow title="Login_Page.and" placement="left-end">
                      <Typography component={"span"}>
                        <ReactTranslation contentKey={"Login_Page.and"} />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <ReactTranslation contentKey={"Login_Page.and"} />
                  )}
                  &nbsp;
                  <a
                    href="https://succeedsmart.com/privacypolicy"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.customLink}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Login_Page.privacy_policy"
                        placement="left"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Login_Page.privacy_policy"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Login_Page.privacy_policy"}
                        />
                      </Typography>
                    )}
                  </a>
                  .
                </Typography>
              }
              value="true"
              className={classes.textAlignLeft}
            />
          </Grid>
          <br />
          <br />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {this.state.acceptTermAndConditions && (
              <a
                href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                style={{ textDecoration: "none" }}
              >
                {baseUrlDev ? (
                  <Tooltip arrow title="Login_Page.button_signup_with_linkedin">
                    <Button
                      disableElevation
                      disabled={!this.state.acceptTermAndConditions}
                      variant="outlined"
                      size="large"
                      className={classNames(
                        classes.linkedInButton,
                        classes.smallBottomMargin
                      )}
                      startIcon={<LinkedInIcon style={{ color: "#0A66C2" }} />}
                    >
                      <ReactTranslation
                        contentKey={"Login_Page.button_signup_with_linkedin"}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    disableElevation
                    disabled={!this.state.acceptTermAndConditions}
                    variant="outlined"
                    size="large"
                    className={classNames(
                      classes.linkedInButton,
                      classes.smallBottomMargin
                    )}
                    startIcon={<LinkedInIcon style={{ color: "#0A66C2" }} />}
                  >
                    <ReactTranslation
                      contentKey={"Login_Page.button_signup_with_linkedin"}
                    />
                  </Button>
                )}
              </a>
            )}
            {!this.state.acceptTermAndConditions && (
              <Tooltip
                arrow
                title={baseUrlDev && "Login_Page.button_signup_with_linkedin"}
              >
                <Button
                  disableElevation
                  disabled={!this.state.acceptTermAndConditions}
                  variant="outlined"
                  size="large"
                  className={classNames(
                    classes.disabledLinkedInButton,
                    classes.smallBottomMargin
                  )}
                  startIcon={<LinkedInIcon />}
                >
                  <ReactTranslation
                    contentKey={"Login_Page.button_signup_with_linkedin"}
                  />
                </Button>
              </Tooltip>
            )}
          </Grid>
          <Grid container className={classes.smallTopMargin}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className={classNames(
                classes.MediumTopMargin,
                classes.smallBottomMargin
              )}
            >
              {/* need help component */}
              <NeedHelp />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography className={classes.responsiveLoginText}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Login_Page.Already_a_member_SucceedSmart"
                  >
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Login_Page.Already_a_member_SucceedSmart"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component={"span"}>
                    <ReactTranslation
                      contentKey={"Login_Page.Already_a_member_SucceedSmart"}
                    />
                  </Typography>
                )}{" "}
                &nbsp;
                <a
                  className={classNames(classes.customLink)}
                  href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                  onClick={(event) => {
                    sendTrackingData(
                      this.props.shared.decryptedMixPanelKey,
                      EVENT_TITLES.SIGN_IN_LINK_CLICK,
                      SCREENS.DEFAULT_LOGIN_SCREEN,
                      ACTIONS.SIGN_IN_LINK_CLICKED
                    );
                    event.stopPropagation();
                  }}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Login_Page.Sign_in_to_my_account">
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Login_Page.Sign_in_to_my_account"}
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Login_Page.Sign_in_to_my_account"}
                      />
                    </Typography>
                  )}
                </a>
                {/* <a  className = {classNames(classes.customLink)} href= {(config.baseUrl === `https://dev1-platform.succeedsmart.com/` || config.baseUrl === `https://dev-platform.succeedsmart.com/`) ? 'https://dev-member.succeedsmart.com' : 'https://member.succeedsmart.com' }>
                  Sign in to my account
                  </a> */}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* <Typography align='right' color='secondary' className={classNames(classes.smallTopMargin, classes.versionPosition)}>{VERSION}</Typography> */}
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BOAModule />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}

LogIn.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedSignInPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(LogIn))
);

export { connectedSignInPage as LogIn };
