import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Slider,
  styled,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import {
  careerHighlightPage,
  sharedActions,
  setTitle,
  preferencesActions,
} from "../../../../actions";
import {
  history,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import { toast } from "react-toastify";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  veryMediumTopMargin: {
    marginTop: "16px",
  },
  TopBottomMargin: {
    marginBottom: "32px",
  },
  preferencesHeading: {
    fontWeight: "700",
    color: "#14131F",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  salaryButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  subHeading: {
    fontSize: "16px",
    color: "#000000",
  },
});

const iOSBoxShadow = "0 0 0 6px #B7B6B8 0.24 !important";

const SalarySlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "" : "black",
  width: "100%",
  [theme.breakpoints.between("sm", "md")]: {
    width: "85%",
  },
  [theme.breakpoints.between("xs", "sm")]: {
    width: "70%",
  },
  padding: "10px 0",
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: "#FBC112",
    boxShadow: iOSBoxShadow,
    "&:focus, &:hover, &.Mui-active, &.MuiSlider-active": {
      boxShadow: iOSBoxShadow,
      "@media (hover: none)": {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -20,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-IOSSlider": {
    top: -10,
  },
  "& .MuiSlider-track": {
    height: 10,
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiSlider-rail": {
    height: 10,
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
    borderRadius: "10px",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 15,
    width: 2,
    "& .MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
  "& .MuiSlider-markLabel": {
    [theme.breakpoints.down("md")]: {
      transform: "rotateZ(45deg) !important",
    },
    [theme.breakpoints.down("sm")]: {
      transform: "rotateZ(45deg) !important",
    },
  },
}));

class SalaryPrefrences extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    desiredSalary: "100000",
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(preferencesActions.getSalary());
    dispatch(setTitle(`${translationEN.PageTitle.Salary_Page_Title}`));
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.SALARY_EXPECTATIONS_PAGE_LOAD,
      SCREENS.SALARY_EXPECTATIONS_SCREEN,
      ACTIONS.SALARY_EXPECTATIONS_PAGE_LOADED,
      userInfo
    );
    let salary =
      this.props?.preferences?.salaryData?.desired_job_salary !== null ||
      this.props?.preferences?.salaryData?.desired_job_salary === undefined
        ? this.props?.preferences?.salaryData?.desired_job_salary?.replace(
            /[^0-9.-]+/g,
            ""
          )
        : "100000";
    this.setState({ desiredSalary: salary });

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    if (
      prevProps?.preferences?.salaryData?.desired_job_salary !==
      this.props?.preferences?.salaryData?.desired_job_salary
    ) {
      let salary =
        this.props?.preferences?.salaryData?.desired_job_salary !== null ||
        this.props?.preferences?.salaryData?.desired_job_salary === undefined
          ? this.props?.preferences?.salaryData?.desired_job_salary?.replace(
              /[^0-9.-]+/g,
              ""
            )
          : "100000";
      this.setState({ desiredSalary: salary });
    }
  }

  handleInputChange = (newValue) => {
    if (newValue) {
      this.setState({ desiredSalary: newValue });
    }
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(careerHighlightPage(4));
    dispatch(sharedActions.setProgressbarValue(68.75));
    dispatch(sharedActions.handlePrivousStep(this.props.shared?.activeStep));
    history.push("/onboarding/career-highlights-video");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.SALARY_EXPECTATIONS_PREVIOUS_CLICK,
      SCREENS.SALARY_EXPECTATIONS_SCREEN,
      ACTIONS.SALARY_EXPECTATIONS_PREVIOUS_CLICKED
    );
  };

  handleSaveSalary = () => {
    const { dispatch } = this.props;
    let price = Intl.NumberFormat("en-US");
    let salary =
      this.state.desiredSalary === null ||
      this.state.desiredSalary === "" ||
      this.state.desiredSalary === undefined
        ? "100000"
        : `${`$`}${price.format(this.state.desiredSalary)}`;
    if (salary === null || salary === "" || salary === undefined) {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Salary_Page.Salary_Range_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={"Toast_Message.Salary_Page.Salary_Range_Message"}
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={"Toast_Message.Salary_Page.Salary_Range_Message"}
          />
        )
      );
      return;
    }
    if (salary === "$500,000") {
      salary = "$500,000+";
    }
    let payload = {
      salary: salary,
    };
    let progressBarStepValue = 81.25;
    dispatch(preferencesActions.saveSalary(payload, progressBarStepValue));
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.SALARY_EXPECTATIONS_NEXT_CLICK,
      SCREENS.SALARY_EXPECTATIONS_SCREEN,
      ACTIONS.SALARY_EXPECTATIONS_NEXT_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    let salaryArray = [];
    if (this.props.authentication?.constants?.minimum_salary) {
      this.props.authentication?.constants?.minimum_salary?.map((ele) =>
        salaryArray.push({ value: ele.replace(/[^0-9.-]+/g, ""), label: ele })
      );
    }
    let salaryValueFormat = Intl.NumberFormat("en-US");
    let minSalaryValue = parseInt(salaryArray[0]?.value);
    let maxSalaryValue = parseInt(salaryArray[salaryArray?.length - 1]?.value);

    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                align="left"
                className={classes.smallTopMargin}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Salary_Page.Salary_Heading_1"
                    placement="left-end"
                  >
                    <Typography
                      variant="h5"
                      className={classNames(
                        classes.preferencesHeading,
                        classes.TopBottomMargin
                      )}
                    >
                      {
                        <ReactTranslation
                          contentKey={"Salary_Page.Salary_Heading_1"}
                        />
                      }
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    variant="h5"
                    className={classNames(
                      classes.preferencesHeading,
                      classes.TopBottomMargin
                    )}
                  >
                    {
                      <ReactTranslation
                        contentKey={"Salary_Page.Salary_Heading_1"}
                      />
                    }
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                align="left"
                className={classNames(
                  classes.TopMargin,
                  classes.TopBottomMargin
                )}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Salary_Page.Salary_Heading_2"
                    placement="left-end"
                  >
                    <Typography className={classes.subHeading}>
                      {
                        <ReactTranslation
                          contentKey={"Salary_Page.Salary_Heading_2"}
                        />
                      }
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography className={classes.subHeading}>
                    {
                      <ReactTranslation
                        contentKey={"Salary_Page.Salary_Heading_2"}
                      />
                    }
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(
                  classes.TopMargin,
                  classes.TopBottomMargin
                )}
                align="left"
              >
                <SalarySlider
                  aria-label="salry-slider"
                  size="medium"
                  value={this.state.desiredSalary}
                  onChange={(event, newValue) => {
                    event.preventDefault();
                    this.handleInputChange(newValue);
                  }}
                  step={10000}
                  min={minSalaryValue}
                  max={maxSalaryValue}
                  valueLabelDisplay="off"
                  name="salarySlider"
                  id="salarySlider"
                />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.verySmallTopMargin}
                >
                  <Typography component="div">
                    {this.state?.desiredSalary !== null ||
                    this.state?.desiredSalary !== ""
                      ? this.state.desiredSalary >= 500000
                        ? `$${salaryValueFormat.format(
                            this.state.desiredSalary
                          )}+`
                        : `$ ${salaryValueFormat.format(
                            this.state.desiredSalary
                          )}`
                      : `$${salaryValueFormat.format(100000)}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                <div
                  className={classNames(
                    classes.TopMargin,
                    classes.salaryButton
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="salaryPreviousButton"
                        variant="outlined"
                        color="primary"
                        className={classes.prevButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="salaryPreviousButton"
                      variant="outlined"
                      color="primary"
                      className={classes.prevButton}
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="salaryNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.prevButton}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveSalary();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="salaryNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={classes.prevButton}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveSalary();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

SalaryPrefrences.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedSalaryPrefrencesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(SalaryPrefrences))
);
export { connectedSalaryPrefrencesPage as SalaryPrefrences };
