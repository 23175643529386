import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as Logo } from "../../assets/logo_dark.svg";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { signOut } from "../../actions";
import { history, sendTrackingData, showHideToolTip } from "../../helpers";
import { connect } from "react-redux";
import {
  ListItem,
  ListItemIcon,
  Avatar,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import cardHeaderBg from "../../assets/lite_profile_bg.png";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { EVENT_TITLES, ACTIONS } from "../../constants/constants";
import ReactTranslation from "./iReact18";
import secureLocalStorage from "react-secure-storage";

const drawerWidth = 300;
const styles = (theme) => ({
  root: {
    display: "flex",
    flexGrow: 1,
    // padding: '0.6% !important'
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  autoWidth: { width: "auto !important" },
  customRightPadding: {
    padding: "16px",
  },
  appBar: {
    height: "82px",
    backgroundColor: "#F8F6EF",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.between("md", "xl")]: {
      width: "100%", //`calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      // display: 'none' //!secureLocalStorage.getItem('auth') ? 'flex' : 'none'
    },
  },
  customAppBar: {
    height: "90px",
    backgroundColor: "#ffffff",
    boxShadow:
      "0px 1px 1px -1px rgb(0 0 0 / 20%), 0px 1px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    [theme.breakpoints.between("md", "xl")]: {
      width: "100%", //`calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: "none",
      //!secureLocalStorage.getItem('auth') ? 'flex' : 'none'
    },
  },
  drawerPaper: {
    position: "relative",
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
  customToolbar: {
    padding: "0.6%",
    justifyContent: "space-between",
  },
  toolbarContent: {
    flex: "auto",
    padding: "2%",
    color: "black",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  smallPaddingLeft: {
    paddingLeft: "2% !important",
  },
  defaultPointer: {
    cursor: "default !important",
  },
  responsive: {
    maxWidth: "100% !important",
    height: "auto !important",
    width: "auto !important",
    alignSelf: "center !important",
  },
  customLink: {
    color: "#048475 !important",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  drawer: {
    [theme.breakpoints.between("md", "xl")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
    whiteSpace: "nowrap",
  },
  menuButton: {
    // marginRight: theme.spacing(2),
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
  },
  //   drawer: {
  //     width: drawerWidth,
  //     flexShrink: 0,
  //     whiteSpace: "nowrap"
  //   },
  drawerOpen: {
    // [theme.breakpoints.between("sm", "xl")]: {
    //   width: drawerWidth
    // },
    // width: theme.spacing(9) + 1,
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: "black",
    color: "white",
    // overflowX: "hidden",
    overflow: "visible",
    // marginTop: '90px'
  },
  customDialogHeader: {
    maxHeight: "38%",
    height: "38%",
    color: "#000",
    backgroundSize: "cover",
    backgroundImage: `url(${cardHeaderBg})`,
    background: "round",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.default.main,
    padding: "0px",
  },
  //   drawerClose: {
  //     transition: theme.transitions.create("width", {
  //       easing: theme.transitions.easing.sharp,
  //       duration: theme.transitions.duration.leavingScreen
  //     }),
  //     // overflowX: "hidden",
  //     overflow: "visible",
  //     width: theme.spacing(7) + 1,
  //     [theme.breakpoints.up("sm")]: {
  //       width: theme.spacing(9) + 1
  //     },
  //     backgroundColor: 'black',
  //     color: 'white',
  //   }
  signOutIcon: {
    display: "flex",
    // marginRight: '16px',
    // alignItems: 'center',
    "&:hover": {
      cursor: "pointer",
    },
  },
  userName: {
    marginRight: "16px",
    minWidth: "20em",
    [theme.breakpoints.between("xs", "sm")]: {
      minWidth: "unset",
    },
    maxWidth: "30em",
    textAlign: "right",
  },
  alternateSignInButton: {
    display: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography {...other} className={classes.customDialogHeader} style={{padding: '3%'}}>
//             {/* className={classes.root} */}
//             <Typography variant="h5" align='center'>{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
//   });

//   const DialogContent = withStyles((theme) => ({
//     root: {
//         padding: theme.spacing(2),
//         paddingLeft: theme.spacing(4),
//         paddingRight: theme.spacing(4)
//     },
//   }))(MuiDialogContent);

//   const DialogActions = withStyles((theme) => ({
//     root: {
//         margin: 0,
//         padding: theme.spacing(2),
//         backgroundColor: '#fff',
//         justifyContent: 'center !important'
//     }
//   }))(MuiDialogActions);

class PermanentDrawer extends React.Component {
  state = {
    anchor: "left",
    mobileOpen: false,
    openConfirmSigOutPopup: false,
    baseUrlDev: false,
  };

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }
  handleChange = (event) => {
    this.setState({
      anchor: event.target.value,
    });
  };

  // handleSignOutClick = async (event) => {
  //     event.preventDefault();
  //     event.stopPropagation();
  //     const { dispatch } = this.props;
  //     dispatch(loading(true));
  //     try {
  //         await Auth.signOut();
  //         secureLocalStorage.clear();
  //         dispatch(authActions.signout());
  //         history.push('/signin');
  //         dispatch(loading(false));
  //         toast.dark('Sign Out Successful');
  //         this.handleClose();
  //     } catch (error) {
  //         let err = null;
  //         !error.message ? err = {"message": error} : err = error;
  //         console.log('error signing out:', err);
  //         this.setState({
  //             errors: {
  //                 ...this.state.errors,
  //                 cognito: error
  //             }
  //         })
  //         dispatch(loading(false));
  //         toast.dark(error.message);
  //     }
  // };

  confirmSignout = () => {
    this.setState({ openConfirmSigOutPopup: true });
  };

  handleClose = () => {
    this.setState({ openConfirmSigOutPopup: false });
  };

  redirectToSignIn = () => {
    history.push("/signin");
  };

  redirectToSignUp = () => {
    history.push("/signup");
  };

  handleDrawerToggle = () => {
    this.setState((prevState) => ({ mobileOpen: !prevState.mobileOpen }));
  };

  handleSignOut = () => {
    const { dispatch } = this.props;
    dispatch(signOut(this.props));
    sendTrackingData(EVENT_TITLES.SIGN_OUT_CLICK, ACTIONS.SIGN_OUT_CLICKED);
  };

  scrollToTermsAndConditions = () => {
    window.scroll(
      0,
      this.findPosition(document.getElementById("rightSideSection"))
    );
  };

  findPosition = (obj) => {
    let currenttop = 0;
    if (obj.offsetParent) {
      do {
        currenttop += obj.offsetTop;
      } while ((obj = obj.offsetParent));
      return [currenttop];
    }
  };

  render() {
    const { classes } = this.props; //shared, window1
    const { anchor, baseUrlDev } = this.state;
    return (
      <>
        {this.props.shared?.badRequest !== true && (
          <AppBar
            position="absolute"
            color="inherit"
            // style={{height: '90px'}}
            className={classNames(classes.appBar, classes[`appBar-${anchor}`])}
          >
            <Toolbar className={classes.customToolbar}>
              {/* <IconButton className={classes.menuButton}>
                                <MenuIcon onClick={this.handleDrawerToggle} />
                            </IconButton> */}
              <div variant="subtitle1" className={classes.smallPaddingLeft}>
                {
                  <Link
                    href={
                      !secureLocalStorage.getItem("token") &&
                      this.props?.authentication?.originalUrlPath
                        ? this.props?.authentication?.originalUrlPath
                        : "/"
                    }
                  >
                    <Logo width="230" className={classes.responsive} />
                  </Link>
                }
              </div>
              {/* <select onChange={(event)=>reacti18next.changeLanguage(event.target.value)}>
                        <option value={'en'}>English</option>
                        <option value={'de'}>German</option>
                        <option value={'fr'}>French</option>
                        </select> */}
              {secureLocalStorage.getItem("token") && (
                <Typography className={classes.signOutIcon}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <ListItem
                          disableGutters
                          className={classNames(
                            classes.autoWidth,
                            classes.customRightPadding
                          )}
                        >
                          <ListItemIcon>
                            <Avatar
                              {...bindTrigger(popupState)}
                              alt={
                                this.props?.authentication?.loginInfo
                                  ?.first_name +
                                " " +
                                this.props?.authentication?.loginInfo?.last_name
                              }
                              src={
                                this.props?.basicInfo?.basicInfoData
                                  ?.profile_image !== ""
                                  ? this.props?.basicInfo?.basicInfoData
                                      ?.profile_image
                                  : "/"
                              }
                              className={classNames(classes.large)}
                            />
                          </ListItemIcon>
                        </ListItem>
                        <Menu
                          {...bindMenu(popupState)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "center",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "center",
                          }}
                          keepMounted
                          style={{ marginTop: "40px" }}
                        >
                          <MenuItem onClick={popupState.close}>
                            <Typography color="textSecondary">
                              {`${
                                this.props?.basicInfo?.basicInfoData?.first_name
                                  ? this.props?.basicInfo?.basicInfoData
                                      ?.first_name
                                  : this.props?.basicInfo?.basicInfoParsedData
                                      ?.first_name
                                  ? this.props?.basicInfo?.basicInfoParsedData
                                      ?.first_name
                                  : this.props?.authentication?.loginInfo
                                      ?.first_name
                                  ? this.props?.authentication?.loginInfo
                                      ?.first_name
                                  : ""
                              } 
                                                                     ${
                                                                       this
                                                                         .props
                                                                         ?.basicInfo
                                                                         ?.basicInfoData
                                                                         ?.last_name
                                                                         ? this
                                                                             .props
                                                                             ?.basicInfo
                                                                             ?.basicInfoData
                                                                             ?.last_name
                                                                         : this
                                                                             .props
                                                                             ?.basicInfo
                                                                             ?.basicInfoParsedData
                                                                             ?.last_name
                                                                         ? this
                                                                             .props
                                                                             ?.basicInfo
                                                                             ?.basicInfoParsedData
                                                                             ?.last_name
                                                                         : this
                                                                             .props
                                                                             ?.authentication
                                                                             ?.loginInfo
                                                                             ?.last_name
                                                                         ? this
                                                                             .props
                                                                             ?.authentication
                                                                             ?.loginInfo
                                                                             ?.last_name
                                                                         : ""
                                                                     }`}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={(event) => {
                              event.preventDefault();
                              this.handleSignOut();
                              popupState.close();
                            }}
                          >
                            <ListItemIcon>
                              <ExitToAppIcon />
                            </ListItemIcon>
                            {baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Sign_Out_Page.Sign_Out"
                                placement="left-start"
                              >
                                <Typography
                                  align="left"
                                  className={classes.textColor}
                                >
                                  <ReactTranslation
                                    contentKey={"Sign_Out_Page.Sign_Out"}
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography
                                align="left"
                                className={classes.textColor}
                              >
                                <ReactTranslation
                                  contentKey={"Sign_Out_Page.Sign_Out"}
                                />
                              </Typography>
                            )}
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </Typography>
              )}
              {!secureLocalStorage.getItem("token") && (
                <ListItem
                  disableGutters
                  style={{ paddingRight: "8px" }}
                  className={classes.autoWidth}
                >
                  <Button
                    style={{
                      float: "right",
                      marginBottom: "4%",
                      width: "6em",
                      backgroundColor: "#FBC112",
                      color: "#000000",
                    }}
                    className={classes.alternateSignInButton}
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={(event) => {
                      event.preventDefault();
                      this.scrollToTermsAndConditions();
                    }}
                  >
                    Sign In
                  </Button>
                </ListItem>
              )}
            </Toolbar>
          </AppBar>
        )}
      </>
    );
  }
}

PermanentDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    ...state,
    loggedIn: secureLocalStorage.getItem("auth") ? true : false,
  };
};
export default connect(
  mapStateToProps,
  null,
  null
)(withStyles(styles)(PermanentDrawer));
