import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Tooltip,
  Card,
  CardContent,
} from "@material-ui/core";
import ReactTranslation from "../core/iReact18";
import HelpIcon from "@material-ui/icons/Help";
import { showHideToolTip } from "../../helpers";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  supportLink: {
    border: "1px solid #FDDB76",
    backgroundColor: "#FEF5DB",
  },
  MediumTopMargin: {
    marginTop: "24px",
  },
  fontSize24Black: {
    fontSize: "24px",
    color: "#000000",
  },
  fontSize24White: {
    fontSize: "24px",
    color: "#FFFFFF",
  },
  textColor: {
    color: "#124425",
  },
  paddingRight: {
    paddingRight: "8px",
  },
  supportLinkLandingPage: {
    border: "1px solid #14131F",
    backgroundColor: "#242338",
    color: "#FFFFFF !important",
    height: "4em",
  },
  textWhite: {
    color: "#FFFFFF !important",
  },
});

class NeedHelp extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              align="left"
              className={classes.MediumTopMargin}
            >
              <Card
                className={
                  window.location.pathname === "/login" ||
                  window.location.pathname ===
                    `/job/${JSON.parse(
                      secureLocalStorage.getItem("jobName")
                    )}` ||
                  window.location.pathname ===
                    `/vip/${JSON.parse(
                      secureLocalStorage.getItem("vipJobName")
                    )}`
                    ? classes.supportLinkLandingPage
                    : classes.supportLink
                }
              >
                <CardContent align="center">
                  <a
                    href="https://support.succeedsmart.com/hc/en-us/requests/new"
                    rel="noreferrer"
                    target={"_blank"}
                    className={classes.customLink}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Support_link.Suppot_Text"
                        placement="left-end"
                      >
                        <Typography component={"span"}>
                          <Typography
                            component={"span"}
                            className={classes.paddingRight}
                          >
                            <HelpIcon
                              className={
                                window.location.pathname === "/login" ||
                                window.location.pathname ===
                                  `/job/${JSON.parse(
                                    secureLocalStorage.getItem("jobName")
                                  )}` ||
                                window.location.pathname ===
                                  `/vip/${JSON.parse(
                                    secureLocalStorage.getItem("vipJobName")
                                  )}`
                                  ? classes.fontSize24White
                                  : classes.fontSize24Black
                              }
                              align="left"
                            />
                          </Typography>
                          <Typography
                            component={"span"}
                            className={
                              window.location.pathname === "/login" ||
                              window.location.pathname ===
                                `/job/${JSON.parse(
                                  secureLocalStorage.getItem("jobName")
                                )}` ||
                              window.location.pathname ===
                                `/vip/${JSON.parse(
                                  secureLocalStorage.getItem("vipJobName")
                                )}`
                                ? classes.textWhite
                                : classes.textColor
                            }
                          >
                            <ReactTranslation
                              contentKey={"Support_link.Suppot_Text"}
                            />
                          </Typography>
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <Typography
                          component={"span"}
                          className={classes.paddingRight}
                        >
                          <HelpIcon
                            className={
                              window.location.pathname === "/login" ||
                              window.location.pathname ===
                                `/job/${JSON.parse(
                                  secureLocalStorage.getItem("jobName")
                                )}` ||
                              window.location.pathname ===
                                `/vip/${JSON.parse(
                                  secureLocalStorage.getItem("vipJobName")
                                )}`
                                ? classes.fontSize24White
                                : classes.fontSize24Black
                            }
                          />
                        </Typography>
                        <Typography
                          component={"span"}
                          className={
                            window.location.pathname === "/login" ||
                            window.location.pathname ===
                              `/job/${JSON.parse(
                                secureLocalStorage.getItem("jobName")
                              )}` ||
                            window.location.pathname ===
                              `/vip/${JSON.parse(
                                secureLocalStorage.getItem("vipJobName")
                              )}`
                              ? classes.textWhite
                              : classes.textColor
                          }
                        >
                          <ReactTranslation
                            contentKey={"Support_link.Suppot_Text"}
                          />
                        </Typography>
                      </Typography>
                    )}
                  </a>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

NeedHelp.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedNeedHelpPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(NeedHelp))
);
export { connectedNeedHelpPage as NeedHelp };
