import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  seo,
  history,
  sendTrackingData,
  showHideToolTip,
} from "../../../helpers";
import config from "../../../config/config";
import { Grid, Typography, Button, Paper, Tooltip } from "@material-ui/core";
import {
  setTitle,
  signOut,
  sharedActions,
  preferencesPage,
  preferencesActions,
} from "../../../actions";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { EVENT_TITLES, SCREENS, ACTIONS } from "../../../constants/constants";
import translationEN from "../../../locales/en/translation.json";
import ReactTranslation from "../../core/iReact18";
import { NeedHelp } from "../../core/needHelp.component";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: theme.spacing(6),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  fontSize: {
    fontSize: "1em",
  },
  pos: {
    marginBottom: 12,
  },
  moduleConfig: {
    float: "left",
    fontSize: "26px",
  },
  customCard: {
    backgroundColor: "#F7F7F7",
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 5%), 0px 0px 3px 0px rgb(0 0 0 / 0%)",
    borderTop: "7px solid #FBC112",
  },
  newCustomCard: {
    backgroundColor: "#F7F7F7",
    boxShadow:
      "0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 2px 1px 0px rgb(0 0 0 / 5%), 0px 0px 3px 0px rgb(0 0 0 / 0%)",
    borderTop: "7px solid #FBC112",
  },
  smallBottomMargin: {
    marginBottom: "20px",
  },
  smallTopMargin: {
    marginTop: "40px",
  },
  verySmallBottomMargin: {
    marginBottom: "10px",
  },
  verySmallTopMargin: {
    marginTop: "10px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  customDivider: {
    color: "#E8E8E8",
    width: "100%",
    marginTop: "2%",
    marginBottom: "2%",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  customAlignment: {
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
    textAlign: "left",
  },
  customPaper: {
    boxShadow: "none",
    padding: "30px",
    height: "100%",
    overflowWrap: "anywhere",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  sideBlock: {
    padding: theme.spacing(1),
  },
  sideDetails: {
    minHeight: "65px",
    maxHeight: "110px",
    fontSize: "0.85rem",
  },
  sideIcon: {
    with: "52px !important",
  },
  fullHeight: {
    height: "100%",
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  onboardingButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
  },
  floatLeft: {
    float: "left !important",
    textAlign: "left !important",
  },
  clearLeft: {
    clear: "left !important",
  },
  completeButton: {
    paddingRight: "16px",
    paddingLeft: "16px",
  },
});

class OnboardingCompleted extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    shareWithCompanies: "false",
    diversity: "false",
    isShareWithCompaniesRequired: false,
    isDiversityRequired: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      setTitle(`${translationEN.PageTitle.Onboarding_Complete_Page_Title}`)
    );
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    dispatch(preferencesActions.getJobRoleExpectation());
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.ONBOARDING_COMPLETE_PAGE_LOAD,
      SCREENS.ONBOARDING_COMPLETE_SCREEN,
      ACTIONS.ONBOARDING_COMPLETE_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
    if (
      prevProps?.onboarding?.onboardingStatus !==
      this.props?.onboarding?.onboardingStatus
    ) {
      let userInfo = {
        userId: this.props?.basicInfo?.basicInfoData?.candidate_id
          ? this.props?.basicInfo?.basicInfoData?.candidate_id
          : this.props?.authentication?.loginInfo?.candidate_id,
        firstName: this.props?.basicInfo?.basicInfoData?.first_name
          ? this.props?.basicInfo?.basicInfoData?.first_name
          : this.props?.authentication?.loginInfo?.first_name,
        lastName: this.props?.basicInfo?.basicInfoData?.last_name
          ? this.props?.basicInfo?.basicInfoData?.last_name
          : this.props?.authentication?.loginInfo?.last_name,
        email: this.props?.basicInfo?.basicInfoData?.email
          ? this.props?.basicInfo?.basicInfoData?.email
          : this.props?.authentication?.loginInfo?.email,
        linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
          ? this.props?.basicInfo?.basicInfoData?.linkedin_url
          : this.props?.authentication?.loginInfo?.linkedin_url,
        phone: this.props?.basicInfo?.basicInfoData?.contact
          ? this.props?.basicInfo?.basicInfoData?.contact
          : this.props?.authentication?.loginInfo?.phone,
        onboardingStatus: this.props?.onboarding?.onboardingStatus,
      };
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.ONBOARDING_COMPLETE_PAGE_LOAD,
        SCREENS.ONBOARDING_COMPLETE_SCREEN,
        ACTIONS.ONBOARDING_COMPLETE_PAGE_LOADED,
        userInfo
      );
    }
  }

  handleContinueClick = () => {
    const { dispatch } = this.props;
    let isProduction = config.baseUrl === "https://platform.succeedsmart.com/";
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.ONBOARDING_COMPLETE_CONTINUE_CLICK,
      SCREENS.ONBOARDING_COMPLETE_SCREEN,
      ACTIONS.ONBOARDING_COMPLETE_CONTINUE_CLICKED
    );
    dispatch(signOut(this.props));
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      if (isProduction) {
        window.location.href = "https://succeedsmart.page.link/install";
      } else {
        window.location.href = "https://succeedsmartdev.page.link/devInstall";
      }
    } else {
      let parameters = `?environment=${this.props.authentication?.environment}&key=${this.props.authentication?.key}`;
      if (isProduction) {
        window.location.href = `https://member.succeedsmart.com/${parameters}`;
      } else {
        window.location.href = `https://dev-member.succeedsmart.com/${parameters}`;
      }
    }
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(preferencesPage(4));
    dispatch(sharedActions.setProgressbarValue(93.75));
    dispatch(sharedActions.handlePrivousStep(this.props?.shared?.activeStep));
    history.push("/onboarding/preferences-jobrole");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.ONBOARDING_COMPLETE_PREVIOUS_CLICK,
      SCREENS.ONBOARDING_COMPLETE_SCREEN,
      ACTIONS.ONBOARDING_COMPLETE_PREVIOUS_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <main>
          <Grid container>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Paper square elevation={0} className={classes.resetContainer}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="On_BoradingComplete_Page.Heading_1"
                    placement="left-end"
                  >
                    <Typography variant="h5" align="left">
                      <ReactTranslation
                        contentKey={"On_BoradingComplete_Page.Heading_1"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="h5" align="left">
                    <ReactTranslation
                      contentKey={"On_BoradingComplete_Page.Heading_1"}
                    />
                  </Typography>
                )}
                {this.props?.authentication?.jodDetailInfo?.type === "job" ? (
                  <Typography
                    align="left"
                    className={classes.smallTopMargin}
                    variant="h6"
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="On_BoradingComplete_Page.Content_1"
                        placement="left-end"
                      >
                        <b>
                          {" "}
                          {
                            <ReactTranslation
                              contentKey={"On_BoradingComplete_Page.Content_1"}
                            />
                          }
                        </b>
                      </Tooltip>
                    ) : (
                      <b>
                        {" "}
                        {
                          <ReactTranslation
                            contentKey={"On_BoradingComplete_Page.Content_1"}
                          />
                        }
                      </b>
                    )}{" "}
                    &nbsp;
                    <Typography className={classes.fontSize}>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="On_BoradingComplete_Page.Content_2"
                          placement="left-end"
                        >
                          <Typography component={"span"}>
                            {" "}
                            {
                              <ReactTranslation
                                contentKey={
                                  "On_BoradingComplete_Page.Content_2"
                                }
                              />
                            }
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          {" "}
                          {
                            <ReactTranslation
                              contentKey={"On_BoradingComplete_Page.Content_2"}
                            />
                          }
                        </Typography>
                      )}
                    </Typography>
                    <Typography
                      align="left"
                      className={classes.smallTopMargin}
                      style={{ display: "flex", gap: "16px" }}
                    >
                      <CheckCircleIcon style={{ color: "#008000" }} />
                      <Typography
                        className={classNames(
                          classes.floatLeft,
                          classes.clearLeft
                        )}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.authentication.jodDetailInfo
                              ?.title?.rendered
                              ? this.props.authentication.jodDetailInfo?.title
                                  ?.rendered
                              : "We’ll keep you posted regarding any updates to your job submission.",
                          }}
                        ></div>
                      </Typography>
                    </Typography>
                  </Typography>
                ) : (
                  <Typography
                    align="left"
                    className={classes.smallTopMargin}
                    variant="h6"
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="On_BoradingComplete_Page.Content_1"
                        placement="left-end"
                      >
                        <b>
                          {" "}
                          {
                            <ReactTranslation
                              contentKey={"On_BoradingComplete_Page.Content_1"}
                            />
                          }
                        </b>
                      </Tooltip>
                    ) : (
                      <b>
                        {" "}
                        {
                          <ReactTranslation
                            contentKey={"On_BoradingComplete_Page.Content_1"}
                          />
                        }
                      </b>
                    )}{" "}
                    &nbsp;
                  </Typography>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  <div
                    className={classNames(
                      classes.smallTopMargin,
                      classes.onboardingButton
                    )}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Buttons.Previous"
                        placement="bottom"
                      >
                        <Button
                          id="onboardingPreviousButton"
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={(event) => {
                            event.preventDefault();
                            this.handlePrevious();
                          }}
                        >
                          <ReactTranslation contentKey={"Buttons.Previous"} />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        id="onboardingPreviousButton"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    )}
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Buttons.Continue_Member_Portal"
                        placement="bottom"
                      >
                        <Button
                          id="onboardingCompletedButton"
                          variant="contained"
                          color="primary"
                          className={classes.completeButton}
                          disableElevation
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleContinueClick();
                          }}
                        >
                          <ReactTranslation
                            contentKey={"Buttons.Continue_Member_Portal"}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        id="onboardingCompletedButton"
                        variant="contained"
                        color="primary"
                        className={classes.completeButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleContinueClick();
                        }}
                      >
                        <ReactTranslation
                          contentKey={"Buttons.Continue_Member_Portal"}
                        />
                      </Button>
                    )}
                  </div>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} align="end">
              {/* Need help component */}
              <NeedHelp />
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

OnboardingCompleted.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedOnboardingCompletedPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(OnboardingCompleted))
);
export { connectedOnboardingCompletedPage as OnboardingCompleted };
