import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardMedia,
  Dialog,
  IconButton,
  Collapse,
  Tooltip,
  Avatar,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import LinkedInImage from "../../../../assets/Linkedin.png";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import clsx from "clsx";
import videoImage from "../../../../assets/recorderImage.svg";
import ReactTranslation from "../../../core/iReact18";
import diversityImage from "../../../../assets/diversityImage.png";
import { showHideToolTip } from "../../../../helpers";
import companyLogo from "../../../../assets/sample_logo.png";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },

  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  media: {
    height: "100px",
    cursor: "pointer",
  },
  smallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  smallTopMargin: {
    marginTop: "16px",
  },
  mediumTopMargin: {
    marginTop: "32px",
  },
  linkedInImage: {
    width: "100%",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main,
  },
  helpTextCardForMobile: {
    display: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  helpTextCard: {
    display: "block",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  fontSize1em: {
    fontSize: "1em",
  },
  orderListItem: {
    textAlign: "initial",
    paddingLeft: "16px",
  },
  dialogTilte: {
    textAlign: "center",
    color: "#000000",
    fontWeight: "bold",
  },
  large: {
    width: "64px",
    height: "64px",
  },
  customCompanyLogoStep: {
    paddingLeft: "16px",
    marginTop: 0,
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6" color="primary" className={classes.dialogTilte}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

class HelpTextCard extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    expandView: false,
    expanded: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }
  handleExpandClick = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded }));
  };

  handleHideCard = () => {
    this.props.linkedInUrlHelpTextHide();
  };

  handleExpandContent = () => {
    this.setState({ expandView: true });
  };

  handleCloseExpandView = () => {
    this.setState({ expandView: false });
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              id="customCardAnimation"
            >
              <Card className={classes.helpTextCardForMobile}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "16px",
                    textAlign: "left",
                  }}
                >
                  <Typography style={{ display: "flex", alignItems: "center" }}>
                    <b>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.Help_Heading"
                          placement="left-end"
                        >
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={"Help_card_content.Help_Heading"}
                              />
                              :
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography>
                          <b>
                            <ReactTranslation
                              contentKey={"Help_card_content.Help_Heading"}
                            />
                            :
                          </b>
                        </Typography>
                      )}
                    </b>
                    &nbsp;
                    <HelpOutlineIcon style={{ fontSize: "larger" }} />
                  </Typography>
                  <Tooltip
                    arrow
                    placement="bottom"
                    title={this.state.expanded ? "Collapse" : "Expand"}
                  >
                    <IconButton
                      size="small"
                      style={{ backgroundColor: "#000000", color: "#FFFFFF" }}
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: this.state.expanded,
                      })}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleExpandClick();
                      }}
                      aria-expanded={this.state.expanded}
                      aria-label="show more"
                    >
                      <ExpandMoreIcon />
                    </IconButton>
                  </Tooltip>
                </div>
                <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
                  <CardContent>
                    {this.props.helpTextCardType === "basicInfoStep1" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                            placement="left-end"
                          >
                            <Typography>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                            placement="left-end"
                          >
                            <Typography
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                              }
                            />
                          </Typography>
                        )}
                      </Typography>
                    )}
                    {this.props.helpTextCardType === "basicInfoStep2" && (
                      <Typography align="left" style={{ fontSize: "14px" }}>
                        {/* <Typography><b>{"Sharing your LinkedIn profile URL gives you the opportunity to include your work history and education within your SucceedSmart profile."}</b></Typography> */}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_2_HelpText.Title"
                            placement="left-end"
                          >
                            <Typography>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.Title"
                                  }
                                />
                                :
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.Title"
                                }
                              />
                              :
                            </b>
                          </Typography>
                        )}
                        <ol className={classes.orderListItem}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.basicInfo_Step_2_HelpText.Title"
                              placement="left-end"
                            >
                              <li
                                align="initiall"
                                className={classes.fontSize1em}
                              >
                                <a
                                  href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                                  rel="noreferrer"
                                  target={"_blank"}
                                  className={classes.customLink}
                                >
                                  {baseUrlDev ? (
                                    <Tooltip
                                      arrow
                                      title="Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                      placement="left-end"
                                    >
                                      <Typography component={"span"}>
                                        <ReactTranslation
                                          contentKey={
                                            "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                          }
                                        />
                                      </Typography>
                                    </Tooltip>
                                  ) : (
                                    <ReactTranslation
                                      contentKey={
                                        "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                      }
                                    />
                                  )}
                                </a>{" "}
                                &nbsp;
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.To_go_to_your_profile_page"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              align="initiall"
                              className={classes.fontSize1em}
                            >
                              <a
                                href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                                rel="noreferrer"
                                target={"_blank"}
                                className={classes.customLink}
                              >
                                {baseUrlDev ? (
                                  <Tooltip
                                    arrow
                                    title="Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                    placement="left-end"
                                  >
                                    <Typography component={"span"}>
                                      <ReactTranslation
                                        contentKey={
                                          "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                    }
                                  />
                                )}
                              </a>{" "}
                              &nbsp;
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.To_go_to_your_profile_page"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                              placement="left-end"
                            >
                              <li className={classes.fontSize1em}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                              placement="left-end"
                            >
                              <li className={classes.fontSize1em}>
                                {
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                                    }
                                  />
                                }
                              </li>
                            </Tooltip>
                          ) : (
                            <li className={classes.fontSize1em}>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                                  }
                                />
                              }
                            </li>
                          )}
                        </ol>
                        <CardMedia
                          className={classNames(
                            classes.media,
                            classes.smallBottomMargin
                          )}
                          image={LinkedInImage}
                          title="Paella dish"
                          onClick={() => {
                            this.handleExpandContent();
                          }}
                        />
                      </Typography>
                    )}
                    {this.props.helpTextCardType === "professionalHelpText" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                            placement="left-end"
                          >
                            <Typography>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.professionalHelpText.Providing_this_information"
                            placement="left-end"
                          >
                            <Typography
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Providing_this_information"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.professionalHelpText.Providing_this_information"
                              }
                            />
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.professionalHelpText.Company_Logo_Details"
                            placement="left-end"
                          >
                            <Typography
                              className={classNames(
                                classes.verySmallTopMargin,
                                classes.verySmallBottomMargin
                              )}
                            >
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Company_Logo_Details"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classNames(
                              classes.verySmallTopMargin,
                              classes.verySmallBottomMargin
                            )}
                          >
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Company_Logo_Details"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        <ul className={classes.customCompanyLogoStep}>
                          <li className={classes.textAlignLeft}>
                            {baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Help_card_content.professionalHelpText.Company_Logo_Des_1"
                                placement="left-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.professionalHelpText.Company_Logo_Des_1"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Company_Logo_Des_1"
                                  }
                                />
                              </Typography>
                            )}
                          </li>
                          <li className={classes.textAlignLeft}>
                            {baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Help_card_content.professionalHelpText.Company_Logo_Des_2"
                                placement="left-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.professionalHelpText.Company_Logo_Des_2"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Company_Logo_Des_2"
                                  }
                                />
                              </Typography>
                            )}
                          </li>
                        </ul>
                        <Typography className={classes.verySmallBottomMargin}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.professionalHelpText.Sample_Logo"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Sample_Logo"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Sample_Logo"
                                }
                              />
                            </Typography>
                          )}
                        </Typography>
                        <Avatar
                          alt={"company logo"}
                          src={companyLogo}
                          className={classNames(classes.large)}
                        />
                      </Typography>
                    )}
                    {this.props.helpTextCardType ===
                      "accomplishmentHelpText" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Typography>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                              placement="left-end"
                            >
                              <Typography className={classes.smallTopMargin}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                              placement="left-end"
                            >
                              <Typography className={classes.smallTopMargin}>
                                <b>
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Examples_of_Quantitative_Accomplishments"
                                    }
                                  />
                                </b>
                              </Typography>
                            </Tooltip>
                            <ul className={classes.orderListItem}>
                              <Tooltip
                                arrow
                                title="Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                                placement="left-end"
                              >
                                <li
                                  className={classNames(
                                    classes.smallTopMargin,
                                    classes.fontSize1em
                                  )}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                                    }
                                  />
                                </li>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title="Help_card_content.accomplishmentHelpText.Played_major_role"
                                placement="left-end"
                              >
                                <li
                                  className={classNames(
                                    classes.smallTopMargin,
                                    classes.fontSize1em
                                  )}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Played_major_role"
                                    }
                                  />
                                </li>
                              </Tooltip>
                            </ul>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                              placement="left-end"
                            >
                              <Typography className={classes.smallTopMargin}>
                                <b>
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                                    }
                                  />
                                </b>
                              </Typography>
                            </Tooltip>
                            <ul className={classes.orderListItem}>
                              <Tooltip
                                arrow
                                title="Help_card_content.accomplishmentHelpText.Launched_the_organization"
                                placement="left-end"
                              >
                                <li
                                  className={classNames(
                                    classes.smallTopMargin,
                                    classes.fontSize1em
                                  )}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Launched_the_organization"
                                    }
                                  />
                                </li>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title="Help_card_content.accomplishmentHelpText.Served_key_member"
                                placement="left-end"
                              >
                                <li
                                  className={classNames(
                                    classes.smallTopMargin,
                                    classes.fontSize1em
                                  )}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.accomplishmentHelpText.Served_key_member"
                                    }
                                  />
                                </li>
                              </Tooltip>
                            </ul>
                          </Typography>
                        ) : (
                          <Typography>
                            <Typography className={classes.smallTopMargin}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                                }
                              />
                            </Typography>
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Examples_of_Quantitative_Accomplishments"
                                  }
                                />
                              </b>
                            </Typography>
                            <ul className={classes.orderListItem}>
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                                  }
                                />
                              </li>
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Played_major_role"
                                  }
                                />
                              </li>
                            </ul>
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                                  }
                                />
                              </b>
                            </Typography>
                            <ul className={classes.orderListItem}>
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Launched_the_organization"
                                  }
                                />
                              </li>
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Served_key_member"
                                  }
                                />
                              </li>
                            </ul>
                          </Typography>
                        )}
                      </Typography>
                    )}
                    {this.props.helpTextCardType === "competenciesHelpText" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.What_add_competencies"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.competenciesHelpText.What_add_competencies"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.What_add_competencies"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.Please_provide_competencies"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Please_provide_competencies"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.Please_provide_competencies"
                              }
                            />
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.This_is_great_opportunity"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.competenciesHelpText.This_is_great_opportunity"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.This_is_great_opportunity"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        <ul className={classes.orderListItem}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.competenciesHelpText.Strategic_Planning"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.competenciesHelpText.Strategic_Planning"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Strategic_Planning"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.competenciesHelpText.Execution"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.competenciesHelpText.Execution"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Execution"
                                }
                              />
                            </li>
                          )}
                        </ul>
                      </Typography>
                    )}
                    {this.props.helpTextCardType ===
                      "coffeequestionsHelpText" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.coffeequestionHelpText.Coffee_question"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                {" "}
                                {
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.coffeequestionHelpText.Coffee_question"
                                    }
                                  />
                                }
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              {" "}
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.coffeequestionHelpText.Coffee_question"
                                  }
                                />
                              }
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                                  }
                                />
                              }
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            {
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                                }
                              />
                            }
                          </Typography>
                        )}
                      </Typography>
                    )}
                    {this.props.helpTextCardType === "videoHelpText" && (
                      <Typography align="left">
                        <Typography className={classes.smallTopMargin}>
                          <img
                            src={videoImage}
                            alt="videoRecordImage"
                            className={classes.linkedInImage}
                          />
                        </Typography>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.videoHelpText.videoHelpText_Heading"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                {" "}
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.videoHelpText.videoHelpText_Heading"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_Heading"
                                }
                              />{" "}
                            </b>
                          </Typography>
                        )}
                        <ul className={classes.orderListItem}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.videoHelpText.videoHelpText_List_1"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.videoHelpText.videoHelpText_List_1"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_1"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.videoHelpText.videoHelpText_List_2"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.videoHelpText.videoHelpText_List_2"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_2"
                                }
                              />
                            </li>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.videoHelpText.videoHelpText_List_3"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.videoHelpText.videoHelpText_List_3"
                                  }
                                />
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_3"
                                }
                              />
                            </li>
                          )}
                        </ul>
                      </Typography>
                    )}
                    {this.props.helpTextCardType === "diversityHelpText" && (
                      <Typography align="left">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.diversityHelptText.diversity_Content_1"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_Content_1"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.smallTopMargin}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.diversityHelptText.diversity_Content_1"
                              }
                            />
                          </Typography>
                        )}
                        <Avatar
                          alt={"diversityImg"}
                          src={diversityImage}
                          className={classNames(
                            classes.large,
                            classes.mediumTopMargin
                          )}
                        />
                        <Typography>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.diversityHelptText.diversity_candidate_name"
                              placement="left-end"
                            >
                              <Typography
                                className={classNames(
                                  classes.verySmallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <b>
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.diversityHelptText.diversity_candidate_name"
                                    }
                                  />
                                </b>
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              className={classNames(
                                classes.verySmallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.diversityHelptText.diversity_candidate_name"
                                  }
                                />
                              </b>
                            </Typography>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.diversityHelptText.diversity_Content_2"
                              placement="left-end"
                            >
                              <Typography className={classes.fontSize1em}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.diversityHelptText.diversity_Content_2"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_Content_2"
                                }
                              />
                            </Typography>
                          )}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.diversityHelptText.diversity_Content_3"
                              placement="left-end"
                            >
                              <Typography className={classes.fontSize1em}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.diversityHelptText.diversity_Content_3"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_Content_3"
                                }
                              />
                            </Typography>
                          )}
                        </Typography>
                      </Typography>
                    )}
                  </CardContent>
                </Collapse>
              </Card>
              <Card className={classes.helpTextCard}>
                <CardContent>
                  {this.props.helpTextCardType === "basicInfoStep1" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                          placement="left-end"
                        >
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_1_HelpText.Why_we_need_your_resume"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                          placement="left-end"
                        >
                          <Typography
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          className={classNames(
                            classes.smallTopMargin,
                            classes.fontSize1em
                          )}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.basicInfo_Step_1_HelpText.Your_time_is_valuable"
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "basicInfoStep2" && (
                    <Typography align="left" style={{ fontSize: "14px" }}>
                      {/* <Typography><b>{"Sharing your LinkedIn profile URL gives you the opportunity to include your work history and education within your SucceedSmart profile."}</b></Typography> */}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.basicInfo_Step_2_HelpText.Title"
                          placement="left-end"
                        >
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.Title"
                                }
                              />
                              :
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_2_HelpText.Title"
                              }
                            />
                            :
                          </b>
                        </Typography>
                      )}
                      <ol className={classes.orderListItem}>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_2_HelpText.Title"
                            placement="left-end"
                          >
                            <li
                              align="initiall"
                              className={classes.fontSize1em}
                            >
                              <a
                                href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                                rel="noreferrer"
                                target={"_blank"}
                                className={classes.customLink}
                              >
                                {baseUrlDev ? (
                                  <Tooltip
                                    arrow
                                    title="Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                    placement="left-end"
                                  >
                                    <Typography component={"span"}>
                                      <ReactTranslation
                                        contentKey={
                                          "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <ReactTranslation
                                    contentKey={
                                      "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                    }
                                  />
                                )}
                              </a>{" "}
                              &nbsp;
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.To_go_to_your_profile_page"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li align="initiall" className={classes.fontSize1em}>
                            <a
                              href="https://www.linkedin.com/public-profile/settings?trk=d_flagship3_profile_self_view_public_profile"
                              rel="noreferrer"
                              target={"_blank"}
                              className={classes.customLink}
                            >
                              {baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                  placement="left-end"
                                >
                                  <Typography component={"span"}>
                                    <ReactTranslation
                                      contentKey={
                                        "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.Click_on_this_link"
                                  }
                                />
                              )}
                            </a>{" "}
                            &nbsp;
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_2_HelpText.To_go_to_your_profile_page"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                            placement="left-end"
                          >
                            <li className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li className={classes.fontSize1em}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.basicInfo_Step_2_HelpText.Copy_link_section_highlighted_below"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                            placement="left-end"
                          >
                            <li className={classes.fontSize1em}>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                                  }
                                />
                              }
                            </li>
                          </Tooltip>
                        ) : (
                          <li className={classes.fontSize1em}>
                            {
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.basicInfo_Step_2_HelpText.Return_SucceedSmart_paste_link"
                                }
                              />
                            }
                          </li>
                        )}
                      </ol>
                      <CardMedia
                        className={classNames(
                          classes.media,
                          classes.smallBottomMargin
                        )}
                        image={LinkedInImage}
                        title="Paella dish"
                        onClick={() => {
                          this.handleExpandContent();
                        }}
                      />
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "professionalHelpText" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                          placement="left-end"
                        >
                          <Typography>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.professionalHelpText.Why_we_need_your_professional_information"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.professionalHelpText.Providing_this_information"
                          placement="left-end"
                        >
                          <Typography
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.professionalHelpText.Providing_this_information"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          className={classNames(
                            classes.smallTopMargin,
                            classes.fontSize1em
                          )}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.professionalHelpText.Providing_this_information"
                            }
                          />
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.professionalHelpText.Company_Logo_Details"
                          placement="left-end"
                        >
                          <Typography
                            className={classNames(
                              classes.verySmallTopMargin,
                              classes.verySmallBottomMargin
                            )}
                          >
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Company_Logo_Details"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          className={classNames(
                            classes.verySmallTopMargin,
                            classes.verySmallBottomMargin
                          )}
                        >
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.professionalHelpText.Company_Logo_Details"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      <ul className={classes.customCompanyLogoStep}>
                        <li className={classes.textAlignLeft}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.professionalHelpText.Company_Logo_Des_1"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Company_Logo_Des_1"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Company_Logo_Des_1"
                                }
                              />
                            </Typography>
                          )}
                        </li>
                        <li className={classes.textAlignLeft}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Help_card_content.professionalHelpText.Company_Logo_Des_2"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.professionalHelpText.Company_Logo_Des_2"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Company_Logo_Des_2"
                                }
                              />
                            </Typography>
                          )}
                        </li>
                      </ul>
                      <Typography className={classes.verySmallBottomMargin}>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.professionalHelpText.Sample_Logo"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.professionalHelpText.Sample_Logo"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.professionalHelpText.Sample_Logo"
                              }
                            />
                          </Typography>
                        )}
                      </Typography>
                      <Avatar
                        alt={"company logo"}
                        src={companyLogo}
                        className={classNames(classes.large)}
                      />
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "accomplishmentHelpText" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.accomplishmentHelpText.What_add_accomplishments"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Typography>
                          <Tooltip
                            arrow
                            title="Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                                }
                              />
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            arrow
                            title="Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Examples_of_Quantitative_Accomplishments"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                          <ul className={classes.orderListItem}>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                                  }
                                />
                              </li>
                            </Tooltip>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Played_major_role"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Played_major_role"
                                  }
                                />
                              </li>
                            </Tooltip>
                          </ul>
                          <Tooltip
                            arrow
                            title="Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                            placement="left-end"
                          >
                            <Typography className={classes.smallTopMargin}>
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                          <ul className={classes.orderListItem}>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Launched_the_organization"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Launched_the_organization"
                                  }
                                />
                              </li>
                            </Tooltip>
                            <Tooltip
                              arrow
                              title="Help_card_content.accomplishmentHelpText.Served_key_member"
                              placement="left-end"
                            >
                              <li
                                className={classNames(
                                  classes.smallTopMargin,
                                  classes.fontSize1em
                                )}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.accomplishmentHelpText.Served_key_member"
                                  }
                                />
                              </li>
                            </Tooltip>
                          </ul>
                        </Typography>
                      ) : (
                        <Typography>
                          <Typography className={classes.smallTopMargin}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.accomplishmentHelpText.Provide_minimum_accomplishments"
                              }
                            />
                          </Typography>
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Examples_of_Quantitative_Accomplishments"
                                }
                              />
                            </b>
                          </Typography>
                          <ul className={classes.orderListItem}>
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Achieved_turnaround_struggling"
                                }
                              />
                            </li>
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Played_major_role"
                                }
                              />
                            </li>
                          </ul>
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Examples_Qualitative_Accomplishments"
                                }
                              />
                            </b>
                          </Typography>
                          <ul className={classes.orderListItem}>
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Launched_the_organization"
                                }
                              />
                            </li>
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.accomplishmentHelpText.Served_key_member"
                                }
                              />
                            </li>
                          </ul>
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "competenciesHelpText" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.competenciesHelpText.What_add_competencies"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.What_add_competencies"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.What_add_competencies"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.competenciesHelpText.Please_provide_competencies"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.Please_provide_competencies"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.competenciesHelpText.Please_provide_competencies"
                            }
                          />
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.competenciesHelpText.This_is_great_opportunity"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.This_is_great_opportunity"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.This_is_great_opportunity"
                              }
                            />
                          </b>
                        </Typography>
                      )}
                      <ul className={classes.orderListItem}>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.Cross-Functional_Collaboration"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.Strategic_Planning"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Strategic_Planning"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.Strategic_Planning"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.competenciesHelpText.Execution"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.competenciesHelpText.Execution"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.competenciesHelpText.Execution"
                              }
                            />
                          </li>
                        )}
                      </ul>
                    </Typography>
                  )}
                  {this.props.helpTextCardType ===
                    "coffeequestionsHelpText" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.coffeequestionHelpText.Coffee_question"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              {" "}
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.coffeequestionHelpText.Coffee_question"
                                  }
                                />
                              }
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <b>
                            {" "}
                            {
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.coffeequestionHelpText.Coffee_question"
                                }
                              />
                            }
                          </b>
                        </Typography>
                      )}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            {
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                                }
                              />
                            }
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          {
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.coffeequestionHelpText.Member_Approch_Problems"
                              }
                            />
                          }
                        </Typography>
                      )}
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "videoHelpText" && (
                    <Typography align="left">
                      <Typography className={classes.smallTopMargin}>
                        <img
                          src={videoImage}
                          alt="videoRecordImage"
                          className={classes.linkedInImage}
                        />
                      </Typography>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.videoHelpText.videoHelpText_Heading"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <b>
                              {" "}
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_Heading"
                                }
                              />
                            </b>
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <b>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.videoHelpText.videoHelpText_Heading"
                              }
                            />{" "}
                          </b>
                        </Typography>
                      )}
                      <ul className={classes.orderListItem}>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.videoHelpText.videoHelpText_List_1"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_1"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.videoHelpText.videoHelpText_List_1"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.videoHelpText.videoHelpText_List_2"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_2"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.videoHelpText.videoHelpText_List_2"
                              }
                            />
                          </li>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.videoHelpText.videoHelpText_List_3"
                            placement="left-end"
                          >
                            <li
                              className={classNames(
                                classes.smallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.videoHelpText.videoHelpText_List_3"
                                }
                              />
                            </li>
                          </Tooltip>
                        ) : (
                          <li
                            className={classNames(
                              classes.smallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.videoHelpText.videoHelpText_List_3"
                              }
                            />
                          </li>
                        )}
                      </ul>
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "salaryHelpText" && (
                    <Typography align="left">
                      <Typography className={classes.smallTopMargin}>
                        <b> {"Salary Expectation"}</b>
                      </Typography>
                      <Typography className={classes.smallTopMargin}>
                        {
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse."
                        }
                      </Typography>
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "workEnvHelpText" && (
                    <Typography align="left">
                      <Typography className={classes.smallTopMargin}>
                        <b> {"Work Environment"}</b>
                      </Typography>
                      <Typography className={classes.smallTopMargin}>
                        {
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse."
                        }
                      </Typography>
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "diversityHelpText" && (
                    <Typography align="left">
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.diversityHelptText.diversity_Content_1"
                          placement="left-end"
                        >
                          <Typography className={classes.smallTopMargin}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.diversityHelptText.diversity_Content_1"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography className={classes.smallTopMargin}>
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.diversityHelptText.diversity_Content_1"
                            }
                          />
                        </Typography>
                      )}
                      <Avatar
                        alt={"diversityImg"}
                        src={diversityImage}
                        className={classNames(
                          classes.large,
                          classes.mediumTopMargin
                        )}
                      />
                      <Typography>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.diversityHelptText.diversity_candidate_name"
                            placement="left-end"
                          >
                            <Typography
                              className={classNames(
                                classes.verySmallTopMargin,
                                classes.fontSize1em
                              )}
                            >
                              <b>
                                <ReactTranslation
                                  contentKey={
                                    "Help_card_content.diversityHelptText.diversity_candidate_name"
                                  }
                                />
                              </b>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classNames(
                              classes.verySmallTopMargin,
                              classes.fontSize1em
                            )}
                          >
                            <b>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_candidate_name"
                                }
                              />
                            </b>
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.diversityHelptText.diversity_Content_2"
                            placement="left-end"
                          >
                            <Typography className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_Content_2"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.fontSize1em}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.diversityHelptText.diversity_Content_2"
                              }
                            />
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Help_card_content.diversityHelptText.diversity_Content_3"
                            placement="left-end"
                          >
                            <Typography className={classes.fontSize1em}>
                              <ReactTranslation
                                contentKey={
                                  "Help_card_content.diversityHelptText.diversity_Content_3"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography className={classes.fontSize1em}>
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.diversityHelptText.diversity_Content_3"
                              }
                            />
                          </Typography>
                        )}
                      </Typography>
                    </Typography>
                  )}
                  {this.props.helpTextCardType === "jobRoleHelpText" && (
                    <Typography align="left">
                      <Typography className={classes.smallTopMargin}>
                        <b> {"Job Role"}</b>
                      </Typography>
                      <Typography className={classes.smallTopMargin}>
                        {
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fames pretium tristique at augue ornare nibh habitasse."
                        }
                      </Typography>
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* LinkedIn Popup */}
          <Dialog
            maxWidth="lg"
            open={this.state.expandView}
            onClose={() => {
              this.handleCloseExpandView();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.handleCloseExpandView();
              }}
            >
              {baseUrlDev ? (
                <Tooltip
                  title="Dialog.Basic_Info_Page.LinkedIn_Dialog.Help"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"span"}
                    className={classes.dialogTilte}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Basic_Info_Page.LinkedIn_Dialog.Help"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Basic_Info_Page.LinkedIn_Dialog.Help"}
                />
              )}
            </DialogTitle>
            <DialogContent>
              <img
                src={LinkedInImage}
                alt="LinkedInImage"
                className={classes.linkedInImage}
              />
            </DialogContent>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

HelpTextCard.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedHelpTextCard = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(HelpTextCard))
);
export { connectedHelpTextCard as HelpTextCard };
