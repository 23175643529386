import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers";
import secureLocalStorage from "react-secure-storage";

function saveTosecureLocalStorage(state) {
  try {
    const serializedState = JSON.stringify(state);
    secureLocalStorage.setItem("state", serializedState);
  } catch (e) {
    console.log(e);
  }
}

function loadFromsecureLocalStorage() {
  try {
    const serializedState = secureLocalStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log(e);
    return undefined;
  }
}

const persistedState = loadFromsecureLocalStorage();

const middleware = [thunk];

const store = createStore(
  rootReducer,
  persistedState,
  composeWithDevTools(applyMiddleware(...middleware))
);

store.subscribe(() => saveTosecureLocalStorage(store.getState()));

export default store;
