import { React, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Typography, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import ReactTranslation from "./iReact18";
import { showHideToolTip } from "../../helpers";

const styles = () => ({
  fullWidth: {
    width: "100%",
  },
});

class BOAModule extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    acceptTermAndConditions: true,
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }
  render() {
    const { classes } = this.props;
    return (
      <Typography variant="body2" className={classes.fullWidth}>
        <div class="OurAdvisoryBoardAni" style={{ backgroundColor: "#FFFFFF" }}>
          <div class="container" style={{ width: "100%" }}>
            <div class="row">
              <div class="col-xs-12 col-sm-10 offset-sm-1">
                {this.state.baseUrlDev ? (
                  <Tooltip arrow title="Login_Page.our_advisory_board">
                    <h2>
                      <ReactTranslation
                        contentKey={"Login_Page.our_advisory_board"}
                      />
                    </h2>
                  </Tooltip>
                ) : (
                  <h2>
                    <ReactTranslation
                      contentKey={"Login_Page.our_advisory_board"}
                    />
                  </h2>
                )}

                <div class="OABAnimation">
                  <div class="OurAdvisory owl-carousel">
                    <div class="EachAdvisory">
                      <img
                        width="350"
                        height="350"
                        src="https://succeedsmart.com/wp-content/uploads/2022/06/josh-bersin.png"
                        alt="Josh Bersin, Global Industry Analyst CEO, The Josh Bersin Company"
                      />
                      <h4>Josh Bersin</h4>
                      <p>
                        Global Industry Analyst <br />
                        CEO, The Josh Bersin Company
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="245"
                        height="245"
                        src="https://succeedsmart.com/wp-content/uploads/2022/06/kathy-waller.png"
                        alt="Kathy Waller"
                      />
                      <h4>Kathy Waller</h4>
                      <p>
                        Ex-EVP/CFO, The Coca-Cola Company <br />
                        Board of Director - Delta Airlines
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/ian-ziskin.png"
                        alt="Ian Ziskin President EXec EXcel Group Ex-CHRO Northrop Grumman"
                      />
                      <h4>Ian Ziskin</h4>
                      <p>
                        President, EXec EXcel Group <br />
                        ex-CHRO, Northrop Grumman
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/elizabeth-adefioye.png"
                        alt="Elizabeth Adefioye CHRO Ingredian Worldwide VP Johnson &amp; Johnson"
                      />
                      <h4>Elizabeth Adefioye</h4>
                      <p>
                        Chief People Officer, <br />
                        Emerson
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/prasad-gune.png"
                        alt="Prasad Gune SVP Product Udemy Ex-SVP Product OpenTable Ex-Head of Product LinkedIn Profile"
                      />
                      <h4>Prasad Gune</h4>
                      <p>
                        SVP Product, Udemy <br />
                        ex-Product Lead, LinkedIn Recruiter
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/kairus-tarapore.png"
                        alt="Kairus Tarapore CHRO Xylem Ex-CHRO Ceridian"
                      />
                      <h4>Kairus Tarapore</h4>
                      <p>
                        ex-CHRO, Xylem <br />
                        ex-CHRO, Babcock & Wilcox (BWXT) and ex-CHRO, Ceridian
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/ravi-srivastava.png"
                        alt="Ravi Srivastava Ex-VP Monetization Yahoo, Egon Zehnder"
                      />
                      <h4>Ravi Srivastava</h4>
                      <p>
                        Global Head of Customer Engagement, NetApp <br />
                        ex-Egon Zehnder
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/sachit-kamat.png"
                        alt="Sachit Kamat Chief Product Officer eightfold.ai Ex-Head of Product LinkedIn Profile"
                      />
                      <h4>Sachit Kamat</h4>
                      <p>
                        Chief Product Officer, eightfold.ai <br />
                        ex-Head of Product, LinkedIn Profile
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="320"
                        height="320"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/lisa-buckingham.png"
                        alt="Lisa Buckingham | EVP, Global Head Enterprise Design and Life &amp; Retirement Separation Initiative AIG"
                      />
                      <h4>Lisa Buckingham</h4>
                      <p>
                        ex-EVP Chief People, Place & Brand Officer, <br />
                        Lincoln Financial Group
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="320"
                        height="320"
                        src="https://succeedsmart.com/wp-content/uploads/2021/10/dermot-obrien.webp"
                        alt="Dermot Obrien"
                      />
                      <h4>Dermot O'Brien</h4>
                      <p>
                        ex-CHRO & Chief Transformation Officer, ADP <br />
                        ex-CHRO, TIAA
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="430"
                        height="430"
                        src="https://succeedsmart.com/wp-content/uploads/2021/11/edie-goldberg.png"
                        alt="Edie Goldberg Future of Work Expert, Author, Talent Management Strategist"
                      />
                      <h4>Edie Goldberg</h4>
                      <p>
                        Future of Work Expert, Author, <br />
                        Talent Management Strategist
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="160"
                        height="160"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/deb-bubb.png"
                        alt="Deb Bubb Global Head of Talent United Healthcare"
                      />
                      <h4>Deb Bubb</h4>
                      <p>
                        EVP & Chief People Officer, <br />
                        Optum
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="245"
                        height="245"
                        src="https://succeedsmart.com/wp-content/uploads/2022/06/julie-currie.png"
                        alt="Julie Currie"
                      />
                      <h4>Julie Currie</h4>
                      <p>
                        Chief People Officer <br />
                        GRAIL
                      </p>
                    </div>
                    <div class="EachAdvisory">
                      <img
                        width="161"
                        height="161"
                        src="https://succeedsmart.com/wp-content/uploads/2021/08/garry-randall-2.png"
                        alt="Garry Randall Ex-Chief Talent Officer Disney"
                      />
                      <h4>Garry Randall</h4>
                      <p>
                        ex-Head of Global Organization & Talent Solutions,{" "}
                        <br />
                        Disney
                      </p>
                    </div>
                    {/* <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/amit-shah-1.png" alt="Amit Shah, President, 1-800-Flowers" /><h4>Amit Shah</h4><p>ex-President <br/>1-800-Flowers</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/bill-rowe-1.png" alt="Bill Rowe | Chairman Rowe Global Retained Executive Search" /><h4>Bill Rowe</h4><p>Chairman & CEO <br/>Rowe Global</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/china-gorman.png" alt="China Gorman Ex-COO SHRM Ex-CEO Great Places to Work" /><h4>China Gorman</h4><p>ex-CEO, Great Places to Work Institute <br/>ex-COO, SHRM</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/deb-bubb.png" alt="Deb Bubb Global Head of Talent United Healthcare" /><h4>Deb Bubb</h4><p>EVP & Chief People Officer, <br/>Optum</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/10/dermot-obrien.webp" alt="Dermot Obrien" /><h4>Dermot O'Brien</h4><p>ex-CHRO & Chief Transformation Officer, ADP <br/>ex-CHRO, TIAA</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/11/edie-goldberg.png" alt="Edie Goldberg Future of Work Expert, Author, Talent Management Strategist" /><h4>Edie Goldberg</h4><p>Future of Work Expert, Author, <br/>Talent Management Strategist</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/elaine-davis.png" alt="Elaine Davis | Executive talent agent, SucceedSmart" /><h4>Elaine Davis,</h4><p>CHRO <br/>HealthComp</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/elizabeth-adefioye.png" alt="Elizabeth Adefioye CHRO Ingredian Worldwide VP Johnson &amp; Johnson" /><h4>Elizabeth Adefioye</h4><p>Chief People Officer, <br/>Emerson</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/garry-randall-2.png" alt="Garry Randall Ex-Chief Talent Officer Disney" /><h4>Garry Randall</h4><p>ex-Head of Global Organization & Talent Solutions, <br/>Disney</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/gary-swart.png" alt="Gary Swart Ex-CEO oDesk (Upwork)" /><h4>Gary Swart</h4><p>General Partner, Polaris Ventures <br/>ex-CEO, oDesk (Upwork)</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/ian-ziskin.png" alt="Ian Ziskin President EXec EXcel Group Ex-CHRO Northrop Grumman" /><h4>Ian Ziskin</h4><p>President, EXec EXcel Group <br/>ex-CHRO, Northrop Grumman</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/jared-lucas.png" alt="Jared Lucas | Executive career agent, SucceedSmart" /><h4>Jared Lucas</h4><p>VP People, Cresta <br/>ex-CHRO, MobileIron</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/jim-fowler.png" alt="Jim Fowler Ex-CEO Jigsaw &amp; Owler" /><h4>Jim Fowler</h4><p>Founder & ex-CEO, Owler & Jigsaw <br/></p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/kairus-tarapore.png" alt="Kairus Tarapore CHRO Xylem Ex-CHRO Ceridian" /><h4>Kairus Tarapore</h4><p>ex-CHRO, Xylem <br/>ex-CHRO, Babcock & Wilcox (BWXT) and ex-CHRO, Ceridian</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/lisa-buckingham.png" alt="Lisa Buckingham | EVP, Global Head Enterprise Design and Life &amp; Retirement Separation Initiative AIG" /><h4>Lisa Buckingham</h4><p>ex-EVP Chief People, Place & Brand Officer, <br/>Lincoln Financial Group</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/padma-thiruvengadam.png" alt="Padma Thiruvengadam Former CHRO, Takeda Pharmaceuticals &amp; Former CPO, Lego" /><h4>Padma Thiruvengadam</h4><p>ex-CHRO, Takeda Pharmaceuticals <br/>ex-CPO, Lego</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/phil-schneidermeyer-1.png" alt="Phil Schneidermeyer | Ex-Parter Heidrick &amp; Korn/Ferry" /><h4>Phil Schneidermeyer</h4><p>Managing Director, CIO Search Group <br/>ex-Partner, Heidrick & Korn Ferry</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/prasad-gune.png" alt="Prasad Gune SVP Product Udemy Ex-SVP Product OpenTable Ex-Head of Product LinkedIn Profile" /><h4>Prasad Gune</h4><p>SVP Product, Udemy <br/>ex-Product Lead, LinkedIn Recruiter</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/ranjan-wadwa.png" alt="Ranjan Wadwa Change Leader HR Fidelity CHRO India Fidelity Investments" /><h4>Ranjan Wadhwa</h4><p>ex-Change Leader, Fidelity US <br/>ex-Head HR, Fidelity India</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/ravi-srivastava.png" alt="Ravi Srivastava Ex-VP Monetization Yahoo, Egon Zehnder" /><h4>Ravi Srivastava</h4><p>Global Head of Customer Engagement, NetApp <br/>ex-Egon Zehnder</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/sachit-kamat.png" alt="Sachit Kamat Chief Product Officer eightfold.ai Ex-Head of Product LinkedIn Profile" /><h4>Sachit Kamat</h4><p>Chief Product Officer, eightfold.ai <br/>ex-Head of Product, LinkedIn Profile</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/steve-pollock.png" alt="Steve Pollock Chief Product Officer CoffeeMeetsBagel" /><h4>Steve Pollock</h4><p>Ex-Chief Product Officer, <br/>CoffeeMeetsBagel</p></div>
                                <div class="EachAdvisory"> <img src="https://succeedsmart.com/wp-content/uploads/2021/08/tom-maddison.png" alt="Tom Maddison | Ex-Global CHRO Xerox" /><h4>Tom Maddison</h4><p>ex-Global CHRO, Xerox <br/>ex-Operating Partner/Head of Portfolio Talent, HgCapital</p></div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Typography>
    );
  }
}

BOAModule.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null, null, { pure: false })(
  withStyles(styles)(BOAModule)
);
