import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  Chip,
  TextareaAutosize,
  FormHelperText,
  FormLabel,
  FormControl,
  DialogActions,
  Dialog,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import {
  careerHighlightPage,
  sharedActions,
  setTitle,
  onBoardingActions,
} from "../../../../actions";
import {
  history,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import { toast } from "react-toastify";
import {
  notAllowWhiteSpaceAtStart,
  trim,
  sendTrackingData,
} from "../../../../helpers";
import validator from "validator";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";
import MicIcon from "@material-ui/icons/Mic";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  TopBottomMargin: {
    marginBottom: "32px !important",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  veryMediumTopMargin: {
    marginTop: "16px",
  },
  careerHighlightHeading: {
    fontWeight: "700",
    color: "#14131F",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  coffeeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  mediumTopMargin: {
    marginTop: "30px",
  },
  customTextArea: {
    width: "100% !important",
    margin: "auto",
    marginTop: "1.5%",
    resize: "none !important",
    overflow: "auto",
    outline: "none",
    webkitBoxShadow: "none",
    mozBoxShadow: "none",
    boxShadow: "none",
    fontFamily: "Public Sans",
    border: "1px solid #D6D5D6",
    padding: "10px",
    borderRadius: "5px",
    "&:focus": {
      border: "1px solid #D6D5D6",
    },
  },

  [theme.breakpoints.down("md")]: {
    marginTop: "0 !important",
  },
  chipLabel: {
    cursor: "pointer",
    padding: "0px",
    margin: "4px 8px 8px 0px",
    backgroundColor: "#e0e0e0",
    wordBreak: "break-word",
  },
  formHelperText: {
    color: "#ff0000",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: "theme.palette.primary.main",
  },
  dialogTitle: {
    fontWeight: "700",
    color: "#14131F",
  },
  skipButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
  },
  smallBottomMargin: {
    marginBottom: "24px",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  autoType: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6" color="primary" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class CoffeQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    coffeequestionAnswer: this.props?.onboarding?.coffeeQuestionData?.answer
      ? this.props?.onboarding?.coffeeQuestionData?.answer
      : null,
    selectedOption: [],
    isCoffeeQuestionAnswerRequired: false,
    answerSectionShow: false,
    skipPopup: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(onBoardingActions.getCoffeeQuestion());
    dispatch(setTitle(`${translationEN.PageTitle.Coffeequestion_Page_Title}`));
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COFFEE_QUE_PAGE_LOAD,
      SCREENS.COFFEE_QUE_SCREEN,
      ACTIONS.COFFEE_QUE_PAGE_LOADED,
      userInfo
    );
    if (this.props?.onboarding?.coffeeQuestionData?.answer) {
      let optionSelected = this.props?.onboarding?.coffeeQuestionData?.answer
        ? this.props?.onboarding?.coffeeQuestionData?.answer
        : null;
      this.handleChipSelection(optionSelected);
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    setTimeout(() => {
      this.setCaretAtEnd(document?.getElementById("coffeequestionAnswer"));
    }, 0);
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.onboarding?.coffeeQuestionData?.answer !==
      this.props?.onboarding?.coffeeQuestionData?.answer
    ) {
      this.setState({
        coffeequestionAnswer: this.props?.onboarding?.coffeeQuestionData?.answer
          ? this.props?.onboarding?.coffeeQuestionData?.answer
          : null,
      });
      let optionSelected = this.props?.onboarding?.coffeeQuestionData?.answer
        ? this.props?.onboarding?.coffeeQuestionData?.answer
        : null;
      this.handleChipSelection(optionSelected);
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setTimeout(() => {
        document.getElementById("autoTypeContent").style.display = "none";
      }, 3000);
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleInputChange = (prop) => (event) => {
    event.preventDefault();
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "coffeequestionAnswer":
          this.setState({
            isCoffeeQuestionAnswerRequired: validator.isEmpty(inputValue),
            coffeequestionAnswer: validator.isEmpty(inputValue)
              ? null
              : inputValue,
          });
          break;
        default:
          break;
      }
    });
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(careerHighlightPage(2));
    dispatch(sharedActions.setProgressbarValue(56.25));
    history.push("/onboarding/career-highlights-accomplishments");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COFFEE_QUE_PREVIOUS_CLICK,
      SCREENS.COFFEE_QUE_SCREEN,
      ACTIONS.COFFEE_QUE_PREVIOUS_CLICKED
    );
  };

  handleSaveCoffeeQuestion = () => {
    const { dispatch } = this.props;
    const { coffeequestionAnswer } = this.state;
    let coffeeQuestionAnswerWithOption;
    if (
      this.state.selectedOption.length === 0 ||
      this.state.selectedOption === null ||
      this.state.selectedOption === undefined
    ) {
      this.setState({
        skipPopup: true,
      });
      return;
    }
    if (
      this.state.coffeequestionAnswer === null ||
      this.state.coffeequestionAnswer === undefined ||
      this.state.coffeequestionAnswer === "" ||
      this.state.coffeequestionAnswer.length < 25
    ) {
      this.setState({ isCoffeeQuestionAnswerRequired: true });
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.CoffeeQuestion_Page.Answer_CQ_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.CoffeeQuestion_Page.Answer_CQ_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={"Toast_Message.CoffeeQuestion_Page.Answer_CQ_Message"}
          />
        )
      );
      return;
    }
    if (
      coffeequestionAnswer.indexOf(this.state.selectedOption[0] + " - ") === -1
    ) {
      coffeeQuestionAnswerWithOption =
        this.state.selectedOption[0] + " - " + coffeequestionAnswer;
    } else {
      coffeeQuestionAnswerWithOption = coffeequestionAnswer;
    }

    let payload = {
      question_id: this.props?.onboarding?.coffeeQuestionData?.question_id,
      question: this.props?.onboarding?.coffeeQuestionData?.question,
      answer: coffeeQuestionAnswerWithOption
        ? trim(coffeeQuestionAnswerWithOption)
        : null,
      option: null,
    };
    dispatch(onBoardingActions.saveCoffeeQuestion(payload, 68.75));
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COFFEE_QUE_NEXT_CLICK,
      SCREENS.COFFEE_QUE_SCREEN,
      ACTIONS.COFFEE_QUE_NEXT_CLICKED
    );
  };

  handleSelect = (option) => {
    let chosenOption = [];
    if (this.state.selectedOption.includes(option)) {
      this.state.selectedOption.pop();
      this.setState({
        selectedOption: chosenOption,
        answerSectionShow: false,
        isCoffeeQuestionAnswerRequired: false,
        coffeequestionAnswer: null,
      });
      return;
    } else {
      chosenOption.push(option);
      this.setState(
        {
          selectedOption: chosenOption,
          answerSectionShow: true,
        },
        () => {
          this.setState({
            coffeequestionAnswer: this.state.selectedOption[0] + " - ",
            isCoffeeQuestionAnswerRequired: false,
          });
          this.setCaretAtEnd(document?.getElementById("coffeequestionAnswer"));
        }
      );
    }
  };

  setCaretAtEnd = (elem) => {
    var elemLen = elem?.value?.length;
    // For IE Only
    if (document?.selection) {
      // Set focus
      elem.focus();
      // Use IE Ranges
      var oSel = document.selection.createRange();
      // Reset position to 0 & then set at end
      oSel.moveStart("character", -elemLen);
      oSel.moveStart("character", elemLen);
      oSel.moveEnd("character", 0);
      oSel.select();
    } else if (
      elem?.selectionStart ||
      elem?.selectionStart === "0" ||
      elem?.selectionStart === 0
    ) {
      // Firefox/Chrome
      elem.selectionStart = elemLen;
      elem.selectionEnd = elemLen;
      elem.focus();
    } // if
  };

  handleAnswerQues = () => {
    this.setState({
      skipPopup: false,
    });
  };

  handleSkipCoffeQuestion = () => {
    const { dispatch } = this.props;
    this.setState({ skipPopup: false });
    dispatch(careerHighlightPage(4));
    dispatch(sharedActions.setProgressbarValue(68.75));
    history.push("/onboarding/career-highlights-video");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COFFEE_QUE_SKIP_CLICK,
      SCREENS.COFFEE_QUE_SCREEN,
      ACTIONS.COFFEE_QUE_SKIP_CLICKED
    );
  };

  handleChipSelection = (value) => {
    if (!value) {
      return;
    }
    let option = [];
    switch (true) {
      case value.startsWith("Tactical"):
        option[0] = "Tactical";
        this.setState({
          selectedOption: option,
          answerSectionShow: true,
        });
        break;
      case value.startsWith("Strategic"):
        option[0] = "Strategic";
        this.setState({
          selectedOption: option,
          answerSectionShow: true,
        });
        break;
      case value.startsWith("A bit of both"):
        option[0] = "A bit of both";
        this.setState({
          selectedOption: option,
          answerSectionShow: true,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="CoffeeQuestion_Page.CoffeeQuestion_Heading"
                  placement="left-end"
                >
                  <Typography
                    align="left"
                    variant={"h5"}
                    className={classNames(
                      classes.careerHighlightHeading,
                      classes.smallTopMargin,
                      classes.verySmallBottomMargin
                    )}
                  >
                    {" "}
                    <ReactTranslation
                      contentKey={"CoffeeQuestion_Page.CoffeeQuestion_Heading"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  align="left"
                  variant={"h5"}
                  className={classNames(
                    classes.careerHighlightHeading,
                    classes.smallTopMargin,
                    classes.verySmallBottomMargin
                  )}
                >
                  <ReactTranslation
                    contentKey={"CoffeeQuestion_Page.CoffeeQuestion_Heading"}
                  />
                </Typography>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                align="left"
                className={classes.TopMargin}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="CoffeeQuestion_Page.Select_Chip_label"
                    placement="left-end"
                  >
                    <FormLabel
                      className={classNames(
                        classes.customFormLabel,
                        classes.smallBottomMargin
                      )}
                      component="legend"
                    >
                      <ReactTranslation
                        contentKey={"CoffeeQuestion_Page.Select_Chip_label"}
                      />
                    </FormLabel>
                  </Tooltip>
                ) : (
                  <FormLabel
                    className={classNames(
                      classes.customFormLabel,
                      classes.smallBottomMargin
                    )}
                    component="legend"
                  >
                    <ReactTranslation
                      contentKey={"CoffeeQuestion_Page.Select_Chip_label"}
                    />
                  </FormLabel>
                )}
                {translationEN.CoffeeQuestion_Page.CoffeeQuestion_Options.map(
                  (option) => (
                    <Chip
                      clickable={true}
                      label={
                        baseUrlDev ? (
                          <Tooltip
                            title={
                              option === "Tactical"
                                ? "CoffeeQuestion_Page.CoffeeQuestion_Options.Tactical"
                                : option === "Strategic"
                                ? "CoffeeQuestion_Page.CoffeeQuestion_Options.Strategic"
                                : option === "A bit of both"
                                ? "CoffeeQuestion_Page.CoffeeQuestion_Options.A bit of both"
                                : null
                            }
                            placement="bottom"
                            arrow
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  option === "Tactical"
                                    ? "CoffeeQuestion_Page.Tactical"
                                    : option === "Strategic"
                                    ? "CoffeeQuestion_Page.Strategic"
                                    : option === "A bit of both"
                                    ? "CoffeeQuestion_Page.A_Bit_Of_Both"
                                    : null
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                option === "Tactical"
                                  ? "CoffeeQuestion_Page.Tactical"
                                  : option === "Strategic"
                                  ? "CoffeeQuestion_Page.Strategic"
                                  : option === "A bit of both"
                                  ? "CoffeeQuestion_Page.A_Bit_Of_Both"
                                  : null
                              }
                            />
                          </Typography>
                        )
                      }
                      className={classNames(
                        classes.chipLabel,
                        classes.TopBottomMargin
                      )}
                      onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        this.handleSelect(option);
                      }}
                      style={{
                        backgroundColor: this.state.selectedOption?.some(
                          (element) =>
                            element?.toLowerCase() === option?.toLowerCase()
                        )
                          ? "#FDDB76"
                          : "#e0e0e0",
                      }}
                    ></Chip>
                  )
                )}
              </Grid>
              {this.state.answerSectionShow && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.TopMargin}
                  align="left"
                >
                  <FormControl
                    fullWidth
                    required
                    component="fieldset"
                    error={this.state.isCoffeeQuestionAnswerRequired}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="CoffeeQuestion_Page.CoffeeQuestion_Answer_label"
                        placement="left-end"
                      >
                        <FormLabel
                          className={classes.customFormLabel}
                          component="legend"
                          required
                        >
                          <ReactTranslation
                            contentKey={
                              "CoffeeQuestion_Page.CoffeeQuestion_Answer_label"
                            }
                          />{" "}
                        </FormLabel>
                      </Tooltip>
                    ) : (
                      <FormLabel
                        className={classes.customFormLabel}
                        component="legend"
                        required
                      >
                        <ReactTranslation
                          contentKey={
                            "CoffeeQuestion_Page.CoffeeQuestion_Answer_label"
                          }
                        />{" "}
                      </FormLabel>
                    )}
                    <TextareaAutosize
                      name="coffeequestionAnswer"
                      id="coffeequestionAnswer"
                      className={classes.customTextArea}
                      maxRows={10}
                      minRows={5}
                      maxLength={1000}
                      value={this.state.coffeequestionAnswer}
                      aria-label="Please explain your choice in a sentence"
                      placeholder={
                        translationEN.Placeholder.CoffeeQuestion_Page
                          .CoffeeQuestion_Answer_Placeholder
                      }
                      error={this.state.isCoffeeQuestionAnswerRequired}
                      style={{
                        border: this.state.isCoffeeQuestionAnswerRequired
                          ? "1px solid #ff0000"
                          : "1px solid #D6D5D6",
                      }}
                      FormHelperTextProps={{
                        classes: {
                          contained: classes.leftMarginZero,
                          root: classes.fontFamily,
                        },
                      }}
                      onChange={this.handleInputChange("coffeequestionAnswer")}
                    />
                  </FormControl>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      component={"div"}
                      className={classNames(
                        classes.displayFlex,
                        classes.smallTopMargin
                      )}
                      id="autoTypeContent"
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.autoTypeHelpText.autoHelpText_1"
                          placement="left-end"
                        >
                          <Typography
                            id="autoTypeText"
                            className={classes.autoType}
                            component={"span"}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.autoTypeHelpText.autoHelpText_1"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_1"
                            }
                          />
                        </Typography>
                      )}
                      <Typography
                        component={"span"}
                        className={classes.autoType}
                      >
                        <MicIcon />
                      </Typography>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.autoTypeHelpText.autoHelpText_2"
                          placement="left-end"
                        >
                          <Typography
                            id="autoTypeText"
                            className={classes.autoType}
                            component={"span"}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.autoTypeHelpText.autoHelpText_2"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_2"
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    className={classNames(
                      classes.verySmallTopMargin,
                      classes.verySmallBottomMargin
                    )}
                  >
                    <Grid item xs={6} sm={6} md={8} lg={8} xl={8}>
                      {this.state.isCoffeeQuestionAnswerRequired ? (
                        <FormHelperText className={classes.formHelperText}>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Validation_Messages.Field_Required"
                              placement="left-end"
                            >
                              <Typography
                                component={"span"}
                                className={classes.fontSize12px}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Validation_Messages.Field_Required"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.fontSize12px}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Validation_Messages.Field_Required"
                                }
                              />
                            </Typography>
                          )}
                        </FormHelperText>
                      ) : this.state.coffeequestionAnswer?.length < 25 ? (
                        baseUrlDev ? (
                          <Tooltip
                            title="CoffeeQuestion_Page.A_minumum_25_charecters"
                            placement="left-end"
                          >
                            <FormHelperText className={classes.formHelperText}>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "CoffeeQuestion_Page.A_minumum_25_charecters"
                                  }
                                />
                              }
                            </FormHelperText>
                          </Tooltip>
                        ) : (
                          <FormHelperText className={classes.formHelperText}>
                            {
                              <ReactTranslation
                                contentKey={
                                  "CoffeeQuestion_Page.A_minumum_25_charecters"
                                }
                              />
                            }
                          </FormHelperText>
                        )
                      ) : this.state.coffeequestionAnswer?.length >= 25 ? (
                        baseUrlDev ? (
                          <Tooltip
                            title="CoffeeQuestion_Page.A_maximum_1000_charecters"
                            placement="left-end"
                          >
                            <FormHelperText>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "CoffeeQuestion_Page.A_maximum_1000_charecters"
                                  }
                                />
                              }
                            </FormHelperText>
                          </Tooltip>
                        ) : (
                          <FormHelperText>
                            {
                              <ReactTranslation
                                contentKey={
                                  "CoffeeQuestion_Page.A_maximum_1000_charecters"
                                }
                              />
                            }
                          </FormHelperText>
                        )
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={4} xl={4} align="right">
                      <Typography align="right" color="secondary">
                        {this.state.coffeequestionAnswer?.length > 0
                          ? this.state.coffeequestionAnswer?.length + "/" + 1000
                          : 0 + "/" + 1000}{" "}
                        &nbsp;
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="CoffeeQuestion_Page.characters"
                            placement="right-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"CoffeeQuestion_Page.characters"}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <ReactTranslation
                            contentKey={"CoffeeQuestion_Page.characters"}
                          />
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.mediumTopMargin}
              >
                <div
                  className={classNames(
                    classes.TopMargin,
                    classes.coffeeButton
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="coffeequestionPreviousButton"
                        variant="outlined"
                        color="primary"
                        className={classes.prevButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="coffeequestionPreviousButton"
                      variant="outlined"
                      color="primary"
                      className={classes.prevButton}
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      {" "}
                      <ReactTranslation contentKey={"Buttons.Previous"} />{" "}
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="coffeequestionNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.prevButton}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveCoffeeQuestion();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="coffeequestionNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveCoffeeQuestion();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* Skip confirm popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.skipPopup}
            onClose={() => {
              this.setState({ skipPopup: false });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.setState({ skipPopup: false });
              }}
              alignItems="center"
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Coffeequestion_Page.Skip_CQ_Popup_Heading"
                  placement="right-end"
                >
                  <Typography
                    component={"span"}
                    variant="h6"
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={
                        "Dialog.Coffeequestion_Page.Skip_CQ_Popup_Heading"
                      }
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={
                    "Dialog.Coffeequestion_Page.Skip_CQ_Popup_Heading"
                  }
                />
              )}{" "}
            </DialogTitle>
            <DialogContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(
                  classes.textCenter,
                  classes.smallTopMargin
                )}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Coffeequestion_Page.Skip_CQ_Popup_Heading"
                    placement="right-end"
                  >
                    <Typography align="center" style={{ color: "#14131F" }}>
                      <ReactTranslation
                        contentKey={
                          "Dialog.Coffeequestion_Page.Skip_CQ_Popup_Content"
                        }
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography align="center" style={{ color: "#14131F" }}>
                    <ReactTranslation
                      contentKey={
                        "Dialog.Coffeequestion_Page.Skip_CQ_Popup_Content"
                      }
                    />
                  </Typography>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.smallTopMargin
              )}
            >
              <Grid container className={classes.skipButton}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Yes_Skip"
                    placement="left-end"
                  >
                    <Button
                      id="coffeequestionSkipButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSkipCoffeQuestion();
                      }}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Buttons.Yes_Skip"}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    id="coffeequestionSkipButton"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleSkipCoffeQuestion();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Yes_Skip"} />
                  </Button>
                )}
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Answer_Coffee_Question"
                    placement="right-end"
                  >
                    <Button
                      id="coffeequestionAnswerButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleAnswerQues();
                      }}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Buttons.Answer_Coffee_Question"}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    id="coffeequestionAnswerButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleAnswerQues();
                    }}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Buttons.Answer_Coffee_Question"}
                    />
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

CoffeQuestions.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedCoffeQuesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(CoffeQuestions))
);
export { connectedCoffeQuesPage as CoffeQuestions };
