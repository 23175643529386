import { apiService } from "../services";
// import { toast } from 'react-toastify';
// //import { updateRecruiterStatus } from './auth.actions';

import { MASTER_KEY } from "../constants/constants";
import { decryptData } from "../helpers";

export const sharedActions = {
  setSelectedNavbarIndex,
  navBarMenuToggle,
  handleNext,
  handlePrivousStep,
  setProgressbarValue,
};

function navBarMenuToggle(flag) {
  return {
    type: "NAV_BAR_MENU_TOGGLE",
    menuToggle: flag,
  };
}

function setSelectedNavbarIndex(index) {
  return {
    type: "SELECTED_NAVBAR_INDEX",
    selectedNavbarIndex: index,
  };
}

function handleNext(activeStep) {
  return (dispatch) => {
    dispatch(setActiveStep(activeStep));
  };
}

export function setActiveStep(activeStep) {
  return {
    type: "SET_ACTIVE_STEP",
    activeStep: activeStep === null ? 0 : activeStep + 1,
  };
}

function handlePrivousStep(activeStep) {
  return (dispatch) => {
    dispatch(setPreviousActiveStep(activeStep));
  };
}

export function setPreviousActiveStep(activeStep) {
  return {
    type: "SET_ACTIVE_STEP",
    activeStep: activeStep === null || 0 ? 0 : activeStep - 1,
  };
}

export function setProgressbarValue(progressStepValue) {
  return {
    type: "PROGRESS_BAR_STEP",
    progressBarStepValue: progressStepValue,
  };
}

export function badRequest(value) {
  return {
    type: "BAD_REQUEST",
    badRequest: value,
  };
}

export function getGoogleKey() {
  return (dispatch) => {
    const apiEndpoint = `api/auth/keys_manager/google_api_key/`;
    apiService.get(apiEndpoint).then((res) => {
      let value = decryptData(MASTER_KEY, res.data.key);
      dispatch(setGoogleKey(value));
    });
  };
}

function setGoogleKey(decryptedGoogleKey) {
  return {
    type: "SET_DECRYPTED_GOOGLE_KEY",
    decryptedGoogleKey: decryptedGoogleKey,
  };
}

export function getCloudName() {
  return (dispatch) => {
    const apiEndpoint = `api/auth/keys_manager/cloudinary_api_key/`;
    apiService.get(apiEndpoint).then((res) => {
      let presetValue = decryptData(
        MASTER_KEY,
        res.data.cloudinary_upload_preset
      );
      let cloudValue = decryptData(MASTER_KEY, res.data.cloud_name);
      dispatch(setCloudName(cloudValue));
      dispatch(setUploadPreset(presetValue));
    });
  };
}

function setCloudName(decryptedCloudName) {
  return {
    type: "SET_DECRYPTED_CLOUD_NAME",
    decryptedCloudName: decryptedCloudName,
  };
}

function setUploadPreset(decryptedUploadPreset) {
  return {
    type: "SET_DECRYPTED_UPLOAD_PRESET",
    decryptedUploadPreset: decryptedUploadPreset,
  };
}

export function getMixPanelkey() {
  return (dispatch) => {
    const apiEndpoint = `api/auth/keys_manager/mixpanel_api_key/`;
    apiService
      .get(apiEndpoint)
      .then((res) => {
        let key = decryptData(MASTER_KEY, res.data.key);
        dispatch(setMixPanel(key));
      })
      .catch((e) => console.log(e));
  };
}

function setMixPanel(decryptedMixPanelKey) {
  return {
    type: "SET_DECRYPTED_MIX_PANEL_KEY",
    decryptedMixPanelKey: decryptedMixPanelKey,
  };
}
