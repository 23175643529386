import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Footer from "../../core/footer";
import { sendTrackingData, showHideToolTip } from "../../../helpers";
import { EVENT_TITLES, SCREENS, ACTIONS } from "../../../constants/constants";
import {
  setTitle,
  authActions,
  getVipJobList,
  saveJobDetail,
  getMixPanelkey,
} from "../../../actions";
import config from "../../../config/config";
import "./landingPage.css";
import BOAModule from "../../core/boa-module";
import ReactTranslation from "../../core/iReact18";
import translationEN from "../../../locales/en/translation.json";
import { NeedHelp } from "../../core/needHelp.component";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 150px)",
  },
  boldFont: {
    fontWeight: "bold",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  alternateSignInButton: {
    display: "none",
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  customLink: {
    color: "#FBC112 !important",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  smallBottomMargin: {
    marginBottom: "16px",
  },
  linkedInButton: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    border: "1px solid #020203",
    color: "#020203 !important",
    fontWeight: "bold !important",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      color: "#000000 !important",
      backgroundColor: "#FFFFFF",
    },
  },
  disabledLinkedInButton: {
    width: "100%",
    fontSize: "16px",
    backgroundColor: "#FFFFFF !important",
  },
  linkedInSignInButton: {
    width: "100%",
    fontWeight: "bold !important",
    backgroundColor: "white",
    color: "#020203 !important",
    cursor: "pointer",
    border: "1px solid #020203",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#000000 !important",
    },
  },
  linkedinIcon: {
    color: "#0A66C2",
  },
  floatLeft: {
    float: "left !important",
    textAlign: "left !important",
  },
  clearLeft: {
    clear: "left !important",
  },
  leftSideContentPadding: {
    backgroundColor: "#F8F6EF",
    paddingTop: "2% !important",
    paddingBottom: "2% !important",
    paddingRight: "2.6% !important",
    paddingLeft: "2.6% !important",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: "16px !important",
      paddingLeft: "16px !important",
      paddingTop: "16px !important",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "32px !important",
      paddingLeft: "32px !important",
      paddingTop: "32px !important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "12% !important",
      paddingLeft: "12% !important",
    },
  },
  rightSideContent: {
    color: "rgba(255, 255, 255, 0.6)",
    paddingRight: "3em !important",
    paddingTop: "2% !important ",
    paddingLeft: "3em !important ",
    backgroundColor: "#14131f",
    [theme.breakpoints.between("xs", "md")]: {
      paddingRight: "16px !important",
      paddingLeft: "16px !important",
      paddingTop: "32px !important ",
    },
  },
  textColor: {
    color: "#FFFFFF",
  },
  responsiveLoginText: {
    textAlign: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: "8px",
    },
  },
  headerStyle: {
    fontSize: "56px",
    fontWeight: 900,
    margin: "0 0 45px 0",
    padding: 0,
    lineHeight: "60px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "32px",
    },
  },
});

const CustomCheckBox = withStyles({
  root: {
    color: "#303030",
    "&$checked": {
      color: "#303030",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class VipLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    acceptTermAndConditions: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    let url = new URL(window.location.href);
    let originalUrlPath;
    dispatch(getMixPanelkey());
    dispatch(setTitle(`${translationEN.PageTitle.Vip_Login_Page_Title}`));
    if (window.location.pathname.match("/vip.*$")) {
      if (!window.location.search) {
        dispatch(getVipJobList());
        let vipJobInfo = url.pathname?.split("/");
        var vipJobName = vipJobInfo[vipJobInfo.length - 1];
        secureLocalStorage.setItem("vipJobName", JSON.stringify(vipJobName));
      } else if (window.location.search) {
        dispatch(getVipJobList());
        let vipJobInfo = url.href.split("/");
        var splitedVipJobName = vipJobInfo[vipJobInfo.length - 1];
        let vipJobName = splitedVipJobName.split("?")[0];
        secureLocalStorage.setItem("vipJobName", JSON.stringify(vipJobName));
      }
    }
    // const { hostname } = new URL(window.location.href);
    const params = new URLSearchParams(window.location.search);
    let utmData = {};
    for (var urlKey of params.keys()) {
      if (urlKey.startsWith("utm_")) {
        utmData[urlKey] = params.get(urlKey);
      }
    }
    if (!url.searchParams.get("key")) {
      utmData["utm_linkurl"] = window.location.href; // hostname;
    }
    if (url.searchParams.get("invite_code")) {
      const inviteCode = url.searchParams.get("invite_code")
        ? url.searchParams.get("invite_code")
        : "REAC22";
      originalUrlPath = window.location.pathname + window.location.search;
      dispatch(authActions.saveParamInfo(utmData, inviteCode, originalUrlPath));
    } else {
      originalUrlPath = window.location.pathname;
      dispatch(
        authActions.saveParamInfo(
          utmData
            ? utmData
            : this.props?.authentication?.utmData
            ? this.props?.authentication?.utmData
            : {},
          this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code
            ? this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code
            : "REAC22",
          originalUrlPath
        )
      );
    }
    if (secureLocalStorage.getItem("jobName")) {
      secureLocalStorage.removeItem("jobName");
    }
    dispatch(saveJobDetail({}));
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIP_LOGIN_PAGE_LOAD,
      SCREENS.VIP_LOGIN_SCREEN,
      ACTIONS.VIP_LOGIN_PAGE_LOADED
    );
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    // let url = new URL(window.location.href);
    // let originalUrlPath;
    // dispatch(setTitle(`${translationEN.PageTitle.Vip_Login_Page_Title}`));
    // if(window.location.pathname.match('/vip.*$')){
    //   if(!window.location.search){
    //     dispatch(getVipJobList());
    //     let vipJobInfo = url.pathname?.split('/');
    //     var vipJobName = vipJobInfo[vipJobInfo.length-1];
    //     secureLocalStorage.setItem('vipJobName', JSON.stringify(vipJobName));
    //   }
    //   else if(window.location.search){
    //     dispatch(getVipJobList());
    //     let vipJobInfo = url.href.split('/');
    //     var splitedVipJobName = vipJobInfo[vipJobInfo.length-1];
    //     let vipJobName = splitedVipJobName.split('?')[0];
    //     secureLocalStorage.setItem('vipJobName', JSON.stringify(vipJobName));
    //   }
    // }
    // // const { hostname } = new URL(window.location.href);
    // const params = new URLSearchParams(window.location.search);
    // let utmData = {};
    // for(var urlKey of params.keys()){
    //   if(urlKey.startsWith('utm_')) {
    //     utmData[urlKey] = params.get(urlKey);
    //   }
    // }
    // if(!url.searchParams.get('key')){
    //   utmData['utm_linkurl'] = window.location.href; // hostname;
    // }
    // if(url.searchParams.get('invite_code')){
    //   const inviteCode = url.searchParams.get('invite_code') ? url.searchParams.get('invite_code') : 'REAC22';
    //   originalUrlPath = window.location.pathname + window.location.search;
    //   dispatch(authActions.saveParamInfo(utmData, inviteCode, originalUrlPath));
    // }
    // else {
    //   originalUrlPath =  window.location.pathname;
    //   dispatch(authActions.saveParamInfo(utmData ? utmData : this.props?.authentication?.utmData ? this.props?.authentication?.utmData : {}, this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code ? this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code : 'REAC22', originalUrlPath));
    // }
    // if(secureLocalStorage.getItem('jobName')){
    //   secureLocalStorage.removeItem('jobName');
    // }
    // dispatch(saveJobDetail({}));
    // sendTrackingData(EVENT_TITLES.VIP_LOGIN_PAGE_LOAD, SCREENS.VIP_LOGIN_SCREEN, ACTIONS.VIP_LOGIN_PAGE_LOADED);
    // if(config.baseUrl === `https://dev1-platform.succeedsmart.com/` || config.baseUrl === `https://dev-platform.succeedsmart.com/`){
    //   this.setState({ baseUrlDev: true});
    // }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    if (
      prevProps?.authentication?.vipJobDetailInfo !==
      this.props?.authentication?.vipJobDetailInfo
    ) {
      dispatch(
        authActions.saveParamInfo(
          this.props?.authentication?.utmData
            ? this.props?.authentication?.utmData
            : this.props?.authetication?.utmData,
          this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code
            ? this.props?.authentication?.vipJobDetailInfo?.acf?.vip_code
            : "REAC22",
          this.props?.authetication?.originalUrlPath
            ? this.props?.authetication?.originalUrlPath
            : window.location.pathname + window.location.search
        )
      );
    }
    if (
      prevProps.shared.decryptedMixPanelKey !==
      this.props.shared.decryptedMixPanelKey
    ) {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.JOB_LOGIN_PAGE_LOAD,
        SCREENS.JOB_LOGIN_SCREEN,
        ACTIONS.JOB_LOGIN_PAGE_LOADED
      );
    }
  }

  handleTermsAndConditionAccept = (prop) => (event) => {
    this.setState({ [prop]: event.target.checked }, () => {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.TERMS_AND_CONDITIONS_CHECKBOX_CLICK,
        SCREENS.VIP_LOGIN_SCREEN,
        `${
          event.target.checked
            ? ACTIONS.T_N_C_CHECKBOX_CHECKED
            : ACTIONS.T_N_C_CHECKBOX_UNCHECKED
        }`
      );
    });
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <Grid container className={classes.root}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              className={classes.leftSideContentPadding}
            >
              <Typography
                className={classNames(
                  classes.floatLeft,
                  classes.clearLeft,
                  classes.boldFont,
                  classes.headerStyle
                )}
                variant="h1"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.authentication?.vipJobDetailInfo?.title
                      ?.rendered
                      ? this.props.authentication?.vipJobDetailInfo?.title
                          ?.rendered
                      : "",
                  }}
                ></div>
              </Typography>
              <Typography
                className={classNames(classes.floatLeft, classes.clearLeft)}
              >
                <div
                  id="vipLandingPageContent"
                  dangerouslySetInnerHTML={{
                    __html: this.props.authentication.vipJobDetailInfo?.content
                      ?.rendered
                      ? this.props.authentication.vipJobDetailInfo?.content
                          ?.rendered
                      : "",
                  }}
                ></div>
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.rightSideContent}
            >
              <Typography
                id="rightSideSection"
                variant="h4"
                className={classNames(
                  classes.boldFont,
                  classes.floatLeft,
                  classes.clearLeft,
                  classes.textColor
                )}
              >
                {this.props.authentication?.vipJobDetailInfo?.acf
                  ?.vip_right_side_title ? (
                  this.props.authentication?.vipJobDetailInfo?.acf
                    ?.vip_right_side_title
                ) : baseUrlDev ? (
                  <Tooltip arrow title="Login_Page.heading">
                    <Typography variant="h4">
                      <ReactTranslation contentKey={"Login_Page.heading"} />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography variant="h4">
                    <ReactTranslation contentKey={"Login_Page.heading"} />
                  </Typography>
                )}
              </Typography>
              {this.props.authentication?.vipJobDetailInfo?.acf
                ?.vip_right_side_content ? (
                <Typography
                  className={classNames(
                    classes.smallTopMargin,
                    classes.smallBottomMargin,
                    classes.floatLeft,
                    classes.clearLeft
                  )}
                >
                  <div
                    id="vipLandingPageRightSideContent"
                    dangerouslySetInnerHTML={{
                      __html:
                        this.props.authentication?.vipJobDetailInfo?.acf
                          ?.vip_right_side_content,
                    }}
                  ></div>
                </Typography>
              ) : (
                <Typography
                  className={classNames(
                    classes.smallTopMargin,
                    classes.smallBottomMargin,
                    classes.floatLeft,
                    classes.clearLeft
                  )}
                >
                  <div id="vipLandingPageRightSideContent">
                    {baseUrlDev ? (
                      <ol>
                        <Tooltip
                          arrow
                          title="Vip_Login_Page.signup_with_linkedin"
                        >
                          <li>
                            <ReactTranslation
                              contentKey={"Vip_Login_Page.signup_with_linkedin"}
                            />
                          </li>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title="Vip_Login_Page.Complete_your_profile"
                        >
                          <li>
                            <ReactTranslation
                              contentKey={
                                "Vip_Login_Page.Complete_your_profile"
                              }
                            />
                          </li>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title="Vip_Login_Page.Start_growing_your_executive_network"
                        >
                          <li>
                            <ReactTranslation
                              contentKey={
                                "Vip_Login_Page.Start_growing_your_executive_network"
                              }
                            />
                          </li>
                        </Tooltip>
                      </ol>
                    ) : (
                      <ol>
                        <li>
                          <ReactTranslation
                            contentKey={"Vip_Login_Page.signup_with_linkedin"}
                          />
                        </li>
                        <li>
                          <ReactTranslation
                            contentKey={"Vip_Login_Page.Complete_your_profile"}
                          />
                        </li>
                        <li>
                          <ReactTranslation
                            contentKey={
                              "Vip_Login_Page.Start_growing_your_executive_network"
                            }
                          />
                        </li>
                      </ol>
                    )}
                  </div>
                </Typography>
              )}
              <Grid
                container
                className={classNames(
                  classes.smallTopMargin,
                  classes.smallBottomMargin
                )}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  id="termsAndConditions"
                >
                  <FormControlLabel
                    style={{ placeItems: "flex-start" }}
                    control={
                      <CustomCheckBox
                        style={{ padding: "2px 9px", color: "#ffffff" }}
                        checked={this.state.acceptTermAndConditions}
                        onChange={this.handleTermsAndConditionAccept(
                          "acceptTermAndConditions"
                        )}
                        name="acceptTermAndConditions"
                      />
                    }
                    label={
                      <Typography
                        color="textSecondary"
                        style={{ color: "rgba(255, 255, 255, 0.6)" }}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Login_Page.content_03"
                            placement="left"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Login_Page.content_03"}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Login_Page.content_03"}
                            />
                          </Typography>
                        )}
                        <a
                          href="https://succeedsmart.com/termsofuse"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.customLink}
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Login_Page.terms_of_use"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={"Login_Page.terms_of_use"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Login_Page.terms_of_use"}
                              />
                            </Typography>
                          )}
                        </a>
                        &nbsp;
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Login_Page.and"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation contentKey={"Login_Page.and"} />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <ReactTranslation contentKey={"Login_Page.and"} />
                        )}
                        &nbsp;
                        <a
                          href="https://succeedsmart.com/privacypolicy"
                          target="_blank"
                          rel="noreferrer"
                          className={classes.customLink}
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Login_Page.privacy_policy"
                              placement="top"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={"Login_Page.privacy_policy"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Login_Page.privacy_policy"}
                              />
                            </Typography>
                          )}
                        </a>
                        .
                      </Typography>
                    }
                    value="true"
                    className={classes.textAlignLeft}
                  />
                </Grid>
                <br />
                <br />
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.largeTopMargin}
                >
                  {this.state.acceptTermAndConditions && (
                    <a
                      href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                      style={{ textDecoration: "none" }}
                      onClick={(event) => {
                        sendTrackingData(
                          this.props.shared.decryptedMixPanelKey,
                          EVENT_TITLES.SIGN_UP_BUTTON_CLICK,
                          SCREENS.VIP_LOGIN_SCREEN,
                          ACTIONS.SIGN_UP_BUTTON_CLICKED
                        );
                        event.stopPropagation();
                      }}
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Login_Page.button_signup_with_linkedin"
                        >
                          <Button
                            id="signInButton"
                            disableElevation
                            disabled={!this.state.acceptTermAndConditions}
                            variant="outlined"
                            size="large"
                            className={classes.linkedInButton}
                            startIcon={
                              <LinkedInIcon style={{ color: "#0A66C2" }} />
                            }
                          >
                            <ReactTranslation
                              contentKey={
                                "Login_Page.button_signup_with_linkedin"
                              }
                            />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          id="signInButton"
                          disableElevation
                          disabled={!this.state.acceptTermAndConditions}
                          variant="outlined"
                          size="large"
                          className={classes.linkedInButton}
                          startIcon={
                            <LinkedInIcon style={{ color: "#0A66C2" }} />
                          }
                        >
                          <ReactTranslation
                            contentKey={
                              "Login_Page.button_signup_with_linkedin"
                            }
                          />
                        </Button>
                      )}
                    </a>
                  )}
                  {!this.state.acceptTermAndConditions && (
                    <Tooltip
                      arrow
                      title={
                        baseUrlDev && "Login_Page.button_signup_with_linkedin"
                      }
                    >
                      <Button
                        disableElevation
                        disabled={!this.state.acceptTermAndConditions}
                        variant="outlined"
                        size="large"
                        className={classNames(
                          classes.disabledLinkedInButton,
                          classes.smallBottomMargin
                        )}
                        startIcon={<LinkedInIcon />}
                      >
                        <ReactTranslation
                          contentKey={"Login_Page.button_signup_with_linkedin"}
                        />
                      </Button>
                    </Tooltip>
                  )}
                  <br />
                  <br />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallBottomMargin}
                >
                  {/* need help component */}
                  <NeedHelp />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography className={classes.responsiveLoginText}>
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Login_Page.Already_a_member_SucceedSmart"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Login_Page.Already_a_member_SucceedSmart"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={
                            "Login_Page.Already_a_member_SucceedSmart"
                          }
                        />
                      </Typography>
                    )}
                    &nbsp;
                    <a
                      className={classNames(classes.customLink)}
                      href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                      onClick={(event) => {
                        sendTrackingData(
                          this.props.shared.decryptedMixPanelKey,
                          EVENT_TITLES.SIGN_IN_LINK_CLICK,
                          SCREENS.JOB_LOGIN_SCREEN,
                          ACTIONS.SIGN_IN_LINK_CLICKED
                        );
                        event.stopPropagation();
                      }}
                    >
                      {baseUrlDev ? (
                        <Tooltip arrow title="Login_Page.Sign_in_to_my_account">
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Login_Page.Sign_in_to_my_account"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Login_Page.Sign_in_to_my_account"}
                          />
                        </Typography>
                      )}
                    </a>
                    {/* <a className = {classNames(classes.customLink)} href= {(config.baseUrl === `https://dev1-platform.succeedsmart.com/` || config.baseUrl === `https://dev-platform.succeedsmart.com/`) ? 'https://dev-member.succeedsmart.com' : 'https://member.succeedsmart.com' } >
                                  Sign in to my account
                                  </a> */}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BOAModule />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}

VipLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedVipSignInPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(VipLandingPage))
);

export { connectedVipSignInPage as VipLandingPage };
