import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Checkbox,
  Button,
  TextField,
  FormControlLabel,
  Dialog,
  DialogContent,
  DialogActions,
  FormHelperText,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import {
  onBoardingActions,
  setTitle,
  sharedActions,
  careerHighlightPage,
} from "../../../../actions";
import { isMemberApproved } from "../../../../actions/basicInfo.actions";
import validator from "validator";
import {
  notAllowWhiteSpaceAtStart,
  history,
  sendTrackingData,
  trim,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import { toast } from "react-toastify";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
// import EasyEdit from 'react-easy-edit';
import classNames from "classnames";
import { ViewMore } from "../../../core/viewmore";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";
import MicIcon from "@material-ui/icons/Mic";

let charCount = 1000;

let isOverlay = false;
const detectBrowser = () => {
  switch (true) {
    case navigator.userAgent.indexOf("Opera") !== -1:
    case navigator.userAgent.indexOf("OPR") !== -1:
      isOverlay = true;
      break;
    case navigator.userAgent.indexOf("Edg") !== -1:
      isOverlay = true;
      break;
    case navigator.userAgent.indexOf("Chrome") !== -1:
      isOverlay = true;
      break;
    case navigator.userAgent.indexOf("Safari") !== -1:
      isOverlay = true;
      break;
    case navigator.userAgent.indexOf("Firefox") !== -1:
      isOverlay = false;
      break;
    case navigator.userAgent.indexOf("MSIE") !== -1:
      isOverlay = false;
      break;
    default:
      break;
  }
};
detectBrowser();

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallTopMargin: {
    marginTop: "20px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  smallBottomMargin: {
    marginBottom: "16px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  veryMediumTopMargin: {
    marginTop: "16px",
  },
  justifyCenter: {
    justifyContent: "center",
  },
  careerHighlightHeading: {
    marginTop: "24px",
    fontWeight: "700",
    color: "#14131F",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  customAlignment: {
    float: "left",
  },
  customScroll: {
    maxHeight: "400px",
    overflow: "hidden",
    [theme.breakpoints.between("xs", "sm")]: {
      overflow: "auto",
    },
    wordBreak: "break-word",
    "&:hover": {
      overflow: isOverlay ? "overlay" : "auto",
    },
    paddingRight: "8px",
  },
  accButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  mediumTopMargin: {
    marginTop: "32px",
  },
  formHelperText: {
    color: "#ff0000",
  },
  dialogTitle: {
    fontWeight: "700",
    color: "#14131F",
  },
  [theme.breakpoints.down("md")]: {
    marginTop: "0 !important",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main,
  },
  paddingLeft: {
    paddingLeft: "6px",
  },
  autoType: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6" color="primary" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

class Accomplishments extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    accomplishmentInput: null,
    isAccomplishmentRequired: false,
    isValidAccomplishment: true,
    selectAllAccomplishments: false,
    editMode: true,
    editValue: "",
    editAccomplishmentPopup: false,
    editAccomplishmentValue: "",
    editAccomplishment: "",
    baseUrlDev: false,
    newAggregatedAccomplishments: [],
    selectedAccomplishments: [],
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(onBoardingActions.getAccomplishments());
    dispatch(setTitle(`${translationEN.PageTitle.Accomplishments_Page_Title}`));
    this.arrayToObject();
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.ACCOMPLISHMENTS_PAGE_LOAD,
      SCREENS.ACCOMPLISHMENTS_SCREEN,
      ACTIONS.ACCOMPLISHMENTS_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.onboarding?.existingAccomplishments?.accomplishments !==
      this.props?.onboarding?.existingAccomplishments?.accomplishments
    ) {
      this.arrayToObject();
    }
    if (
      prevProps?.onboarding?.suggestedAccomplishments?.accomplishments !==
      this.props?.onboarding?.suggestedAccomplishments?.accomplishments
    ) {
      this.arrayToObject();
    }
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setTimeout(() => {
        document.getElementById("autoTypeContent").style.display = "none";
      }, 3000);
    }

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  arrayToObject = () => {
    let existingAccomplishmentArray = [];
    let suggestedAccomplishmentArray = [];
    if (
      this.props?.onboarding?.suggestedAccomplishments?.accomplishments
        ?.length === 0 &&
      this.props?.onboarding?.existingAccomplishments?.accomplishments
        .length === 0
    ) {
      this.setState({ selectAllAccomplishments: false });
    } else {
      if (
        this.props?.onboarding?.suggestedAccomplishments?.accomplishments
          ?.length === 0
      ) {
        this.setState({ selectAllAccomplishments: true });
      } else {
        this.setState({ selectAllAccomplishments: false });
      }
    }
    this.props?.onboarding?.existingAccomplishments?.accomplishments?.map(
      (ele) =>
        existingAccomplishmentArray.push({
          value: ele,
          selected: true,
        })
    );
    this.props?.onboarding?.suggestedAccomplishments?.accomplishments?.map(
      (ele) =>
        suggestedAccomplishmentArray.push({
          value: ele,
          selected: false,
        })
    );
    let newAggregatedAccomplishments = [
      ...existingAccomplishmentArray,
      ...suggestedAccomplishmentArray,
    ];
    this.setState({
      newAggregatedAccomplishments: newAggregatedAccomplishments,
      selectedAccomplishments: existingAccomplishmentArray,
    });
  };

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "accomplishmentInput":
          this.setState({
            isValidAccomplishment: inputValue
              ? validator.matches(inputValue, /^.{1,25}$/)
              : true,
            isAccomplishmentRequired: inputValue
              ? validator.matches(inputValue, /^.{26,1000}$/)
              : true,
          });
          break;
        case "editAccomplishmentValue":
          this.setState({
            isValidAccomplishment: inputValue
              ? validator.matches(inputValue, /^.{1,25}$/)
              : true,
            isAccomplishmentRequired: inputValue
              ? validator.matches(inputValue, /^.{26,1000}$/)
              : true,
          });
          break;
        default:
          break;
      }
    });
  };

  addAccomplishment = () => {
    if (
      this.state.accomplishmentInput === null ||
      this.state.accomplishmentInput === "" ||
      this.state.accomplishmentInput === undefined
    ) {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Accomplishment_Page.Empty_Input_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.Accomplishment_Page.Empty_Input_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={"Toast_Message.Accomplishment_Page.Empty_Input_Message"}
          />
        )
      );
      return;
    } else {
      let newAccomplishmentValueExist =
        this.state.newAggregatedAccomplishments.some(
          (value) => value.value === trim(this.state.accomplishmentInput)
        );
      if (newAccomplishmentValueExist) {
        toast.dark(
          this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Accomplishment_Page.Same_Accomplishment_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Accomplishment_Page.Same_Accomplishment_Message"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Accomplishment_Page.Same_Accomplishment_Message"
              }
            />
          )
        );
        return;
      }
      let addNewAccomplishmentValue = [];
      addNewAccomplishmentValue.push({
        value: this.state.accomplishmentInput,
        selected: true,
      });
      this.state.newAggregatedAccomplishments.unshift(
        addNewAccomplishmentValue[0]
      );
      let selectedAccomplishments =
        this.state.newAggregatedAccomplishments.filter(
          (el) => el.selected === true
        );

      this.setState({
        selectedAccomplishments: selectedAccomplishments,
        selectAllAccomplishments:
          selectedAccomplishments.length ===
          this.state.newAggregatedAccomplishments.length
            ? true
            : false,
      });
      this.setState({ accomplishmentInput: "" });
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.ACCOMPLISHMENTS_ADD_NEW_ACCOMPLISHMENT,
        SCREENS.ACCOMPLISHMENTS_SCREEN,
        ACTIONS.ACCOMPLISHMENTS_ADDED_NEW_ACCOMPLISHMENT
      );
    }
  };

  handleSelectAllAccomplishments = (event) => {
    if (event.target.checked) {
      let selectAllAccomplismentArray = this.state.newAggregatedAccomplishments;
      selectAllAccomplismentArray.forEach(
        (ele, index) => (selectAllAccomplismentArray[index].selected = true)
      );
      this.setState({
        selectAllAccomplishments: true,
        newAggregatedAccomplishments: selectAllAccomplismentArray,
        selectedAccomplishments: selectAllAccomplismentArray,
      });
    } else {
      let selectAllAccomplismentArray = this.state.newAggregatedAccomplishments;
      selectAllAccomplismentArray.forEach(
        (ele, index) => (selectAllAccomplismentArray[index].selected = false)
      );
      this.setState({
        selectAllAccomplishments: false,
        newAggregatedAccomplishments: selectAllAccomplismentArray,
        selectedAccomplishments: [],
      });
    }
  };

  handleChangeAccomplishment = (event, index) => {
    let accomplishmentIndex = this.state.newAggregatedAccomplishments.findIndex(
      (value) => value === index
    );
    let newAggregatedAccomplishments = [];
    newAggregatedAccomplishments = this.state.newAggregatedAccomplishments;
    if (event.target.checked) {
      newAggregatedAccomplishments[accomplishmentIndex].selected = true;
      this.setState(
        { newAggregatedAccomplishments: newAggregatedAccomplishments },
        () => {
          let selectedAccomplishments =
            this.state.newAggregatedAccomplishments.filter(
              (el) => el.selected === true
            );
          if (
            selectedAccomplishments.length ===
            this.state.newAggregatedAccomplishments.length
          ) {
            this.setState({ selectAllAccomplishments: true });
          }
          this.setState({ selectedAccomplishments: selectedAccomplishments });
        }
      );
    } else {
      newAggregatedAccomplishments[accomplishmentIndex].selected = false;
      this.setState(
        { newAggregatedAccomplishments: newAggregatedAccomplishments },
        () => {
          let selectedAccomplishments =
            this.state.newAggregatedAccomplishments.filter(
              (el) => el.selected === true
            );
          this.setState({
            selectedAccomplishments: selectedAccomplishments,
            selectAllAccomplishments: false,
          });
        }
      );
    }
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(sharedActions.handlePrivousStep(this.props?.shared.activeStep));
    dispatch(sharedActions.setProgressbarValue(25));
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.ACCOMPLISHMENTS_PREVIOUS_CLICK,
      SCREENS.ACCOMPLISHMENTS_SCREEN,
      ACTIONS.ACCOMPLISHMENTS_PREVIOUS_CLICKED
    );
  };

  handleSaveAccomplishment = () => {
    let selectedAccomplishments = this.state.selectedAccomplishments.map(
      (ele) => ele.value
    );
    if (selectedAccomplishments.length < 3) {
      this.setState({
        isAccomplishmentRequired: true,
        isValidAccomplishment: true,
      });
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Accomplishment_Page.Min_3_Accomplishment_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.Accomplishment_Page.Min_3_Accomplishment_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={
              "Toast_Message.Accomplishment_Page.Min_3_Accomplishment_Message"
            }
          />
        )
      );
    } else {
      let payload = {
        accomplishments: selectedAccomplishments,
      };
      if (this.props.onboarding?.suggestedAccomplishments?.count === 0) {
        if (selectedAccomplishments?.length <= 20) {
          this.addAccomplishmentPostApiCall(payload);
        } else {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Accomplishment_Page.Max_20_Accomplishment_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Accomplishment_Page.Max_20_Accomplishment_Message"
                    }
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation
                contentKey={
                  "Toast_Message.Accomplishment_Page.Max_20_Accomplishment_Message"
                }
              />
            )
          );
        }
      } else {
        this.addAccomplishmentPostApiCall(payload);
      }
    }
  };

  addAccomplishmentPostApiCall = (payload) => {
    const { dispatch } = this.props;
    let progressBarStepValue = 56.25;
    onBoardingActions.addAccomplishments(payload).then((response) => {
      if (response && (response.status === 200 || response.status === 201)) {
        toast.dark(
          response.data.message ? (
            response.data.message
          ) : this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Accomplishment_Page.Accomplishments_Added_Successfully_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Accomplishment_Page.Accomplishments_Added_Successfully_Message"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Accomplishment_Page.Accomplishments_Added_Successfully_Message"
              }
            />
          )
        );
        dispatch(onBoardingActions.getAccomplishments());
        dispatch(careerHighlightPage(2));
        dispatch(sharedActions.setProgressbarValue(progressBarStepValue));
        sendTrackingData(
          this.props.shared.decryptedMixPanelKey,
          EVENT_TITLES.ACCOMPLISHMENTS_NEXT_CLICK,
          SCREENS.ACCOMPLISHMENTS_SCREEN,
          ACTIONS.ACCOMPLISHMENTS_NEXT_CLICKED
        );
        history.push("/onboarding/career-highlights-competencies");
      } else {
        dispatch(isMemberApproved("approved"));
      }
    });
  };

  handleAccomplishmentEdit = (accomplishmentValue) => {
    this.setState({
      editAccomplishmentPopup: true,
      editAccomplishmentValue: accomplishmentValue.value,
      editAccomplishment: accomplishmentValue,
      isValidAccomplishment: accomplishmentValue.length <= 25 ? true : false,
    });
  };

  handleSaveEditAccomplishment = () => {
    let newValueExit = this.state.newAggregatedAccomplishments.some(
      (value) => value.value === trim(this.state.editAccomplishmentValue)
    );
    if (newValueExit) {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Accomplishment_Page.Same_Accomplishment_Is_Already_Present_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.Accomplishment_Page.Same_Accomplishment_Is_Already_Present_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={
              "Toast_Message.Accomplishment_Page.Same_Accomplishment_Is_Already_Present_Message"
            }
          />
        )
      );
      return;
    }
    let accomplishmentIndex = this.state.newAggregatedAccomplishments.findIndex(
      (value) => value === trim(this.state.editAccomplishment)
    );
    let newAggregatedAccomplishments = [];
    newAggregatedAccomplishments = this.state.newAggregatedAccomplishments;
    newAggregatedAccomplishments[accomplishmentIndex].selected = true;
    newAggregatedAccomplishments[accomplishmentIndex].value = trim(
      this.state.editAccomplishmentValue
    );
    this.setState(
      { newAggregatedAccomplishments: newAggregatedAccomplishments },
      () => {
        let selectedAccomplishments =
          this.state.newAggregatedAccomplishments.filter(
            (el) => el.selected === true
          );
        this.setState(
          { selectedAccomplishments: selectedAccomplishments },
          () => {
            this.setState({
              selectAllAccomplishments:
                this.state.selectedAccomplishments?.length ===
                this.state.newAggregatedAccomplishments?.length,
            });
          }
        );
      }
    );
    this.setState({ editAccomplishmentPopup: false });
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid>
                {this.state.newAggregatedAccomplishments.length <= 3 ? (
                  baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Accomplishments_Heading_1_1"
                      placement="left-end"
                    >
                      <Typography
                        align="left"
                        variant={"h5"}
                        className={classNames(
                          classes.careerHighlightHeading,
                          classes.smallBottomMargin
                        )}
                      >
                        <ReactTranslation
                          contentKey={
                            "Accomplishments_Page.Accomplishments_Heading_1_1"
                          }
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      align="left"
                      variant={"h5"}
                      className={classNames(
                        classes.careerHighlightHeading,
                        classes.smallBottomMargin
                      )}
                    >
                      <ReactTranslation
                        contentKey={
                          "Accomplishments_Page.Accomplishments_Heading_1_1"
                        }
                      />
                    </Typography>
                  )
                ) : null}

                {this.state.newAggregatedAccomplishments.length > 3 ? (
                  baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Accomplishments_Heading_1_2"
                      placement="left-end"
                    >
                      <Typography
                        align="left"
                        variant={"h5"}
                        className={classNames(
                          classes.careerHighlightHeading,
                          classes.smallBottomMargin
                        )}
                      >
                        <ReactTranslation
                          contentKey={
                            "Accomplishments_Page.Accomplishments_Heading_1_2"
                          }
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      align="left"
                      variant={"h5"}
                      className={classNames(
                        classes.careerHighlightHeading,
                        classes.smallBottomMargin
                      )}
                    >
                      <ReactTranslation
                        contentKey={
                          "Accomplishments_Page.Accomplishments_Heading_1_2"
                        }
                      />
                    </Typography>
                  )
                ) : null}
                <Grid container alignItems="center">
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      autoComplete={false}
                      autoFocus
                      multiline
                      id="accomplishments"
                      name="accomplishments"
                      fullWidth
                      inputProps={{ maxLength: charCount }}
                      label={
                        baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Accomplishment_label"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Accomplishments_Page.Accomplishment_label"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Accomplishments_Page.Accomplishment_label"
                              }
                            />
                          </Typography>
                        )
                      }
                      placeholder={`${translationEN.Placeholder.Accomplishment_Page.Add_Accomplishment_Placeholder}`}
                      onChange={this.handleInputChange("accomplishmentInput")}
                      value={this.state.accomplishmentInput}
                      variant="standard"
                      InputProps={{
                        classes: {
                          notchedOutline: classes.textField,
                          input: classes.notchedInput,
                          root: classes.fontFamily,
                        },
                      }}
                      FormHelperTextProps={{
                        classes: {
                          contained: classes.leftMarginZero,
                          root: classes.fontFamily,
                        },
                      }}
                    ></TextField>
                    <Grid
                      container
                      className={classNames(
                        classes.verySmallTopMargin,
                        classes.verySmallBottomMargin
                      )}
                    >
                      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        {this.state.accomplishmentInput?.length > 0 ? (
                          this.state.accomplishmentInput?.length < 25 ? (
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Accomplishments_Page.A_minumum_25_charecters"
                                placement="left-end"
                              >
                                <FormHelperText
                                  className={classes.formHelperText}
                                >
                                  {
                                    <ReactTranslation
                                      contentKey={
                                        "Accomplishments_Page.A_minumum_25_charecters"
                                      }
                                    />
                                  }
                                </FormHelperText>
                              </Tooltip>
                            ) : (
                              <FormHelperText
                                className={classes.formHelperText}
                              >
                                {
                                  <ReactTranslation
                                    contentKey={
                                      "Accomplishments_Page.A_minumum_25_charecters"
                                    }
                                  />
                                }
                              </FormHelperText>
                            )
                          ) : this.state.accomplishmentInput?.length >= 25 ? (
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Accomplishments_Page.A_minumum_25_charecters"
                                placement="left-end"
                              >
                                <FormHelperText>
                                  {
                                    <ReactTranslation
                                      contentKey={
                                        "Accomplishments_Page.A_maximum_1000_charecters"
                                      }
                                    />
                                  }
                                </FormHelperText>
                              </Tooltip>
                            ) : (
                              <FormHelperText>
                                {
                                  <ReactTranslation
                                    contentKey={
                                      "Accomplishments_Page.A_maximum_1000_charecters"
                                    }
                                  />
                                }
                              </FormHelperText>
                            )
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        align="right"
                      >
                        <Typography
                          align={
                            this.state.accomplishmentInput?.length < 1
                              ? "right"
                              : "none"
                          }
                          color="secondary"
                        >
                          {this.state.accomplishmentInput?.length > 0
                            ? this.state.accomplishmentInput?.length +
                              "/" +
                              1000
                            : 0 + "/" + 1000}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Accomplishments_Page.characters"
                              placement="right-end"
                            >
                              <Typography
                                component={"span"}
                                className={classes.paddingLeft}
                              >
                                <ReactTranslation
                                  contentKey={"Accomplishments_Page.characters"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.paddingLeft}
                            >
                              <ReactTranslation
                                contentKey={"Accomplishments_Page.characters"}
                              />
                            </Typography>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography
                      component={"div"}
                      className={classNames(
                        classes.displayFlex,
                        classes.smallTopMargin
                      )}
                      id="autoTypeContent"
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.autoTypeHelpText.autoHelpText_1"
                          placement="left-end"
                        >
                          <Typography
                            id="autoTypeText"
                            className={classes.autoType}
                            component={"span"}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.autoTypeHelpText.autoHelpText_1"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_1"
                            }
                          />
                        </Typography>
                      )}
                      <Typography
                        component={"span"}
                        className={classes.autoType}
                      >
                        <MicIcon />
                      </Typography>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Help_card_content.autoTypeHelpText.autoHelpText_2"
                          placement="left-end"
                        >
                          <Typography
                            id="autoTypeText"
                            className={classes.autoType}
                            component={"span"}
                          >
                            <ReactTranslation
                              contentKey={
                                "Help_card_content.autoTypeHelpText.autoHelpText_2"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_2"
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Buttons.Add+"
                        placement="left-start"
                      >
                        <Button
                          className={classes.customAlignment}
                          id="customAddAccomplishment"
                          variant="contained"
                          color="primary"
                          disableElevation
                          disabled={
                            this.state.accomplishmentInput === null ||
                            this.state.accomplishmentInput?.length < 25
                          }
                          onClick={() => this.addAccomplishment()}
                        >
                          <ReactTranslation contentKey={"Buttons.Add+"} />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        className={classes.customAlignment}
                        id="customAddAccomplishment"
                        variant="contained"
                        color="primary"
                        disableElevation
                        disabled={
                          this.state.accomplishmentInput === null ||
                          this.state.accomplishmentInput?.length < 25
                        }
                        onClick={() => this.addAccomplishment()}
                      >
                        <ReactTranslation contentKey={"Buttons.Add+"} />
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  {this.state.newAggregatedAccomplishments.length > 0 && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      align="left"
                      className={classes.smallTopMargin}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            style={{ color: "#000000" }}
                            onChange={(event) =>
                              this.handleSelectAllAccomplishments(event)
                            }
                            name={"selectAll"}
                            value={"selectAll"}
                            checked={this.state.selectAllAccomplishments}
                          />
                        }
                        label={
                          baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Accomplishments_Page.Select_All"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={"Accomplishments_Page.Select_All"}
                                />{" "}
                                &nbsp;
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Accomplishments_Page.Opening_parenthesis"
                                    }
                                  />
                                  <ReactTranslation
                                    contentKey={"Accomplishments_Page.Selected"}
                                  />
                                  &nbsp;
                                  {this.state.selectedAccomplishments?.length}
                                  &nbsp;
                                  {baseUrlDev && (
                                    <Tooltip
                                      arrow
                                      title="Accomplishments_Page.Of"
                                      placement="right-end"
                                    >
                                      <Typography component={"span"}>
                                        <ReactTranslation
                                          contentKey={"Accomplishments_Page.Of"}
                                        />
                                      </Typography>
                                    </Tooltip>
                                  )}
                                  &nbsp;
                                  {
                                    this.state.newAggregatedAccomplishments
                                      ?.length
                                  }
                                  <ReactTranslation
                                    contentKey={
                                      "Accomplishments_Page.Closing_parenthesis"
                                    }
                                  />
                                </Typography>
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Accomplishments_Page.Select_All"}
                              />
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={
                                    "Accomplishments_Page.Opening_parenthesis"
                                  }
                                />
                                <ReactTranslation
                                  contentKey={"Accomplishments_Page.Selected"}
                                />{" "}
                                {this.state.selectedAccomplishments?.length}
                                <Typography component={"span"}>
                                  {" "}
                                  <ReactTranslation
                                    contentKey={"Accomplishments_Page.Of"}
                                  />{" "}
                                </Typography>
                                {
                                  this.state.newAggregatedAccomplishments
                                    ?.length
                                }{" "}
                                <ReactTranslation
                                  contentKey={
                                    "Accomplishments_Page.Closing_parenthesis"
                                  }
                                />
                              </Typography>
                            </Typography>
                          )
                        }
                      />
                    </Grid>
                  )}
                  <div className={classes.customScroll}>
                    {this.state.newAggregatedAccomplishments.length > 0 &&
                      this.state.newAggregatedAccomplishments?.map(
                        (el, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            align="left"
                            className={classNames(
                              classes.veryMediumTopMargin,
                              classes.verySmallBottomMargin
                            )}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <FormControlLabel
                              id="easyInputLabel"
                              key={index}
                              control={
                                <Checkbox
                                  style={{
                                    color: "#000000",
                                    padding: "2px 8px",
                                  }}
                                  name={el.value}
                                  value={el.value}
                                  onChange={(event) => {
                                    this.handleChangeAccomplishment(event, el);
                                  }}
                                  checked={el.selected}
                                />
                              }
                              label={
                                <Typography>
                                  {<ViewMore>{el.value}</ViewMore>}
                                </Typography>
                              }
                            />
                            <Tooltip
                              title="Edit Accomplishment"
                              arrow
                              placement="bottom"
                              aria-label="Edit Accomplishment"
                            >
                              <EditIcon
                                style={{ cursor: "pointer" }}
                                onClick={(event) => {
                                  event.preventDefault();
                                  this.handleAccomplishmentEdit(el);
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        )
                      )}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.mediumTopMargin}
                >
                  <div
                    className={classNames(classes.TopMargin, classes.accButton)}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Buttons.Previous"
                        placement="bottom"
                      >
                        <Button
                          id="accomplishmentPreviousButton"
                          variant="outlined"
                          color="primary"
                          className={classes.prevButton}
                          disableElevation
                          onClick={(event) => {
                            event.preventDefault();
                            this.handlePrevious();
                          }}
                        >
                          <ReactTranslation contentKey={"Buttons.Previous"} />{" "}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        id="accomplishmentPreviousButton"
                        variant="outlined"
                        color="primary"
                        className={classes.prevButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />{" "}
                      </Button>
                    )}
                    {baseUrlDev ? (
                      <Tooltip arrow title="Buttons.Next" placement="bottom">
                        <Button
                          id="accomplishmentNextButton"
                          variant="contained"
                          color="primary"
                          disableElevation
                          className={classes.prevButton}
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleSaveAccomplishment();
                          }}
                        >
                          {" "}
                          <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        id="accomplishmentNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.prevButton}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveAccomplishment();
                        }}
                      >
                        {" "}
                        <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Accomplishment Edit popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.editAccomplishmentPopup}
            onClose={() => {
              this.setState({ editAccomplishmentPopup: false });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => this.setState({ editAccomplishmentPopup: false })}
              className={classes.dialogTitle}
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Accomplishment_Page.Edit_Accomplisment_Title"
                  placement="right-end"
                >
                  <Typography
                    component={"span"}
                    variant="h6"
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={
                        "Dialog.Accomplishment_Page.Edit_Accomplisment_Title"
                      }
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={
                    "Dialog.Accomplishment_Page.Edit_Accomplisment_Title"
                  }
                />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="column"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs={12} sm={12}>
                  <TextField
                    multiline
                    id="editAccomplishment"
                    aria-label="editAccomplishment"
                    label={
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Dialog.Accomplishment_Page.Edit_Accomplishment_label"
                          placement="right-end"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Dialog.Accomplishment_Page.Edit_Accomplishment_label"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Dialog.Accomplishment_Page.Edit_Accomplishment_label"
                            }
                          />
                        </Typography>
                      )
                    }
                    fullWidth
                    placeholder={`${translationEN.Placeholder.Accomplishment_Page.Add_Accomplishment_Placeholder}`}
                    value={this.state.editAccomplishmentValue}
                    inputProps={{ maxLength: charCount }}
                    onChange={this.handleInputChange("editAccomplishmentValue")}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                    {this.state.editAccomplishmentValue?.length > 0 ? (
                      this.state.editAccomplishmentValue?.length < 25 ? (
                        baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Accomplishments_Page.A_minumum_25_charecters"
                            placement="bottom"
                          >
                            <FormHelperText className={classes.formHelperText}>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Accomplishments_Page.A_minumum_25_charecters"
                                  }
                                />
                              }
                            </FormHelperText>
                          </Tooltip>
                        ) : (
                          <FormHelperText className={classes.formHelperText}>
                            {
                              <ReactTranslation
                                contentKey={
                                  "Accomplishments_Page.A_minumum_25_charecters"
                                }
                              />
                            }
                          </FormHelperText>
                        )
                      ) : this.state.editAccomplishmentValue?.length >= 25 ? (
                        baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Accomplishments_Page.A_minumum_25_charecters"
                            placement="bottom"
                          >
                            <FormHelperText>
                              {
                                <ReactTranslation
                                  contentKey={
                                    "Accomplishments_Page.A_maximum_1000_charecters"
                                  }
                                />
                              }
                            </FormHelperText>
                          </Tooltip>
                        ) : (
                          <FormHelperText>
                            {
                              <ReactTranslation
                                contentKey={
                                  "Accomplishments_Page.A_maximum_1000_charecters"
                                }
                              />
                            }
                          </FormHelperText>
                        )
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="right">
                    <Typography
                      align={
                        this.state.editAccomplishmentValue?.length < 1
                          ? "right"
                          : "none"
                      }
                      color="secondary"
                    >
                      {this.state.editAccomplishmentValue?.length > 0
                        ? this.state.editAccomplishmentValue?.length +
                          "/" +
                          1000
                        : 0 + "/" + 1000}{" "}
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Accomplishments_Page.characters"
                          placement="bottom"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Accomplishments_Page.characters"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <ReactTranslation
                          contentKey={"Accomplishments_Page.characters"}
                        />
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.verySmallTopMargin,
                classes.justifyCenter
              )}
            >
              {baseUrlDev ? (
                <Tooltip title="Dialog.Buttons.Cancel" placement="bottom">
                  <Button
                    color="primary"
                    disableElevation
                    onClick={() =>
                      this.setState({ editAccomplishmentPopup: false })
                    }
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  color="primary"
                  disableElevation
                  onClick={() =>
                    this.setState({ editAccomplishmentPopup: false })
                  }
                >
                  <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                </Button>
              )}
              {baseUrlDev ? (
                <Tooltip title="Dialog.Buttons.Submit" placement="bottom">
                  <Button
                    color="primary"
                    disableElevation
                    variant="contained"
                    onClick={(event) =>
                      this.handleSaveEditAccomplishment(event)
                    }
                    disabled={this.state.editAccomplishmentValue.length < 25}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Submit"} />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  color="primary"
                  disableElevation
                  variant="contained"
                  onClick={(event) => this.handleSaveEditAccomplishment(event)}
                  disabled={this.state.editAccomplishmentValue.length < 25}
                >
                  <ReactTranslation contentKey={"Dialog.Buttons.Submit"} />
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

Accomplishments.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedAccomplishmentsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Accomplishments))
);
export { connectedAccomplishmentsPage as Accomplishments };
