import { apiService } from "../services";
import { history } from "../helpers";
import { sharedActions } from "./shared.action";
import { toast } from "react-toastify";
import { basicInfoPage, onboardingStatus } from "./onboarding.actions";

export const basicInfoActions = {
  basicInformationStep1,
  basicInformationStep2,
  getBasicInfo,
};

function getBasicInfo() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/basic_information/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(
            saveBasicInfo(response?.data?.data, response?.data?.parsed_data)
          );
          dispatch(onboardingStatus(response?.data?.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveBasicInfo(basicInfoData, basicInfoParsedData) {
  return {
    type: "BASIC_INFO_DATA",
    basicInfoData: basicInfoData,
    basicInfoParsedData: basicInfoParsedData,
  };
}

export function isMemberApproved(value) {
  return {
    type: "MEMBER_APPROVED",
    memberStatus: value,
  };
}

function basicInformationStep1(payload, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/basic_information/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(basicInfoPage(2));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Profile updated successfully"
          );
          history.push("/onboarding/basic-info-step-2");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function basicInformationStep2(payload, activeStep, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/basic_information/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getBasicInfo());
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(sharedActions.handleNext(activeStep));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Profile updated successfully"
          );
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}
