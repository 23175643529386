const {
  REACT_APP_BACKEND_URL,
  REACT_APP_STRIPE_KEY,
  REACT_APP_ZENDESK_URL,
  REACT_APP_TERMS_URL,
} = process.env;

module.exports = {
  baseUrl: REACT_APP_BACKEND_URL, // 'https://dev.succeedsmart.com:8000/api/v1/', //'http://15.207.229.157:8000/api/v1/'
  stripePublicationKey: REACT_APP_STRIPE_KEY,
  // cloudName: REACT_APP_CLOUDINARY_CLOUD_NAME, //'deohdcrr6', // for cloudinary use
  // upload_preset: REACT_APP_CLOUDINARY_UPLOAD_PRESET, //'tdz84yhl', // for cloudinary use
  zendesk_url: REACT_APP_ZENDESK_URL,
  terms_url: REACT_APP_TERMS_URL,
};
