const initialState = {
  menuToggle: false,
  activeStep: 0,
  progressStepValue: 0,
};

export function shared(state = initialState, action) {
  switch (action.type) {
    case "NAV_BAR_MENU_TOGGLE":
      return {
        ...state,
        menuToggle: action.menuToggle,
      };
    case "SELECTED_NAVBAR_INDEX":
      return {
        ...state,
        selectedNavbarIndex: action.selectedNavbarIndex,
      };
    case "SET_ACTIVE_STEP":
      return {
        ...state,
        activeStep: action.activeStep,
      };
    case "PROGRESS_BAR_STEP":
      return {
        ...state,
        progressStepValue: action.progressBarStepValue,
      };
    case "BAD_REQUEST":
      return {
        ...state,
        badRequest: action.badRequest,
      };
    case "SET_DECRYPTED_GOOGLE_KEY":
      return {
        ...state,
        decryptedGoogleKey: action.decryptedGoogleKey,
      };
    case "SET_DECRYPTED_CLOUD_NAME":
      return {
        ...state,
        decryptedCloudName: action.decryptedCloudName,
      };
    case "SET_DECRYPTED_UPLOAD_PRESET":
      return {
        ...state,
        decryptedUploadPreset: action.decryptedUploadPreset,
      };
    case "SET_DECRYPTED_MIX_PANEL_KEY":
      return {
        ...state,
        decryptedMixPanelKey: action.decryptedMixPanelKey,
      };
    default:
      return state;
  }
}
