import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Button,
  DialogActions,
  Dialog,
  Avatar,
  IconButton,
  Box,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import {
  onBoardingActions,
  setTitle,
  sharedActions,
  careerHighlightPage,
  preferencesPage,
  getCloudName,
} from "../../../../actions";
import videoImage from "../../../../assets/Video.svg";
import scheduleImage from "../../../../assets/schedule_meeting.svg";
import uploadVideoImage from "../../../../assets/uploadImage.svg";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CancelIcon from "@material-ui/icons/Cancel";
import VideoRecorder from "react-video-recorder";
import { VideoRecorderComponent } from "./videoRecorder.component";
import insertDrive from "../../../../assets/Insert drive file.svg";
import PublishIcon from "@material-ui/icons/Publish";
import LinearProgress from "@material-ui/core/LinearProgress";
import { toast } from "react-toastify";
import { loading } from "../../../../actions/loader.actions";
import {
  history,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import RedoIcon from "@material-ui/icons/Redo";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
  CALNEDLY_SCHEDULE_LINK,
} from "../../../../constants/constants";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";

const sliceSize = 20000000; // 20MB
const innerHeight = window.innerHeight + "px";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallBottomMargin: {
    marginBottom: "24px",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  topMargin: {
    marginTop: "32px",
  },
  fullHeight: {
    height: innerHeight,
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  videoButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  careerHighlightHeading: {
    textAlign: "left",
    fontWeight: "700",
    color: "#14131F",
  },
  floatLeft: {
    float: "left !important",
    textAlign: "left !important",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    cursor: "pointer",
    "&:hover": {
      boxShadow: "0 0 0 5px #D6D5D6 !important",
    },
  },
  customLarge: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginTop: "32px",
  },
  alignCenter: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main,
  },
  videoText: {
    fontSize: "14px",
    color: "#14131F",
    marginLeft: "24px",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  boldFont: {
    fontWeight: "bold",
  },
  textCenter: {
    textAlign: "center !important",
  },
  uploadText: {
    fontSize: "16px",
    color: "#000000 !important",
  },
  dragBox: {
    border: "2px dashed #676767",
    borderRadius: "10px",
  },
  dragSuccesText: {
    fontSize: "16px",
    color: "#268C4D",
    cursor: "pointer",
  },
  videoBox: {
    backgroundColor: "#F4F4F5",
  },
  videoHeading: {
    textAlign: "left",
    [theme.breakpoints.between("xs", "sm")]: {
      textAlign: "center",
    },
  },
  videoRetakeButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
  },
  takenewVideoButton: {
    backgroundColor: "#FFFFFF",
    color: "#14131F",
    border: "1px solid #020203",
  },
  videoRecordButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
  },
  startOverButton: {
    backgroundColor: "#FFFFFF",
    color: "#020203",
    border: "1px solid #020203",
  },
  dialogTitle: {
    color: "#14131F",
    fontWeight: "700",
  },
  buttonPadding: {
    padding: "10px",
  },
  skipButton: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    width: "100%",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  displayBlock: {
    display: "block",
  },
  buttonBorder: {
    border: "1px solid #000000 !important",
  },
});

const nodeRef = React.createRef(null);

function PaperComponent(props) {
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#alert-draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography variant="h6" color="primary" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
class Video extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    videoPopup: false,
    calendlyPopup: false,
    videoUplodedSuccessfuly: this.props?.onboarding?.videoInfoData?.video_url
      ? true
      : false,
    videoData: this.props?.onboarding?.videoInfoData?.video_url
      ? this.props?.onboarding?.videoInfoData?.video_url
      : null,
    uploadVideoPopup: false,
    uploadedVideo: null,
    videoUploaded: false,
    videoUploadedId: null,
    video: null,
    progress: 0,
    newVideoPopup: false,
    videoType: null,
    videoSaved: false,
    confirmPopup: false,
    videoThumbnailUrl: this.props?.onboarding?.videoInfoData?.thumbnail_url,
    newVideoUploaded: false,
    isCameraTurnedOn: false,
    skipPopup: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(onBoardingActions.getVideo());
    dispatch(setTitle(`${translationEN.PageTitle.Video_Page_Title}`));
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }

    dispatch(getCloudName());
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_LOAD,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.onboarding?.videoInfoData?.video_url !==
      this.props?.onboarding?.videoInfoData?.video_url
    ) {
      this.setState({
        videoData: this.props?.onboarding?.videoInfoData?.video_url
          ? this.props?.onboarding?.videoInfoData?.video_url
          : null,
        videoUplodedSuccessfuly:
          this.props?.onboarding?.videoInfoData?.video_url === null
            ? false
            : true,
      });
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleVideoPopup = () => {
    this.setState({
      videoPopup: true,
      videoType: "record",
      uploadedVideo: null,
    });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_RECORD_NEW_VIDEO,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_RECORDED_NEW_VIDEO
    );
  };

  handleCalendlyPopup = () => {
    this.setState({ calendlyPopup: true });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_SCHEDULE_MEETING,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_SCHEDULED_MEETING
    );
  };

  handleUploadPopup = () => {
    this.setState({ uploadVideoPopup: true, videoType: "upload" });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_UPLOAD_NEW_VIDEO,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_UPLOADED_NEW_VIDEO
    );
  };

  handleCloseVideoPopup = () => {
    if (this.state.videoData === null) {
      this.setState({
        videoPopup: false,
        videoUplodedSuccessfuly: false,
        progress: 0,
        uploadedVideo: null,
      });
      return;
    }
    this.setState({
      videoPopup: false,
      videoUplodedSuccessfuly: true,
      progress: 0,
      videoType: "record",
      uploadedVideo: null,
    });
  };

  handleCloseCalendlyPopup = () => {
    this.setState({ calendlyPopup: false });
  };

  handleCloseUploadPopup = () => {
    if (this.state.videoData === null) {
      this.setState({
        uploadVideoPopup: false,
        videoUplodedSuccessfuly: false,
        progress: 0,
        videoSaved: false,
        uploadedVideo: null,
        videoUploaded: false,
      });
      return;
    }
    this.setState({
      uploadVideoPopup: false,
      videoUplodedSuccessfuly: true,
      progress: 0,
      videoType: "upload",
      videoSaved: false,
      uploadedVideo: null,
      videoUploaded: false,
    });
  };

  handleSaveRecordedVideo = (event) => {
    this.uploadVideoToCloudinary(event);
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_SAVE_RECORDED_VIDEO,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_SAVED_RECORDED_VIDEO
    );
  };

  handleVideoUpload = () => {
    document.getElementById("insightVideo").value = "";
    document.getElementById("insightVideo").click();
    const XUniqueUploadId = +new Date();
    document.getElementById("insightVideo").onchange = (e) => {
      const uploadedVideo = e.target.files[0];
      if (e.target.files[0] && e.target.files[0].size > 250000000) {
        toast.dark(
          this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Video_Page.Video_Files_Allowed_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Video_Page.Video_Files_Allowed_Message"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Video_Page.Video_Files_Allowed_Message"
              }
            />
          )
        );
      } else {
        this.setState({
          uploadedVideo: uploadedVideo,
          XUniqueUploadId: XUniqueUploadId,
        });
      }
      if (
        e.target.files[0] &&
        e.target.files[0].type !== "video/mp4" &&
        e.target.files[0].type !== "video/x-msvideo" &&
        e.target.files[0].type !== "video/quicktime" &&
        e.target.files[0].type !== "video/x-matroska" &&
        e.target.files[0].type !== "video/x-ms-wmv" &&
        e.target.files[0].type !== "video/webm" &&
        e.target.files[0].type !== "video/x-flv" &&
        e.target.files[0].type !== "video/ogg" &&
        e.target.files[0].type !== "video/mpeg"
      ) {
        toast.dark(
          this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Video_Page.Video_Files_Allowed_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Video_Page.Only_Video_Files_Are_Allowed"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Video_Page.Only_Video_Files_Are_Allowed"
              }
            />
          )
        );
        this.setState({ video: "", uploadedVideo: null });
      } else {
        if (uploadedVideo) {
          this.setState({ videoUploaded: true });
        } else {
          this.setState({ videoUploaded: false });
        }
      }
    };
  };

  handleSaveUploadedVideo = () => {
    if (this.state.uploadedVideo && this.state.videoUploaded) {
      this.setState({ videoSaved: true });
      this.uploadVideoToCloudinary();
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.VIDEO_PAGE_SAVE_UPLOADED_VIDEO,
        SCREENS.VIDEO_SCREEN,
        ACTIONS.VIDEO_PAGE_SAVED_UPLOADED_VIDEO
      );
    } else {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Video_Page.Please_Upload_Video"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={"Toast_Message.Video_Page.Please_Upload_Video"}
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={"Toast_Message.Video_Page.Please_Upload_Video"}
          />
        )
      );
    }
  };

  uploadVideoToCloudinary = () => {
    let decryptedUploadPreset = this.props.shared.decryptedUploadPreset;
    let decryptedCloudName = this.props.shared.decryptedCloudName;
    this.setState({ uploading: true });
    this.props.dispatch(loading(true));
    let thisInstance = this;
    var file = this.state.uploadedVideo;
    var size = file.size;
    var start = 0;

    function slice(file, start, end) {
      var slice = file.mozSlice
        ? file.mozSlice
        : file.webkitSlice
        ? file.webkitSlice
        : file.slice
        ? file.slice
        : noop;

      return slice.bind(file)(start, end);
    }

    function noop() {
      console.log("No operation...");
    }

    function send(piece, start, end, size) {
      var formdata = new FormData();
      formdata.append("file", piece);
      formdata.append("upload_preset", decryptedUploadPreset);
      formdata.append("cloud_name", decryptedCloudName);
      thisInstance.setState({
        start: start,
        end: end,
        size: size,
        formData: formdata,
      });
      fetch(
        "https://api.cloudinary.com/v1_1/" +
          decryptedCloudName +
          "/video/upload",
        {
          method: "post",
          body: thisInstance.state.formData, //this.state.videoData,
          headers: {
            "X-Unique-Upload-Id": thisInstance.state.XUniqueUploadId,
            "Content-Range":
              "bytes " +
              thisInstance.state.start +
              "-" +
              thisInstance.state.end +
              "/" +
              thisInstance.state.size,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          thisInstance.setState(
            (prevState) => ({
              progress:
                prevState.progress !== null &&
                prevState.progress > Math.round((100 * data.bytes) / size)
                  ? prevState.progress
                  : Math.round((100 * data.bytes) / size),
            }),
            () => {
              if (thisInstance.state.progress === 100) {
                thisInstance.props.dispatch(loading(false));
                toast.dark("Video Uploaded Successfully");
              }
            }
          );
          if (data.secure_url) {
            let uploadedVideoUrl =
              data.secure_url.replace(/.([^.]*)$/, "") + ".mp4"; //extension transformation
            let uploadedThumbnailUrl = uploadedVideoUrl.replace(".mp4", ".jpg"); //extension from mp4 to jpg transformation
            thisInstance.setState(
              {
                video: uploadedVideoUrl,
                videoData: uploadedVideoUrl,
                videoThumbnailUrl: uploadedThumbnailUrl,
                newVideoUploaded: true,
              },
              () => {
                if (thisInstance.state.videoType === "record") {
                  thisInstance.handleCloseVideoPopup();
                } else if (thisInstance.state.videoType === "upload") {
                  thisInstance.handleCloseUploadPopup();
                }
              }
            ); //uploading: false
          }
        });
    }

    setTimeout(loop, 3);

    function loop() {
      var end = start + sliceSize;

      if (end > size) {
        end = size;
      }
      var s = slice(file, start, end);
      send(s, start, end - 1, size);
      if (end < size) {
        start += sliceSize;
        setTimeout(loop, 3);
      }
    }
  };

  hanldeRecordNewVideo = () => {
    this.setState({
      newVideoPopup: true,
      videoType: "record",
      uploadedVideo: null,
    });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_RECORD_NEW_VIDEO,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_RECORDED_NEW_VIDEO
    );
  };

  hanldeUploadNewVideo = () => {
    this.setState({
      newVideoPopup: true,
      videoType: "upload",
      uploadedVideo: null,
    });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PAGE_UPLOAD_NEW_VIDEO,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PAGE_UPLOADED_NEW_VIDEO
    );
  };

  handleCloseRetakePopup = () => {
    this.setState({ newVideoPopup: false });
  };

  handleConfirmRetakeVideoPopup = () => {
    switch (this.state.videoType) {
      case "record":
        this.setState({
          videoPopup: true,
          newVideoPopup: false,
          videoUploaded: false,
        });
        break;
      case "upload":
        this.setState({
          uploadVideoPopup: true,
          newVideoPopup: false,
          videoUploaded: false,
        });
        break;
      default:
        break;
    }
  };

  handleSaveVideo = () => {
    const { dispatch } = this.props;
    let progressBarStepValue = 75;
    if (this.state.videoData === null || this.state.videoData === undefined) {
      this.setState({ skipPopup: true });
      return;
    }
    let payload = {
      insight_id: this.props?.onboarding?.videoInfoData?.insight_videos_id,
      video_url: this.state.videoData,
      thumbnail_url: this.state.videoThumbnailUrl,
      topic: "Your elevator pitch",
    };
    dispatch(
      onBoardingActions.saveVideo(
        payload,
        this.props.shared.activeStep,
        progressBarStepValue
      )
    );
    dispatch(preferencesPage(1));
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_NEXT_CLICK,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_NEXT_CLICKED
    );
    this.setState({ newVideoUploaded: false });
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    if (this.state.newVideoUploaded) {
      this.setState({
        confirmPopup: true,
      });
    } else {
      dispatch(careerHighlightPage(3));
      dispatch(sharedActions.setProgressbarValue(62.5));
      history.push("/onboarding/career-highlights-coffeequestion");
    }
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_PREVIOUS_CLICK,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_PREVIOUS_CLICKED
    );
  };

  handleDeleteUploadedVideo = () => {
    this.setState({
      uploadedVideo: null,
      videoUploaded: false,
      video: "",
    });
  };

  handleConfirmGoBack = () => {
    const { dispatch } = this.props;
    dispatch(careerHighlightPage(3));
    dispatch(sharedActions.setProgressbarValue(62.5));
    history.push("/onboarding/career-highlights-coffeequestion");
  };

  handleSkipVideo = () => {
    const { dispatch } = this.props;
    dispatch(sharedActions.handleNext(this.props?.shared?.activeStep));
    dispatch(sharedActions.setProgressbarValue(75));
    dispatch(preferencesPage(1));
    history.push("/onboarding/preferences-salary");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.VIDEO_SKIP_CLICK,
      SCREENS.VIDEO_SCREEN,
      ACTIONS.VIDEO_SKIP_CLICKED
    );
  };

  handleRestartVideo = () => {
    this.setState(
      {
        videoPopup: false,
        uploadedVideo: null,
      },
      () => {
        setTimeout(() => {
          this.setState({ videoPopup: true });
        }, 300);
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(
                  classes.smallTopMargin,
                  classes.verySmallBottomMargin
                )}
              >
                <Typography
                  variant="h5"
                  className={classes.careerHighlightHeading}
                >
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Video_Page.Video_Heading"
                      placement="left-end"
                    >
                      <Typography
                        variant="h5"
                        className={classes.careerHighlightHeading}
                      >
                        {
                          <ReactTranslation
                            contentKey={"Video_Page.Video_Heading"}
                          />
                        }
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      variant="h5"
                      className={classes.careerHighlightHeading}
                    >
                      {
                        <ReactTranslation
                          contentKey={"Video_Page.Video_Heading"}
                        />
                      }
                    </Typography>
                  )}
                </Typography>
              </Grid>
              {!this.state.videoUplodedSuccessfuly && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.topMargin}
                >
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Video_Page.Select_Opition"
                      placement="left-end"
                    >
                      <Typography className={classes.floatLeft}>
                        {/* {'Confidence and engagement are key to a compelling elevator pitch. We’re here if you want some additional support. Simply click “Schedule a time” below and you can speak with one of our executive coaches for help.'} */}
                        {
                          <ReactTranslation
                            contentKey={"Video_Page.Select_Opition"}
                          />
                        }
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography className={classes.floatLeft}>
                      {/* {'Confidence and engagement are key to a compelling elevator pitch. We’re here if you want some additional support. Simply click “Schedule a time” below and you can speak with one of our executive coaches for help.'} */}
                      {
                        <ReactTranslation
                          contentKey={"Video_Page.Select_Opition"}
                        />
                      }
                    </Typography>
                  )}
                </Grid>
              )}
              {!this.state.videoUplodedSuccessfuly ? (
                <Grid container spacing={9}>
                  <Grid
                    className={classes.cursorPointer}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    align="left"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleVideoPopup();
                    }}
                  >
                    <div
                      className={classNames(
                        classes.displayFlex,
                        classes.topMargin1
                      )}
                    >
                      <Avatar
                        alt="videoImage"
                        src={videoImage}
                        className={classNames(classes.large)}
                      />
                      <div
                        className={classNames(
                          classes.videoText,
                          classes.displayBlock
                        )}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Record_Video_Heading"
                            placement="right-end"
                          >
                            <Typography
                              className={classes.boldFont}
                              component="div"
                            >
                              {
                                <ReactTranslation
                                  contentKey={"Video_Page.Record_Video_Heading"}
                                />
                              }
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classes.boldFont}
                            component="div"
                          >
                            {
                              <ReactTranslation
                                contentKey={"Video_Page.Record_Video_Heading"}
                              />
                            }
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Click_Here_To_Record_It"
                            placement="right-end"
                          >
                            <Typography component="div" color="textSecondary">
                              <ReactTranslation
                                contentKey={
                                  "Video_Page.Click_Here_To_Record_It"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component="div" color="textSecondary">
                            <ReactTranslation
                              contentKey={"Video_Page.Click_Here_To_Record_It"}
                            />
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cursorPointer}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    align="left"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleCalendlyPopup();
                    }}
                  >
                    <div
                      className={classNames(
                        classes.displayFlex,
                        classes.topMargin1
                      )}
                    >
                      <Avatar
                        alt="scheduleImage"
                        src={scheduleImage}
                        className={classNames(classes.large)}
                      />
                      <div
                        className={classNames(
                          classes.videoText,
                          classes.displayBlock
                        )}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Need_Help_With_Your_Elevator_Pitch_Video"
                            placement="right-end"
                          >
                            <Typography
                              className={classes.boldFont}
                              component="div"
                            >
                              <ReactTranslation
                                contentKey={
                                  "Video_Page.Need_Help_With_Your_Elevator_Pitch_Video"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classes.boldFont}
                            component="div"
                          >
                            <ReactTranslation
                              contentKey={
                                "Video_Page.Need_Help_With_Your_Elevator_Pitch_Video"
                              }
                            />
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Schedule_A_Time_With_Our_Member_Concierge"
                            placement="right-end"
                          >
                            <Typography component="div" color="textSecondary">
                              <ReactTranslation
                                contentKey={
                                  "Video_Page.Schedule_A_Time_With_Our_Member_Concierge"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component="div" color="textSecondary">
                            <ReactTranslation
                              contentKey={
                                "Video_Page.Schedule_A_Time_With_Our_Member_Concierge"
                              }
                            />
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    className={classes.cursorPointer}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    align="left"
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleUploadPopup();
                    }}
                  >
                    <div
                      className={classNames(
                        classes.displayFlex,
                        classes.topMargin1
                      )}
                    >
                      <Avatar
                        alt="videoUploadImage"
                        src={uploadVideoImage}
                        className={classNames(classes.large)}
                      />
                      <div
                        className={classNames(
                          classes.videoText,
                          classes.displayBlock
                        )}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Already_Have_An_Elevator_Pitch_Video"
                            placement="right-end"
                          >
                            <Typography
                              className={classes.boldFont}
                              component="div"
                            >
                              <ReactTranslation
                                contentKey={
                                  "Video_Page.Already_Have_An_Elevator_Pitch_Video"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            className={classes.boldFont}
                            component="div"
                          >
                            <ReactTranslation
                              contentKey={
                                "Video_Page.Already_Have_An_Elevator_Pitch_Video"
                              }
                            />
                          </Typography>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Upload_It_Here"
                            placement="right-end"
                          >
                            <Typography component="div" color="textSecondary">
                              <ReactTranslation
                                contentKey={"Video_Page.Upload_It_Here"}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component="div" color="textSecondary">
                            <ReactTranslation
                              contentKey={"Video_Page.Upload_It_Here"}
                            />
                          </Typography>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className={classNames(classes.videoBox)}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <video
                      preload="auto"
                      autoPlay={false}
                      controls
                      disablePictureInPicture
                      id="dashboardVideo"
                      width="100%"
                      height="100%"
                      src={this.state.videoData}
                      frameBorder="0"
                      // allow="accelerometer, autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allow="accelerometer; encrypted-media; gyroscope"
                      allowFullScreen
                      controlsList="nodownload"
                      style={{ objectFit: "contain" }}
                    />
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classNames(
                        classes.smallTopMargin,
                        classes.textCenter,
                        classes.smallBottomMargin
                      )}
                    >
                      <div className={classes.videoRetakeButton}>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Record_New_Video"
                            placement="bottom"
                          >
                            <Button
                              id="retakevideo"
                              variant="contained"
                              disableElevation
                              color="primary"
                              startIcon={<RedoIcon />}
                              className={classes.takenewVideoButton}
                              onClick={(event) => {
                                event.preventDefault();
                                this.hanldeRecordNewVideo();
                              }}
                            >
                              <ReactTranslation
                                contentKey={"Video_Page.Record_New_Video"}
                              />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            id="retakevideo"
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<RedoIcon />}
                            className={classes.takenewVideoButton}
                            onClick={(event) => {
                              event.preventDefault();
                              this.hanldeRecordNewVideo();
                            }}
                          >
                            <ReactTranslation
                              contentKey={"Video_Page.Record_New_Video"}
                            />
                          </Button>
                        )}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Video_Page.Upload_New_Video"
                            placement="bottom"
                          >
                            <Button
                              id="retakevideo"
                              variant="contained"
                              disableElevation
                              color="primary"
                              startIcon={<PublishIcon />}
                              className={classes.takenewVideoButton}
                              onClick={(event) => {
                                event.preventDefault();
                                this.hanldeUploadNewVideo();
                              }}
                            >
                              <ReactTranslation
                                contentKey={"Video_Page.Upload_New_Video"}
                              />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Button
                            id="retakevideo"
                            variant="contained"
                            disableElevation
                            color="primary"
                            startIcon={<PublishIcon />}
                            className={classes.takenewVideoButton}
                            onClick={(event) => {
                              event.preventDefault();
                              this.hanldeUploadNewVideo();
                            }}
                          >
                            <ReactTranslation
                              contentKey={"Video_Page.Upload_New_Video"}
                            />
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.mediumTopMargin}
              >
                <div
                  className={classNames(classes.topMargin, classes.videoButton)}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="videoRecordPreviousButton"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="videoRecordPreviousButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="videoRecordNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveVideo();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="videoRecordNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveVideo();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* {video popup} */}
          <Dialog
            fullWidth
            maxWidth="lg"
            disableBackdropClick
            open={this.state.videoPopup}
            onClose={() => {
              this.handleCloseVideoPopup();
            }}
            PaperComponent={PaperComponent}
            ref={nodeRef}
            aria-labelledby="alert-draggable-dialog-title"
            aria-describedby="alert-draggable-dialog-description"
          >
            <DialogTitle
              style={{ cursor: "move" }}
              id="alert-draggable-dialog-title"
              onClose={() => {
                this.handleCloseVideoPopup();
              }}
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Video_Page.Record_Video_Title"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"span"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Record_Video_Title"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Record_Video_Title"}
                />
              )}
            </DialogTitle>
            <DialogContent className={classes.fullHeight}>
              <VideoRecorder
                constraints={{ audio: true, video: true }}
                timeLimit={90000}
                isOnInitially={this.state.isCameraTurnedOn}
                showReplayControls
                replayVideoAutoplayAndLoopOff
                countdownTime={3000}
                onRecordingComplete={(videoBlob) => {
                  this.setState({ uploadedVideo: videoBlob });
                }}
                onSwitchCamera={() => {
                  this.setState({ isCameraTurnedOn: true });
                }}
                renderActions={(props) => <VideoRecorderComponent {...props} />}
              />
            </DialogContent>
            {this.state.uploadedVideo && (
              <DialogActions
                className={classNames(
                  classes.verySmallBottomMargin,
                  classes.verySmallTopMargin
                )}
              >
                <div
                  className={classNames(
                    classes.verySmallTopMargin,
                    classes.videoRecordButtons,
                    classes.smallBottomMargin
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Dialog.Buttons.Start_Over"
                      placement="bottom"
                    >
                      <Button
                        startIcon={<RedoIcon />}
                        className={classes.startOverButton}
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleRestartVideo();
                        }}
                      >
                        <ReactTranslation
                          contentKey={"Dialog.Buttons.Start_Over"}
                        />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      startIcon={<RedoIcon />}
                      className={classes.startOverButton}
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleRestartVideo();
                      }}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Buttons.Start_Over"}
                      />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Dialog.Buttons.Save"
                      placement="bottom"
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={
                          this.state.uploadedVideo === null ? true : false
                        }
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveRecordedVideo();
                        }}
                      >
                        <ReactTranslation contentKey={"Dialog.Buttons.Save"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        this.state.uploadedVideo === null ? true : false
                      }
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveRecordedVideo();
                      }}
                    >
                      <ReactTranslation contentKey={"Dialog.Buttons.Save"} />
                    </Button>
                  )}
                </div>
              </DialogActions>
            )}
          </Dialog>
          {/* upload video popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.uploadVideoPopup}
            onClose={() => {
              this.handleCloseUploadPopup();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.handleCloseUploadPopup();
              }}
              alignItems="center"
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Video_Page.Upload_Elevator_Pitch"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"span"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Upload_Elevator_Pitch"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Upload_Elevator_Pitch"}
                />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(classes.mediumTopMargin, classes.dragBox)}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Upload_Your_Video"
                    placement="bottom"
                  >
                    <Typography
                      className={classNames(
                        classes.topMargin,
                        classes.textCenter,
                        classes.uploadText
                      )}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Upload_Your_Video"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography
                    className={classNames(
                      classes.topMargin,
                      classes.textCenter,
                      classes.uploadText
                    )}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Upload_Your_Video"}
                    />
                  </Typography>
                )}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classNames(classes.mediumTopMargin)}
                >
                  <Avatar
                    alt={"video-image"}
                    src={insertDrive}
                    className={classNames(
                      classes.customLarge,
                      classes.alignCenter
                    )}
                  ></Avatar>
                </Grid>
                <input
                  hidden
                  id="insightVideo"
                  type="file"
                  accept=".mp4, .avi, .mov, .mkv, .wmv, .flv, .webm, .ogv, .mpeg, .mpg"
                />
                <div
                  className={classNames(classes.topMargin, classes.textCenter)}
                >
                  {this.state.uploadedVideo && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      align="center"
                    >
                      <Typography component="span" color="textSecondary">
                        {this.state.uploadedVideo.name}
                      </Typography>
                      <Tooltip title="Delete" arrow placement="bottom">
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleDeleteUploadedVideo();
                          }}
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  {!this.state.uploadedVideo ? (
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Dialog.Video_Page.Browse"
                        placement="right-end"
                      >
                        <Button
                          id="btn_browse_1"
                          disableElevation
                          variant="outlined"
                          color="primary"
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleVideoUpload();
                          }}
                          className={classes.buttonBorder}
                        >
                          <ReactTranslation
                            contentKey={"Dialog.Video_Page.Browse"}
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        id="btn_browse_1"
                        disableElevation
                        variant="outlined"
                        color="primary"
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleVideoUpload();
                        }}
                        className={classes.buttonBorder}
                      >
                        <ReactTranslation
                          contentKey={"Dialog.Video_Page.Browse"}
                        />
                      </Button>
                    )
                  ) : null}
                </div>
                {!this.state.uploadedVideo ? (
                  baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Dialog.Video_Page.Allowed_File"
                      placement="right-end"
                    >
                      <Typography
                        component="div"
                        color="secondary"
                        className={classNames(
                          classes.smallTopMargin,
                          classes.textCenter,
                          classes.smallBottomMargin
                        )}
                      >
                        {
                          <ReactTranslation
                            contentKey={"Dialog.Video_Page.Allowed_File"}
                          />
                        }
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      component="div"
                      color="secondary"
                      className={classNames(
                        classes.smallTopMargin,
                        classes.textCenter,
                        classes.smallBottomMargin
                      )}
                    >
                      {
                        <ReactTranslation
                          contentKey={"Dialog.Video_Page.Allowed_File"}
                        />
                      }
                    </Typography>
                  )
                ) : null}
              </Grid>
              {this.state.videoSaved && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classNames(classes.smallTopMargin)}
                >
                  <div className={classes.textCenter}>
                    <Typography component="div" className={classes.topMargin}>
                      <LinearProgressWithLabel
                        variant="determinate"
                        value={this.state.progress}
                      />
                    </Typography>
                  </div>
                </Grid>
              )}
            </DialogContent>
            {this.state.videoUploaded && !this.state.videoSaved && (
              <DialogActions
                className={classNames(
                  classes.verySmallBottomMargin,
                  classes.justifyCenter
                )}
              >
                <div className={classes.textCenter}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Dialog.Buttons.Upload"
                      placement="right-end"
                    >
                      <Button
                        id="uploadvideoSuccess"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.buttonPadding}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveUploadedVideo();
                        }}
                      >
                        {
                          <ReactTranslation
                            contentKey={"Dialog.Buttons.Upload"}
                          />
                        }
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="uploadvideoSuccess"
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={classes.buttonPadding}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveUploadedVideo();
                      }}
                    >
                      {
                        <ReactTranslation
                          contentKey={"Dialog.Buttons.Upload"}
                        />
                      }
                    </Button>
                  )}
                </div>
              </DialogActions>
            )}
          </Dialog>
          {/* Confirm retake video popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.newVideoPopup}
            onClose={() => {
              this.handleCloseRetakePopup();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.handleCloseRetakePopup();
              }}
              alignItems="center"
            >
              {this.state.videoType === "record" ? (
                baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Record_New_Video"
                    placement="right-end"
                  >
                    <Typography
                      variant="h6"
                      component={"span"}
                      className={classes.dialogTitle}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Record_New_Video"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <ReactTranslation
                    contentKey={"Dialog.Video_Page.Record_New_Video"}
                  />
                )
              ) : baseUrlDev ? (
                <Tooltip
                  title="Dialog.Video_Page.Upload_New_Video"
                  placement="right-end"
                >
                  <Typography
                    component={"span"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Upload_New_Video"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Upload_New_Video"}
                />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.textCenter}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Comfirm_Popup_Content_1"
                    placement="right-end"
                  >
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Comfirm_Popup_Content_1"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <ReactTranslation
                    contentKey={"Dialog.Video_Page.Comfirm_Popup_Content_1"}
                  />
                )}
                <Typography component={"span"}>
                  {this.state.videoType === "record" ? "record" : "upload"}
                </Typography>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Comfirm_Popup_Content_2"
                    placement="right-end"
                  >
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Comfirm_Popup_Content_2"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <ReactTranslation
                    contentKey={"Dialog.Video_Page.Comfirm_Popup_Content_2"}
                  />
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.smallTopMargin
              )}
            >
              <Grid container className={classes.videoRetakeButton}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Cancel"
                    placement="left-end"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleCloseRetakePopup();
                      }}
                    >
                      <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleCloseRetakePopup();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                  </Button>
                )}
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Confirm"
                    placement="right-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleConfirmRetakeVideoPopup();
                      }}
                    >
                      <ReactTranslation contentKey={"Dialog.Buttons.Confirm"} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleConfirmRetakeVideoPopup();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Confirm"} />
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </Dialog>
          {/* Confirm previous page popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.confirmPopup}
            onClose={() => {
              this.setState({ confirmPopup: false });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.setState({ confirmPopup: false });
              }}
              alignItems="center"
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Video_Page.Go_Back_Popup_Heading"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"div"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Go_Back_Popup_Heading"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Go_Back_Popup_Heading"}
                />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.textCenter}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Go_Back_Popup_Content"
                    placement="right-end"
                  >
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Dialog.Video_Page.Go_Back_Popup_Content"}
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography>
                    {" "}
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Go_Back_Popup_Content"}
                    />
                  </Typography>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.smallTopMargin
              )}
            >
              <Grid container className={classes.videoRetakeButton}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Cancel"
                    placement="left-end"
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ confirmPopup: false });
                      }}
                    >
                      <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.setState({ confirmPopup: false });
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                  </Button>
                )}
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Confirm"
                    placement="right-end"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleConfirmGoBack();
                      }}
                    >
                      <ReactTranslation contentKey={"Dialog.Buttons.Confirm"} />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleConfirmGoBack();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Confirm"} />
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </Dialog>
          {/* Skip confirm popup */}
          <Dialog
            fullWidth
            maxWidth="sm"
            disableBackdropClick
            open={this.state.skipPopup}
            onClose={() => {
              this.setState({ skipPopup: false });
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.setState({ skipPopup: false });
              }}
              alignItems="center"
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Video_Page.Skip_Video_Popup_Heading"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"span"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Skip_Video_Popup_Heading"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Skip_Video_Popup_Heading"}
                />
              )}
            </DialogTitle>
            <DialogContent>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classNames(
                  classes.textCenter,
                  classes.smallTopMargin
                )}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Video_Page.Skip_Video_Popup_Content"
                    placement="right-end"
                  >
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={
                          "Dialog.Video_Page.Skip_Video_Popup_Content"
                        }
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography>
                    {" "}
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Skip_Video_Popup_Content"}
                    />
                  </Typography>
                )}
              </Grid>
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.smallTopMargin
              )}
            >
              <Grid container className={classes.skipButton}>
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Yes_Skip"
                    placement="left-end"
                  >
                    <Button
                      id="videoSkipPreviousButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSkipVideo();
                      }}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Buttons.Yes_Skip"}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    id="videoSkipPreviousButton"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleSkipVideo();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Yes_Skip"} />
                  </Button>
                )}
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Dialog.Buttons.Add_Video_Now"
                    placement="right-end"
                  >
                    <Button
                      id="videoAddPreviousButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.setState({ skipPopup: false });
                      }}
                    >
                      <ReactTranslation
                        contentKey={"Dialog.Buttons.Add_Video_Now"}
                      />
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    id="videoAddPreviousButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.setState({ skipPopup: false });
                    }}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Buttons.Add_Video_Now"}
                    />
                  </Button>
                )}
              </Grid>
            </DialogActions>
          </Dialog>
          {/* Schedule meeting popup */}
          <Dialog
            fullWidth
            maxWidth="lg"
            disableBackdropClick
            open={this.state.calendlyPopup}
            onClose={() => {
              this.handleCloseCalendlyPopup();
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              onClose={() => {
                this.handleCloseCalendlyPopup();
              }}
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Video_Page.Schedule_A_Meeting_Title"
                  placement="right-end"
                >
                  <Typography
                    variant="h6"
                    component={"span"}
                    className={classes.dialogTitle}
                  >
                    <ReactTranslation
                      contentKey={"Dialog.Video_Page.Schedule_A_Meeting_Title"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <ReactTranslation
                  contentKey={"Dialog.Video_Page.Schedule_A_Meeting_Title"}
                />
              )}
            </DialogTitle>
            <DialogContent className={classes.fullHeight}>
              <iframe
                loading="eager"
                allowFullScreen
                src={CALNEDLY_SCHEDULE_LINK}
                name="scheduleMeetingIframe"
                height="98%"
                width="100%"
                title="Schedule A Meeting"
              />
            </DialogContent>
            <DialogActions
              className={classNames(
                classes.verySmallBottomMargin,
                classes.smallTopMargin,
                classes.justifyCenter
              )}
            >
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Dialog.Buttons.Cancel"
                  placement="right-end"
                >
                  <Button
                    id="cancelScheduleMeetingButton"
                    variant="outlined"
                    color="primary"
                    disableElevation
                    onClick={(event) => {
                      event.preventDefault();
                      this.handleCloseCalendlyPopup();
                    }}
                  >
                    <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  id="cancelScheduleMeetingButton"
                  variant="outlined"
                  color="primary"
                  disableElevation
                  onClick={(event) => {
                    event.preventDefault();
                    this.handleCloseCalendlyPopup();
                  }}
                >
                  <ReactTranslation contentKey={"Dialog.Buttons.Cancel"} />
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
    );
  }
}

Video.propTypes = {
  classes: PropTypes.object.isRequired,
  onStartRecording: PropTypes.func,
  onStopRecording: PropTypes.func,
  onPauseRecording: PropTypes.func,
};

function mapStateToProps(state) {
  return state;
}
const connectedVideoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Video))
);
export { connectedVideoPage as Video };
