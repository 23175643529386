import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  seo,
  history,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  setTitle,
  sharedActions,
  preferencesPage,
  preferencesActions,
} from "../../../../actions";
import {
  Grid,
  Radio,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
  CONSTANTS,
} from "../../../../constants/constants";
import { toast } from "react-toastify";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  TopBottomMargin: {
    marginBottom: "32px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  veryMediumTopMargin: {
    marginTop: "16px",
  },
  preferencesHeading: {
    fontWeight: "700",
    color: "#14131F",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  workPreferedButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  workpreferedText: {
    color: "#14131F",
    fontWeight: "700",
  },
  formHelperText: {
    color: "#ff0000",
  },
  fontSize12px: {
    fontSize: "12px",
  },
});

class WorkPrefrences extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    workPrefered:
      this.props?.preferences?.workEnvData?.desired_job_work_style !== null
        ? this.props?.preferences?.workEnvData?.desired_job_work_style
        : null,
    isWorkPreferedRequired: false,
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setTitle(`${translationEN.PageTitle.WorkEnviorment_Page_Title}`));
    dispatch(preferencesActions.getWorkEnviroment());
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.WORK_ENVIROMENT_PAGE_LOAD,
      SCREENS.WORK_ENVIROMENT_SCREEN,
      ACTIONS.WORK_ENVIROMENT_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
    if (
      prevProps?.preferences?.workEnvData?.desired_job_work_style !==
      this.props?.preferences?.workEnvData?.desired_job_work_style
    ) {
      this.setState({
        workPrefered:
          this.props?.preferences?.workEnvData?.desired_job_work_style !== null
            ? this.props?.preferences?.workEnvData?.desired_job_work_style
            : null,
      });
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleChange = (event) => {
    switch (event.target.value) {
      case CONSTANTS.NO_PREDERENCES:
        this.setState({
          workPrefered: event.target.value,
          isWorkPreferedRequired: false,
        });
        break;
      case CONSTANTS.REMOTE_ONLY:
        this.setState({
          workPrefered: event.target.value,
          isWorkPreferedRequired: false,
        });
        break;
      case CONSTANTS.HYBRID:
        this.setState({
          workPrefered: event.target.value,
          isWorkPreferedRequired: false,
        });
        break;
      case CONSTANTS.IN_OFFICE:
        this.setState({
          workPrefered: event.target.value,
          isWorkPreferedRequired: false,
        });
        break;
      default:
        this.setState({ workPrefered: event.target.value });
        break;
    }
  };

  handleSaveWorkEnv = () => {
    const { dispatch } = this.props;
    if (
      this.state.workPrefered === null ||
      this.state.workPrefered === "" ||
      this.state.workPrefered === undefined
    ) {
      this.setState({ isWorkPreferedRequired: true });
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            arrow
            title="Toast_Message.Work_Env_Page.Please_Select_Option_Message"
            placement="left-end"
          >
            <Typography>
              {
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Work_Env_Page.Please_Select_Option_Message"
                  }
                />
              }
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={
              "Toast_Message.Work_Env_Page.Please_Select_Option_Message"
            }
          />
        )
      );
      return;
    }
    let payload = {
      work_style: this.state.workPrefered,
    };
    let progressBarStepValue = 87.5;
    dispatch(
      preferencesActions.saveWorkEnviroment(payload, progressBarStepValue)
    );
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.WORK_ENVIROMENT_NEXT_CLICK,
      SCREENS.WORK_ENVIROMENT_SCREEN,
      ACTIONS.WORK_ENVIROMENT_NEXT_CLICKED
    );
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(preferencesPage(1));
    dispatch(sharedActions.setProgressbarValue(75));
    history.push("/onboarding/preferences-salary");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.WORK_ENVIROMENT_PREVIOUS_CLICK,
      SCREENS.WORK_ENVIROMENT_SCREEN,
      ACTIONS.WORK_ENVIROMENT_PREVIOUS_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;

    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                <FormControl component="fieldset" className={classes.fullWidth}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Work_Env_Page.Work_Env_Heading"
                      placement="left-end"
                    >
                      <Typography
                        variant="h5"
                        className={classNames(
                          classes.smallTopMargin,
                          classes.TopBottomMargin,
                          classes.workpreferedText
                        )}
                        component="legend"
                      >
                        {
                          <ReactTranslation
                            contentKey={"Work_Env_Page.Work_Env_Heading"}
                          />
                        }
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      variant="h5"
                      className={classNames(
                        classes.smallTopMargin,
                        classes.TopBottomMargin,
                        classes.workpreferedText
                      )}
                      component="legend"
                    >
                      {
                        <ReactTranslation
                          contentKey={"Work_Env_Page.Work_Env_Heading"}
                        />
                      }
                    </Typography>
                  )}
                  <RadioGroup
                    key={new Date().getMilliseconds()}
                    aria-label="email-label"
                    name="email"
                    value={this.state.workPrefered}
                    onChange={(event) => {
                      event.preventDefault();
                      this.handleChange(event);
                    }}
                  >
                    <Grid container>
                      {this?.props.authentication?.constants?.work_style?.map(
                        (option) => (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            className={classes.displayFlex}
                          >
                            <FormControlLabel
                              name="workEnv"
                              id="workEnv"
                              control={<Radio />}
                              label={
                                baseUrlDev ? (
                                  <Tooltip
                                    title={
                                      option === CONSTANTS.NO_PREDERENCES
                                        ? "Work_Env_Page.No_Preference"
                                        : option === CONSTANTS.REMOTE_ONLY
                                        ? "Work_Env_Page.Remote_Only"
                                        : option === CONSTANTS.HYBRID
                                        ? "Work_Env_Page.Hybrid"
                                        : option === CONSTANTS.IN_OFFICE
                                        ? "Work_Env_Page.In_Office"
                                        : null
                                    }
                                    placement="right-end"
                                    arrow
                                  >
                                    <Typography component={"span"}>
                                      <ReactTranslation
                                        contentKey={
                                          option === CONSTANTS.NO_PREDERENCES
                                            ? "Work_Env_Page.No_Preference"
                                            : option === CONSTANTS.REMOTE_ONLY
                                            ? "Work_Env_Page.Remote_Only"
                                            : option === CONSTANTS.HYBRID
                                            ? "Work_Env_Page.Hybrid"
                                            : option === CONSTANTS.IN_OFFICE
                                            ? "Work_Env_Page.In_Office"
                                            : null
                                        }
                                      />
                                    </Typography>
                                  </Tooltip>
                                ) : (
                                  <Typography component={"span"}>
                                    <ReactTranslation
                                      contentKey={
                                        option === "No preferences"
                                          ? "Work_Env_Page.No_Preference"
                                          : option === CONSTANTS.REMOTE_ONLY
                                          ? "Work_Env_Page.Remote_Only"
                                          : option === CONSTANTS.HYBRID
                                          ? "Work_Env_Page.Hybrid"
                                          : option === CONSTANTS.IN_OFFICE
                                          ? "Work_Env_Page.In_Office"
                                          : null
                                      }
                                    />
                                  </Typography>
                                )
                              }
                              value={option}
                            />
                          </Grid>
                        )
                      )}
                    </Grid>
                  </RadioGroup>
                  {this.state.isWorkPreferedRequired ? (
                    <FormHelperText className={classes.formHelperText}>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                <div
                  className={classNames(
                    classes.TopMargin,
                    classes.workPreferedButton
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="workPreferencesPreviousButton"
                        variant="outlined"
                        color="primary"
                        className={classes.prevButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="workPreferencesPreviousButton"
                      variant="outlined"
                      color="primary"
                      className={classes.prevButton}
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="workPreferencesNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.prevButton}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveWorkEnv();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="workPreferencesNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={classes.prevButton}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveWorkEnv();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

WorkPrefrences.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedWorkPrefrencesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(WorkPrefrences))
);
export { connectedWorkPrefrencesPage as WorkPrefrences };
