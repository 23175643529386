import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  seo,
  history,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  setTitle,
  sharedActions,
  preferencesPage,
  preferencesActions,
} from "../../../../actions";
import {
  Grid,
  Typography,
  Radio,
  FormControl,
  Button,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import ReactTranslation from "../../../core/iReact18";
import translationEN from "../../../../locales/en/translation.json";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  TopBottomMargin: {
    marginBottom: "32px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  fullWidth: {
    width: "100%",
  },
  diversityButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
  },
  preferencesHeading: {
    fontWeight: "700",
    color: "#14131F",
  },
  fontSize12px: {
    fontSize: "12px",
  },
});

class DiversityPreferences extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    diversity: this.props?.preferences?.memberDiversityData?.diversity
      ? "true"
      : "false",
    shareWithCompanies: this.props?.preferences?.memberDiversityData
      ?.share_with_companies
      ? "true"
      : "false",
    isShareWithCompaniesRequired: false,
    isDiversityRequired: false,
    helpTextCardType: "diversityHelpText",
    baseUrlDev: false,
  });
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setTitle(`${translationEN.PageTitle.Diversity_Page_Title}`));
    dispatch(preferencesActions.getMemberDiversity());
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.DIVERSITY_PAGE_LOAD,
      SCREENS.DIVERSITY_SCREEN,
      ACTIONS.DIVERSITY_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
    if (
      prevProps?.preferences?.memberDiversityData?.diversity !==
      this.props?.preferences?.memberDiversityData?.diversity
    ) {
      this.setState({
        diversity: this.props?.preferences?.memberDiversityData?.diversity
          ? "true"
          : "false",
      });
    }

    if (
      prevProps?.preferences?.memberDiversityData?.share_with_companies !==
      this.props?.preferences?.memberDiversityData?.share_with_companies
    ) {
      this.setState({
        shareWithCompanies: this.props?.preferences?.memberDiversityData
          ?.share_with_companies
          ? "true"
          : "false",
      });
    }

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleSaveDiversityInfo = () => {
    const { dispatch } = this.props;
    const { shareWithCompanies, diversity } = this.state;
    let progressBarStepValue = 93.75;
    let payload = {
      share_with_companies: shareWithCompanies === "true" ? true : false,
      diversity: diversity === "true" ? true : false,
    };
    dispatch(
      preferencesActions.candidateUpdate(
        payload,
        this.props?.authentication?.environment,
        this.props?.authentication?.key,
        progressBarStepValue
      )
    );
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.DIVERSITY_NEXT_CLICK,
      SCREENS.DIVERSITY_SCREEN,
      ACTIONS.DIVERSITY_NEXT_CLICKED
    );
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    let progressBarStepValue = 81.25;
    dispatch(preferencesPage(2));
    dispatch(sharedActions.setProgressbarValue(progressBarStepValue));
    history.push("/onboarding/preferences-work");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.DIVERSITY_PREVIOUS_CLICK,
      SCREENS.DIVERSITY_SCREEN,
      ACTIONS.DIVERSITY_PREVIOUS_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;

    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title="Diversity_Page.Diversity_candidate"
                  placement="left-end"
                >
                  <Typography
                    align="left"
                    variant="h5"
                    className={classNames(
                      classes.preferencesHeading,
                      classes.smallTopMargin,
                      classes.TopBottomMargin
                    )}
                  >
                    <ReactTranslation
                      contentKey={"Diversity_Page.Diversity_candidate"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  align="left"
                  variant="h5"
                  className={classNames(
                    classes.preferencesHeading,
                    classes.smallTopMargin,
                    classes.TopBottomMargin
                  )}
                >
                  <ReactTranslation
                    contentKey={"Diversity_Page.Diversity_candidate"}
                  />
                </Typography>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                {baseUrlDev ? (
                  <Tooltip
                    arrow
                    title="Diversity_Page.Welcome_message"
                    placement="left-end"
                  >
                    <Typography component={"div"} align="left">
                      {
                        <ReactTranslation
                          contentKey={"Diversity_Page.Welcome_message"}
                        />
                      }
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component={"div"} align="left">
                    {
                      <ReactTranslation
                        contentKey={"Diversity_Page.Welcome_message"}
                      />
                    }
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                align="left"
                className={classes.verySmallTopMargin}
              >
                <FormControl
                  required
                  component="fieldset"
                  className={classNames(
                    classes.fullWidth,
                    classes.verySmallTopMargin
                  )}
                  error={this.state.isDiversityRequired}
                >
                  <RadioGroup
                    aria-label="diversity-candidate"
                    name="diversity-candidate"
                    value={this.state.diversity}
                    onChange={(event, value) => {
                      if (value) {
                        this.setState({
                          diversity: value,
                          isDiversityRequired: false,
                          isShareWithCompaniesRequired: false,
                          shareWithCompanies: "false",
                        });
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Diversity_Page.I_dentify_diverse_candidate"
                                placement="left-end"
                              >
                                <Typography>
                                  <ReactTranslation
                                    contentKey={
                                      "Diversity_Page.I_dentify_diverse_candidate"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>
                                <ReactTranslation
                                  contentKey={
                                    "Diversity_Page.I_dentify_diverse_candidate"
                                  }
                                />
                              </Typography>
                            )
                          }
                          value="true"
                        />
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormControlLabel
                          control={<Radio />}
                          label={
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Diversity_Page.Dont_identify_diverse_candidate"
                                placement="left-end"
                              >
                                <Typography>
                                  <ReactTranslation
                                    contentKey={
                                      "Diversity_Page.Dont_identify_diverse_candidate"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography>
                                <ReactTranslation
                                  contentKey={
                                    "Diversity_Page.Dont_identify_diverse_candidate"
                                  }
                                />
                              </Typography>
                            )
                          }
                          value="false"
                        />
                      </Grid>
                    </Grid>
                  </RadioGroup>
                  {this.state.isDiversityRequired ? (
                    <FormHelperText>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              {this.state.diversity === "true" && (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  align="left"
                  className={classes.verySmallTopMargin}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.TopMargin}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Diversity_Page.Potential_employers"
                        placement="left-end"
                      >
                        <Typography component={"div"} align="left">
                          {
                            <ReactTranslation
                              contentKey={"Diversity_Page.Potential_employers"}
                            />
                          }
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"div"} align="left">
                        {
                          <ReactTranslation
                            contentKey={"Diversity_Page.Potential_employers"}
                          />
                        }
                      </Typography>
                    )}
                  </Grid>
                  <FormControl
                    className={classNames(
                      classes.fullWidth,
                      classes.verySmallTopMargin
                    )}
                    required
                    error={this.state.isShareWithCompaniesRequired}
                  >
                    <RadioGroup
                      aria-labelledby="shareWithCompanies"
                      name="shareWithCompanies"
                      value={this.state.shareWithCompanies}
                      onChange={(event, value) => {
                        if (value) {
                          this.setState({
                            shareWithCompanies: value,
                            isShareWithCompaniesRequired: false,
                          });
                        }
                      }}
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label={
                          baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Diversity_Page.Yes"
                              placement="left-end"
                            >
                              <Typography>
                                <ReactTranslation
                                  contentKey={"Diversity_Page.Yes"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              <ReactTranslation
                                contentKey={"Diversity_Page.Yes"}
                              />
                            </Typography>
                          )
                        }
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label={
                          baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Diversity_Page.No"
                              placement="left-end"
                            >
                              <Typography>
                                <ReactTranslation
                                  contentKey={"Diversity_Page.No"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography>
                              <ReactTranslation
                                contentKey={"Diversity_Page.No"}
                              />
                            </Typography>
                          )
                        }
                      />
                    </RadioGroup>
                    {this.state.isShareWithCompaniesRequired ? (
                      <FormHelperText>
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Validation_Messages.Field_Required"
                            placement="left-end"
                          >
                            <Typography
                              component={"span"}
                              className={classes.fontSize12px}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Validation_Messages.Field_Required"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        )}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                <div
                  className={classNames(
                    classes.TopMargin,
                    classes.diversityButton
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="diversityPreviousButton"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={() => this.handlePrevious()}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="diversityPreviousButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={() => this.handlePrevious()}
                    >
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="diversityNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => this.handleSaveDiversityInfo()}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="diversityNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.handleSaveDiversityInfo()}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

DiversityPreferences.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedDiversityPreferencesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(DiversityPreferences))
);
export { connectedDiversityPreferencesPage as DiversityPreferences };
