import React, { Component } from "react";
import axios from "axios";
import "./App.css";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import AppBar from "./components/core/appbar";
import { Onboarding } from "./components/views/onboarding";
import {
  LogIn,
  JobLandingPage,
  VipLandingPage,
  Loading,
} from "./components/views/login";
import { history } from "./helpers";
import { PrivateRoute } from "./components";
import { loading, getJobList, getVipJobList } from "./actions";
import AppLoader from "./components/core/apploader";
import { connect } from "react-redux";
import Toaster from "./components/core/toaster";
import { authActions } from "../src/actions";
import secureLocalStorage from "react-secure-storage";
import BadRequest from "./components/core/badRequest";

class App extends Component {
  constructor(props) {
    super(props);
    const self = this;

    window.addEventListener("storage", () => {
      window.location = props?.authentication?.originalUrlPath
        ? props?.authentication?.originalUrlPath
        : JSON.parse(secureLocalStorage.getItem("state"))?.authentication
            ?.originalUrlPath
        ? JSON.parse(secureLocalStorage.getItem("state"))?.authentication
            ?.originalUrlPath
        : "/login";

      window.removeEventListener("storage", (e) => {
        e;
      });
    });

    var pendingAPICallsCount = 0;
    axios.interceptors.request.use(
      function (config) {
        pendingAPICallsCount++;
        // show loader
        if (
          config.url.indexOf("skill_search") !== -1 ||
          config.url.indexOf("search_index") !== -1 ||
          config.url.indexOf("certificate_search") !== -1 ||
          config.url.indexOf("degree_search") !== -1 ||
          config.url.indexOf("major_search") !== -1 ||
          config.url.indexOf("clearbit") !== -1
        ) {
          self.props.loading(false);
        } else {
          self.props.loading(true);
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      function (response) {
        pendingAPICallsCount--;
        // hide loader
        if (pendingAPICallsCount === 0) {
          self.props.loading(false);
        }
        return response;
      },
      function (error) {
        pendingAPICallsCount--;
        // hide loader
        if (pendingAPICallsCount === 0) {
          self.props.loading(false);
        }
        return Promise.reject(error);
      }
    );
    this.currentPathname = null;
    this.currentSearch = null;
    this.state = {
      completedApproved: false,
    };
  }

  render() {
    return (
      <div className="App">
        {this.props.shared?.badRequest !== true && <AppBar />}
        {this.props.loader ? <AppLoader /> : null}
        <Toaster />
        <Router {...this.props} history={history}>
          <React.Fragment>
            <Switch>
              {!this.props?.loggedIn && (
                <PrivateRoute
                  exact
                  path={`/vip/:name`}
                  component={VipLandingPage}
                />
              )}
              {!this.props?.loggedIn && (
                <PrivateRoute
                  exact
                  path={`/job/:name`}
                  component={JobLandingPage}
                />
              )}
              {/* {!this.props?.loggedIn &&
                <PrivateRoute exact path = {`/vip/:name/:id`}  component={VipLandingPage} />
              }

              {!this.props?.loggedIn &&
                <PrivateRoute exact path = {`/job/:name/:id`}  component={JobLandingPage} />
              } */}
              {!this.props?.loggedIn && (
                <PrivateRoute exact path="/login" component={LogIn} />
              )}
              {!this.props?.loggedIn && (
                <PrivateRoute exact path="/loading" component={Loading} />
              )}
              {!this.props?.loggedIn && (
                <PrivateRoute exact path="/onboarding" component={Onboarding} />
              )}
              {!this.props?.loggedIn && (
                <PrivateRoute
                  exact
                  path="/onboarding/basic-info-step-1"
                  component={Onboarding}
                />
              )}
              {/* <Route path='/login' component={window.location.pathname.match('^/login.*$') && !this.props?
              .loggedIn ? LogIn : Onboarding} /> */}
              <Route
                path={`/job/${JSON.parse(
                  secureLocalStorage.getItem("jobName")
                )}`}
                render={() => (
                  <Redirect
                    to={{
                      pathname:
                        window.location.pathname === "/job"
                          ? "/login"
                          : window.location.pathname.match("^/job/.*$") &&
                            !this.props?.loggedIn
                          ? window.location.pathname
                          : !this.props?.loggedIn
                          ? "/login"
                          : "/onboarding",
                    }}
                  />
                )}
              />
              <Route
                path={`/vip/${JSON.parse(
                  secureLocalStorage.getItem("vipJobName")
                )}`}
                render={() => (
                  <Redirect
                    to={{
                      pathname:
                        window.location.pathname === "/vip"
                          ? "/login"
                          : window.location.pathname.match("^/vip/.*$") &&
                            !this.props?.loggedIn
                          ? window.location.pathname
                          : !this.props?.loggedIn
                          ? "/login"
                          : "/onboarding",
                    }}
                  />
                )}
              />
              {/* <Route path = {`/job/${JSON.parse(secureLocalStorage.getItem('jobName'))}/${JSON.parse(secureLocalStorage.getItem('jobId'))}`}  render={() => <Redirect to={{ pathname: window.location.pathname === '/job' ? '/login' : window.location.pathname.match('^/job/.*$') && !this.props?.loggedIn ? window.location.pathname : !this.props?.loggedIn ? '/login' : '/onboarding'}} />} />
              
              <Route path = {`/vip/${JSON.parse(secureLocalStorage.getItem('vipJobName'))}/${JSON.parse(secureLocalStorage.getItem('vipJobId'))}`}  render={() => <Redirect to={{ pathname: window.location.pathname === '/vip' ? '/login' : window.location.pathname.match('^/vip/.*$') && !this.props?.loggedIn ? window.location.pathname : !this.props?.loggedIn ? '/login' : '/onboarding'}} />} /> */}
              <Route
                path="/login"
                render={() => (
                  <Redirect
                    to={{
                      pathname:
                        window.location.pathname.match("^/login.*$") &&
                        !this.props?.loggedIn
                          ? "/login"
                          : !this.props?.loggedIn
                          ? "/login"
                          : "/onboarding",
                    }}
                  />
                )}
              />
              <Route
                path="/login"
                render={() => (
                  <Redirect
                    to={{ pathname: !this.props?.loggedIn ? "/login" : "/" }}
                  />
                )}
              />
              <Route path="/bad-request" exact component={BadRequest} />;
              <Route
                exact
                path="/onboarding"
                render={() => (
                  <Redirect
                    to={{
                      pathname:
                        // this.props?.activeStep === 0 ? '/onboarding/basic-info' :
                        this.props?.activeStep === 0 &&
                        this.props?.basicInfoPage === 1
                          ? "/onboarding/basic-info-step-1"
                          : this.props?.activeStep === 0 &&
                            this.props?.basicInfoPage === 2
                          ? "/onboarding/basic-info-step-2"
                          : this.props?.activeStep === 1
                          ? "/onboarding/professional-info"
                          : this.props?.activeStep === 2 &&
                            this.props?.careerPage === 1
                          ? "/onboarding/career-highlights-accomplishments"
                          : this.props?.activeStep === 2 &&
                            this.props?.careerPage === 2
                          ? "/onboarding/career-highlights-competencies"
                          : this.props?.activeStep === 2 &&
                            this.props?.careerPage === 3
                          ? "/onboarding/career-highlights-coffeequestion"
                          : this.props?.activeStep === 2 &&
                            this.props?.careerPage === 4
                          ? "/onboarding/career-highlights-video"
                          : this.props?.activeStep === 3 &&
                            this.props?.preferencesPage === 1
                          ? "/onboarding/preferences-salary"
                          : this.props?.activeStep === 3 &&
                            this.props?.preferencesPage === 2
                          ? "/onboarding/preferences-work"
                          : this.props?.activeStep === 3 &&
                            this.props?.preferencesPage === 3
                          ? "/onboarding/preferences-diversity"
                          : this.props?.activeStep === 3 &&
                            this.props?.preferencesPage === 4
                          ? "/onboarding/preferences-jobrole"
                          : // this.props?.activeStep === 3 ? '/onboarding/diversity-preferences' :
                            "/onboarding/onboarding-completed",
                    }}
                  />
                )}
              />
              {/* {this.props?.activeStep === 0 && this.props?.loggedIn &&
                <PrivateRoute path='/onboarding/basic-info' component={ Onboarding }/>
              } */}
              {this.props?.activeStep === 0 &&
                this.props?.loggedIn &&
                this.props?.basicInfoPage === 1 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/basic-info-step-1"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 0 &&
                this.props?.loggedIn &&
                this.props?.basicInfoPage === 2 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/basic-info-step-2"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 1 && this.props?.loggedIn && (
                <PrivateRoute
                  exact
                  path="/onboarding/professional-info"
                  component={Onboarding}
                />
              )}
              {this.props?.activeStep === 2 &&
                this.props?.loggedIn &&
                this.props?.careerPage === 1 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/career-highlights-accomplishments"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 2 &&
                this.props?.loggedIn &&
                this.props?.careerPage === 2 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/career-highlights-competencies"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 2 &&
                this.props?.loggedIn &&
                this.props?.careerPage === 3 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/career-highlights-coffeequestion"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 2 &&
                this.props?.loggedIn &&
                this.props?.careerPage === 4 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/career-highlights-video"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 3 &&
                this.props?.loggedIn &&
                this.props?.preferencesPage === 1 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/preferences-salary"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 3 &&
                this.props?.loggedIn &&
                this.props?.preferencesPage === 2 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/preferences-work"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 3 &&
                this.props?.loggedIn &&
                this.props?.preferencesPage === 3 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/preferences-diversity"
                    component={Onboarding}
                  />
                )}
              {this.props?.activeStep === 3 &&
                this.props?.loggedIn &&
                this.props?.preferencesPage === 4 && (
                  <PrivateRoute
                    exact
                    path="/onboarding/preferences-jobrole"
                    component={Onboarding}
                  />
                )}
              {/* {this.props?.activeStep === 3 && this.props?.loggedIn &&
                <PrivateRoute exact path='/onboarding/diversity-preferences' component={ Onboarding }/>
              } */}
              {this.props?.activeStep === 4 && this.props?.loggedIn && (
                <PrivateRoute
                  exact
                  path="/onboarding/onboarding-completed"
                  component={Onboarding}
                />
              )}
              {/* <PrivateRoute exact path='/onboarding/career-highlights-competencies' component={ Onboarding }/> */}
              <Route
                path="/"
                render={() => (
                  <Redirect
                    to={{
                      pathname: !this.props?.loggedIn
                        ? "/login"
                        : // this.props?.activeStep === 0 ? '/onboarding/basic-info' :
                        this.props?.activeStep === 0 &&
                          this.props?.basicInfoPage === 1
                        ? "/onboarding/basic-info-step-1"
                        : this.props?.activeStep === 0 &&
                          this.props?.basicInfoPage === 2
                        ? "/onboarding/basic-info-step-2"
                        : this.props?.activeStep === 1
                        ? "/onboarding/professional-info"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 1
                        ? "/onboarding/career-highlights-accomplishments"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 2
                        ? "/onboarding/career-highlights-competencies"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 3
                        ? "/onboarding/career-highlights-coffeequestion"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 4
                        ? "/onboarding/career-highlights-video"
                        : // this.props?.activeStep === 3 ? '/onboarding/diversity-preferences' :
                        this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 1
                        ? "/onboarding/preferences-salary"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 2
                        ? "/onboarding/preferences-work"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 3
                        ? "/onboarding/preferences-diversity"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 4
                        ? "/onboarding/preferences-jobrole"
                        : this.props?.activeStep === 4
                        ? "/onboarding/onboarding-completed"
                        : "/",
                    }}
                  />
                )}
              />
              <Route
                path="*"
                render={() => (
                  <Redirect
                    to={{
                      pathname: !this.props?.loggedIn
                        ? "/login"
                        : // this.props?.activeStep === 0 ? '/onboarding/basic-info' :
                        this.props?.activeStep === 0 &&
                          this.props?.basicInfoPage === 1
                        ? "/onboarding/basic-info-step-1"
                        : this.props?.activeStep === 0 &&
                          this.props?.basicInfoPage === 2
                        ? "/onboarding/basic-info-step-2"
                        : this.props?.activeStep === 1
                        ? "/onboarding/professional-info"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 1
                        ? "/onboarding/career-highlights-accomplishments"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 2
                        ? "/onboarding/career-highlights-competencies"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 3
                        ? "/onboarding/career-highlights-coffeequestion"
                        : this.props?.activeStep === 2 &&
                          this.props?.careerPage === 4
                        ? "/onboarding/career-highlights-video"
                        : // this.props?.activeStep === 3 ? '/onboarding/diversity-preferences' :
                        this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 1
                        ? "/onboarding/preferences-salary"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 2
                        ? "/onboarding/preferences-work"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 3
                        ? "/onboarding/preferences-diversity"
                        : this.props?.activeStep === 3 &&
                          this.props?.preferencesPage === 4
                        ? "/onboarding/preferences-jobrole"
                        : this.props?.activeStep === 4
                        ? "/onboarding/onboarding-completed"
                        : "/",
                    }}
                  />
                )}
              />
            </Switch>
          </React.Fragment>
        </Router>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn:
      state?.authentication &&
      state?.authentication?.loginInfo &&
      Object.keys(state?.authentication).length > 0 &&
      Object.keys(state?.authentication?.loginInfo).length > 0
        ? true
        : false,
    loader: state?.loader,
    activeStep: state?.shared?.activeStep,
    basicInfoPage: state?.onboarding?.basicInfoPage,
    careerPage: state?.onboarding?.careerPage,
    preferencesPage: state?.preferences?.preferencesPage,
  };
};

export default connect(mapStateToProps, {
  loading,
  getJobList,
  getVipJobList,
  authActions,
})(App);
