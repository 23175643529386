import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  TextField,
  Chip,
  FormHelperText,
  Tooltip,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import {
  onBoardingActions,
  careerHighlightPage,
  sharedActions,
  setTitle,
} from "../../../../actions";
import {
  notAllowWhiteSpaceAtStart,
  history,
  trim,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import { toast } from "react-toastify";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { ViewMore } from "../../../core/viewmore";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";
import MicIcon from "@material-ui/icons/Mic";

const filterOptions = createFilterOptions({ ignoreCase: true, trim: true });

let isOverlay = false;
const detectBrowser = () => {
  switch (true) {
    case navigator.userAgent.indexOf("Opera") !== -1:
    case navigator.userAgent.indexOf("OPR") !== -1:
      isOverlay = false;
      break;
    case navigator.userAgent.indexOf("Edg") !== -1:
      isOverlay = false;
      break;
    case navigator.userAgent.indexOf("Chrome") !== -1:
      isOverlay = true;
      break;
    case navigator.userAgent.indexOf("Safari") !== -1:
      isOverlay = false;
      break;
    case navigator.userAgent.indexOf("Firefox") !== -1:
      isOverlay = false;
      break;
    case navigator.userAgent.indexOf("MSIE") !== -1:
      isOverlay = false;
      break;
    default:
      isOverlay = false;
  }
};
detectBrowser();
const styles = (theme) => ({
  root: {
    width: "100%",
  },
  customAlignment: {
    float: "left",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  verySmallBottomMargin: {
    marginBottom: "8px",
  },
  verySmallTopMargin: {
    marginTop: "8px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  careerHighlightHeading: {
    marginTop: "24px",
    fontWeight: "700",
    color: "#14131F",
  },
  floatLeft: {
    float: "left !important",
    textAlign: "left !important",
  },
  clearLeft: {
    clear: "left !important",
  },
  inlineChipList: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(0.5),
    },
    paddingRight: "8px",
  },
  customScroll: {
    maxHeight: "450px",
    overflowY: "hidden",
    wordBreak: "break-word",
    [theme.breakpoints.between("xs", "md")]: {
      overflow: "auto",
    },
    "&:hover": {
      overflow: isOverlay ? "overlay" : "auto",
    },
  },
  competenciesButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  suggestedtextColor: {
    color: "#000000",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  paddingLeft: {
    paddingLeft: "6px",
  },
  autoType: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      display: "block",
    },
  },
  displayFlex: {
    display: "flex",
  },
  clearIndicator: {
    display: "block",
  },
});

class Competencies extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    isCompetenciesRequire: false,
    isValidCompetencies: true,
    competencies: [],
    existingCompetenciesList: [],
    competencyValue: null,
    baseUrlDev: false,
    newAggregatedCompetencies: [],
    selectedCompetencies: [],
    selectAllCompetencies: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(onBoardingActions.getCompetencies());
    dispatch(setTitle(`${translationEN.PageTitle.Competencies_Page_Title}`));
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    // convert the suggested and existing competenices array to an object
    this.arrayToObject();
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COMPETENCIES_PAGE_LOAD,
      SCREENS.COMPETENCIES_SCREEN,
      ACTIONS.COMPETENCIES_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.onboarding?.existingCompetencies?.competencies !==
      this.props?.onboarding?.existingCompetencies?.competencies
    ) {
      // convert the suggested and existing competenices array to an object
      this.arrayToObject();
    }
    if (
      prevProps?.onboarding?.existingCompetencies?.competencies !==
        this.props?.onboarding?.existingCompetencies?.competencies &&
      prevProps?.onboarding?.suggestedCompetencies?.competencies !==
        this.props?.onboarding?.suggestedCompetencies.competencies
    ) {
      // convert the suggested and existing competenices array to an object
      this.arrayToObject();
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setTimeout(() => {
        document.getElementById("autoTypeContent").style.display = "none";
      }, 3000);
    }

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(careerHighlightPage(1));
    dispatch(sharedActions.setProgressbarValue(50));
    history.push("/onboarding/career-highlights-accomplishments");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.COMPETENCIES_PREVIOUS_CLICK,
      SCREENS.COMPETENCIES_SCREEN,
      ACTIONS.COMPETENCIES_PREVIOUS_CLICKED
    );
  };

  handleOnChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue });
  };

  handleClickSelectCompetencies = (competency) => {
    let newAggregatedCompetencies = [];
    let competencyIndex;
    if (competency.selected) {
      competencyIndex = this.state.newAggregatedCompetencies.findIndex(
        (value) => value === competency
      );
      newAggregatedCompetencies = [...this.state.newAggregatedCompetencies];
      newAggregatedCompetencies[competencyIndex].selected = false;
      this.setState({ newAggregatedCompetencies: newAggregatedCompetencies });
      let existingCompetencyIndex =
        this.state.existingCompetenciesList.findIndex(
          (value) => value === competency.value
        );
      let existingCompetenciesList = [...this.state.existingCompetenciesList];
      existingCompetenciesList.splice(existingCompetencyIndex, 1);
      let selectedCompetencies = [...this.state.selectedCompetencies];
      let selectedIndex = selectedCompetencies.findIndex(
        (value) => value === competency.value
      );
      selectedCompetencies.splice(selectedIndex, 1);
      this.setState(
        {
          existingCompetenciesList: existingCompetenciesList,
          selectedCompetencies: selectedCompetencies,
        },
        () => {
          this.setState({
            selectAllCompetencies:
              this.state.selectedCompetencies?.length ===
              this.state.newAggregatedCompetencies?.length
                ? true
                : false,
          });
        }
      );
    } else {
      competencyIndex = this.state.newAggregatedCompetencies.findIndex(
        (value) => value === competency
      );
      newAggregatedCompetencies = [...this.state.newAggregatedCompetencies];
      let valueExistInExistingCompetenciesList =
        this.state.existingCompetenciesList.some(
          (item) => item.toLowerCase() === competency.value.toLowerCase()
        );
      if (valueExistInExistingCompetenciesList) {
        toast.dark(
          this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Competencies_Page.Same_Competenices_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Competencies_Page.Same_Competenices_Message"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Competencies_Page.Same_Competenices_Message"
              }
            />
          )
        );
        return;
      }

      newAggregatedCompetencies[competencyIndex].selected = true;
      this.setState(
        { newAggregatedCompetencies: newAggregatedCompetencies },
        () => {
          this.setState(
            (prevState) => ({
              existingCompetenciesList: [
                ...prevState.existingCompetenciesList,
                competency.value,
              ],
              selectedCompetencies: [
                ...prevState.selectedCompetencies,
                competency.value,
              ],
              competencyValue: null,
            }),
            () => {
              this.setState({
                selectAllCompetencies:
                  this.state.selectedCompetencies?.length ===
                  this.state.newAggregatedCompetencies?.length
                    ? true
                    : false,
              });
            }
          );
        }
      );
    }
  };

  handleSaveCompetencies = () => {
    const { dispatch } = this.props;
    if (this.state.existingCompetenciesList?.length < 3) {
      this.setState({ isCompetenciesRequire: true });
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Competencies_Page.Min_3_Competenices_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.Competencies_Page.Min_3_Competenices_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={
              "Toast_Message.Competencies_Page.Min_3_Competenices_Message"
            }
          />
        )
      );
    } else {
      this.setState(
        {
          existingCompetenciesList: this.state.existingCompetenciesList.filter(
            (item) => trim(item)
          ),
        },
        () => {
          if (this.props?.onboarding?.suggestedCompetencies?.count === 0) {
            if (this.state.existingCompetenciesList?.length <= 50) {
              let progressBarStepValue = 62.5;
              let payload = {
                competencies: this.state.existingCompetenciesList,
              };
              dispatch(
                onBoardingActions.addCompetencies(payload, progressBarStepValue)
              );
              sendTrackingData(
                this.props.shared.decryptedMixPanelKey,
                EVENT_TITLES.COMPETENCIES_NEXT_CLICK,
                SCREENS.COMPETENCIES_SCREEN,
                ACTIONS.COMPETENCIES_NEXT_CLICKED
              );
            } else {
              toast.dark(
                this.state.baseUrlDev ? (
                  <Tooltip
                    title="Toast_Message.Competencies_Page.Max_50_Competenices_Message"
                    placement="left-end"
                    arrow
                  >
                    <Typography>
                      <ReactTranslation
                        contentKey={
                          "Toast_Message.Competencies_Page.Max_50_Competenices_Message"
                        }
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Competencies_Page.Max_50_Competenices_Message"
                    }
                  />
                )
              );
            }
          } else {
            if (this.state.existingCompetenciesList?.length >= 3) {
              let progressBarStepValue = 62.5;
              let payload = {
                competencies: this.state.existingCompetenciesList,
              };
              dispatch(
                onBoardingActions.addCompetencies(payload, progressBarStepValue)
              );
              sendTrackingData(
                this.props.shared.decryptedMixPanelKey,
                EVENT_TITLES.COMPETENCIES_NEXT_CLICK,
                SCREENS.COMPETENCIES_SCREEN,
                ACTIONS.COMPETENCIES_NEXT_CLICKED
              );
            } else {
              toast.dark(
                this.state.baseUrlDev ? (
                  <Tooltip
                    title="Toast_Message.Competencies_Page.Enter_3_Competenices_Message"
                    placement="left-end"
                    arrow
                  >
                    <Typography>
                      <ReactTranslation
                        contentKey={
                          "Toast_Message.Competencies_Page.Enter_3_Competenices_Message"
                        }
                      />
                    </Typography>
                  </Tooltip>
                ) : (
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Competencies_Page.Enter_3_Competenices_Message"
                    }
                  />
                )
              );
            }
          }
        }
      );
    }
  };

  handleCustomAddCompetencies = () => {
    let customAddCompetencies = [];
    if (this.state.existingCompetenciesList?.length > 0) {
      let competencies = [
        ...this.state.existingCompetenciesList,
        ...customAddCompetencies,
      ];
      if (
        trim(this.state.competencyValue) !== null ||
        trim(this.state.competencyValue) !== undefined ||
        trim(this.state.competencyValue) !== "" ||
        trim(this.state.competencyValue).length > 0
      ) {
        if (
          competencies.some(
            (item) =>
              item.toLowerCase() === this.state.competencyValue.toLowerCase()
          )
        ) {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Competencies_Page.Same_Competenices_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Competencies_Page.Same_Competenices_Message"
                    }
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation
                contentKey={
                  "Toast_Message.Competencies_Page.Same_Competenices_Message"
                }
              />
            )
          );
          return;
        }
        competencies.push(this.state.competencyValue);
        this.setState({
          existingCompetenciesList: competencies,
          competencyValue: null,
        });
        sendTrackingData(
          this.props.shared.decryptedMixPanelKey,
          EVENT_TITLES.COMPETENCIES_ADD_NEW_COMPETENCY,
          SCREENS.COMPETENCIES_SCREEN,
          ACTIONS.COMPETENCIES_ADDED_NEW_COMPETENCY
        );
      }
    } else {
      if (
        this.state.competencyValue !== null ||
        this.state.competencyValue !== undefined ||
        this.state.competencyValue !== ""
      ) {
        customAddCompetencies.push(this.state.competencyValue);
        this.setState({
          existingCompetenciesList: customAddCompetencies,
          competencyValue: null,
        });
      }
    }
  };

  arrayToObject = () => {
    let existingCompetenciesArray = [];
    let suggestedCompetenciesArray = [];
    this.props?.onboarding?.existingCompetencies?.competencies?.map((ele) =>
      existingCompetenciesArray.push({
        value: ele,
        selected: true,
      })
    );
    this.props?.onboarding?.suggestedCompetencies?.competencies?.map((ele) =>
      suggestedCompetenciesArray.push({
        value: ele,
        selected: false,
      })
    );
    let newAggregatedCompetencies = [
      ...existingCompetenciesArray,
      ...suggestedCompetenciesArray,
    ];
    this.setState(
      {
        newAggregatedCompetencies: newAggregatedCompetencies,
        existingCompetenciesList:
          this.props?.onboarding?.existingCompetencies?.competencies?.length > 0
            ? this.props?.onboarding?.existingCompetencies?.competencies
            : [],
        selectedCompetencies:
          this.props?.onboarding?.existingCompetencies?.competencies?.length > 0
            ? this.props?.onboarding?.existingCompetencies?.competencies
            : [],
      },
      () => {
        let suggestedCompetencies = this.state.newAggregatedCompetencies.map(
          (ele) => ele.value
        );
        this.setState({
          selectAllCompetencies:
            suggestedCompetencies?.length ===
            this.state.selectedCompetencies?.length
              ? true
              : false,
        });
      }
    );
  };

  handleSelectAllCompetencies = (event) => {
    let selectedCompetenciesArray = [];
    if (event.target.checked) {
      let selectAllCompetenicesArray = this.state.newAggregatedCompetencies.map(
        (ele) => {
          return { value: ele.value, selected: true };
        }
      );
      let competenciesArray = selectAllCompetenicesArray.filter(
        (competency) => {
          return competency.selected === true;
        }
      );
      let selectedCompetencies = competenciesArray.map((option) => {
        let existValueInCompetenciesList =
          this.state.existingCompetenciesList.some(
            (item) => item === option.value
          );
        if (!existValueInCompetenciesList) {
          selectedCompetenciesArray.push(option.value);
        }
        return option.value;
      });
      this.setState(
        {
          newAggregatedCompetencies: selectAllCompetenicesArray,
          selectAllCompetencies: true,
        },
        () => {
          this.setState((prevState) => ({
            existingCompetenciesList: [
              ...prevState.existingCompetenciesList,
              ...selectedCompetenciesArray,
            ],
            selectedCompetencies: selectedCompetencies,
          }));
        }
      );
    } else {
      let selectAllCompetenicesArray = this.state.newAggregatedCompetencies.map(
        (ele) => {
          return { value: ele.value, selected: false };
        }
      );
      let existingCompetencies = [];
      this.state.existingCompetenciesList.filter((ele) => {
        let existingValue = this.state.selectedCompetencies.includes(ele);
        if (!existingValue) {
          existingCompetencies.push(ele);
        }
        return ele;
      });
      this.setState({
        newAggregatedCompetencies: selectAllCompetenicesArray,
        selectAllCompetencies: false,
        existingCompetenciesList: existingCompetencies,
        selectedCompetencies: [],
      });
    }
  };

  handleDeleteCompetencies = (option, index) => {
    let existingCompetenciesList = [...this.state.existingCompetenciesList];
    existingCompetenciesList.splice(index, 1);
    this.setState({ existingCompetenciesList: existingCompetenciesList });
    let competencyValueExist = this.state.newAggregatedCompetencies.some(
      (value) => value.value.toLowerCase() === option.toLowerCase()
    );
    if (competencyValueExist) {
      let newAggregatedCompetencies = this.state.newAggregatedCompetencies;
      let competencyIndex = this.state.newAggregatedCompetencies.findIndex(
        (value) => value.value.toLowerCase() === option.toLowerCase()
      );
      newAggregatedCompetencies[competencyIndex].selected = false;
      this.setState(
        {
          newAggregatedCompetencies: newAggregatedCompetencies,
        },
        () => {
          let selectAllCompetencies =
            this.state.newAggregatedCompetencies.filter((competency) => {
              return competency.selected === true;
            });
          this.setState({ selectedCompetencies: selectAllCompetencies }, () => {
            this.setState({
              selectAllCompetencies:
                this.state.selectedCompetencies?.length ===
                this.state.newAggregatedCompetencies?.length
                  ? true
                  : false,
            });
          });
        }
      );
    }
  };

  hadleClearAllCompetencies = () => {
    this.setState({ existingCompetenciesList: [], selectedCompetencies: [] });
    let newAggregatedCompetencies = this.state.newAggregatedCompetencies.map(
      (ele) => {
        return { value: ele.value, selected: false };
      }
    );
    this.setState({
      newAggregatedCompetencies: newAggregatedCompetencies,
      selectAllCompetencies: false,
    });
  };

  handleClickBackspace = (newValue) => {
    let newAggregatedCompetencies = [];
    this.state.newAggregatedCompetencies.map((ele, index) => {
      newAggregatedCompetencies = this.state.newAggregatedCompetencies;
      let valueExist = newValue.some(
        (item) => item.toLowerCase() === ele.value.toLowerCase()
      );
      if (valueExist) {
        newAggregatedCompetencies[index].selected = true;
      } else {
        newAggregatedCompetencies[index].selected = false;
      }
      return newAggregatedCompetencies;
    });
    this.setState(
      { newAggregatedCompetencies: newAggregatedCompetencies },
      () => {
        let selectAllCompetencies = this.state.newAggregatedCompetencies.filter(
          (competency) => {
            return competency.selected === true;
          }
        );
        this.setState({ selectedCompetencies: selectAllCompetencies }, () => {
          this.setState({
            selectAllCompetencies:
              this.state.selectedCompetencies?.length ===
              this.state.newAggregatedCompetencies?.length
                ? true
                : false,
          });
        });
      }
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classNames(classes.mainGrid)}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title={`${"Competencies_Page.Competencies_Heading"}`}
                  placement="left-start"
                >
                  <Typography
                    align="left"
                    variant={"h5"}
                    className={classNames(
                      classes.careerHighlightHeading,
                      classes.verySmallBottomMargin
                    )}
                  >
                    <ReactTranslation
                      contentKey={"Competencies_Page.Competencies_Heading"}
                    />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography
                  align="left"
                  variant={"h5"}
                  className={classNames(
                    classes.careerHighlightHeading,
                    classes.verySmallBottomMargin
                  )}
                >
                  <ReactTranslation
                    contentKey={"Competencies_Page.Competencies_Heading"}
                  />
                </Typography>
              )}
              <Grid container alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Autocomplete
                    classes={{
                      clearIndicatorDirty: classes.clearIndicator,
                    }}
                    multiple
                    filterOptions={filterOptions}
                    id="competencies"
                    name="competencies"
                    value={this.state.existingCompetenciesList}
                    options={[]}
                    onChange={(event, newValue) => {
                      if (event.key === "Backspace") {
                        this.handleClickBackspace(newValue);
                      }
                      if (event.target.value === "\n") {
                        this.setState({
                          isValidCompetencies: true,
                          competencyValue: null,
                        });
                        return;
                      }
                      if (newValue?.length === 0) {
                        this.setState({
                          existingCompetenciesList: [],
                          isValidCompetencies: true,
                          competencyValue: null,
                        });
                        this.hadleClearAllCompetencies();
                      }
                      if (
                        this.state.existingCompetenciesList?.some(
                          (item) =>
                            item.toLowerCase() ===
                            newValue[newValue?.length - 1]?.toLowerCase()
                        )
                      ) {
                        this.setState({
                          isValidCompetencies: true,
                          isCompetenciesRequire: false,
                        });
                        if (
                          this.state.existingCompetenciesList?.length <
                          newValue?.length
                        ) {
                          return;
                        } else {
                          this.setState({
                            existingCompetenciesList: newValue,
                            competencyValue: null,
                          });
                        }
                      } else if (newValue?.length <= 50) {
                        this.setState(
                          { existingCompetenciesList: newValue },
                          () => {
                            this.setState({ competencyValue: null });
                          }
                        );
                      }
                      newValue?.map((ele) => {
                        if (ele?.length > 1000) {
                          this.setState({ isValidCompetencies: false });
                          return [];
                        } else {
                          this.setState({ isValidCompetencies: true });
                          return [];
                        }
                      });
                    }}
                    freeSolo
                    renderTags={(value, getTagProps) =>
                      value?.map((option, index) => {
                        if (option.length > 1000 || trim(option).length === 0) {
                          return [];
                        }
                        return (
                          <Chip
                            label={
                              <ViewMore length="100">{trim(option)}</ViewMore>
                            }
                            {...getTagProps({ index })}
                            onDelete={() => {
                              this.handleDeleteCompetencies(option, index);
                            }}
                            style={{
                              height: "100%",
                              padding: "5px",
                              margin: "5px 4px 8px 0px",
                              backgroundColor: "#FDDB76",
                              wordBreak: "break-word",
                            }}
                          />
                        );
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        multiline
                        value={this.state.competencyValue}
                        variant="standard"
                        id="competency"
                        name="competency"
                        placeholder={
                          this.state.existingCompetenciesList?.length === 0 &&
                          `${translationEN.Placeholder.Competencies_Page.Add_Competency_Placeholder}`
                        }
                        onChange={(event) => {
                          if (event.target.value.length === 0) {
                            this.setState({
                              isValidCompetencies: true,
                              competencyValue: null,
                              isCompetenciesRequire: true,
                            });
                          }
                          if (
                            this.state.existingCompetenciesList.some(
                              (item) =>
                                item.toLowerCase() ===
                                trim(event.target.value.toLowerCase())
                            )
                          ) {
                            this.setState({
                              isValidCompetencies: true,
                              competencyValue: null,
                              isCompetenciesRequire: false,
                            });
                          } else {
                            this.setState({
                              isValidCompetencies: false,
                              competencyValue: event.target.value,
                              isCompetenciesRequire: false,
                            });
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          inputProps: { ...params.inputProps, maxLength: 1000 },
                        }}
                      />
                    )}
                  />
                  {this.state.isCompetenciesRequire ? (
                    <FormHelperText error={this.state.isCompetenciesRequire}>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )}
                    </FormHelperText>
                  ) : null}
                </Grid>
                <Grid container>
                  <Grid item xs={8} sm={8} md={8} lg={8} xl={8} align="left">
                    {!this.state.isCompetenciesRequire ? (
                      this.state.isValidCompetencies ? (
                        ""
                      ) : baseUrlDev ? (
                        <Tooltip
                          title="Competencies_Page.A_maximum_1000_characters"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={
                                "Competencies_Page.A_maximum_1000_characters"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={
                              "Competencies_Page.A_maximum_1000_characters"
                            }
                          />
                        </Typography>
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align="right">
                    {!this.state.isCompetenciesRequire ? (
                      this.state.isValidCompetencies ? (
                        ""
                      ) : (
                        <Typography
                          align={
                            this.state.competencyValue?.length < 1
                              ? "right"
                              : "none"
                          }
                          color="secondary"
                        >
                          {this.state.competencyValue?.length > 0
                            ? this.state.competencyValue?.length + "/" + 1000
                            : 0 + "/" + 1000}
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Competencies_Page.characters"
                              placement="bottom"
                            >
                              <Typography
                                component={"span"}
                                className={classes.paddingLeft}
                              >
                                <ReactTranslation
                                  contentKey={"Competencies_Page.characters"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.paddingLeft}
                            >
                              <ReactTranslation
                                contentKey={"Competencies_Page.characters"}
                              />
                            </Typography>
                          )}
                        </Typography>
                      )
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className={classes.smallTopMargin}
                >
                  <Typography
                    component={"div"}
                    className={classes.displayFlex}
                    id="autoTypeContent"
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Help_card_content.autoTypeHelpText.autoHelpText_1"
                        placement="left-end"
                      >
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_1"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        id="autoTypeText"
                        className={classes.autoType}
                        component={"span"}
                      >
                        <ReactTranslation
                          contentKey={
                            "Help_card_content.autoTypeHelpText.autoHelpText_1"
                          }
                        />
                      </Typography>
                    )}
                    <Typography component={"span"} className={classes.autoType}>
                      <MicIcon />
                    </Typography>
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Help_card_content.autoTypeHelpText.autoHelpText_2"
                        placement="left-end"
                      >
                        <Typography
                          id="autoTypeText"
                          className={classes.autoType}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={
                              "Help_card_content.autoTypeHelpText.autoHelpText_2"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        id="autoTypeText"
                        className={classes.autoType}
                        component={"span"}
                      >
                        <ReactTranslation
                          contentKey={
                            "Help_card_content.autoTypeHelpText.autoHelpText_2"
                          }
                        />
                      </Typography>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Add+" placement="left-end">
                      <Button
                        className={classNames(
                          classes.customAlignment,
                          classes.smallTopMargin
                        )}
                        id="customAddCompetencies"
                        color="primary"
                        variant="contained"
                        disableElevation
                        disabled={
                          trim(this.state.competencyValue) === null ||
                          trim(this.state.competencyValue) === undefined ||
                          trim(this.state.competencyValue).length === 0
                        }
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleCustomAddCompetencies();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Add+"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      className={classNames(
                        classes.customAlignment,
                        classes.smallTopMargin
                      )}
                      id="customAddCompetencies"
                      color="primary"
                      variant="contained"
                      disableElevation
                      disabled={
                        trim(this.state.competencyValue) === null ||
                        trim(this.state.competencyValue) === undefined ||
                        trim(this.state.competencyValue).length === 0
                      }
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleCustomAddCompetencies();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Add+"} />
                    </Button>
                  )}
                </Grid>
              </Grid>

              {this.state.newAggregatedCompetencies?.length > 0 ? (
                <Grid container>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    align="left"
                    className={classes.smallTopMargin}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          style={{ color: "#000000" }}
                          onChange={(event) =>
                            this.handleSelectAllCompetencies(event)
                          }
                          name={"selectAll"}
                          value={"selectAll"}
                          checked={this.state.selectAllCompetencies}
                        />
                      }
                      label={
                        baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Competencies_Page.Select_All"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation
                                contentKey={"Competencies_Page.Select_All"}
                              />{" "}
                              &nbsp;
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Competencies_Page.Opening_parenthesis"
                                  }
                                />
                                <ReactTranslation
                                  contentKey={"Competencies_Page.Selected"}
                                />
                                &nbsp;{this.state.selectedCompetencies?.length}
                                &nbsp;
                                {baseUrlDev && (
                                  <Tooltip
                                    arrow
                                    title="Competencies_Page.Of"
                                    placement="right-end"
                                  >
                                    <Typography component={"span"}>
                                      <ReactTranslation
                                        contentKey={"Competencies_Page.Of"}
                                      />
                                    </Typography>
                                  </Tooltip>
                                )}
                                &nbsp;
                                {this.state.newAggregatedCompetencies?.length}
                                <ReactTranslation
                                  contentKey={
                                    "Competencies_Page.Closing_parenthesis"
                                  }
                                />
                              </Typography>
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Competencies_Page.Select_All"}
                            />
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation
                                contentKey={
                                  "Competencies_Page.Opening_parenthesis"
                                }
                              />
                              <ReactTranslation
                                contentKey={"Competencies_Page.Selected"}
                              />{" "}
                              {this.state.selectedCompetencies?.length}
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={"Competencies_Page.Of"}
                                />{" "}
                              </Typography>
                              {this.state.newAggregatedCompetencies?.length}{" "}
                              <ReactTranslation
                                contentKey={
                                  "Competencies_Page.Closing_parenthesis"
                                }
                              />
                            </Typography>
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.smallTopMargin}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Competencies_Page.Suggested_Competencies"
                        placement="left-end"
                      >
                        <Typography
                          align="left"
                          variant="div"
                          className={classNames(
                            classes.floatLeft,
                            classes.suggestedtextColor
                          )}
                        >
                          {
                            <ReactTranslation
                              contentKey={
                                "Competencies_Page.Suggested_Competencies"
                              }
                            />
                          }
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        align="left"
                        variant="div"
                        className={classNames(
                          classes.floatLeft,
                          classes.suggestedtextColor
                        )}
                      >
                        {
                          <ReactTranslation
                            contentKey={
                              "Competencies_Page.Suggested_Competencies"
                            }
                          />
                        }
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    className={classNames(
                      classes.floatLeft,
                      classes.clearLeft,
                      classes.smallFont,
                      classes.inlineChipList,
                      classes.smallTopMargin,
                      classes.customScroll
                    )}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                  >
                    {this.state.newAggregatedCompetencies?.map(
                      (competency, index) => (
                        <Chip
                          label={competency.value}
                          style={{
                            backgroundColor: competency.selected
                              ? "#FDDB76"
                              : "#e0e0e0",
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleClickSelectCompetencies(
                              competency,
                              index
                            );
                          }}
                        />
                      )
                    )}
                  </Grid>
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                <div className={classes.competenciesButton}>
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="competenciesPreviousButton"
                        variant="outlined"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        {" "}
                        <ReactTranslation
                          contentKey={"Buttons.Previous"}
                        />{" "}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="competenciesPreviousButton"
                      variant="outlined"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      {" "}
                      <ReactTranslation contentKey={"Buttons.Previous"} />{" "}
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="competenciesNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveCompetencies();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="competenciesNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveCompetencies();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

Competencies.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedCompetenciesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Competencies))
);
export { connectedCompetenciesPage as Competencies };
