import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { HelpTextCard } from "../helpCardContent/helpCardContent";
import ReactTranslation from "../../../core/iReact18";
import { NeedHelp } from "../../../core/needHelp.component";
import { BasicInfoStep1 } from "./basicInfoStep1.component";
import { BasicInfoStep2 } from "./basicInfoStep2.component";
import { basicInfoActions, setLocationDetail } from "../../../../actions";
import {
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import { sharedActions, saveLoginInfo, authActions } from "../../../../actions";
import { basicInfoPage } from "../../../../actions/onboarding.actions";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  pageNumberText: {
    color: "#666666",
    textAlign: "end",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "unset",
  },
  textColor: {
    color: "#8f8f8f",
    fontWeight: "700",
    fontSize: "22px",
  },
});

class BasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    firstName: this.props?.basicInfo?.basicInfoData?.first_name
      ? this.props?.basicInfo?.basicInfoData?.first_name
      : this.props?.basicInfo?.basicInfoParsedData?.first_name
      ? this.props?.basicInfo?.basicInfoParsedData?.first_name
      : this.props?.authentication?.loginInfo?.first_name
      ? this.props?.authentication?.loginInfo?.first_name
      : "",
    lastName: this.props?.basicInfo?.basicInfoData?.last_name
      ? this.props?.basicInfo?.basicInfoData?.last_name
      : this.props?.basicInfo?.basicInfoParsedData?.last_name
      ? this.props?.basicInfo?.basicInfoParsedData?.last_name
      : this.props?.authentication?.loginInfo?.last_name
      ? this.props?.authentication?.loginInfo?.last_name
      : "",
    email: this.props?.basicInfo?.basicInfoData?.email
      ? this.props?.basicInfo?.basicInfoData?.email
      : this.props?.basicInfo?.basicInfoParsedData?.email
      ? this.props?.basicInfo?.basicInfoParsedData?.email[0]
      : this.props?.authentication?.loginInfo?.email
      ? this.props?.authentication?.loginInfo?.email
      : "",
    phoneNumber: this.props?.basicInfo?.basicInfoData?.contact
      ? this.props?.basicInfo?.basicInfoData?.contact
      : this.props?.basicInfo?.basicInfoParsedData?.contact
      ? this.props?.basicInfo?.basicInfoParsedData?.contact
      : this.props?.authentication?.loginInfo?.phone
      ? this.props?.authentication?.loginInfo?.phone
      : "",
    linkedInUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
      ? this.props?.basicInfo?.basicInfoData?.linkedin_url
      : this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
      ? this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
      : this.props?.authentication?.loginInfo?.linkedin_url
      ? this.props?.authentication?.loginInfo?.linkedin_url
      : "",
    profile_image: this.props?.authentication?.loginInfo?.profile_image,
    resumeFile: this.props?.basicInfo?.basicInfoData?.resume
      ? this.props?.basicInfo?.basicInfoData?.resume
      : this.props?.authentication.loginInfo?.resume
      ? this.props?.authentication?.loginInfo?.resume
      : null,
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let url = new URL(window.location.href);
    if (
      url.searchParams.get("token") &&
      url.searchParams.get("login_type") === "basic_step1"
    ) {
      let token = url.searchParams.get("token");
      let environment = "";
      const key = "";
      secureLocalStorage.setItem("token", JSON.stringify(token));
      dispatch(saveLoginInfo(token, environment, key));
      dispatch(basicInfoPage(1));
      dispatch(authActions.getConstant());
      dispatch(sharedActions.setProgressbarValue(0));
    }
    dispatch(basicInfoActions.getBasicInfo());

    let address = this.props?.basicInfo?.basicInfoData?.location
      ? this.props?.basicInfo?.basicInfoData?.location?.city !== null &&
        this.props?.basicInfo?.basicInfoData?.location?.city !== undefined
        ? this.props?.basicInfo?.basicInfoData?.location
        : this.props?.basicInfo?.basicInfoParsedData?.location?.city
        ? this.props?.basicInfo?.basicInfoParsedData?.location
        : Object.keys(this.props?.authentication?.loginInfo?.address).length !==
          0
        ? this.props?.authentication?.loginInfo?.address
        : this.props?.authentication?.locationData?.address?.city !== null ||
          this.props?.authentication?.locationData?.address?.city !== undefined
        ? this.props?.authentication?.locationData?.address
        : ""
      : "";
    if (address !== undefined && address !== null) {
      dispatch(setLocationDetail(address));
    }
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch } = this.props;
    if (
      prevProps?.basicInfo?.basicInfoData !==
      this.props?.basicInfo?.basicInfoData
    ) {
      this.setState({
        firstName: this.props?.basicInfo?.basicInfoData?.first_name
          ? this.props?.basicInfo?.basicInfoData?.first_name
          : "",
        lastName: this.props?.basicInfo?.basicInfoData?.last_name
          ? this.props?.basicInfo?.basicInfoData?.last_name
          : "",
      });
    }
    if (
      prevProps?.basicInfo?.basicInfoData?.linkedin_url !==
      this.props?.basicInfo?.basicInfoData?.linkedin_url
    ) {
      this.setState({
        linkedInUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
          ? this.props?.basicInfo?.basicInfoData?.linkedin_url
          : this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
          ? this.props?.basicInfo?.basicInfoParsedData?.linkedin_url
          : "",
      });
    }
    if (
      prevProps?.basicInfo?.basicInfoData?.contact !==
      this.props?.basicInfo?.basicInfoData?.contact
    ) {
      this.setState({
        phoneNumber: this.props?.basicInfo?.basicInfoData?.contact
          ? this.props?.basicInfo?.basicInfoData?.contact
          : this.props?.basicInfo?.basicInfoParsedData?.contact
          ? this.props?.basicInfo?.basicInfoParsedData?.contact
          : this.props?.authentication?.loginInfo?.phone
          ? this.props?.authentication?.loginInfo?.phone
          : "",
      });
    }

    if (
      prevProps?.basicInfo?.basicInfoData?.location !==
      this.props?.basicInfo?.basicInfoData?.location
    ) {
      let address =
        this.props?.basicInfo?.basicInfoData?.location?.city !== null
          ? this.props?.basicInfo?.basicInfoData?.location
          : this.props?.basicInfo?.basicInfoParsedData?.location?.city
          ? this.props?.basicInfo?.basicInfoParsedData?.location
          : Object.keys(this.props?.authentication?.loginInfo?.address)
              .length !== 0
          ? this.props?.authentication?.loginInfo?.address
          : this.props?.authentication?.loginInfo?.address?.city !== null ||
            this.props?.authentication?.loginInfo?.address?.city !== undefined
          ? this.props?.authentication?.loginInfo?.address
          : "";
      if (address) {
        dispatch(setLocationDetail(address));
      }
    } else if (
      prevProps?.basicInfo?.basicInfoParsedData?.location !==
      this.props?.basicInfo?.basicInfoParsedData?.location
    ) {
      let address = this.props?.basicInfo?.basicInfoData?.location?.city
        ? this.props?.basicInfo?.basicInfoData?.location
        : this.props?.basicInfo?.basicInfoParsedData?.location?.city
        ? this.props?.basicInfo?.basicInfoParsedData?.location
        : this.props?.authentication?.loginInfo?.address &&
          Object.keys(this.props?.authentication?.loginInfo?.address).length !==
            0
        ? this.props?.authentication?.loginInfo?.address
        : this.props?.authentication?.loginInfo?.address?.city !== null ||
          this.props?.authentication?.loginInfo?.address?.city !== undefined
        ? this.props?.authentication?.loginInfo?.address
        : {};
      if (address) {
        dispatch(setLocationDetail(address));
      }
    }
    if (
      prevProps?.basicInfo?.basicInfoData?.resume !==
      this.props?.basicInfo?.basicInfoData?.resume
    ) {
      this.setState({
        resumeFile: this.props?.basicInfo?.basicInfoData?.resume
          ? this.props?.basicInfo?.basicInfoData?.resume
          : null,
      });
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container alignItems="center">
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Basic_Information_Page.Basic_Information_Heading"
                      placement="left-start"
                    >
                      <Typography align="left" className={classes.textColor}>
                        <ReactTranslation
                          contentKey={
                            "Basic_Information_Page.Basic_Information_Heading"
                          }
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography align="left" className={classes.textColor}>
                      <ReactTranslation
                        contentKey={
                          "Basic_Information_Page.Basic_Information_Heading"
                        }
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography className={classes.pageNumberText}>
                    {this.props?.onboarding?.basicInfoPage} &nbsp;
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Page.of"
                        placement="bottom"
                      >
                        <Typography
                          className={classes.pageNumberText}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={"Basic_Information_Page.of"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        className={classes.pageNumberText}
                        component={"span"}
                      >
                        <ReactTranslation
                          contentKey={"Basic_Information_Page.of"}
                        />
                      </Typography>
                    )}
                    &nbsp;
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Page.2"
                        placement="bottom"
                      >
                        <Typography
                          className={classes.pageNumberText}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={"Basic_Information_Page.2"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        className={classes.pageNumberText}
                        component={"span"}
                      >
                        <ReactTranslation
                          contentKey={"Basic_Information_Page.2"}
                        />
                      </Typography>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              {(this.props?.onboarding?.basicInfoPage === 1 ||
                this.props?.onboarding?.basicInfoPage === "1" ||
                this.props?.onboarding?.basicInfoPage === null) && (
                <BasicInfoStep1 />
              )}
              {(this.props?.onboarding?.basicInfoPage === 2 ||
                this.props?.onboarding?.basicInfoPage === "2") && (
                <BasicInfoStep2 />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {this.props?.onboarding?.basicInfoPage === 1 && (
                <HelpTextCard helpTextCardType={"basicInfoStep1"} />
              )}
              {this.props?.onboarding?.basicInfoPage === 2 && (
                <HelpTextCard helpTextCardType={"basicInfoStep2"} />
              )}
              <NeedHelp />
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

BasicInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedBasicInfoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(BasicInfo))
);
export { connectedBasicInfoPage as BasicInfo };
