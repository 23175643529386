import mixpanel from "mixpanel-browser";
import config from "../config/config";
import CryptoJS from "crypto-js";
import { REMOVE_DASH_REGEX } from "../constants/constants";

export const approvedMemberRedirection = (environment, key) => {
  let url = new URL(window.location.href);
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    if (
      url.hostname === "localhost" ||
      url.hostname === "dev" ||
      url.hostname === "dev1"
    ) {
      window.open("https://succeedsmartdev.page.link/devInstall", "_self");
    } else {
      window.open("https://succeedsmart.page.link/install", "_self");
    }
  } else {
    if (
      config.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
      config.baseUrl === `https://dev-platform.succeedsmart.com/` ||
      config.baseUrl === `https://demo2-platform.succeedsmart.com/`
    ) {
      window.location.href = `https://dev-member.succeedsmart.com/?environment=${environment}&key=${key}`;
    } else {
      window.location.href = `https://member.succeedsmart.com/?environment=${environment}&key=${key}`;
    }
  }
};

export const notAllowWhiteSpaceAtStart = (value) => {
  if (typeof value === "string") {
    return value.replace(/^\s*\s*$/, "");
  }
  return value;
};

export const trim = (value) => {
  if (typeof value === "string") {
    return value.replace(/\s+/g, " ").trimEnd();
  }
  return value;
};

export const truncate = (str, n = 30) => {
  if (str !== null && str !== undefined) {
    return str.length > n ? str.substr(0, n - 1) + "..." : str;
  }
};

export const removeTags = (value) => {
  var regex = /(<em>|<em [^>]+>|<em>|<\/em>)/g;
  if (value !== null && value !== undefined) {
    var emptyObject =
      Object.keys(value).length === 0 && value.constructor === Object;
    if (value && !emptyObject) {
      if (value.constructor === Object) {
        return;
      }
      return value.replace(regex, "");
    }
    value = "";
    return value.replace(regex, "");
  }
};

export const removeUnderscores = (value) => {
  var regex = /_/g;
  if (value !== null && value !== undefined) {
    var emptyObject =
      Object.keys(value).length === 0 && value.constructor === Object;
    if (value && !emptyObject) {
      if (value.constructor === Object) {
        return;
      }
      return value.replace(regex, " ");
    }
    value = "";
    return value.replace(regex, " ");
  }
};

export function seo(data = {}) {
  data.title = data.title || "SucceedSmart Member Portal";
  data.metaDescription =
    data.metaDescription || "A reimagined platform for executive search";
  document.title = data.title;
  document
    .querySelector('meta[name="description"]')
    .setAttribute("content", data.metaDescription);
}

export const capitalize = (str) => {
  if (str !== null && str !== undefined && typeof str === "string") {
    return str
      .split(" ")
      .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
      .join(" ");
  } else return str;
};

export function sendTrackingData(key, eventTitle, source, action, userInfo) {
  mixpanel.init(key, {
    debug:
      config.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
      config.baseUrl === `https://dev-platform.succeedsmart.com/`
        ? true
        : false,
    loaded: function (mixpanel) {
      if (userInfo && Object.keys(userInfo)?.length) {
        mixpanel.alias(userInfo.userId, mixpanel.get_distinct_id());
      }
    },
  });
  if (userInfo && Object.keys(userInfo)?.length) {
    mixpanel.identify(userInfo.userId);
    mixpanel.people.set({
      $name: userInfo.firstName + " " + userInfo.lastName,
      $email: userInfo.email,
      "User ID": userInfo.userId,
      "Onboarding Status": userInfo.onboardingStatus,
    });
  }
  mixpanel.register({ Source: "Member Web App - React" });
  mixpanel.track(eventTitle, {
    Screen: source,
    Action: action ? action : "No Action Performed",
  });
}

// for remove (-)
export const modifyString = (inputvalue) => {
  return inputvalue.replace(REMOVE_DASH_REGEX, "");
};

export function showHideToolTip() {
  if (
    config.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
    config.baseUrl === `https://dev-platform.succeedsmart.com/`
  ) {
    return true;
  } else if (config.baseUrl === `https://demo2-platform.succeedsmart.com/`) {
    return false;
  }
}

export function decryptData(securekey, value) {
  const encryptedData = value;
  const Base64Key = securekey;
  const key = CryptoJS.enc.Base64.parse(Base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  return decryptedText;
}
