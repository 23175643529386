import { apiService } from "../services";
import { history } from "../helpers";
// import { toast } from 'react-toastify';
import { sharedActions } from "./shared.action";
import { toast } from "react-toastify";
import { onboardingStatus } from "./onboarding.actions";
import { isMemberApproved } from "./basicInfo.actions";

export const preferencesActions = {
  candidateUpdate,
  getMemberDiversity,
  getSalary,
  saveSalary,
  getWorkEnviroment,
  saveWorkEnviroment,
  saveJobRole,
  getJobRoleExpectation,
};

function getMemberDiversity() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/diversity/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveMemberDiversity(response.data.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function candidateUpdate(payload, environment, key, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/diversity/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(preferencesPage(4));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Onboarding completed successfully"
          );
          dispatch(getMemberDiversity());
          history.push("/onboarding/preferences-jobrole");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

export function saveMemberDiversity(diversity) {
  return {
    type: "DIVERSITY_DATA",
    memberDiversity: diversity,
  };
}

export function preferencesPage(pageNo) {
  return {
    type: "PREFERENCES_PAGE",
    pageNo: pageNo,
  };
}

function saveSalary(payload, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/salary_expectation/ ";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(preferencesPage(2));
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          toast.dark(response.data.message ? response.data.message : "");
          history.push("/onboarding/preferences-work");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function getSalary() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/salary_expectation/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveSalaryData(response.data.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveSalaryData(salaryData) {
  return {
    type: "SAVE_SALARY",
    salaryData: salaryData,
  };
}

function saveWorkEnviroment(payload, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/work_environment/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(preferencesPage(3));
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          toast.dark(response.data.message ? response.data.message : "");
          history.push("/onboarding/preferences-diversity");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function getWorkEnviroment() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/work_environment/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveWorkEnviromentData(response.data.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveWorkEnviromentData(workEnvData) {
  return {
    type: "SAVE_WORK_ENV",
    workEnvData: workEnvData,
  };
}

function saveJobRole(payload, activeStep, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/advisor_director_role/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(sharedActions.handleNext(activeStep));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Matching Preferences Updated Successfully"
          );
          history.push("/onboarding/onboarding-completed");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function getJobRoleExpectation() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/advisor_director_role/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveJobRoleExpectation(response.data.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveJobRoleExpectation(jobRoleExpectationData) {
  return {
    type: "JOB_ROLE_EXPECTATION",
    jobRoleExpectationData: jobRoleExpectationData,
  };
}
