import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Competencies } from "./competencies.component";
import { withStyles } from "@material-ui/core/styles";
import { Accomplishments } from "./accomplishment.component";
import { CoffeQuestions } from "./coffeeQuestion.component";
import { Video } from "./video.component";
import { Grid, Typography, Tooltip } from "@material-ui/core";
import { HelpTextCard } from "../helpCardContent/helpCardContent";
import ReactTranslation from "../../../core/iReact18";
import { NeedHelp } from "../../../core/needHelp.component";
import { showHideToolTip } from "../../../../helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  pageNumberText: {
    color: "#666666",
    textAlign: "end",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "unset",
  },
  textColor: {
    color: "#8f8f8f",
    fontWeight: "700",
    fontSize: "22px",
  },
});

class CareerHighlights extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container alignItems="center">
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Career_Highlights.Career_Highlights_Heading"
                      placement="left-start"
                    >
                      <Typography align="left" className={classes.textColor}>
                        <ReactTranslation
                          contentKey={
                            "Career_Highlights.Career_Highlights_Heading"
                          }
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography align="left" className={classes.textColor}>
                      <ReactTranslation
                        contentKey={
                          "Career_Highlights.Career_Highlights_Heading"
                        }
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography className={classes.pageNumberText}>
                    {this.props?.onboarding?.careerPage} &nbsp;
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Career_Highlights.of"
                        placement="bottom"
                      >
                        <Typography
                          className={classes.pageNumberText}
                          component={"span"}
                        >
                          <ReactTranslation
                            contentKey={"Career_Highlights.of"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        className={classes.pageNumberText}
                        component={"span"}
                      >
                        <ReactTranslation contentKey={"Career_Highlights.of"} />
                      </Typography>
                    )}
                    &nbsp; 4
                  </Typography>
                </Grid>
              </Grid>
              {this.props?.onboarding?.careerPage === 1 ||
              this.props?.onboarding?.careerPage === "1" ||
              this.props?.onboarding?.careerPage === null ? (
                <Accomplishments />
              ) : this.props?.onboarding?.careerPage === 2 ||
                this.props?.onboarding?.careerPage === "2" ? (
                <Competencies />
              ) : this.props?.onboarding?.careerPage === 3 ||
                this.props?.onboarding?.careerPage === "3" ? (
                <CoffeQuestions />
              ) : this.props?.onboarding?.careerPage === 4 ||
                this.props?.onboarding?.careerPage === "4" ? (
                <Video />
              ) : (
                <Accomplishments />
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {this.props?.onboarding?.careerPage === 1 && (
                <HelpTextCard helpTextCardType={"accomplishmentHelpText"} />
              )}
              {this.props?.onboarding?.careerPage === 2 && (
                <HelpTextCard helpTextCardType={"competenciesHelpText"} />
              )}
              {this.props?.onboarding?.careerPage === 3 && (
                <HelpTextCard helpTextCardType={"coffeequestionsHelpText"} />
              )}
              {this.props?.onboarding?.careerPage === 4 && (
                <HelpTextCard helpTextCardType={"videoHelpText"} />
              )}
              {/* Need help component */}
              <NeedHelp />
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

CareerHighlights.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedCareerHighlightsPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(CareerHighlights))
);
export { connectedCareerHighlightsPage as CareerHighlights };
