import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { authActions, loading } from "../../../actions";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { seo } from "../../../helpers";
import { setTitle } from "../../../actions";
import { ReactComponent as LoaderImage } from "../../../assets/loading_screen.svg";
import secureLocalStorage from "react-secure-storage";

const styles = () => ({
  root: {
    height: "calc(100vh - 150px)",
  },
  boldFont: {
    fontWeight: "bold",
  },
  center: {
    alignItems: "center",
    justifyContent: "center",
  },
  paddingSmall: {
    padding: "16px 16px 16px 16px !important",
  },
});

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    dispatch(setTitle(`Loading`));
    dispatch(loading(false));
    let url = new URL(window.location.href);
    if (url.searchParams.get("key") && !secureLocalStorage.getItem("token")) {
      const { dispatch } = this.props;
      let payload = {
        device_information: navigator.userAgent,
        phone: "",
        device_token: "",
        access_token: url.searchParams.get("key"),
        source_link: this.props?.authentication?.utmData?.utm_linkurl
          ? this.props?.authentication?.utmData?.utm_linkurl
          : window.location.href,
        referrer_link: document.referrer,
      };
      dispatch(
        authActions.login(payload, url.searchParams.get("key"), this.props)
      );
    } else {
      window.location = this.props?.authentication?.originalUrlPath
        ? this.props?.authentication?.originalUrlPath
        : "/login";
    }
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classNames(
          classes.root,
          classes.center,
          classes.paddingSmall
        )}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <LoaderImage width="100%" />
          <Typography variant="h4" className={classes.boldFont}>
            {"Hold on, we're signing you in..."}
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

Loading.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedLoadingPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Loading))
);

export { connectedLoadingPage as Loading };
