import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  seo,
  notAllowWhiteSpaceAtStart,
  sendTrackingData,
  history,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../helpers";
import {
  authActions,
  setTitle,
  onBoardingActions,
  sharedActions,
  basicInfoPage,
  getCloudName,
} from "../../../actions";
import {
  Grid,
  Button,
  Typography,
  TextField,
  MenuItem,
  DialogActions,
  Dialog,
  Tooltip,
  CircularProgress,
  IconButton,
  FormHelperText,
  FormControl,
  Select,
  Avatar,
  InputLabel,
  InputAdornment,
  FormLabel,
  Badge,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CloseIcon from "@material-ui/icons/Close";
import validator from "validator";
import { debounce } from "lodash";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
  MESSAGE_FIELD_IS_REQUIRED,
} from "../../../constants/constants";
import { toast } from "react-toastify";
import classNames from "classnames";
import { HelpTextCard } from "./helpCardContent/helpCardContent";
import AddIcon from "@material-ui/icons/Add";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import ReactTranslation from "../../core/iReact18";
import translationEN from "../../../locales/en/translation.json";
import { NeedHelp } from "../../core/needHelp.component";
import { Edit } from "@material-ui/icons";
import secureLocalStorage from "react-secure-storage";

// const filter = createFilterOptions();
let companyNames = [];
let jobTitles = [];

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: theme.spacing(6),
  },
  companySearchLogo: { height: "23px", width: "23px" },
  customButton: {
    border: "1px solid #000000 !important",
  },
  verySmallMarginRightLogo: { marginRight: "2%" },
  content: {
    flexGrow: 1,
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  customBackground: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
    color: "#000000",
  },
  title: {
    fontSize: 14,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  smallBottomMargin: {
    marginBottom: "2%",
  },
  mediumBottomMargin: {
    marginBottom: "4%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  verySmallTopMargin: {
    marginTop: "1%",
  },
  boldFont: {
    fontWeight: "bold",
  },
  fullWidth: {
    width: "100%",
  },
  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  professionalButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(0),
    color: theme.palette.primary.main,
  },
  customPadding: {
    justifyContent: "center !important",
  },
  dialogTitle: {
    fontWeight: "700",
    color: "#14131F",
  },
  uploadButton: {
    border: "1px solid #000000 !important",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  verySmallMarginRightCompanyLogo: {
    marginRight: "2%",
  },
  companyNameWithLogo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  displayFlex: {
    display: "flex",
    alignItems: "center",
  },
  verySmallMarginRight: {
    marginRight: "8px",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  verySmallBottomMargin: {
    marginBottom: "12px",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography {...other}>
      <Typography color="primary" className={classes.dialogTitle}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
}))(MuiDialogContent);

class ProfessionalInfo extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    currentJobTitle: this.props?.onboarding?.professionalData?.title
      ? this.props?.onboarding?.professionalData?.title
      : this.props?.onboarding?.professionalParsedData?.title
      ? this.props?.onboarding?.professionalParsedData?.title
      : this.props?.authentication?.loginInfo?.title
      ? this.props?.authentication?.loginInfo?.title
      : null,
    totalYearExperience: this.props?.onboarding?.professionalData?.overall_exp
      ? this.props?.onboarding?.professionalData?.overall_exp
      : this.props?.onboarding?.professionalParsedData?.overall_exp
      ? this.props?.onboarding?.professionalParsedData?.overall_exp
      : this.props?.authentication?.loginInfo?.overall_exp
      ? this.props?.authentication?.loginInfo?.overall_exp
      : "",
    directorLevelExperience: this.props?.onboarding?.professionalData
      ?.relevent_exp
      ? this.props?.onboarding?.professionalData?.relevent_exp
      : this.props?.onboarding?.professionalParsedData?.relevent_exp
      ? this.props?.onboarding?.professionalParsedData?.relevent_exp
      : this.props?.authentication?.loginInfo?.relevent_exp
      ? this.props?.authentication?.loginInfo?.relevent_exp
      : "",
    openCompanyOptions: false,
    companyValue: [],
    companyLoading: false,
    companyOptions: [],
    companyList: null,
    companyListValue: null,
    companyDomain: "",
    companyLogoFile: null,
    companyDomainIsValid: false,
    isCompanyDomainRequired: false,
    isCustomCompany: false,
    isCompanyListValueRequired: false,
    isTotalYearExperienceRequired: false,
    isCurrentJobTitleRequired: false,
    jobTitles: [],
    jobTitlesValue: [],
    jobTitleLoading: false,
    customJobTitlePopUp: false,
    customJobTitleValue: "",
    isCustomJobTitleRequired: false,
    isCustomJobTitleValid: true,
    helpTextCardType: "professionalHelpText",
    baseUrlDev: false,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(onBoardingActions.getProfessionalInfo());
    const { pageTitle } = this.props.pageTitle;
    dispatch(
      setTitle(`${translationEN.PageTitle.Professional_Info_Page_Title}`)
    );
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    let address =
      this.props?.onboarding?.professionalData?.address?.city !== null
        ? this.props?.onboarding?.professionalData?.address
        : Object.keys(this.props?.authentication?.loginInfo?.address).length !==
          0
        ? this.props?.authentication?.loginInfo?.address
        : this.props?.authentication?.loginInfo?.address.city !== null ||
          this.props?.authentication?.loginInfo?.address?.city !== undefined
        ? this.props?.authentication?.loginInfo?.address
        : "";
    if (address !== undefined && address !== null) {
      secureLocalStorage.setItem("gLocation", JSON.stringify(address));
    }
    this.handleCompany();
    this.handleJobTitle();
    seo({
      title: `${pageTitle}`,
    });

    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    dispatch(getCloudName());
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.PROFESSIONAL_INFO_PAGE_LOAD,
      SCREENS.PROFESSIONAL_INFO_SCREEN,
      ACTIONS.PROFESSIONAL_INFO_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.authentication?.companyNames !==
      this.props?.authentication?.companyNames
    ) {
      companyNames = this.props.authentication?.companyNames;
      this.setState({ companyOptions: companyNames, companyLoading: false });
    }
    if (
      prevProps?.authentication?.jobTitles !==
      this.props?.authentication?.jobTitles
    ) {
      jobTitles = this.props.authentication?.jobTitles;
      this.setState({ jobTitles: jobTitles, jobTitleLoading: false });
    }
    if (
      prevProps?.onboarding?.professionalData?.address !==
      this.props?.onboarding?.professionalData?.address
    ) {
      let address =
        this.props?.onboarding?.professionalData?.address?.city !== null
          ? this.props?.onboarding?.professionalData?.address
          : Object.keys(this.props?.authentication?.loginInfo?.address)
              .length !== 0
          ? this.props?.authentication?.loginInfo?.address
          : this.props?.authentication?.loginInfo?.address.city !== null ||
            this.props?.authentication?.loginInfo?.address?.city !== undefined
          ? this.props?.authentication?.loginInfo?.address
          : "";
      if (address === undefined || address === null) {
        return;
      } else {
        secureLocalStorage.setItem("gLocation", JSON.stringify(address));
      }
    }
    if (
      prevProps?.onboarding?.professionalData?.current_company !==
      this.props?.onboarding?.professionalData?.current_company
    ) {
      this.handleCompany();
    }
    if (
      prevProps?.onboarding?.professionalData?.title !==
      this.props?.onboarding?.professionalData?.title
    ) {
      this.handleJobTitle();
    }
    if (
      prevProps?.onboarding?.professionalData?.overall_exp !==
      this.props?.onboarding?.professionalData?.overall_exp
    ) {
      this.setState({
        totalYearExperience: this.props?.onboarding?.professionalData
          ?.overall_exp
          ? this.props?.onboarding?.professionalData?.overall_exp
          : this.props?.onboarding?.professionalParsedData?.overall_exp
          ? this.props?.onboarding?.professionalParsedData?.overall_exp
          : this.props?.authentication?.loginInfo?.overall_exp
          ? this.props?.authentication?.loginInfo?.overall_exp
          : "",
      });
    }
    if (
      prevProps?.onboarding?.professionalData?.relevent_exp !==
      this.props?.onboarding?.professionalData?.relevent_exp
    ) {
      this.setState({
        directorLevelExperience: this.props?.onboarding?.professionalData
          ?.relevent_exp
          ? this.props?.onboarding?.professionalData?.relevent_exp
          : this.props?.onboarding?.professionalParsedData?.relevent_exp
          ? this.props?.onboarding?.professionalParsedData?.relevent_exp
          : this.props?.authentication?.loginInfo?.relevent_exp
          ? this.props?.authentication?.loginInfo?.relevent_exp
          : "",
      });
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleCompany = () => {
    if (
      this.props?.onboarding?.professionalData?.current_company?.company_name
    ) {
      this.setState({
        companyListValue:
          this.props?.onboarding?.professionalData?.current_company
            ?.company_name,
        companyDomain:
          this.props?.onboarding?.professionalData?.current_company?.domain,
        companyLogoFile:
          this.props?.onboarding?.professionalData?.current_company?.logo,
        isCustomCompany:
          this.props?.onboarding?.professionalData?.current_company?.is_custom,
      });
    } else if (
      this.props?.onboarding?.professionalParsedData?.current_company &&
      this.props?.onboarding?.professionalParsedData?.domain
    ) {
      this.setState({
        companyListValue:
          this.props?.onboarding?.professionalParsedData?.current_company,
        companyDomain: this.props?.onboarding?.professionalParsedData?.domain,
        companyLogoFile: this.props?.onboarding?.professionalParsedData?.logo,
        isCustomCompany: this.state.isCustomCompany,
      });
    } else if (
      this.props?.authentication?.loginInfo?.company &&
      Object.keys(this.props?.authentication?.loginInfo?.company).length !== 0
    ) {
      this.setState({
        companyListValue:
          this.props?.authentication?.loginInfo?.company?.company_name,
        companyDomain: this.props?.authentication?.loginInfo?.company?.domain,
        companyLogoFile: this.props?.authentication?.loginInfo?.company?.logo,
        isCustomCompany: this.state.isCustomCompany,
      });
    } else {
      this.setState({ companyListValue: null });
    }
  };

  handleJobTitle = () => {
    this.setState({
      currentJobTitle: this.props?.onboarding?.professionalData?.title
        ? this.props?.onboarding?.professionalData?.title
        : this.props?.onboarding?.professionalParsedData?.title
        ? this.props?.onboarding?.professionalParsedData?.title
        : this.props?.authentication?.loginInfo?.title
        ? this.props?.authentication?.loginInfo?.title
        : null,
    });
  };

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "currentJobTitle":
          this.setState({
            isCurrentJobTitleRequired: validator.isEmpty(inputValue),
          });
          break;
        case "totalYearExperience":
          this.setState({
            isTotalYearExperienceRequired: validator.isEmpty(inputValue),
          });
          break;
        case "directorLevelExperience":
          this.setState({
            isDirectorLevelExperienceRequired: validator.isEmpty(inputValue),
          });
          break;
        case "companyDomain":
          this.setState({
            companyDomainIsValid:
              event.target.value.length > 0
                ? !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-=?!@">`~]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+))?$/gs.test(
                    event.target.value
                  )
                : false,
            isCompanyDomainRequired: validator.isEmpty(event.target.value),
          });
          break;
        case "customJobTitleValue":
          this.setState({
            isCustomJobTitleRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          break;
      }
    });
  };

  handleOnBlurCompany = () => {
    this.setState({ companyOptions: [], companyList: "" });
  };

  handleOnBlurJobTitle = () => {
    this.setState({ jobTitles: [], jobTitlesValue: "" });
  };

  handleOnSelectjobTitle = () => {
    this.setState({ jobTitles: [], jobTitlesValue: "" });
  };

  handleOnSelectCompany = () => {
    this.setState({ companyOptions: [], companyList: "" });
  };

  handleCompanyInput = debounce((event) => {
    this.setState({ companyList: event.target.value });
    if (event.target.value === "" || event.target.value.length === 0) {
      this.setState({
        companyOptions: [],
        companyValue: [],
        companyLoading: false,
        companyList: "",
        isCompanyListValueRequired: true,
      });
    } else {
      this.setState({ isCompanyListValueRequired: false });
      this.getCompanyNames(event.target.value);
    }
  }, 500);

  handleJobTitleInput = debounce((event) => {
    this.setState({ jobTitlesValue: event.target.value });
    if (event.target.value === "" || event.target.value.length === 0) {
      this.setState({
        jobTitles: [],
        jobTitlesValue: [],
        jobTitleLoading: false,
        isCurrentJobTitleRequired: true,
      });
    } else {
      this.setState({ isCurrentJobTitleRequired: false });
      this.getTitles(event.target.value);
    }
  }, 500);

  getCompanyNames = (title) => {
    const { dispatch } = this.props;
    dispatch(authActions.getCompanyNames(title));
  };

  getTitles = (title) => {
    const { dispatch } = this.props;
    dispatch(authActions.getJobTitles(title));
  };

  handleSaveProfssionalInfo = () => {
    const { dispatch } = this.props;
    let company = null;
    let progressBarStepValue = 50;
    const {
      totalYearExperience,
      directorLevelExperience,
      companyListValue,
      currentJobTitle,
      companyDomain,
      companyLogoFile,
      isCustomCompany,
      companyDomainIsValid,
    } = this.state;
    if (companyListValue) {
      company = {
        name: companyListValue,
        logo: companyLogoFile,
        domain: companyDomain,
        is_custom: isCustomCompany,
      };
    } else {
      company = null;
    }
    if (
      totalYearExperience &&
      companyListValue &&
      directorLevelExperience &&
      currentJobTitle &&
      companyDomain &&
      !companyDomainIsValid
    ) {
      let payload = {
        company: company,
        title: currentJobTitle?._source?.Title
          ? currentJobTitle?._source?.Title
          : currentJobTitle,
        relevent_exp: directorLevelExperience,
        overall_exp: totalYearExperience,
        invite_code: this.props?.authentication?.inviteCode
          ? this.props?.authentication?.inviteCode
          : "REAC22",
        type: "newonboarding",
      };
      if (companyLogoFile) {
        let fd = new FormData();
        fd.append("file", companyLogoFile);
        fd.append("upload_preset", this.props.shared.decryptedUploadPreset);
        onBoardingActions
          .uploadFileToCloudinary(
            fd,
            "auto",
            this.props.shared.decryptedCloudName
          )
          .then((result) => {
            if (result) {
              payload.company.logo = result.data.secure_url
                ? result.data.secure_url
                : null;
              dispatch(
                onBoardingActions.professionalInfo(
                  payload,
                  this.props.activeStep,
                  progressBarStepValue
                )
              );
              sendTrackingData(
                this.props.shared.decryptedMixPanelKey,
                EVENT_TITLES.PROFESSIONAL_INFO_NEXT_CLICK,
                SCREENS.PROFESSIONAL_INFO_SCREEN,
                ACTIONS.PROFESSIONAL_INFO_NEXT_CLICKED
              );
            }
          });
      } else {
        dispatch(
          onBoardingActions.professionalInfo(
            payload,
            this.props.activeStep,
            progressBarStepValue
          )
        );
        sendTrackingData(
          this.props.shared.decryptedMixPanelKey,
          EVENT_TITLES.PROFESSIONAL_INFO_NEXT_CLICK,
          SCREENS.PROFESSIONAL_INFO_SCREEN,
          ACTIONS.PROFESSIONAL_INFO_NEXT_CLICKED
        );
      }
    } else {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Required_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
        )
      );
      this.setState({
        isCurrentJobTitleRequired:
          currentJobTitle === null || currentJobTitle === ""
            ? true
            : Object.keys(currentJobTitle).length === 0
            ? true
            : false,
        isCompanyListValueRequired:
          companyListValue === null ||
          companyListValue === "" ||
          companyListValue === undefined
            ? true
            : false,
        isTotalYearExperienceRequired: validator.isEmpty(totalYearExperience)
          ? true
          : false,
        isDirectorLevelExperienceRequired: validator.isEmpty(
          directorLevelExperience
        )
          ? true
          : false,
        isCompanyDomainRequired: validator.isEmpty(companyDomain)
          ? true
          : false,
      });
    }
  };

  handleLogoUpload = (event) => {
    document.getElementById("logoUpload").click();
    document.getElementById("logoUpload").onchange = (e) => {
      const reader = new FileReader();
      if (e.target.files && e.target.files.length) {
        const [file] = e.target.files;
        if (
          !(
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg"
          )
        ) {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Professional_Info_Page.Logo_Accepted_Formats_Type_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Professional_Info_Page.Logo_Accepted_Formats_Type_Message"
                    }
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation
                contentKey={
                  "Toast_Message.Professional_Info_Page.Logo_Accepted_Formats_Type_Message"
                }
              />
            )
          );
          return;
        }
        const fileSize = Math.round(file.size / Math.pow(1024, 2));
        if (fileSize < 5) {
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({ companyLogoFile: reader.result });
          };
        } else {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Professional_Info_Page.Please_Select_A_File_Upto_5MB_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={
                      "Toast_Message.Professional_Info_Page.Please_Select_A_File_Upto_5MB_Message"
                    }
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation
                contentKey={
                  "Toast_Message.Professional_Info_Page.Please_Select_A_File_Upto_5MB_Message"
                }
              />
            )
          );
          return;
        }
      }
    };
    event.preventDefault();
  };

  handleCustomJobtTitle = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({
      currentJobTitle: this.state.customJobTitleValue,
      customJobTitlePopUp: false,
    });
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(sharedActions.handlePrivousStep(this.props?.shared?.activeStep));
    dispatch(sharedActions.setProgressbarValue(12.5));
    dispatch(basicInfoPage(2));
    history.push("/onboarding/basic-info-step-2");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.PROFESSIONAL_INFO_PREVIOUS_CLICK,
      SCREENS.PROFESSIONAL_INFO_SCREEN,
      ACTIONS.PROFESSIONAL_INFO_PREVIOUS_CLICKED
    );
  };

  handleChangeCompanyInput = (value) => {
    if (typeof value === "string") {
      setTimeout(() => {
        this.setState({
          companyDomain: "",
          companyLogoFile: null,
          companyList: "",
          companyListValue: value,
          isCustomCompany: true,
        });
      });
    } else if (value && value.inputValue) {
      this.setState({
        companyDomain: "",
        companyLogoFile: null,
        companyList: value.inputValue,
        companyListValue: value.inputValue,
        isCustomCompany: true,
        isCompanyDomainRequired: false,
      });
    } else {
      if (value) {
        this.setState({
          expIsCustomCompany: false,
          companyListValue: value.name,
          companyDomain: value?.domain,
          companyLogoFile: value?.logo,
          isCustomCompany: false,
          isCompanyDomainRequired: false,
        });
      } else {
        this.setState({ companyList: "" }, () => {
          this.setState({
            companyListValue: "",
            companyDomain: "",
            companyLogoFile: null,
            isCompanyListValueRequired: true,
          });
        });
      }
    }
  };
  render() {
    let constants = this.props.authentication?.constants;
    const { classes } = this.props;
    const { baseUrlDev } = this.state;

    return (
      <div className={classes.root}>
        <main>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              {baseUrlDev ? (
                <Tooltip
                  arrow
                  title={`${translationEN.Professional_Information_Page.Professional_Information_Heading_1}`}
                  placement="left-end"
                >
                  <Typography variant={"h5"} align="left">
                    <ReactTranslation contentKey="Professional_Information_Page.Professional_Information_Heading_1" />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant={"h5"} align="left">
                  <ReactTranslation contentKey="Professional_Information_Page.Professional_Information_Heading_1" />
                </Typography>
              )}
              <Fragment>
                <Grid container spacing={2} className={classes.smallTopMargin}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      autoComplete
                      id="companyName"
                      name="companyName"
                      required
                      // filterSelectedOptions
                      options={this.state.companyOptions}
                      value={this.state.companyListValue}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?.name ? option?.name : "";
                      }}
                      onBlur={(event) => {
                        this.handleOnBlurCompany(event);
                      }}
                      loading={this.state.companyLoading}
                      onSelect={this.handleOnSelectCompany}
                      getOptionSelected={(option, value) =>
                        option.name === value.name
                      }
                      selectOnFocus
                      clearOnBlur
                      onChange={(event, value) => {
                        this.handleChangeCompanyInput(value);
                      }}
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.unshift({
                            inputValue: params.inputValue,
                            name: `Add: "${params.inputValue}"`,
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => {
                        if (option.name.startsWith("Add:")) {
                          return (
                            <React.Fragment>
                              <span>
                                <AddIcon
                                  fontSize="small"
                                  style={{
                                    backgroundColor: "#000000",
                                    color: "#FFFFFF",
                                    borderRadius: "5px",
                                  }}
                                />
                              </span>{" "}
                              &nbsp;&nbsp;
                              {option.name}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment>
                              <Grid container>
                                <Grid
                                  item
                                  sm={12}
                                  xs={12}
                                  md={12}
                                  xl={12}
                                  lg={12}
                                >
                                  <Typography
                                    component={"div"}
                                    className={classes.companyNameWithLogo}
                                  >
                                    <Typography
                                      component={"div"}
                                      className={classes.displayFlex}
                                    >
                                      <Avatar
                                        className={classes.verySmallMarginRight}
                                        src={option.logo}
                                      />
                                      {option?.name}
                                    </Typography>
                                    <Typography color="textSecondary">
                                      {option.domain}
                                    </Typography>
                                  </Typography>
                                </Grid>
                              </Grid>
                            </React.Fragment>
                          );
                        }
                      }}
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          required
                          label={
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Professional_Information_Page.Form.Current_Former_Employer"
                                placement="left-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.Form.Current_Former_Employer"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Form.Current_Former_Employer"
                                  }
                                />
                              </Typography>
                            )
                          }
                          classes={{
                            root: classes.fontFamily,
                          }}
                          {...params}
                          variant="standard"
                          onChange={(event) => {
                            this.setState({ companyLoading: true });
                            this.handleCompanyInput(event);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                            startAdornment: (
                              <>
                                {this.state?.companyLogoFile && (
                                  <InputAdornment position="start">
                                    <Avatar
                                      className={classes.companySearchLogo}
                                      src={this.state?.companyLogoFile}
                                    />
                                  </InputAdornment>
                                )}
                              </>
                            ),
                            endAdornment: (
                              <React.Fragment>
                                {this.state.companyLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          value={this.state.companyList}
                          error={this.state.isCompanyListValueRequired}
                          helperText={
                            this.state.isCompanyListValueRequired ? (
                              baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Validation_Messages.Field_Required"
                                  placement="left-end"
                                >
                                  <Typography
                                    component={"span"}
                                    className={classes.fontSize12px}
                                  >
                                    <ReactTranslation
                                      contentKey={
                                        "Validation_Messages.Field_Required"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  component={"span"}
                                  className={classes.fontSize12px}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Validation_Messages.Field_Required"
                                    }
                                  />
                                </Typography>
                              )
                            ) : this.state.companyListValue?.length > 1 ? (
                              baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Professional_Information_Page.max_1_company"
                                  placement="left-end"
                                >
                                  <Typography
                                    component={"span"}
                                    className={classes.fontSize12px}
                                  >
                                    <ReactTranslation
                                      contentKey={
                                        "Professional_Information_Page.max_1_company"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.max_1_company"
                                  }
                                />
                              )
                            ) : baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Professional_Information_Page.If_your_company_not_listed"
                                placement="left-end"
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.fontSize12px}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.If_your_company_not_listed"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.If_your_company_not_listed"
                                }
                              />
                            )
                          }
                          placeholder={
                            this.state.companyListValue?.length === 0
                              ? `${translationEN.Placeholder.Proffesional_Info_Page.Current_Company_Placeholder}`
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <TextField
                      id="companyDomain"
                      aria-label="new-domain"
                      label={
                        baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Professional_Information_Page.Company_Website_URL"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Company_Website_URL"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Professional_Information_Page.Company_Website_URL"
                              }
                            />
                          </Typography>
                        )
                      }
                      fullWidth
                      placeholder={`${translationEN.Placeholder.Dialog.Professional_Dialog_Placeholder.Enter_company_website_URL}`}
                      value={this.state.companyDomain}
                      required
                      inputProps={{
                        maxLength: 100,
                      }}
                      onChange={this.handleInputChange("companyDomain")}
                      error={
                        this.state.isCompanyDomainRequired ||
                        this.state.companyDomainIsValid
                      }
                      helperText={
                        this.state.isCompanyDomainRequired ? (
                          MESSAGE_FIELD_IS_REQUIRED
                        ) : this.state.companyDomainIsValid ? (
                          baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Professional_Information_Page.Invalid_Domain_URL"
                              placement="left-end"
                            >
                              <Typography
                                component={"span"}
                                className={classes.fontSize12px}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Invalid_Domain_URL"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.fontSize12px}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Invalid_Domain_URL"
                                }
                              />
                            </Typography>
                          )
                        ) : this.state.companyDomain.length > 100 ? (
                          baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Professional_Information_Page.Max_100_Characters_Allowed"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Max_100_Characters_Allowed"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Max_100_Characters_Allowed"
                                }
                              />
                            </Typography>
                          )
                        ) : baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Professional_Information_Page.Website_Url_Eg"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Website_Url_Eg"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Professional_Information_Page.Website_Url_Eg"
                              }
                            />
                          </Typography>
                        )
                      }
                    />
                  </Grid>
                  {/* this code for upload company logo */}
                  {this.state.companyLogoFile && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className={classes.textAlignLeft}
                    >
                      <div className={classes.verySmallBottomMargin}>
                        <FormLabel>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Professional_Information_Page.Form.Company_logo"
                              placement="left-end"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Form.Company_logo"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Form.Company_logo"
                                }
                              />
                            </Typography>
                          )}
                        </FormLabel>
                      </div>
                      <Badge
                        style={{ cursor: "pointer" }}
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Grid>
                            <input
                              hidden
                              type="file"
                              accept=".png, .jpeg, .jpg"
                              id="logoUpload"
                            />
                            <IconButton
                              onClick={(event) => {
                                this.handleLogoUpload(event);
                              }}
                              style={{
                                background: "#000000",
                                height: "10px",
                                width: "10px",
                              }}
                            >
                              <Tooltip title="Edit Company logo">
                                <Edit
                                  style={{ color: "#ffffff", height: "15px" }}
                                />
                              </Tooltip>
                            </IconButton>
                          </Grid>
                        }
                      >
                        <Avatar
                          // variant='rounded'
                          alt={this.state.companyListValue}
                          src={
                            this.state.companyLogoFile
                              ? this.state.companyLogoFile
                              : "/"
                          }
                          className={classNames(
                            classes.large,
                            classes.customBackground
                          )}
                          //  style={{ border: '2px dashed #000000', height: '100px', width: '100px' }}
                        ></Avatar>
                      </Badge>
                    </Grid>
                  )}
                  {!this.state.companyLogoFile ? (
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Dialog.Buttons.Add_Company_Logo"
                        placement="left"
                      >
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          md={12}
                          lg={12}
                          xl={12}
                          className={classes.textAlignLeft}
                        >
                          <div className={classes.verySmallBottomMargin}>
                            <FormLabel>
                              {baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Professional_Information_Page.Form.Company_logo"
                                  placement="left-end"
                                >
                                  <Typography component={"span"}>
                                    <ReactTranslation
                                      contentKey={
                                        "Professional_Information_Page.Form.Company_logo"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.Form.Company_logo"
                                    }
                                  />
                                </Typography>
                              )}
                            </FormLabel>
                          </div>
                          <input
                            hidden
                            type="file"
                            accept=".png, .jpg, .jpeg"
                            id="logoUpload"
                          />
                          <Button
                            startIcon={<AddIcon />}
                            disableElevation
                            className={classes.customButton}
                            variant="outlined"
                            color="primary"
                            onClick={(event) => this.handleLogoUpload(event)}
                          >
                            <ReactTranslation
                              contentKey={"Dialog.Buttons.Add_Company_Logo"}
                            />
                          </Button>
                        </Grid>
                      </Tooltip>
                    ) : (
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className={classes.verySmallBottomMargin}>
                          <FormLabel>
                            {baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Professional_Information_Page.Form.Company_logo"
                                placement="left-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.Form.Company_logo"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Form.Company_logo"
                                  }
                                />
                              </Typography>
                            )}
                          </FormLabel>
                        </div>
                        <input
                          hidden
                          type="file"
                          accept=".png, .jpg, .jpeg"
                          id="logoUpload"
                        />
                        <Button
                          startIcon={<AddIcon />}
                          disableElevation
                          className={classes.customButton}
                          variant="outlined"
                          color="primary"
                          onClick={(event) => this.handleLogoUpload(event)}
                        >
                          <ReactTranslation
                            contentKey={"Dialog.Buttons.Add_Company_Logo"}
                          />
                        </Button>
                      </Grid>
                    )
                  ) : null}
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Autocomplete
                      required
                      autoComplete={false}
                      id="jobTitle"
                      name="jobTitle"
                      options={this.state.jobTitles}
                      value={this.state.currentJobTitle}
                      getOptionSelected={(option, value) =>
                        option?._source?.Title === value?._source?.Title
                      }
                      onBlur={(event) => {
                        this.handleOnBlurJobTitle(event);
                      }}
                      loading={this.state.jobTitleLoading}
                      onSelect={(event) => this.handleOnSelectjobTitle(event)}
                      selectOnFocus
                      clearOnBlur
                      onChange={(event, value) => {
                        if (typeof value === "string") {
                          setTimeout(() => {
                            this.setState({
                              customJobTitlePopUp: true,
                              customJobTitleValue: value,
                              currentJobTitle: "",
                              jobTitlesValue: "",
                            });
                          });
                        } else if (value && value.inputValue) {
                          this.setState({
                            customJobTitlePopUp: true,
                            customJobTitleValue: value.inputValue,
                            currentJobTitle: "",
                            jobTitlesValue: "",
                          });
                        } else {
                          if (value) {
                            this.setState({
                              customJobTitlePopUp: false,
                              currentJobTitle: value,
                              jobTitlesValue: value,
                            });
                          } else {
                            this.setState({
                              currentJobTitle: "",
                              jobTitlesValue: "",
                              isCurrentJobTitleRequired: true,
                            });
                          }
                        }
                      }}
                      getOptionLabel={(option) => {
                        if (typeof option === "string") {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        return option?._source?.Title
                          ? option?._source?.Title
                          : "";
                      }}
                      filterOptions={(options, params) => {
                        let filtered = options;
                        // const filtered = filter(options, params);
                        if (params.inputValue !== "") {
                          filtered.unshift({
                            inputValue: params.inputValue,
                            _source: { Title: `Add: "${params.inputValue}"` },
                          });
                        }
                        return filtered;
                      }}
                      renderOption={(option) => {
                        if (option?._source?.Title.startsWith("Add:")) {
                          return (
                            <React.Fragment>
                              <span>
                                <AddIcon
                                  fontSize="small"
                                  style={{
                                    backgroundColor: "#000000",
                                    color: "#FFFFFF",
                                    borderRadius: "5px",
                                  }}
                                />
                              </span>{" "}
                              &nbsp;&nbsp;
                              {option?._source?.Title}
                            </React.Fragment>
                          );
                        } else {
                          return (
                            <React.Fragment>
                              {option?._source?.Title}
                            </React.Fragment>
                          );
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          label={
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Professional_Information_Page.Form.Current_Job_Title"
                                placement="left-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.Form.Current_Job_Title"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.Form.Current_Job_Title"
                                  }
                                />
                              </Typography>
                            )
                          }
                          classes={{
                            root: classes.fontFamily,
                          }}
                          {...params}
                          variant="standard"
                          onChange={(event) => {
                            this.setState({ jobTitleLoading: true });
                            this.handleJobTitleInput(event);
                          }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {this.state.jobTitleLoading ? (
                                  <CircularProgress
                                    className={classes.verySmallMarginRight}
                                    color="inherit"
                                    size={20}
                                  />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                            inputProps: {
                              ...params.inputProps,
                              maxLength: 1000,
                            },
                          }}
                          value={this.state.jobTitlesValue}
                          error={this.state.isCurrentJobTitleRequired}
                          helperText={
                            this.state.isCurrentJobTitleRequired ? (
                              baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Validation_Messages.Field_Required"
                                  placement="left-end"
                                >
                                  <Typography
                                    component={"span"}
                                    className={classes.fontSize12px}
                                  >
                                    <ReactTranslation
                                      contentKey={
                                        "Validation_Messages.Field_Required"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  component={"span"}
                                  className={classes.fontSize12px}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Validation_Messages.Field_Required"
                                    }
                                  />
                                </Typography>
                              )
                            ) : this.state.jobTitlesValue?.length > 1 ? (
                              baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Professional_Information_Page.max_1_job"
                                  placement="left-end"
                                >
                                  <Typography
                                    component={"span"}
                                    className={classes.fontSize12px}
                                  >
                                    <ReactTranslation
                                      contentKey={
                                        "Professional_Information_Page.max_1_job"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <ReactTranslation
                                  contentKey={
                                    "Professional_Information_Page.max_1_job"
                                  }
                                />
                              )
                            ) : baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Professional_Information_Page.If_job_title_not_listed"
                                placement="left-end"
                              >
                                <Typography
                                  component={"span"}
                                  className={classes.fontSize12px}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Professional_Information_Page.If_job_title_not_listed"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.If_job_title_not_listed"
                                }
                              />
                            )
                          }
                          // 'Please click here to add: <your job title>'
                          placeholder={
                            this.state.jobTitlesValue?.length === 0
                              ? `${translationEN.Placeholder.Proffesional_Info_Page.Current_Job_Title_Placeholder}`
                              : ""
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={this.state.isTotalYearExperienceRequired}
                    >
                      <InputLabel id="experience-label">
                        {" "}
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Professional_Information_Page.Form.Total_Years_of_Experience"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Form.Total_Years_of_Experience"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Professional_Information_Page.Form.Total_Years_of_Experience"
                              }
                            />
                          </Typography>
                        )}
                      </InputLabel>
                      <Select
                        labelId="experience-label"
                        name="jobTitle"
                        id="totalYearExperience"
                        defaultValue={this.state.totalYearExperience}
                        value={this.state.totalYearExperience}
                        placeholder={`${translationEN.Placeholder.Proffesional_Info_Page.Total_Year_Exp_Placeholder}`}
                        onChange={this.handleInputChange("totalYearExperience")}
                      >
                        {constants?.overall_exp?.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                      {this.state.isTotalYearExperienceRequired ? (
                        <FormHelperText>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Validation_Messages.Field_Required"
                              placement="left-end"
                            >
                              <Typography
                                component={"span"}
                                className={classes.fontSize12px}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Validation_Messages.Field_Required"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.fontSize12px}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Validation_Messages.Field_Required"
                                }
                              />
                            </Typography>
                          )}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                      required
                      error={this.state.isDirectorLevelExperienceRequired}
                    >
                      <InputLabel id="experience-label">
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Professional_Information_Page.Form.Level_and_Above_Experience"
                            placement="left-end"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={
                                  "Professional_Information_Page.Form.Level_and_Above_Experience"
                                }
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Professional_Information_Page.Form.Level_and_Above_Experience"
                              }
                            />
                          </Typography>
                        )}
                      </InputLabel>
                      <Select
                        labelId="experience-label"
                        id="directorLevelExperience"
                        name="directorLevelExperience"
                        value={this.state.directorLevelExperience}
                        placeholder={`${translationEN.Placeholder.Proffesional_Info_Page.Director_Level_Exp_Placeholder}`}
                        onChange={this.handleInputChange(
                          "directorLevelExperience"
                        )}
                      >
                        {constants?.relevant_exp?.map((obj, index) => (
                          <MenuItem key={index} value={obj}>
                            {obj}
                          </MenuItem>
                        ))}
                      </Select>
                      {this.state.isDirectorLevelExperienceRequired ? (
                        <FormHelperText>
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Validation_Messages.Field_Required"
                              placement="left-end"
                            >
                              <Typography
                                component={"span"}
                                className={classes.fontSize12px}
                              >
                                <ReactTranslation
                                  contentKey={
                                    "Validation_Messages.Field_Required"
                                  }
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography
                              component={"span"}
                              className={classes.fontSize12px}
                            >
                              <ReactTranslation
                                contentKey={
                                  "Validation_Messages.Field_Required"
                                }
                              />
                            </Typography>
                          )}
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.smallTopMargin}
                  >
                    <div
                      className={classNames(
                        classes.smallTopMargin,
                        classes.professionalButton
                      )}
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Buttons.Previous"
                          placement="bottom"
                        >
                          <Button
                            id="professionalPreviousButton"
                            variant="outlined"
                            color="primary"
                            disableElevation
                            onClick={(event) => {
                              event.preventDefault();
                              this.handlePrevious();
                            }}
                          >
                            {" "}
                            <ReactTranslation contentKey={"Buttons.Previous"} />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          id="professionalPreviousButton"
                          variant="outlined"
                          color="primary"
                          disableElevation
                          onClick={(event) => {
                            event.preventDefault();
                            this.handlePrevious();
                          }}
                        >
                          {" "}
                          <ReactTranslation contentKey={"Buttons.Previous"} />
                        </Button>
                      )}
                      {baseUrlDev ? (
                        <Tooltip arrow title="Buttons.Next" placement="bottom">
                          <Button
                            id="professionalNextButton"
                            variant="contained"
                            color="primary"
                            disableElevation
                            onClick={(event) => {
                              event.preventDefault();
                              this.handleSaveProfssionalInfo();
                            }}
                          >
                            {" "}
                            <ReactTranslation
                              contentKey={"Buttons.Next"}
                            />{" "}
                          </Button>
                        </Tooltip>
                      ) : (
                        <Button
                          id="professionalNextButton"
                          variant="contained"
                          color="primary"
                          disableElevation
                          onClick={(event) => {
                            event.preventDefault();
                            this.handleSaveProfssionalInfo();
                          }}
                        >
                          {" "}
                          <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Grid>

                {/* Custom job title pop up */}
                <Dialog
                  fullWidth
                  maxWidth="xs"
                  disableBackdropClick
                  open={this.state.customJobTitlePopUp}
                  onClose={() => this.setState({ customJobTitlePopUp: false })}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle
                    id="alert-dialog-title"
                    onClose={() =>
                      this.setState({ customJobTitlePopUp: false })
                    }
                    className={classes.customDialogTitle}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Dialog.Professional_Page.Custom_Job_Dialog.Add_Current_Job_Title"
                        placement="right-end"
                      >
                        <Typography
                          variant="h6"
                          component={"span"}
                          className={classes.dialogTitle}
                        >
                          <ReactTranslation
                            contentKey={
                              "Dialog.Professional_Page.Custom_Job_Dialog.Add_Current_Job_Title"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <ReactTranslation
                        contentKey={
                          "Dialog.Professional_Page.Custom_Job_Dialog.Add_Current_Job_Title"
                        }
                      />
                    )}
                  </DialogTitle>
                  <DialogContent>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      spacing={2}
                    >
                      <Grid item xs={12} sm={12}>
                        <TextField
                          autoFocus
                          id="new-job-title"
                          aria-label="new-job-title"
                          label={
                            baseUrlDev ? (
                              <Tooltip
                                arrow
                                title="Dialog.Professional_Page.Custom_Job_Dialog.Job_Title"
                                placement="right-end"
                              >
                                <Typography component={"span"}>
                                  <ReactTranslation
                                    contentKey={
                                      "Dialog.Professional_Page.Custom_Job_Dialog.Job_Title"
                                    }
                                  />
                                </Typography>
                              </Tooltip>
                            ) : (
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={
                                    "Dialog.Professional_Page.Custom_Job_Dialog.Job_Title"
                                  }
                                />
                              </Typography>
                            )
                          }
                          fullWidth
                          placeholder={`${translationEN.Placeholder.Dialog.Professional_Dialog_Placeholder.Current_Job_Title}`}
                          value={this.state.customJobTitleValue}
                          required
                          inputProps={{
                            maxLength: 100,
                          }}
                          onChange={this.handleInputChange(
                            "customJobTitleValue"
                          )}
                          helperText={
                            this.state.isCustomJobTitleRequired ? (
                              baseUrlDev ? (
                                <Tooltip
                                  arrow
                                  title="Validation_Messages.Field_Required"
                                  placement="right-end"
                                >
                                  <Typography
                                    component={"span"}
                                    className={classes.fontSize12px}
                                  >
                                    <ReactTranslation
                                      contentKey={
                                        "Validation_Messages.Field_Required"
                                      }
                                    />
                                  </Typography>
                                </Tooltip>
                              ) : (
                                <Typography
                                  component={"span"}
                                  className={classes.fontSize12px}
                                >
                                  <ReactTranslation
                                    contentKey={
                                      "Validation_Messages.Field_Required"
                                    }
                                  />
                                </Typography>
                              )
                            ) : (
                              ""
                            )
                          }
                          error={this.state.isCustomJobTitleRequired}
                        />
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions
                    className={classNames(
                      classes.smallBottomMargin,
                      classes.mediumTopMargin
                    )}
                  >
                    {baseUrlDev ? (
                      <Grid container className={classes.customPadding}>
                        <Tooltip
                          arrow
                          title="Dialog.Buttons.Cancel"
                          placement="bottom"
                        >
                          <Button
                            onClick={(event) => {
                              event.preventDefault();
                              this.setState({ customJobTitlePopUp: false });
                              sendTrackingData(
                                this.props.shared.decryptedMixPanelKey,
                                EVENT_TITLES.PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICK,
                                SCREENS.PROFESSIONAL_INFO_SCREEN,
                                ACTIONS.PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICKED
                              );
                            }}
                            variant="outlined"
                            color="primary"
                            disableElevation
                          >
                            <ReactTranslation
                              contentKey={"Dialog.Buttons.Cancel"}
                            />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          arrow
                          title="Dialog.Buttons.Add"
                          placement="bottom"
                        >
                          <Button
                            onClick={(event) => {
                              this.handleCustomJobtTitle(event);
                              sendTrackingData(
                                this.props.shared.decryptedMixPanelKey,
                                EVENT_TITLES.PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICK,
                                SCREENS.PROFESSIONAL_INFO_SCREEN,
                                ACTIONS.PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICKED
                              );
                            }}
                            color="primary"
                            disableElevation
                            variant="contained"
                            disabled={this.state.customJobTitleValue === ""}
                          >
                            <ReactTranslation
                              contentKey={"Dialog.Buttons.Add"}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    ) : (
                      <Grid container className={classes.customPadding}>
                        <Button
                          onClick={(event) => {
                            event.preventDefault();
                            this.setState({ customJobTitlePopUp: false });
                            sendTrackingData(
                              this.props.shared.decryptedMixPanelKey,
                              EVENT_TITLES.PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICK,
                              SCREENS.PROFESSIONAL_INFO_SCREEN,
                              ACTIONS.PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICKED
                            );
                          }}
                          variant="outlined"
                          color="primary"
                          disableElevation
                        >
                          <ReactTranslation
                            contentKey={"Dialog.Buttons.Cancel"}
                          />
                        </Button>
                        <Button
                          onClick={(event) => {
                            this.handleCustomJobtTitle(event);
                            sendTrackingData(
                              this.props.shared.decryptedMixPanelKey,
                              EVENT_TITLES.PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICK,
                              SCREENS.PROFESSIONAL_INFO_SCREEN,
                              ACTIONS.PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICKED
                            );
                          }}
                          color="primary"
                          disableElevation
                          variant="contained"
                          disabled={this.state.customJobTitleValue === ""}
                        >
                          <ReactTranslation contentKey={"Dialog.Buttons.Add"} />
                        </Button>
                      </Grid>
                    )}
                  </DialogActions>
                </Dialog>
              </Fragment>
            </Grid>

            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <HelpTextCard helpTextCardType={this.state.helpTextCardType} />
              {/* Need help component */}
              <NeedHelp />
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

ProfessionalInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedProfessionalInfoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(ProfessionalInfo))
);
export { connectedProfessionalInfoPage as ProfessionalInfo };
