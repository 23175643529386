const initialState = {
  basicInfoData: {},
  basicInfoParsedData: {},
  memberStatus: "",
};

export function basicInfo(state = initialState, action) {
  switch (action.type) {
    case "BASIC_INFO_DATA":
      return {
        ...state,
        basicInfoData: action.basicInfoData,
        basicInfoParsedData: action.basicInfoParsedData,
      };
    case "MEMBER_APPROVED":
      return {
        ...state,
        memberStatus: action.memberStatus,
      };
    default:
      return state;
  }
}
