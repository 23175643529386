import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import Footer from "../../core/footer";
import { sendTrackingData, showHideToolTip } from "../../../helpers";
import { EVENT_TITLES, SCREENS, ACTIONS } from "../../../constants/constants";
import {
  setTitle,
  authActions,
  getJobList,
  saveVipJobDetail,
  getMixPanelkey,
} from "../../../actions";
import config from "../../../config/config";
import BOAModule from "../../core/boa-module";
import ReactTranslation from "../../core/iReact18";
import translationEN from "../../../locales/en/translation.json";
import { NeedHelp } from "../../core/needHelp.component";
import secureLocalStorage from "react-secure-storage";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 150px)",
    [theme.breakpoints.between("xs", "sm")]: {
      height: "unset",
    },
    // marginTop: '90px'
  },
  boldFont: {
    fontWeight: "bold",
  },
  smallTopMargin: {
    marginTop: "2%",
  },
  mediumTopMargin: {
    marginTop: "4%",
  },
  largeTopMargin: {
    marginTop: "10%",
  },
  textAlignLeft: {
    textAlign: "left",
  },
  // spacing: {
  //   backgroundImage: `url(${Background})`,
  //   backgroundSize: 'auto',
  //   // background: 'round'
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center bottom'
  // },
  customLink: {
    //color: '#048475 !important',
    color: "#FBC112 !important",
    cursor: "pointer",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  loginForm: {
    margin: "auto",
    width: "60%",
    padding: "8px",
    paddingBottom: "48px",
    [theme.breakpoints.down("md")]: {
      width: "75%",
      paddingBottom: "48px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: "95%",
      paddingBottom: "48px",
    },
  },
  smallBottomMargin: {
    marginBottom: "16px",
  },
  linkedInButton: {
    width: "100%",
    // backgroundColor: '#0078C7',
    backgroundColor: "#FFFFFF",
    border: "1px solid #020203",
    color: "#020203 !important",
    fontWeight: "bold !important",
    borderRadius: "4px",
    fontSize: "16px",

    "&:hover": {
      // backgroundColor: '#FBC112',
      color: "#000000 !important",
      backgroundColor: "#FFFFFF",
    },
  },
  disabledLinkedInButton: {
    width: "100%",
    fontSize: "16px",
    backgroundColor: "#FFFFFF !important",
  },
  linkedInSignInButton: {
    width: "100%",
    fontWeight: "bold !important",
    backgroundColor: "white",
    color: "#020203 !important",
    cursor: "pointer",
    border: "1px solid #020203",
    borderRadius: "4px",
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      color: "#000000 !important",
    },
  },
  linkedinIcon: {
    color: "#0A66C2",
  },
  versionPosition: {
    fontSize: "small",
    bottom: 85,
    left: "2.6%",
    position: "fixed",
    [theme.breakpoints.between("xs", "sm")]: {
      position: "relative",
      bottom: 120,
    },
  },
  floatLeft: {
    float: "left !important",
    textAlign: "left !important",
  },
  clearLeft: {
    clear: "left !important",
  },
  leftSideContentPadding: {
    backgroundColor: "#F8F6EF",
    paddingTop: "2% !important",
    paddingBottom: "2% !important",
    paddingRight: "2.6% !important",
    paddingLeft: "2.6% !important",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: "16px !important",
      paddingLeft: "16px !important",
      paddingTop: "32px !important",
    },
    [theme.breakpoints.only("md")]: {
      paddingRight: "32px !important",
      paddingLeft: "32px !important",
      paddingTop: "32px !important",
    },
    [theme.breakpoints.up("xl")]: {
      paddingRight: "12% !important",
      paddingLeft: "12% !important",
    },
  },
  rightSideContent: {
    color: "rgba(255, 255, 255, 0.6)",
    paddingRight: "3em !important",
    paddingTop: "2% !important ",
    paddingLeft: "3em !important ",
    backgroundColor: "#14131f",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingRight: "16px !important",
      paddingLeft: "16px !important",
      paddingTop: "32px !important ",
    },
  },
  textColor: {
    color: "#FFFFFF",
  },
  responsiveLoginText: {
    textAlign: "center",
    [theme.breakpoints.between("xs", "sm")]: {
      marginBottom: "8px",
    },
  },
  headerStyle: {
    fontSize: "56px",
    fontWeight: 900,
    margin: "0 0 45px 0",
    padding: 0,
    lineHeight: "60px",
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "32px",
    },
  },
});

const CustomCheckBox = withStyles({
  root: {
    color: "#303030",
    "&$checked": {
      color: "#303030",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class JobLandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    acceptTermAndConditions: false,
    baseUrlDev: false,
    jobName: null,
  });

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const { dispatch } = this.props;
    dispatch(getMixPanelkey());
    // let url = new URL(window.location.href);
    //  dispatch(setTitle(`${translationEN.PageTitle.Job_Login_Page_Title}`));
    // // const { hostname } = new URL(window.location.href);
    // const params = new URLSearchParams(window.location.search);
    // let originalUrlPath ;
    // if( window.location.pathname.match('/job.*$')){
    //   if(!window.location.search){
    //     // dispatch(getJobList());
    //     let jobInfo = url.href.split('/');
    //     var jobId = jobInfo[jobInfo.length-1];
    //     this.setState({jobName: jobInfo[jobInfo.length-2]}, () => {
    //       secureLocalStorage.setItem('jobId', JSON.stringify(jobId));
    //       secureLocalStorage.setItem('jobName', JSON.stringify(this.state.jobName));
    //     });
    //    // let jobName = jobInfo[jobInfo.length-2];
    //     dispatch(getJobDetail(jobId));
    //   } else if(window.location.search){
    //      // dispatch(getJobList());
    //       let jobInfo = url.href.split('/');
    //       var splitedJobId = jobInfo[jobInfo.length-1];
    //       this.setState({jobName: jobInfo[jobInfo.length-2]}, () => {
    //         secureLocalStorage.setItem('jobId', JSON.stringify(jobId));
    //         secureLocalStorage.setItem('jobName', JSON.stringify(this.state.jobName));
    //       });
    //       let jobId = splitedJobId.split('?')[0];
    //       dispatch(getJobDetail(jobId));

    //   }
    // }
    // let utmData = {};
    // for(var urlKey of params.keys()){
    //   if(urlKey.startsWith('utm_')) {
    //     utmData[urlKey] = params.get(urlKey);
    //   }
    // }
    // if(!url.searchParams.get('key')){
    //   utmData['utm_linkurl'] = window.location.href; // hostname;
    // }
    // if(url.searchParams.get('invite_code') || url.searchParams.get('id')){
    //   const inviteCode = url.searchParams.get('invite_code') ? url.searchParams.get('invite_code') : 'REAC22';
    //   const originalUrlPath = window.location.pathname + window.location.search;
    //   dispatch(authActions.saveParamInfo(utmData, inviteCode, originalUrlPath));
    // }
    // else {
    //   originalUrlPath =  window.location.pathname;
    //   dispatch(authActions.saveParamInfo(utmData ? utmData : this.props?.authentication?.utmData ? this.props?.authentication?.utmData : {}, this.props?.authentication?.jodDetailInfo?.acf?.vip_code ? this.props?.authentication?.jodDetailInfo?.acf?.vip_code : 'REAC22', originalUrlPath));
    // }
    // if(secureLocalStorage.getItem('vipJobName')){
    //   secureLocalStorage.removeItem('vipJobName');
    // }
    // dispatch(saveVipJobDetail({}));
    // sendTrackingData(EVENT_TITLES.JOB_LOGIN_PAGE_LOAD, SCREENS.JOB_LOGIN_SCREEN, ACTIONS.JOB_LOGIN_PAGE_LOADED);
    // if(config.baseUrl === `https://dev1-platform.succeedsmart.com/` || config.baseUrl === `https://dev-platform.succeedsmart.com/`){
    //   this.setState({ baseUrlDev: true});
    // }
    let url = new URL(window.location.href);
    dispatch(setTitle(`${translationEN.PageTitle.Job_Login_Page_Title}`));
    // const { hostname } = new URL(window.location.href);
    const params = new URLSearchParams(window.location.search);
    let originalUrlPath;
    if (window.location.pathname.match("/job.*$")) {
      if (!window.location.search) {
        dispatch(getJobList());
        let jobInfo = url.href.split("/");
        var jobName = jobInfo[jobInfo.length - 1];
        secureLocalStorage.setItem("jobName", JSON.stringify(jobName));
      } else if (window.location.search) {
        dispatch(getJobList());
        let jobInfo = url.href.split("/");
        var splitedJobName = jobInfo[jobInfo.length - 1];
        let jobName = splitedJobName.split("?")[0];
        secureLocalStorage.setItem("jobName", JSON.stringify(jobName));
      }
    }
    let utmData = {};
    for (var urlKey of params.keys()) {
      if (urlKey.startsWith("utm_")) {
        utmData[urlKey] = params.get(urlKey);
      }
    }
    if (!url.searchParams.get("key")) {
      utmData["utm_linkurl"] = window.location.href; // hostname;
    }
    if (url.searchParams.get("invite_code")) {
      const inviteCode = url.searchParams.get("invite_code")
        ? url.searchParams.get("invite_code")
        : "REAC22";
      const originalUrlPath = window.location.pathname + window.location.search;
      dispatch(authActions.saveParamInfo(utmData, inviteCode, originalUrlPath));
    } else {
      originalUrlPath = window.location.pathname;
      dispatch(
        authActions.saveParamInfo(
          utmData
            ? utmData
            : this.props?.authentication?.utmData
            ? this.props?.authentication?.utmData
            : {},
          this.props?.authentication?.jodDetailInfo?.acf?.vip_code
            ? this.props?.authentication?.jodDetailInfo?.acf?.vip_code
            : "REAC22",
          originalUrlPath
        )
      );
    }
    if (secureLocalStorage.getItem("vipJobName")) {
      secureLocalStorage.removeItem("vipJobName");
    }
    dispatch(saveVipJobDetail({}));

    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.shared.decryptedMixPanelKey !==
      this.props.shared.decryptedMixPanelKey
    ) {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.JOB_LOGIN_PAGE_LOAD,
        SCREENS.JOB_LOGIN_SCREEN,
        ACTIONS.JOB_LOGIN_PAGE_LOADED
      );
    }
  }

  handleTermsAndConditionAccept = (prop) => (event) => {
    this.setState({ [prop]: event.target.checked }, () => {
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.TERMS_AND_CONDITIONS_CHECKBOX_CLICK,
        SCREENS.JOB_LOGIN_SCREEN,
        `${
          event.target.checked
            ? ACTIONS.T_N_C_CHECKBOX_CHECKED
            : ACTIONS.T_N_C_CHECKBOX_UNCHECKED
        }`
      );
    });
  };
  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <Grid container className={classes.root}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            lg={8}
            xl={8}
            className={classes.leftSideContentPadding}
          >
            {/* <div style={{position: 'absolute', top: 120, left: '2%'}}><img src={LandingPageIcon} alt='landing page icon' /></div> */}
            <Typography
              className={classNames(
                classes.floatLeft,
                classes.clearLeft,
                classes.boldFont,
                classes.headerStyle
              )}
              variant="h1"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.authentication.jodDetailInfo?.title
                    ?.rendered
                    ? this.props.authentication?.jodDetailInfo?.title?.rendered
                    : "",
                }}
              ></div>
            </Typography>
            <Typography
              className={classNames(classes.floatLeft, classes.clearLeft)}
            >
              <Typography
                id="jobLandingPageContent"
                dangerouslySetInnerHTML={{
                  __html: this.props.authentication.jodDetailInfo?.content
                    ?.rendered
                    ? this.props.authentication.jodDetailInfo?.content?.rendered
                    : "",
                }}
              ></Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={4}
            xl={4}
            className={classes.rightSideContent}
          >
            <Typography
              id="rightSideSection"
              variant="h4"
              className={classNames(
                classes.boldFont,
                classes.floatLeft,
                classes.clearLeft,
                classes.textColor
              )}
            >
              {this.props.authentication?.jodDetailInfo?.acf
                ?.job_right_side_title ? (
                this.props.authentication?.jodDetailInfo?.acf
                  ?.job_right_side_title
              ) : baseUrlDev ? (
                <Tooltip arrow title="Login_Page.heading">
                  <Typography variant="h4">
                    <ReactTranslation contentKey={"Login_Page.heading"} />
                  </Typography>
                </Tooltip>
              ) : (
                <Typography variant="h4">
                  <ReactTranslation contentKey={"Login_Page.heading"} />
                </Typography>
              )}
            </Typography>
            {this.props.authentication?.jodDetailInfo?.acf
              ?.job_right_side_content ? (
              <Typography
                className={classNames(
                  classes.smallTopMargin,
                  classes.floatLeft,
                  classes.clearLeft
                )}
              >
                <div
                  id="jobLandingPageRightSideContent"
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.authentication?.jodDetailInfo?.acf
                        ?.job_right_side_content,
                  }}
                ></div>
              </Typography>
            ) : (
              <Typography
                className={classNames(
                  classes.smallTopMargin,
                  classes.smallBottomMargin,
                  classes.floatLeft,
                  classes.clearLeft
                )}
              >
                {/* <div id='jobLandingPageRightSideContent' dangerouslySetInnerHTML={{__html: '<ol><li>Sign up with LinkedIn</li><li>Complete your profile</li><li>Start growing your executive network and entertain career defining job opportunities while you enjoy unique services and content.</li></ol>'}}></div> */}
                <div id="jobLandingPageRightSideContent">
                  {baseUrlDev ? (
                    <ol>
                      <Tooltip
                        arrow
                        title="Vip_Login_Page.signup_with_linkedin"
                      >
                        <li>
                          <ReactTranslation
                            contentKey={"Vip_Login_Page.signup_with_linkedin"}
                          />
                        </li>
                      </Tooltip>
                      <Tooltip title="Vip_Login_Page.Complete_your_profile">
                        <li>
                          <ReactTranslation
                            contentKey={"Vip_Login_Page.Complete_your_profile"}
                          />
                        </li>
                      </Tooltip>
                      <Tooltip title="Vip_Login_Page.Start_growing_your_executive_network">
                        <li>
                          <ReactTranslation
                            contentKey={
                              "Vip_Login_Page.Start_growing_your_executive_network"
                            }
                          />
                        </li>
                      </Tooltip>
                    </ol>
                  ) : (
                    <ol>
                      <li>
                        <ReactTranslation
                          contentKey={"Vip_Login_Page.signup_with_linkedin"}
                        />
                      </li>
                      <li>
                        <ReactTranslation
                          contentKey={"Vip_Login_Page.Complete_your_profile"}
                        />
                      </li>
                      <li>
                        <ReactTranslation
                          contentKey={
                            "Vip_Login_Page.Start_growing_your_executive_network"
                          }
                        />
                      </li>
                    </ol>
                  )}
                </div>
              </Typography>
            )}{" "}
            <br />
            <Grid
              container
              className={classNames(
                classes.smallTopMargin,
                classes.smallBottomMargin
              )}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.smallTopMargin}
                id="termsAndConditions"
              >
                <FormControlLabel
                  style={{ placeItems: "flex-start" }}
                  control={
                    <CustomCheckBox
                      style={{ padding: "2px 9px", color: "#ffffff" }}
                      checked={this.state.acceptTermAndConditions}
                      onChange={this.handleTermsAndConditionAccept(
                        "acceptTermAndConditions"
                      )}
                      name="acceptTermAndConditions"
                    />
                  }
                  label={
                    <Typography
                      color="textSecondary"
                      style={{ color: "rgba(255, 255, 255, 0.6)" }}
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Login_Page.content_03"
                          placement="left"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Login_Page.content_03"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Login_Page.content_03"}
                          />
                        </Typography>
                      )}
                      <a
                        href="https://succeedsmart.com/termsofuse"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.customLink}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Login_Page.terms_of_use"
                            placement="left"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Login_Page.terms_of_use"}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Login_Page.terms_of_use"}
                            />
                          </Typography>
                        )}
                      </a>
                      &nbsp;
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Login_Page.and"
                          placement="left-end"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation contentKey={"Login_Page.and"} />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <ReactTranslation contentKey={"Login_Page.and"} />
                      )}
                      &nbsp;
                      <a
                        href="https://succeedsmart.com/privacypolicy"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.customLink}
                      >
                        {baseUrlDev ? (
                          <Tooltip
                            arrow
                            title="Login_Page.privacy_policy"
                            placement="top"
                          >
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Login_Page.privacy_policy"}
                              />
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={"Login_Page.privacy_policy"}
                            />
                          </Typography>
                        )}
                      </a>
                      .
                    </Typography>
                  }
                  value="true"
                  className={classes.textAlignLeft}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.largeTopMargin}
              >
                {this.state.acceptTermAndConditions && (
                  <a
                    href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                    style={{ textDecoration: "none" }}
                  >
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Login_Page.button_signup_with_linkedin"
                      >
                        <Button
                          disableElevation
                          disabled={!this.state.acceptTermAndConditions}
                          variant="outlined"
                          size="large"
                          className={classNames(
                            classes.linkedInButton,
                            classes.smallBottomMargin
                          )}
                          startIcon={
                            <LinkedInIcon style={{ color: "#0A66C2" }} />
                          }
                        >
                          <ReactTranslation
                            contentKey={
                              "Login_Page.button_signup_with_linkedin"
                            }
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        disableElevation
                        disabled={!this.state.acceptTermAndConditions}
                        variant="outlined"
                        size="large"
                        className={classNames(
                          classes.linkedInButton,
                          classes.smallBottomMargin
                        )}
                        startIcon={
                          <LinkedInIcon style={{ color: "#0A66C2" }} />
                        }
                      >
                        <ReactTranslation
                          contentKey={"Login_Page.button_signup_with_linkedin"}
                        />
                      </Button>
                    )}
                  </a>
                )}
                {!this.state.acceptTermAndConditions && (
                  <Tooltip
                    arrow
                    title={
                      baseUrlDev && "Login_Page.button_signup_with_linkedin"
                    }
                  >
                    <Button
                      disableElevation
                      disabled={!this.state.acceptTermAndConditions}
                      variant="outlined"
                      size="large"
                      className={classNames(
                        classes.disabledLinkedInButton,
                        classes.smallBottomMargin
                      )}
                      startIcon={<LinkedInIcon />}
                    >
                      <ReactTranslation
                        contentKey={"Login_Page.button_signup_with_linkedin"}
                      />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.smallBottomMargin}
              >
                {/* need help component */}
                <NeedHelp />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.smallTopMargin}
              >
                <Typography className={classes.responsiveLoginText}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Login_Page.Already_a_member_SucceedSmart"
                    >
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={
                            "Login_Page.Already_a_member_SucceedSmart"
                          }
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography component={"span"}>
                      <ReactTranslation
                        contentKey={"Login_Page.Already_a_member_SucceedSmart"}
                      />
                    </Typography>
                  )}
                  &nbsp;
                  <a
                    className={classNames(classes.customLink)}
                    href={config.baseUrl + "api/auth/mr/linkedin/redirect"}
                    onClick={(event) => {
                      sendTrackingData(
                        this.props.shared.decryptedMixPanelKey,
                        EVENT_TITLES.SIGN_IN_LINK_CLICK,
                        SCREENS.JOB_LOGIN_SCREEN,
                        ACTIONS.SIGN_IN_LINK_CLICKED
                      );
                      event.stopPropagation();
                    }}
                  >
                    {baseUrlDev ? (
                      <Tooltip arrow title="Login_Page.Sign_in_to_my_account">
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Login_Page.Sign_in_to_my_account"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Login_Page.Sign_in_to_my_account"}
                        />
                      </Typography>
                    )}
                  </a>
                  {/* <a className = {classes.customLink} href= {(config.baseUrl === `https://dev1-platform.succeedsmart.com/` || config.baseUrl === `https://dev-platform.succeedsmart.com/`) ? 'https://dev-member.succeedsmart.com' : 'https://member.succeedsmart.com' }>
                          Sign in to my account
                        </a> */}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <BOAModule />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Footer />
        </Grid>
      </Grid>
    );
  }
}

JobLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

const connectedJobSignInPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(JobLandingPage))
);

export { connectedJobSignInPage as JobLandingPage };
