import { React, Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import ReactTranslation from "./iReact18";
import { Tooltip } from "@material-ui/core";
import { showHideToolTip } from "../../helpers";

const styles = (theme) => ({
  footer: {
    flexDirection: "row",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column",
    },
    width: "100%",
    position: "relative",
  },
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    baseUrlDev: false,
    currentYear: null,
  });
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "9327469",
          formId: "c1b9a0b9-4e27-4302-8c72-dc494f92df46",
          target: "#hubspotForm",
        });
      }
    });
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    this.setState({
      currentYear: new Date().getFullYear(),
    });
  }
  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <Typography variant="body2" className={classes.footer}>
        <footer id="Footer" style={{ width: "100%", textAlign: "left" }}>
          <div class="container">
            <div class="LogoFooterLink">
              <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4 col-xxl-4">
                  <div class="FooterLogo">
                    <img
                      src="https://succeedsmart.com/wp-content/themes/succeedsmart/images/SucceedSmart-logo-reverse.svg"
                      alt="SucceedSmart"
                    />
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Footer.connecting_people"
                        placement="left"
                      >
                        <p>
                          <ReactTranslation
                            contentKey={"Footer.connecting_people"}
                          />
                        </p>
                      </Tooltip>
                    ) : (
                      <p>
                        <ReactTranslation
                          contentKey={"Footer.connecting_people"}
                        />
                      </p>
                    )}
                    <div class="SocialShare">
                      <ul>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/succeedsmart/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i class="fab fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/succeedsmart/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i class="fab fa-instagram" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.facebook.com/SucceedSmartUS/"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i class="fab fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/succeedsmart"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i class="fab fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.youtube.com/channel/UCbRyCRJbLU5K2ieUsTop8Yg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i class="fab fa-youtube" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-7 col-lg-3 col-xxl-4">
                  <div class="FooterLink">
                    <h3>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Footer.quick_link"
                          placement="left"
                        >
                          <Typography variant="h3">
                            {" "}
                            <ReactTranslation
                              contentKey={"Footer.quick_link"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="h3">
                          {" "}
                          <ReactTranslation contentKey={"Footer.quick_link"} />
                        </Typography>
                      )}
                    </h3>
                    <ul>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/about"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Footer.About"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation contentKey={"Footer.About"} />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation contentKey={"Footer.About"} />
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/advisory-board"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Footer.Advisory_Board"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={"Footer.Advisory_Board"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation
                                contentKey={"Footer.Advisory_Board"}
                              />
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/why-succeedsmart"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Footer.Why_SucceedSmart"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={"Footer.Why_SucceedSmart"}
                                />
                                ?
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation
                                contentKey={"Footer.Why_SucceedSmart"}
                              />
                              ?
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/blog"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip arrow title="Footer.Blog" placement="left">
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation contentKey={"Footer.Blog"} />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation contentKey={"Footer.Blog"} />
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://support.succeedsmart.com/hc/en-us/sections/4402372511252-FAQ"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip arrow title="Footer.Faq" placement="left">
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation contentKey={"Footer.Faq"} />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation contentKey={"Footer.Faq"} />
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/contact-us"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Footer.Contact"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                {" "}
                                <ReactTranslation
                                  contentKey={"Footer.Contact"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              {" "}
                              <ReactTranslation contentKey={"Footer.Contact"} />
                            </Typography>
                          )}
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.succeedsmart.com/request-a-demo"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {baseUrlDev ? (
                            <Tooltip
                              arrow
                              title="Footer.Request_a_Demo"
                              placement="left"
                            >
                              <Typography component={"span"}>
                                <ReactTranslation
                                  contentKey={"Footer.Request_a_Demo"}
                                />
                              </Typography>
                            </Tooltip>
                          ) : (
                            <Typography component={"span"}>
                              <ReactTranslation
                                contentKey={"Footer.Request_a_Demo"}
                              />
                            </Typography>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-6 col-md-6 col-lg-5 col-xxl-4">
                  <div class="FooterSubscribe">
                    <h3>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Footer.Join_our_email_list"
                          placement="left"
                        >
                          <Typography component={"h3"}>
                            {" "}
                            <ReactTranslation
                              contentKey={"Footer.Join_our_email_list"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"h3"}>
                          {" "}
                          <ReactTranslation
                            contentKey={"Footer.Join_our_email_list"}
                          />
                        </Typography>
                      )}
                    </h3>
                    <p>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Footer.latest_update"
                          placement="left"
                        >
                          <Typography component={"span"}>
                            {" "}
                            <ReactTranslation
                              contentKey={"Footer.latest_update"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          {" "}
                          <ReactTranslation
                            contentKey={"Footer.latest_update"}
                          />
                        </Typography>
                      )}
                    </p>
                    <div id="hubspotForm"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Copyright">
              <p>
                {baseUrlDev ? (
                  <Tooltip arrow title="Footer.@" placement="bottom">
                    <Typography component={"span"}>
                      {" "}
                      <ReactTranslation contentKey={"Footer.@"} />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component={"span"}>
                    {" "}
                    <ReactTranslation contentKey={"Footer.@"} />
                  </Typography>
                )}
                {baseUrlDev ? (
                  <Tooltip arrow title="Footer.Copyright" placement="bottom">
                    <Typography component={"span"}>
                      {" "}
                      <ReactTranslation contentKey={"Footer.Copyright"} />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component={"span"}>
                    {" "}
                    <ReactTranslation contentKey={"Footer.Copyright"} />
                  </Typography>
                )}
                &nbsp;
                {this.state.currentYear},
                {baseUrlDev ? (
                  <Tooltip arrow title="Footer.Inc" placement="bottom">
                    <Typography component={"span"}>
                      {" "}
                      <ReactTranslation contentKey={"Footer.Inc"} />
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography component={"span"}>
                    {" "}
                    <ReactTranslation contentKey={"Footer.Inc"} />
                  </Typography>
                )}{" "}
                &nbsp;
                <a
                  href="https://www.succeedsmart.com/privacypolicy"
                  target="_blank"
                  rel="noreferrer"
                >
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Footer.Privacy_Policy"
                      placement="bottom"
                    >
                      <Typography component={"span"}>
                        {" "}
                        <ReactTranslation
                          contentKey={"Footer.Privacy_Policy"}
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography component={"span"}>
                      {" "}
                      <ReactTranslation contentKey={"Footer.Privacy_Policy"} />
                    </Typography>
                  )}
                </a>
              </p>
            </div>
          </div>
        </footer>
      </Typography>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, null, null, { pure: false })(
  withStyles(styles)(Footer)
);
