import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import translationEN from "../../locales/en/translation.json";
import {
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from "react-international-phone";
import ReactTranslation from "./iReact18";
import { makeStyles } from "@material-ui/core/styles";
import { modifyString } from "../../helpers";

const useStyles = makeStyles(() => ({
  textField: {
    width: "100%",
  },
  inputAdornment: {
    marginRight: "2px",
  },
  flagImage: {
    display: "flex",
  },
  smallMarginRight: {
    marginRight: "8px",
  },
  select: {
    border: "none",
  },
}));

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const PhoneNumber = ({ value, onChangeValue, baseUrlDev, ...restProps }) => {
  const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      defaultCountry: "us",
      value,
      countries: defaultCountries,
      onChange: (data) => {
        onChange(data.phone, data.inputValue);
      },
    });

  const [validate, setValidate] = useState(true);
  const [numberRequired, setNumberRequired] = useState(false);
  const classes = useStyles();

  const onChange = (phone, inputvalue) => {
    let modifiedNumber = modifyString(inputvalue);
    !modifiedNumber.length ? setNumberRequired(true) : setNumberRequired(false);
    let validate = isPhoneValid(phone);
    setValidate(validate);
    onChangeValue(modifiedNumber, validate, numberRequired);
  };

  return (
    <TextField
      className={classes.textField}
      variant="standard"
      required
      label={
        baseUrlDev ? (
          <Tooltip
            arrow
            title="Basic_Information_Step_2.Form.Phone_Number"
            placement="left-end"
          >
            <Typography component={"span"}>
              <ReactTranslation
                contentKey={"Basic_Information_Step_2.Form.Phone_Number"}
              />
            </Typography>
          </Tooltip>
        ) : (
          <Typography component={"span"}>
            <ReactTranslation
              contentKey={"Basic_Information_Step_2.Form.Phone_Number"}
            />
          </Typography>
        )
      }
      color="primary"
      error={!validate}
      placeholder={`${translationEN.Placeholder.Basic_Info_Page.Phone_Number_Placeholder}`}
      value={inputValue}
      onChange={handlePhoneValueChange}
      type="tel"
      inputRef={inputRef}
      helperText={
        numberRequired ? (
          baseUrlDev ? (
            <Tooltip
              arrow
              title="Validation_Messages.Field_Required"
              placement="left-end"
            >
              <Typography component={"span"}>
                <ReactTranslation
                  contentKey={"Validation_Messages.Field_Required"}
                />
              </Typography>
            </Tooltip>
          ) : (
            <Typography component={"span"}>
              <ReactTranslation
                contentKey={"Validation_Messages.Field_Required"}
              />
            </Typography>
          )
        ) : validate ? (
          ""
        ) : baseUrlDev ? (
          <Tooltip
            arrow
            title="Validation_Messages.Field_Invalid"
            placement="left-end"
          >
            <Typography component={"span"}>
              <ReactTranslation
                contentKey={"Validation_Messages.Field_Invalid"}
              />
            </Typography>
          </Tooltip>
        ) : (
          <Typography component={"span"}>
            <ReactTranslation
              contentKey={"Validation_Messages.Field_Invalid"}
            />
          </Typography>
        )
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start" className={classes.inputAdornment}>
            <Select
              className={classes.select}
              MenuProps={{
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
              }}
              sx={{
                width: "max-content",
                // Remove default outline (display only on focus)
                fieldset: {
                  display: "none",
                },
                '&.Mui-focused:has(div[aria-expanded="false"])': {
                  fieldset: {
                    display: "block",
                  },
                },
                // Update default spacing
                ".MuiSelect-select": {
                  padding: "8px",
                  paddingRight: "24px !important",
                },
                ".MuiSelect-selectMenu": {
                  height: "0px",
                },
                svg: {
                  right: 0,
                },
              }}
              value={country.iso2}
              onChange={(e) => setCountry(e.target.value)}
              renderValue={(value) => (
                <FlagImage iso2={value} className={classes.flagImage} />
              )}
            >
              {defaultCountries.map((c) => {
                const country = parseCountry(c);
                return (
                  <MenuItem key={country.iso2} value={country.iso2}>
                    <FlagImage
                      iso2={country.iso2}
                      className={classes.smallMarginRight}
                    />
                    <Typography className={classes.smallMarginRight}>
                      {country.name}
                    </Typography>
                    <Typography color="gray">+{country.dialCode}</Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </InputAdornment>
        ),
      }}
      {...restProps}
    />
  );
};

export default PhoneNumber;
