import { Button, Divider, Grid, Link, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import BadRequestIcon from "../../assets/badRequest.svg";
import { ReactComponent as Logo } from "../../assets/logo_dark.svg";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { badRequest, signOut } from "../../actions";
import { sendTrackingData, seo } from "../../helpers";
import { EVENT_TITLES, SCREENS } from "../../constants/constants";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
  },
  logo: { margin: "10px 10px 10px 20px" },
  loginButton: {
    margin: "10px",
    backgroundColor: "#FBC112",
    color: "#000000",
  },
  imgwithContent: {
    marginTop: "20px",
  },
  contentTypo: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: "32px",
    letterSpacing: "0.001em",
  },
  footerContent: {
    marginTop: "50px",
    position: "fixed",
    bottom: 0,
    left: "29%",
  },
}));

const BadRequest = () => {
  let classes = useStyles();
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(badRequest(true));
    seo({
      title: `Bad request`,
    });
    document.body.style.padding = 0;
  }, []);

  const handlelogin = () => {
    dispatch(signOut());
  };
  useEffect(() => {
    sendTrackingData(EVENT_TITLES.BAD_RQUEST, SCREENS.BAD_REQUEST_SCREEN);
  });

  return (
    <Grid container style={{ paddingTop: 0 }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.header}
      >
        <Link href="/">
          <Logo height={30} width={200} className={classes.logo} />
        </Link>
        <Button
          variant="contained"
          disableElevation
          className={classes.loginButton}
          onClick={() => {
            handlelogin();
          }}
        >
          Login
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
        <Divider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.imgwithContent}
      >
        <img height={300} width={500} src={BadRequestIcon}></img>
        <Typography className={classes.contentTypo}>
          Too many bad requests. Please try after Something...
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        xl={12}
        lg={12}
        className={classes.footerContent}
      >
        <Typography align="center">
          © Copyright 2023, SucceedSmart, Inc. All Right Reserved. |
          <a href="https://succeedsmart.com/privacypolicy">Privacy Policy</a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BadRequest;
