import { apiService } from "../services";
import { history } from "../helpers";
import { toast } from "react-toastify";
import store from "../store/store";
import config from "../config/config";
import { loading } from "./loader.actions";
import { sharedActions } from "./shared.action";
import {
  saveProfessionalInfo,
  saveAccomplishments,
  saveCompetencies,
  saveCoffeQuestionData,
  saveVideoDetail,
  careerHighlightPage,
  basicInfoPage,
  onboardingStatus,
} from "./onboarding.actions";
import { saveBasicInfo } from "./basicInfo.actions";
import {
  saveMemberDiversity,
  saveSalaryData,
  saveWorkEnviromentData,
  saveJobRoleExpectation,
  preferencesPage,
} from "./preferences.actions";
import secureLocalStorage from "react-secure-storage";

export const authActions = {
  login,
  getCompanyNames,
  getConstant,
  getJobTitles,
  saveParamInfo,
  signOut,
  getJobList,
};

// function  linkedInSignIn() {
//   return dispatch => {
//     let apiEndpoint = 'api/auth/linkedin/redirect';
//     apiService.get(apiEndpoint)
//     .then((response)=>{
//         if(response && (response.status === 200 || response.status === 201)) {
//             dispatch(login ());
//         }
//         else if(response.status !== 401) {
//              toast.dark('Something went haywire');
//         }
//     }).catch((err)=>{
//      console.log(err);
//      })
//  };
// }

function login(payload, key, props) {
  let url = new URL(window.location.href);
  const environment = url.searchParams.get("environment")
    ? url.searchParams.get("environment")
    : "";
  return (dispatch) => {
    let apiEndpoint = "api/v2/candidate/login/";
    apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          secureLocalStorage.setItem(
            "token",
            JSON.stringify(response?.data?.data?.id_token)
          );
          store.dispatch(getConstant());
          dispatch(saveLoginInfo(response.data.data, environment, key));
          dispatch(onboardingStatus(response.data.data.onboarding_status));
          switch (response.data.data.onboarding_status) {
            case "Invite":
            case "Profile Information":
            case "Professional Information":
            case "Diversity":
            case "Basic Information":
              dispatch(basicInfoPage(1));
              dispatch(sharedActions.setProgressbarValue(0));
              history.push("/onboarding/basic-info-step-1");
              break;
            case "Pending Approval":
            case "Complete Onboarding":
            case "Complete":
            case "Parse Information":
            case "Rejected":
              dispatch(signOut(props));
              if (
                config.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
                config.baseUrl === `https://dev-platform.succeedsmart.com/` ||
                config.baseUrl === `https://demo2-platform.succeedsmart.com/`
              ) {
                window.location.href = `https://dev-member.succeedsmart.com/?environment= ${environment}&key=${key}`;
              } else {
                window.location.href = `https://member.succeedsmart.com/?environment=${environment}&key=${key}`;
              }
              break;
            default:
              dispatch(signOut(props));
              if (
                config?.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
                config?.baseUrl === `https://dev-platform.succeedsmart.com/` ||
                config?.baseUrl === `https://demo2-platform.succeedsmart.com/`
              ) {
                window.location.href = `https://dev-member.succeedsmart.com/?environment= ${environment}&key=${key}`;
              } else {
                window.location.href = `https://member.succeedsmart.com/?environment=${environment}&key=${key}`;
              }
              break;
          }
        } else if (response.status === 401) {
          toast.dark("Unauthorised access. Please sign in again.");
          dispatch(signOut(props));
        } else if (response.status !== 401 && response.status !== 429) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveLoginInfo(loginInfo, environment, key) {
  return {
    type: "LOGIN_SUCCESS",
    loginInfo: loginInfo,
    environment: environment,
    key: key,
  };
}

function getCompanyNames(input = "") {
  return (dispatch) => {
    let apiEndpoint =
      "https://autocomplete.clearbit.com/v1/companies/suggest?query=" + input;
    apiService
      .thirdPartyApiGet(apiEndpoint)
      .then((response) => {
        if (response) {
          dispatch(getCompanyNamesData(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getCompanyNamesData(data) {
  return {
    type: "COMPANY_NAME_DATA",
    companyNames: data,
  };
}

function getConstant() {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/master_data/";
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && response.data) {
          dispatch(changeConstants(response?.data?.data));
        } else {
          toast.dark("Something went haywire");
          history.push("/");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function getJobTitles(input) {
  return (dispatch) => {
    let apiEndpoint = "api/v1/search_index/?title=" + input;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getJobTitlesData(response.data.data));
        } else if (response.status !== 401) {
          toast.dark("Something went haywire");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function changeConstants(data) {
  return {
    type: "CONSTANTS",
    constant: data,
  };
}

function getJobTitlesData(data) {
  return {
    type: "JOB_TITLE_DATA",
    jobTitles: data,
  };
}

function saveParamInfo(utmData, inviteCode, originalUrlPath) {
  return {
    type: "SAVE_PARAM_INFO",
    utmData: utmData,
    inviteCode: inviteCode,
    originalUrlPath: originalUrlPath,
  };
}

export function getJobList() {
  return (dispatch) => {
    let apiEndpoint = "https://succeedsmart.com/wp-json/wp/v2/job?per_page=100";
    apiService
      .thirdPartyApiGet(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          response?.data?.filter((job) => {
            if (job.link) {
              let jobInfo = job?.link?.split("/");
              var lastsegment = jobInfo[jobInfo.length - 1];
              if (
                lastsegment ===
                JSON.parse(secureLocalStorage.getItem("jobName"))
              ) {
                dispatch(getJobDetail(job.id));
                return job;
              }
            }
            return job;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function getJobDetail(jobId) {
  return (dispatch) => {
    let apiEndpoint = `https://succeedsmart.com/wp-json/wp/v2/job/${jobId}`;
    apiService
      .thirdPartyApiGet(apiEndpoint)
      .then((response) => {
        if (response) {
          dispatch(saveJobDetail(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// export function getJobDetail(jobId){
//   return dispatch => {
//     let apiEndpoint = `https://succeedsmart.com/wp-json/wp/v2/job/${jobId}`;
//     apiService.thirdPartyApiGet(apiEndpoint)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         dispatch(saveJobDetail(response.data));
//         let url = new URL(window.location.href);
//         let jobInfo = url.href.split('/');
//         let splittedJobName = jobInfo[jobInfo.length-2];
//         let jobNameFromApi = response.data?.link?.split('/');
//         let splittedJobNameFromApi = jobNameFromApi[jobNameFromApi.length-1];
//         if(splittedJobNameFromApi !== splittedJobName){
//           history.push('/login');
//         }
//       }else{
//         if(response.data?.status === 404 || response.data?.status === 400){

//           history.push('/login');
//           return;
//        }
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       history.push('/login');
//     });
//   };
// }

export function saveJobDetail(jobDetail) {
  return {
    type: "JOB_DATA",
    jobDetail: jobDetail,
  };
}

export function getVipJobList() {
  return (dispatch) => {
    let apiEndpoint = "https://succeedsmart.com/wp-json/wp/v2/vip?per_page=100";
    apiService
      .thirdPartyApiGet(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          response?.data?.filter((vipJob) => {
            if (vipJob.link) {
              let vipJobInfo = vipJob?.link?.split("/");
              var lastsegment = vipJobInfo[vipJobInfo.length - 1];
              if (
                lastsegment ===
                JSON.parse(secureLocalStorage.getItem("vipJobName"))
              ) {
                dispatch(getVipJobDetail(vipJob.id));
                return vipJob;
              }
            }
            return vipJob;
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// export function getVipJobDetail(vipJobId){
//   return dispatch => {
//     let apiEndpoint = `https://succeedsmart.com/wp-json/wp/v2/vip/${vipJobId}`;
//     apiService.thirdPartyApiGet(apiEndpoint)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         dispatch(saveVipJobDetail(response.data));
//         let url = new URL(window.location.href);
//         let vipJobInfo = url.href.split('/');
//         let vipName = vipJobInfo[vipJobInfo.length-2];
//         let splittedVipNameFromApi = response.data?.link?.split('/');
//         let vipNameFromApi = splittedVipNameFromApi[splittedVipNameFromApi.length -1];
//         if(vipNameFromApi !== vipName){
//           history.push('/login');
//         }
//       }else{
//         if(response.status === 404 || response.status === 400){
//           history.push('/login');
//           return;
//        }
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       history.push('/login');
//     });
//   };
// }

export function getVipJobDetail(vipJobId) {
  return (dispatch) => {
    let apiEndpoint = `https://succeedsmart.com/wp-json/wp/v2/vip/${vipJobId}`;
    apiService
      .thirdPartyApiGet(apiEndpoint)
      .then((response) => {
        if (response) {
          dispatch(saveVipJobDetail(response.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// export function getVipJobDetail(vipJobId){
//   return dispatch => {
//     let apiEndpoint = `https://succeedsmart.com/wp-json/wp/v2/vip/${vipJobId}`;
//     apiService.thirdPartyApiGet(apiEndpoint)
//     .then((response) => {
//       if (response.status === 200 || response.status === 201) {
//         dispatch(saveVipJobDetail(response.data));
//         let url = new URL(window.location.href);
//         let vipJobInfo = url.href.split('/');
//         let vipName = vipJobInfo[vipJobInfo.length-2];
//         let splittedVipNameFromApi = response.data?.link?.split('/');
//         let vipNameFromApi = splittedVipNameFromApi[splittedVipNameFromApi.length -1];
//         if(vipNameFromApi !== vipName){
//           history.push('/login');
//         }
//       }else{
//         if(response.status === 404 || response.status === 400){
//           history.push('/login');
//           return;
//        }
//       }
//     })
//     .catch((err) => {
//       console.log(err);
//       history.push('/login');
//     });
//   };
// }

export function saveVipJobDetail(vipJobDetail) {
  return {
    type: "VIP_JOB_DATA",
    vipJobDetail: vipJobDetail,
  };
}

export function setLocationDetail(locationData) {
  return {
    type: "LOCATION_DATA",
    locationData: locationData,
  };
}

export function signOut(props) {
  return (dispatch) => {
    dispatch(sharedActions.handleNext(null));
    dispatch(sharedActions.setProgressbarValue(null));
    dispatch(postSignOutAction());
    dispatch(basicInfoPage(null));
    dispatch(saveBasicInfo({}, {}, null));
    dispatch(saveProfessionalInfo({}));
    dispatch(careerHighlightPage(null));
    dispatch(saveCompetencies({}));
    dispatch(saveMemberDiversity({}));
    dispatch(saveAccomplishments({}));
    dispatch(saveJobDetail({}));
    dispatch(saveVipJobDetail({}));
    dispatch(saveCoffeQuestionData({}));
    dispatch(saveVideoDetail({}));
    dispatch(preferencesPage(null));
    dispatch(saveSalaryData({}));
    dispatch(saveWorkEnviromentData({}));
    dispatch(saveJobRoleExpectation({}));
    dispatch(loading(false));
    window.location = props?.authentication?.originalUrlPath
      ? props?.authentication?.originalUrlPath
      : "/login";

    secureLocalStorage.clear();
    // toast.dark('Sign out successful');
  };
}

function postSignOutAction() {
  return {
    type: "SIGN_OUT_DELETE",
  };
}
