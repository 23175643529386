import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import {
  history,
  sendTrackingData,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  setTitle,
  sharedActions,
  preferencesPage,
  preferencesActions,
} from "../../../../actions";
import {
  Grid,
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Typography,
  Tooltip,
} from "@material-ui/core";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import { toast } from "react-toastify";
import translationEN from "../../../../locales/en/translation.json";
import ReactTranslation from "../../../core/iReact18";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  TopBottomMargin: {
    marginBottom: "32px",
  },
  smallTopMargin: {
    marginTop: "24px",
  },
  TopMargin: {
    marginTop: "32px",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  jobRoleButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  jobRoleText: {
    color: "#14131F",
    fontWeight: "700",
  },
  formHelperText: {
    color: "#ff0000",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  colorBlack: {
    color: "#000000 !important",
  },
});

class JobRolePrefrences extends Component {
  constructor(props) {
    super(props);
    let jobRoleArray =
      this.props?.authentication?.constants?.other_opportunities?.map(
        (ele) => ({ value: ele[1], selected: false })
      );
    this.state = this.getInitialState(jobRoleArray);
  }

  getInitialState = (jobRoleArray) => ({
    isJobRoleRequired: false,
    baseUrlDev: false,
    jobRoleOptions: jobRoleArray,
    jobRoleArray: [],
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(
      setTitle(`${translationEN.PageTitle.Job_Role_Preferences_Page_Title}`)
    );
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    dispatch(preferencesActions.getJobRoleExpectation());
    // selected the job role or api data
    this.selectedJobRole();
    let userInfo = {
      userId: this.props?.basicInfo?.basicInfoData?.candidate_id
        ? this.props?.basicInfo?.basicInfoData?.candidate_id
        : this.props?.authentication?.loginInfo?.candidate_id,
      firstName: this.props?.basicInfo?.basicInfoData?.first_name
        ? this.props?.basicInfo?.basicInfoData?.first_name
        : this.props?.authentication?.loginInfo?.first_name,
      lastName: this.props?.basicInfo?.basicInfoData?.last_name
        ? this.props?.basicInfo?.basicInfoData?.last_name
        : this.props?.authentication?.loginInfo?.last_name,
      email: this.props?.basicInfo?.basicInfoData?.email
        ? this.props?.basicInfo?.basicInfoData?.email
        : this.props?.authentication?.loginInfo?.email,
      linkedinUrl: this.props?.basicInfo?.basicInfoData?.linkedin_url
        ? this.props?.basicInfo?.basicInfoData?.linkedin_url
        : this.props?.authentication?.loginInfo?.linkedin_url,
      phone: this.props?.basicInfo?.basicInfoData?.contact
        ? this.props?.basicInfo?.basicInfoData?.contact
        : this.props?.authentication?.loginInfo?.phone,
      onboardingStatus: this.props?.onboarding?.onboardingStatus,
    };
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.JOB_ROLE_PREFERENCES_PAGE_LOAD,
      SCREENS.JOB_ROLE_PREFERENCES_SCREEN,
      ACTIONS.JOB_ROLE_PREFERENCES_PAGE_LOADED,
      userInfo
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.preferences?.jobRoleExpectationData?.advisor_director_role !==
      this.props?.preferences?.jobRoleExpectationData?.advisor_director_role
    ) {
      this.selectedJobRole();
    }
    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  selectedJobRole = () => {
    let jobRoleArraySelected =
      this.props?.authentication?.constants?.other_opportunities?.map(
        (ele) => ({ value: ele[1], selected: false })
      );
    this.setState({
      jobRoleOptions: jobRoleArraySelected,
    });
    if (
      this.props?.preferences?.jobRoleExpectationData?.advisor_director_role ===
        undefined ||
      this.props?.preferences?.jobRoleExpectationData?.advisor_director_role ===
        null
    ) {
      this.setState({ jobRoleArray: [] });
    }
    if (
      this.props?.preferences?.jobRoleExpectationData?.advisor_director_role
        ?.length > 0
    ) {
      let valuesAtIndices =
        this.props?.preferences?.jobRoleExpectationData?.advisor_director_role.map(
          (index) =>
            this.props?.authentication?.constants?.other_opportunities[index][1]
        );
      valuesAtIndices?.map((selectedRole) => {
        this.state.jobRoleOptions.map((role) => {
          if (selectedRole === role.value) {
            let jobRoleIndex = jobRoleArraySelected.findIndex(
              (option) => option.value === role.value
            );
            jobRoleArraySelected[jobRoleIndex].selected = true;
            return jobRoleArraySelected;
          }
          return jobRoleArraySelected;
        });
        return jobRoleArraySelected;
      });
      this.setState({ jobRoleOptions: jobRoleArraySelected }, () => {
        this.setState({ jobRoleArray: this.state.jobRoleOptions });
      });
    }
  };

  handleRoleChange = (event, option) => {
    let jobRoleArray = this.state.jobRoleOptions;
    let jobRoleIndex = jobRoleArray.findIndex(
      (value) => value.value === option.value
    );
    if (event.target.checked) {
      switch (option.value) {
        case this.state.jobRoleOptions[3].value:
          this.state.jobRoleOptions.filter((value, index) => {
            if (jobRoleIndex !== index) {
              return (jobRoleArray[index].selected = false);
            } else {
              jobRoleArray[jobRoleIndex].selected = true;
            }
            return jobRoleArray;
          });
          this.setState({ jobRoleArray: jobRoleArray });
          break;
        case this.state.jobRoleOptions[0].value:
        case this.state.jobRoleOptions[1].value:
        case this.state.jobRoleOptions[2].value:
          jobRoleArray.filter((value, index) => {
            if (value.value === this.state.jobRoleOptions[3].value) {
              return (jobRoleArray[index].selected = false);
            } else {
              jobRoleArray[jobRoleIndex].selected = true;
            }
            return jobRoleArray;
          });
          this.setState({ jobRoleArray: jobRoleArray });
          break;
        default:
          break;
      }
    } else {
      jobRoleArray[jobRoleIndex].selected = false;
      this.setState({ jobRoleArray: jobRoleArray });
    }
  };

  handlePrevious = () => {
    const { dispatch } = this.props;
    dispatch(preferencesPage(3));
    dispatch(sharedActions.setProgressbarValue(87.5));
    history.push("/onboarding/preferences-diversity");
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.JOB_ROLE_PREFERENCES_PREVIOUS_CLICK,
      SCREENS.JOB_ROLE_PREFERENCES_SCREEN,
      ACTIONS.JOB_ROLE_PREFERENCES_PREVIOUS_CLICKED
    );
  };

  handleSaveJobRole = () => {
    const { dispatch } = this.props;
    let jobRoleArray = [];
    let jobRoles = this.state.jobRoleArray.filter((option) => {
      return option.selected === true;
    });
    jobRoleArray = jobRoles.map((option) => option.value);

    let payloadArray = jobRoleArray.map((option) => {
      let jobRoleIndex = this.state.jobRoleArray.findIndex(
        (value) => value.value === option
      );
      return jobRoleIndex;
    });

    if (jobRoleArray?.length === 0) {
      this.setState({ isJobRoleRequired: true });
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Job_Role_Page.Please_Select_Option_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation
                contentKey={
                  "Toast_Message.Job_Role_Page.Please_Select_Option_Message"
                }
              />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation
            contentKey={
              "Toast_Message.Job_Role_Page.Please_Select_Option_Message"
            }
          />
        )
      );
      return;
    }
    let payload = {
      advisor_director_role: payloadArray,
    };

    let progressBarStepValue = 100;
    dispatch(
      preferencesActions.saveJobRole(
        payload,
        this.props?.shared?.activeStep,
        progressBarStepValue
      )
    );
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.JOB_ROLE_PREFERENCES_NEXT_CLICK,
      SCREENS.JOB_ROLE_PREFERENCES_SCREEN,
      ACTIONS.JOB_ROLE_PREFERENCES_NEXT_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;

    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="left">
                <FormControl component="fieldset" fullWidth>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Job_Role_Page.Job_Role_Heading"
                      placement="left-end"
                    >
                      <Typography
                        variant="h5"
                        className={classNames(
                          classes.smallTopMargin,
                          classes.TopBottomMargin,
                          classes.jobRoleText
                        )}
                        component="legend"
                      >
                        {
                          <ReactTranslation
                            contentKey={"Job_Role_Page.Job_Role_Heading"}
                          />
                        }
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      variant="h5"
                      className={classNames(
                        classes.smallTopMargin,
                        classes.TopBottomMargin,
                        classes.jobRoleText
                      )}
                      component="legend"
                    >
                      {
                        <ReactTranslation
                          contentKey={"Job_Role_Page.Job_Role_Heading"}
                        />
                      }
                    </Typography>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {this.state.jobRoleOptions?.map((option, index) => (
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            className={classes.colorBlack}
                            onChange={(event) => {
                              this.handleRoleChange(event, option, index);
                            }}
                            name={option.value}
                            value={option.value}
                            checked={option.selected}
                          />
                        }
                        label={<ReactTranslation contentKey={option.value} />}
                      />
                    ))}
                  </Grid>
                  {this.state.isJobRoleRequired ? (
                    <FormHelperText className={classes.formHelperText}>
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )}
                    </FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.TopMargin}
              >
                <div
                  className={classNames(
                    classes.TopMargin,
                    classes.jobRoleButton
                  )}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Previous" placement="bottom">
                      <Button
                        id="jobRolePreviousButton"
                        variant="outlined"
                        color="primary"
                        className={classes.prevButton}
                        disableElevation
                        onClick={(event) => {
                          event.preventDefault();
                          this.handlePrevious();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Previous"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="jobRolePreviousButton"
                      variant="outlined"
                      color="primary"
                      className={classes.prevButton}
                      disableElevation
                      onClick={(event) => {
                        event.preventDefault();
                        this.handlePrevious();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Previous"} />
                    </Button>
                  )}
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next" placement="bottom">
                      <Button
                        id="jobRoleNextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        className={classes.prevButton}
                        onClick={(event) => {
                          event.preventDefault();
                          this.handleSaveJobRole();
                        }}
                      >
                        <ReactTranslation contentKey={"Buttons.Next"} />
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="jobRoleNextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      className={classes.prevButton}
                      onClick={(event) => {
                        event.preventDefault();
                        this.handleSaveJobRole();
                      }}
                    >
                      <ReactTranslation contentKey={"Buttons.Next"} />
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

JobRolePrefrences.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedJobRolePrefrencesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(JobRolePrefrences))
);
export { connectedJobRolePrefrencesPage as JobRolePrefrences };
