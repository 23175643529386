import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { seo, showHideToolTip } from "../../../helpers";
import { sharedActions } from "../../../actions";
import {
  Stepper,
  Step,
  StepLabel,
  Grid,
  StepConnector,
  Tooltip,
} from "@material-ui/core";
import { ProfessionalInfo } from ".";
import { BasicInfo } from "./basicInfo/basicInfo.component";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Check from "@material-ui/icons/Check";
import { OnboardingCompleted } from "./onboardingCompleted.component";
import PersonIcon from "@material-ui/icons/Person";
import WorkIcon from "@material-ui/icons/Work";
import TuneIcon from "@material-ui/icons/Tune";
import StarRateIcon from "@material-ui/icons/StarRate";
import LinearProgress from "@material-ui/core/LinearProgress";
import { CareerHighlights } from "./careerHighlights/carrerHighlights.component";
import { Preferences } from "./preferences/preferences.component";
import translationEN from "../../../locales/en/translation.json";
import ReactTranslation from "../../core/iReact18";

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "calc(100vh - 90px)",
    // marginTop: '90px'
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: theme.spacing(9),
  },
  content: {
    height: "calc(100vh - 82px)",
    flexGrow: 1,
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "auto",
    },
  },
  customPadding: {
    paddingLeft: "48px",
    paddingRight: "48px",
    [theme.breakpoints.between("xs", "sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  progressContent: {
    flexGrow: 1,
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      padding: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "4px",
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "auto",
    },
  },
  stepperContent: {
    height: "calc(100vh - 90px)",
    flexGrow: 1,
    padding: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.between("xs", "sm")]: {
      height: "auto",
    },
    backgroundColor: "#F4F4F5",
  },
  leftSideContent: {
    backgroundColor: "#F7F7F7 !important",
  },
  horizontalStepper: {
    [theme.breakpoints.between("md", "xl")]: {
      display: "none",
    },
  },
  verticalStepper: {
    [theme.breakpoints.between("xs", "sm")]: {
      display: "none",
    },
  },
  largeFont: {
    fontSize: "larger !important",
  },
});
// const useQontoStepIconStyles = makeStyles({
//   root: {
//     color: "#EAEAF0",
//     display: "flex",
//     height: 22,
//     alignItems: "center"
//   },
//   active: {
//     color: "#784AF4"
//   },
//   circle: {
//     width: 8,
//     height: 8,
//     borderRadius: "50%",
//     backgroundColor: "currentColor"
//   },
//   completed: {
//     color: "#784AF4",
//     zIndex: 1,
//     fontSize: 18
//   }
// });
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#303030 0%,#303030 50%,#303030 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,#303030 0%,#303030 50%,#303030 100%)",
    },
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#303030",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, #FBC112 0%, #FBC112 50%, #FBC112 100%)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, #008000 0%, #008000 50%, #008000 100%)",
    color: "#FFFFFF",
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  const icons = {
    1: <PersonIcon />,
    2: <WorkIcon />,
    3: <StarRateIcon />,
    4: <TuneIcon />,
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {active ? (
        icons[String(props.icon)]
      ) : completed ? (
        <Check />
      ) : (
        icons[String(props.icon)]
      )}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    baseUrlDev: false,
  });

  componentDidMount() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  componentDidUpdate() {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });
  }

  getSteps = () => {
    // return [ "Basic Information", "Professional Information", "Career Highlights", "Preferences"]
    //  return (
    //    <ReactTranslation  contentKey={"SideNavMenu.StteperArray"}/>
    //  )
    return translationEN.SideNavMenu.StteperArray;
  };

  getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <BasicInfo
            handleNext={sharedActions.handleNext(
              this.props?.shared?.activeStep
            )}
            activeStep={this.props?.shared?.activeStep}
          />
        );
      case 1:
        return (
          <ProfessionalInfo
            handleNext={sharedActions.handleNext(
              this.props?.shared?.activeStep
            )}
            activeStep={this.props?.shared?.activeStep}
          />
        );
      case 2:
        return (
          <CareerHighlights
            handleNext={sharedActions.handleNext(
              this.props?.shared?.activeStep
            )}
            activeStep={this.props?.shared?.activeStep}
          />
        );
      case 3:
        return (
          <Preferences
            handleNext={sharedActions.handleNext(
              this.props?.shared?.activeStep
            )}
            activeStep={this.props?.shared?.activeStep}
          />
        );
      case 4:
        return <OnboardingCompleted handle />;
      default:
        return "Onboarding completed";
    }
  };

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    const steps = this.getSteps();
    return (
      <div className={classes.root}>
        <main>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={3}
              lg={3}
              xl={3}
              className={classes.stepperContent}
            >
              <Stepper
                activeStep={this.props?.shared?.activeStep}
                orientation="vertical"
                connector={<ColorlibConnector />}
                className={classes.verticalStepper}
              >
                {steps.map((label) =>
                  baseUrlDev ? (
                    <Tooltip
                      arrow
                      title={
                        label === "Basic Information"
                          ? "step_bar.Basic_Information"
                          : label === "Professional Information"
                          ? "step_bar.Professional_Information"
                          : label === "Career Highlights"
                          ? "step_bar.Career_Highlights"
                          : label === "Preferences"
                          ? "step_bar.Preferences"
                          : null
                      }
                      placement="top-end"
                    >
                      <Step key={label}>
                        <StepLabel
                          style={{ textAlign: "left" }}
                          StepIconComponent={ColorlibStepIcon}
                        >
                          <ReactTranslation
                            contentKey={
                              label === "Basic Information"
                                ? "step_bar.Basic_Information"
                                : label === "Professional Information"
                                ? "step_bar.Professional_Information"
                                : label === "Career Highlights"
                                ? "step_bar.Career_Highlights"
                                : label === "Preferences"
                                ? "step_bar.Preferences"
                                : null
                            }
                          />
                        </StepLabel>
                      </Step>
                    </Tooltip>
                  ) : (
                    <Step key={label}>
                      <StepLabel
                        style={{ textAlign: "left" }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        <ReactTranslation
                          contentKey={
                            label === "Basic Information"
                              ? "step_bar.Basic_Information"
                              : label === "Professional Information"
                              ? "step_bar.Professional_Information"
                              : label === "Career Highlights"
                              ? "step_bar.Career_Highlights"
                              : label === "Preferences"
                              ? "step_bar.Preferences"
                              : null
                          }
                        />
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
              <Stepper
                className={classes.horizontalStepper}
                activeStep={this.props?.shared?.activeStep}
                orientation="horizontal"
                connector={<ColorlibConnector />}
                alternativeLabel
              >
                {steps.map((label) =>
                  baseUrlDev ? (
                    <Tooltip
                      arrow
                      title={
                        label === "Basic Information"
                          ? "step_bar.Basic_Information"
                          : label === "Professional Information"
                          ? "step_bar.Professional_Information"
                          : label === "Career Highlights"
                          ? "step_bar.Career_Highlights"
                          : label === "Preferences"
                          ? "step_bar.Preferences"
                          : null
                      }
                      placement="top-end"
                    >
                      <Step key={label}>
                        <StepLabel
                          style={{ textAlign: "left" }}
                          StepIconComponent={ColorlibStepIcon}
                        >
                          <ReactTranslation
                            contentKey={
                              label === "Basic Information"
                                ? "step_bar.Basic_Information"
                                : label === "Professional Information"
                                ? "step_bar.Professional_Information"
                                : label === "Career Highlights"
                                ? "step_bar.Career_Highlights"
                                : label === "Preferences"
                                ? "step_bar.Preferences"
                                : null
                            }
                          />
                        </StepLabel>
                      </Step>
                    </Tooltip>
                  ) : (
                    <Step key={label}>
                      <StepLabel
                        style={{ textAlign: "left" }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        <ReactTranslation
                          contentKey={
                            label === "Basic Information"
                              ? "step_bar.Basic_Information"
                              : label === "Professional Information"
                              ? "step_bar.Professional_Information"
                              : label === "Career Highlights"
                              ? "step_bar.Career_Highlights"
                              : label === "Preferences"
                              ? "step_bar.Preferences"
                              : null
                          }
                        />
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={9}
              xl={9}
              className={classes.content}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={9}
                lg={9}
                xl={9}
                style={{ position: "absolute", top: "82px", width: "100%" }}
              >
                <LinearProgress
                  variant="determinate"
                  value={this.props?.shared?.progressStepValue}
                  className={classes.progressContent}
                />
              </Grid>
              <div className={classes.customPadding}>
                {this.getStepContent(this.props?.shared?.activeStep)}
              </div>
            </Grid>
          </Grid>
        </main>
      </div>
    );
  }
}

Onboarding.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedDashboardPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Onboarding))
);
export { connectedDashboardPage as Onboarding };
