import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { DiversityPreferences } from "./diversityPreferences.component";
import { SalaryPrefrences } from "./salaryPrefrences.component";
import { JobRolePrefrences } from "./jobRolePreferences.component";
import { WorkPrefrences } from "./workEnvPrefrences.component";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import ReactTranslation from "../../../core/iReact18";
import { HelpTextCard } from "../helpCardContent/helpCardContent";
import { NeedHelp } from "../../../core/needHelp.component";
import { showHideToolTip } from "../../../../helpers";

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  pageNumberText: {
    color: "#666666",
    textAlign: "end",
    fontSize: "12px",
    fontWeight: "500",
    lineHeight: "unset",
  },
  preferencesHeading: {
    color: "#8f8f8f",
    fontWeight: "700",
    fontSize: "22px",
  },
  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
});

class Preferences extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    baseUrlDev: false,
  });

  componentDidMount() {
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });
  }

  render() {
    const { classes } = this.props;
    const { baseUrlDev } = this.state;
    return (
      <div className={classes.root}>
        <Fragment>
          <Grid container spacing={2} className={classes.mainGrid}>
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
              <Grid container alignItems="center">
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                  {baseUrlDev ? (
                    <Tooltip
                      arrow
                      title="Preferences.Preferences_Heading"
                      placement="left-end"
                    >
                      <Typography
                        align="left"
                        className={classes.preferencesHeading}
                      >
                        <ReactTranslation
                          contentKey={"Preferences.Preferences_Heading"}
                        />
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      align="left"
                      className={classes.preferencesHeading}
                    >
                      <ReactTranslation
                        contentKey={"Preferences.Preferences_Heading"}
                      />
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <Typography className={classes.pageNumberText}>
                    {this.props?.preferences?.preferencesPage} &nbsp;
                    {baseUrlDev ? (
                      <Tooltip arrow title="Preferences.of" placement="bottom">
                        <Typography
                          className={classes.pageNumberText}
                          component={"span"}
                        >
                          <ReactTranslation contentKey={"Preferences.of"} />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        className={classes.pageNumberText}
                        component={"span"}
                      >
                        <ReactTranslation contentKey={"Preferences.of"} />
                      </Typography>
                    )}
                    &nbsp; 4
                  </Typography>
                </Grid>
              </Grid>
              {this.props?.preferences?.preferencesPage === 1 ||
              this.props?.preferences?.preferencesPage === "1" ||
              this.props?.preferences?.preferencesPage === null ? (
                <SalaryPrefrences />
              ) : this.props?.preferences?.preferencesPage === 2 ||
                this.props?.preferences?.preferencesPage === "2" ? (
                <WorkPrefrences />
              ) : this.props?.preferences?.preferencesPage === 3 ||
                this.props?.preferences?.preferencesPage === "3" ? (
                <DiversityPreferences />
              ) : this.props?.preferences?.preferencesPage === 4 ||
                this.props?.preferences?.preferencesPage === "4" ? (
                <JobRolePrefrences />
              ) : (
                <SalaryPrefrences />
              )}
            </Grid>
            {/* <Grid item xs = {12} sm = {12} md = {4} lg = {4} xl = {4}>
                        {this.props?.preferences?.preferencesPage === 1  && <HelpTextCard helpTextCardType = {"salaryHelpText"} />}
                        {this.props?.preferences?.preferencesPage === 2  && <HelpTextCard helpTextCardType = {"workEnvHelpText"} />}
                        {this.props?.preferences?.preferencesPage === 3  && <HelpTextCard helpTextCardType = {"diversityHelpText"} />}
                        {this.props?.preferences?.preferencesPage === 4  && <HelpTextCard helpTextCardType = {"jobRoleHelpText"} />}
                    </Grid> */}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              {this.props?.preferences?.preferencesPage === 3 && (
                <HelpTextCard helpTextCardType={"diversityHelpText"} />
              )}
              {/* Need help component */}
              <NeedHelp />
            </Grid>
          </Grid>
        </Fragment>
      </div>
    );
  }
}

Preferences.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedPreferencesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(Preferences))
);
export { connectedPreferencesPage as Preferences };
