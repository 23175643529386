import { apiService } from "../services";
import { history } from "../helpers";
import { sharedActions } from "./shared.action";
// import store from '../store/store';
import { toast } from "react-toastify";
import { preferencesPage } from "./preferences.actions";
import { isMemberApproved } from "./basicInfo.actions";

export const onBoardingActions = {
  professionalInfo,
  uploadFileToCloudinary,
  getAccomplishments,
  addAccomplishments,
  getCompetencies,
  addCompetencies,
  getProfessionalInfo,
  saveVideo,
  getVideo,
  getCoffeeQuestion,
  saveCoffeeQuestion,
};

function getProfessionalInfo() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/candidate_detail/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveProfessionalInfo(response?.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveProfessionalInfo(professionalInfo) {
  return {
    type: "PROFESSIONAL_INFO_DATA",
    professionalInfo: professionalInfo.data,
    professionalParsedData: professionalInfo.parsed_data,
  };
}

function professionalInfo(payload, activeStep, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/candidate_detail/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(sharedActions.handleNext(activeStep));
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(getProfessionalInfo());
          dispatch(careerHighlightPage(1));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Profile updated successfully"
          );
          history.push("/onboarding/career-highlights-accomplishments");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function uploadFileToCloudinary(formGroup, type, key) {
  let apiEndpoint = `https://api.cloudinary.com/v1_1/${key}/${type}/upload`;
  return apiService.thirdPartyApiPost(apiEndpoint, formGroup).catch((error) => {
    console.log(error);
    if (error.response) {
      toast.dark(
        error.response.data.message
          ? error.response.data.message
          : "Something went haywire"
      );
    }
  });
}

function getAccomplishments() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/accomplishments/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveAccomplishments(response.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveAccomplishments(accomplishments) {
  return {
    type: "ACCOMPLISHMENTS_DATA",
    suggested_accomplishments: accomplishments?.data?.suggested_accomplishments,
    existing_accomplishments: accomplishments?.data?.existing_accomplishments,
  };
}

function addAccomplishments(payload) {
  let apiEndpoint = `api/v3/candidate/accomplishments/`;
  return apiService.post(apiEndpoint, payload).catch((error) => {
    console.log(error);
    if (error.response) {
      toast.dark(
        error.response.data.message
          ? error.response.data.message
          : "Something went haywire"
      );
    }
  });
}

function getCompetencies() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/competencies/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveCompetencies(response.data.data));
          dispatch(onboardingStatus(response.data.data?.onboarding_status));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function addCompetencies(payload, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/competencies/";
    return apiService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(onBoardingActions.getCompetencies());
          dispatch(careerHighlightPage(3));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Competencies Added Successfully"
          );
          history.push("/onboarding/career-highlights-coffeequestion");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

export function saveCompetencies(competencies) {
  return {
    type: "COMPETENCIES_DATA",
    existing_competencies: competencies.existing_competencies,
    suggested_competencies: competencies.suggested_competencies,
  };
}

export function careerHighlightPage(pageNo) {
  return {
    type: "CAREER_PAGE",
    pageNo: pageNo,
  };
}

function saveVideo(payload, activeStep, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/add_insight_video/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(getVideo());
          dispatch(sharedActions.handleNext(activeStep));
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          dispatch(preferencesPage(1));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Profile updated successfully"
          );
          history.push("/onboarding/preferences-salary");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

function getVideo() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/get_insight_video/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveVideoDetail(response?.data?.data));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function saveVideoDetail(videoData) {
  return {
    type: "SAVE_VIDEO",
    videoData: videoData,
  };
}

function getCoffeeQuestion() {
  return (dispatch) => {
    let apiEndpoint = `api/v3/candidate/get_coffee_question/`;
    apiService
      .get(apiEndpoint)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(saveCoffeQuestionData(response?.data?.data));
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

function saveCoffeeQuestion(payload, progressBarValue) {
  return (dispatch) => {
    let apiEndpoint = "api/v3/candidate/add_coffee_question/";
    apiService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 201)) {
          dispatch(careerHighlightPage(4));
          dispatch(getCoffeeQuestion());
          dispatch(sharedActions.setProgressbarValue(progressBarValue));
          toast.dark(
            response.data.message
              ? response.data.message
              : "Profile updated successfully"
          );
          history.push("/onboarding/career-highlights-video");
        } else if (response.status === 406) {
          dispatch(isMemberApproved("approved"));
        } else if (response.status !== 401) {
          toast.dark(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          toast.dark(
            err.response.data.message
              ? err.response.data.message
              : "Something went haywire"
          );
        }
      });
  };
}

export function saveCoffeQuestionData(coffeeQuestion) {
  return {
    type: "SAVE_COFFEQUESTION",
    coffeeQuestion: coffeeQuestion,
  };
}

export function basicInfoPage(pageNo) {
  return {
    type: "BASIC_INFO_PAGE",
    pageNo: pageNo,
  };
}

export function onboardingStatus(onboardingStatus) {
  return {
    type: "ONBOARDING_STATUS",
    onboardingStatus: onboardingStatus,
  };
}
