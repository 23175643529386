import { combineReducers } from "redux";
import { authentication } from "./auth.reducer";
import { pageTitle } from "./pageTitle.reducer";
import { shared } from "./shared.reducer";
import { onboarding } from "./onboarding.reducers";
import { loader } from "./loader.reducer";
import { preferences } from "./preferences.reducers";
import { basicInfo } from "./basicInfo.reducer";

const appReducer = combineReducers({
  authentication,
  loader,
  shared,
  onboarding,
  pageTitle,
  basicInfo,
  preferences,
});

const rootReducer = (state, action) => {
  if (action.type === "SIGNOUT_SUCCESS") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
