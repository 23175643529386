import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  seo,
  sendTrackingData,
  notAllowWhiteSpaceAtStart,
  showHideToolTip,
  approvedMemberRedirection,
} from "../../../../helpers";
import {
  EVENT_TITLES,
  SCREENS,
  ACTIONS,
} from "../../../../constants/constants";
import {
  Typography,
  Grid,
  Button,
  TextField,
  Avatar,
  Tooltip,
  FormHelperText,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import {
  setTitle,
  basicInfoActions,
  onBoardingActions,
  getCloudName,
} from "../../../../actions";
import validator from "validator";
import { toast } from "react-toastify";
import classNames from "classnames";
import PublishIcon from "@material-ui/icons/Publish";
import ReactTranslation from "../../../core/iReact18";
import translationEN from "../../../../locales/en/translation.json";
import { ReactComponent as ResumeIcon } from "../../../../assets/resume-icon.svg";
import CloseIcon from "@material-ui/icons/Close";

let charCount = 50;

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  appFrame: {
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    padding: theme.spacing(6),
  },
  mainGrid: {
    justifyContent: "space-between",
    [theme.breakpoints.between("xs", "sm")]: {
      flexDirection: "column-reverse",
    },
  },
  title: {
    fontSize: 14,
  },
  smallBottomMargin: {
    marginBottom: "20px",
  },
  smallTopMargin: {
    marginTop: "20px",
  },
  mediumBottomMargin: {
    marginBottom: "16px",
  },
  smallMarginLeft: {
    marginLeft: "8px",
  },
  verySmallBottomMargin: {
    marginBottom: "10px",
  },
  verySmallTopMargin: {
    marginTop: "10px",
  },
  mediumTopMargin: {
    marginTop: "24px",
  },
  boldFont: {
    fontWeight: "bold",
  },
  alignSelfCenter: {
    alignSelf: "center",
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    marginTop: "32px",
  },
  customLink: {
    cursor: "pointer",
    color: "#048475 !important",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  linkedInUrlHelpText: {
    padding: "8px",
    fontWeight: 500,
    fontSize: "18px",
  },
  linkedInUrlHelpTextContent: {
    padding: "8px",
  },
  basicButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "16px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      paddingBottom: "48px !important",
    },
  },
  cardAnimation: {
    marginTop: "-10px",
  },
  fontSize12px: {
    fontSize: "12px",
  },
  uploadButton: {
    border: "1px solid #000000 !important",
  },
  resumeRequiredTextColor: {
    color: "rgb(220, 0, 78)",
  },
  resumeNotRequiredTextColor: {
    color: "unset",
  },
  backgroundColorWhite: {
    backgroundColor: "#FFFFFF",
  },
  backgroundColorGray: {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
});

class BasicInfoStep1 extends Component {
  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => ({
    firstName: this.props?.basicInfo?.basicInfoData?.first_name
      ? this.props?.basicInfo?.basicInfoData?.first_name
      : this.props?.basicInfo?.basicInfoParsedData?.first_name
      ? this.props?.basicInfo?.basicInfoParsedData?.first_name
      : this.props?.authentication?.loginInfo?.first_name
      ? this.props?.authentication?.loginInfo?.first_name
      : "",
    lastName: this.props?.basicInfo?.basicInfoData?.last_name
      ? this.props?.basicInfo?.basicInfoData?.last_name
      : this.props?.basicInfo?.basicInfoParsedData?.last_name
      ? this.props?.basicInfo?.basicInfoParsedData?.last_name
      : this.props?.authentication?.loginInfo?.last_name
      ? this.props?.authentication?.loginInfo?.last_name
      : "",
    email: this.props?.basicInfo?.basicInfoData?.email
      ? this.props?.basicInfo?.basicInfoData?.email
      : this.props?.basicInfo?.basicInfoParsedData?.email
      ? this.props?.basicInfo?.basicInfoParsedData?.email[0]
      : this.props?.authentication?.loginInfo?.email
      ? this.props?.authentication?.loginInfo?.email
      : "",
    profile_image: this.props?.authentication?.loginInfo?.profile_image,
    resumeFile: this.props?.basicInfo?.basicInfoData?.resume
      ? this.props?.basicInfo?.basicInfoData?.resume
      : this.props?.basicInfo?.basicInfoParsedData?.resume
      ? this.props?.basicInfo?.basicInfoParsedData?.resume
      : this.props?.authentication?.loginInfo?.resume
      ? this.props?.authentication?.loginInfo?.resume
      : "",
    isFirstNameRequired: false,
    isLastNameRequired: false,
    isEmailRequired: false,
    isValidFirstName: true,
    isValidLastName: true,
    isValidEmail: true,
    isResumeRequired: false,
    baseUrlDev: false,
    basicInfoStep: 1,
  });

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(setTitle(`${translationEN.PageTitle.Basic_Info_Step_1_Title}`));
    // show or hide tooltip
    const showOrHideToolTip = showHideToolTip();
    this.setState({ baseUrlDev: showOrHideToolTip });

    dispatch(getCloudName());

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { pageTitle } = this.props.pageTitle;
    seo({
      title: `${pageTitle}`,
    });

    if (
      prevProps?.basicInfo?.basicInfoData !==
      this.props?.basicInfo?.basicInfoData
    ) {
      let { firstName, lastName, email, resumeFile } = this.state;
      let userInfo = {
        userId: this.props?.basicInfo?.basicInfoData?.candidate_id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        resume: resumeFile,
        onboardingStatus: this.props?.onboarding?.onboardingStatus,
      };
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.BASIC_INFO_STEP_1_PAGE_LOAD,
        SCREENS.BASIC_INFO_STEP_1_SCREEN,
        ACTIONS.BASIC_INFO_STEP_1_PAGE_LOADED,
        userInfo
      );
    }

    if (
      prevProps?.basicInfo?.basicInfoData !==
      this.props?.basicInfo?.basicInfoData
    ) {
      this.setState({
        firstName: this.props?.basicInfo?.basicInfoData?.first_name
          ? this.props?.basicInfo?.basicInfoData?.first_name
          : "",
        lastName: this.props?.basicInfo?.basicInfoData?.last_name
          ? this.props?.basicInfo?.basicInfoData?.last_name
          : "",
        email: this.props?.basicInfo?.basicInfoData?.email
          ? this.props?.basicInfo?.basicInfoData?.email
          : "",
        resumeFile: this.props?.basicInfo?.basicInfoData?.resume
          ? this.props?.basicInfo?.basicInfoData?.resume
          : "",
      });
    }

    if (this.props?.basicInfo?.memberStatus === "approved") {
      approvedMemberRedirection(
        this.props?.authentication?.environment,
        this.props?.authentication?.key
      );
    }
  }

  handleInputChange = (prop) => (event) => {
    const inputValue = notAllowWhiteSpaceAtStart(event.target.value);
    this.setState({ [prop]: inputValue }, () => {
      switch (prop) {
        case "firstName":
          this.setState({
            isValidFirstName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isFirstNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "lastName":
          this.setState({
            isValidLastName: inputValue
              ? validator.matches(inputValue, /^.{2,50}$/)
              : true,
            isLastNameRequired: validator.isEmpty(inputValue),
          });
          break;
        case "email":
          this.setState({
            isValidEmail: inputValue ? validator.isEmail(inputValue) : true,
            isEmailRequired: validator.isEmpty(inputValue),
          });
          break;
        default:
          break;
      }
    });
  };

  handleSaveBasicInfo = () => {
    const { dispatch } = this.props;
    const {
      firstName,
      lastName,
      email,
      isValidFirstName,
      isValidLastName,
      isValidEmail,
      resumeFile,
    } = this.state;
    let progressBarStepValue = 12.5;
    const utmData = this.props?.authentication?.utmData
      ? this.props?.authentication?.utmData
      : {};
    let url = new URL(window.location.href);
    let urlPayloadValue = `${url.origin}/login`;
    if (
      firstName &&
      lastName &&
      email &&
      isValidFirstName &&
      isValidLastName &&
      isValidEmail
    ) {
      if (this.props?.authentication?.jodDetailInfo?.type === "job") {
        // && resumeFile
        if (!resumeFile) {
          toast.dark(
            this.state.baseUrlDev ? (
              <Tooltip
                title="Toast_Message.Required_Message"
                placement="left-end"
                arrow
              >
                <Typography>
                  <ReactTranslation
                    contentKey={"Toast_Message.Required_Message"}
                  />
                </Typography>
              </Tooltip>
            ) : (
              <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
            )
          );
          this.setState({
            isResumeRequired:
              this.props?.authentication?.jodDetailInfo?.type === "job" &&
              !resumeFile
                ? true
                : false,
          });
          return;
        }
      }

      let payload = {
        step: this.state.basicInfoStep,
        first_name: firstName,
        last_name: lastName,
        email: email,
        resume: resumeFile,
        source_link: urlPayloadValue,
        invite_code: this.props?.authentication?.inviteCode
          ? this.props?.authentication?.inviteCode
          : "REAC22",
        type: this.props?.authentication?.vipJobDetailInfo?.type
          ? this.props?.authentication?.vipJobDetailInfo?.type
          : this.props?.authentication?.jodDetailInfo?.type
          ? this.props?.authentication?.jodDetailInfo?.type
          : null,
      };

      if (Object.keys(utmData).length > 0) {
        for (var key in utmData) {
          payload[key] = utmData[key];
        }
      }
      dispatch(
        basicInfoActions.basicInformationStep1(payload, progressBarStepValue)
      );

      let userInfo = {
        userId: this.props?.basicInfo?.basicInfoData?.candidate_id,
        firstName: firstName,
        lastName: lastName,
        email: email,
        resume: resumeFile,
        onboardingStatus: this.props?.onboarding?.onboardingStatus,
      };
      sendTrackingData(
        this.props.shared.decryptedMixPanelKey,
        EVENT_TITLES.BASIC_INFO_STEP_1_NEXT_CLICK,
        SCREENS.BASIC_INFO_STEP_1_SCREEN,
        ACTIONS.BASIC_INFO_STEP_1_NEXT_CLICKED,
        userInfo
      );
    } else {
      toast.dark(
        this.state.baseUrlDev ? (
          <Tooltip
            title="Toast_Message.Required_Message"
            placement="left-end"
            arrow
          >
            <Typography>
              <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
            </Typography>
          </Tooltip>
        ) : (
          <ReactTranslation contentKey={"Toast_Message.Required_Message"} />
        )
      );
      this.setState({
        isFirstNameRequired: validator.isEmpty(firstName) ? true : false,
        isLastNameRequired: validator.isEmpty(lastName) ? true : false,
        isEmailRequired: validator.isEmpty(email) ? true : false,
        isResumeRequired:
          this.props?.authentication?.jodDetailInfo?.type === "job" &&
          !resumeFile
            ? true
            : false,
      });
    }
  };

  handleResumeUpload = (event) => {
    document.getElementById("resumeUpload").click();
    document.getElementById("resumeUpload").onchange = (e) => {
      if (
        e.target.files[0] &&
        e.target.files[0].type !== "application/pdf" &&
        e.target.files[0].type !== "application/msword" &&
        e.target.files[0].type !==
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        this.setState({ resumeFile: null });
        toast.dark(
          this.state.baseUrlDev ? (
            <Tooltip
              title="Toast_Message.Basic_Info_Step_1.Resume_Accept_File_Type_Message"
              placement="left-end"
              arrow
            >
              <Typography>
                <ReactTranslation
                  contentKey={
                    "Toast_Message.Basic_Info_Step_1.Resume_Accept_File_Type_Message"
                  }
                />
              </Typography>
            </Tooltip>
          ) : (
            <ReactTranslation
              contentKey={
                "Toast_Message.Basic_Info_Step_1.Resume_Accept_File_Type_Message"
              }
            />
          )
        );
        return;
      }
      const [file] = e.target.files;
      this.uploadResumeIfAny(file);
    };
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.BASIC_INFO_STEP_1_UPLOAD_RESUME_CLICK,
      SCREENS.BASIC_INFO_STEP_1_SCREEN,
      ACTIONS.BASIC_INFO_STEP_1_UPLOAD_RESUME_CLICKED
    );
    event.preventDefault();
  };

  uploadResumeIfAny = (file) => {
    if (file !== null) {
      let fd = new FormData();
      fd.append("file", file);
      fd.append("upload_preset", this.props.shared.decryptedUploadPreset);
      onBoardingActions
        .uploadFileToCloudinary(
          fd,
          "auto",
          this.props.shared.decryptedCloudName
        )
        .then((result) => {
          if (result) {
            this.setState({
              resumeFile: result.data.secure_url,
              uploadedResumeName: result?.data?.original_filename,
            }); //isResumeRequired: false
            toast.dark("Resume Uploaded Successfully");
          }
        });
    } else {
      if (this.props?.authentication?.jodDetailInfo?.type === "job") {
        this.setState({ resumeFile: null, isResumeRequired: true }); //isResumeRequired: true
        return;
      }
      this.setState({ resumeFile: null }); //isResumeRequired: true
    }
  };

  handleDeleteResume = () => {
    this.setState({
      resumeFile: null,
      isResumeRequired:
        this.props?.authentication?.jodDetailInfo?.type === "job"
          ? true
          : false,
    });
    sendTrackingData(
      this.props.shared.decryptedMixPanelKey,
      EVENT_TITLES.BASIC_INFO_STEP_1_DELETE_RESUME_CLICK,
      SCREENS.BASIC_INFO_STEP_1_SCREEN,
      ACTIONS.BASIC_INFO_STEP_1_DELETE_RESUME_CLICKED
    );
  };

  render() {
    const { classes } = this.props;
    const { firstName, lastName, email, baseUrlDev } = this.state;
    let resumeName;
    if (this.state.resumeFile) {
      const resumeExt = this.state.resumeFile?.split(".").pop();
      switch (resumeExt.toLowerCase()) {
        case "docx":
        case "pdf":
        case "doc":
          resumeName = `${firstName}_ ${lastName}.${resumeExt}`;
          break;
        default:
          resumeName = `${firstName}_${lastName}.docx`;
          break;
      }
    }
    return (
      <div className={classes.root}>
        <Grid container spacing={2} className={classes.mainGrid}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Avatar
                  alt={this.state.firstName}
                  src={
                    this.props?.basicInfo?.basicInfoData?.profile_image !== ""
                      ? this.props?.basicInfo?.basicInfoData?.profile_image
                      : "/"
                  }
                  className={classNames(classes.large)}
                ></Avatar>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.smallTopMargin}
              >
                <TextField
                  autoComplete={false}
                  id="firstName"
                  name="firstName"
                  inputProps={{ maxLength: charCount }}
                  fullWidth
                  label={
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Step_1.Form.First_Name"
                        placement="left-end"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_1.Form.First_Name"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={
                            "Basic_Information_Step_1.Form.First_Name"
                          }
                        />
                      </Typography>
                    )
                  }
                  placeholder={`${translationEN.Placeholder.Basic_Info_Page.First_Name_Placeholder}`}
                  required
                  value={firstName}
                  onChange={this.handleInputChange("firstName")}
                  variant="standard"
                  InputProps={{
                    classes: {
                      notchedOutline: classes.textField,
                      input: classes.notchedInput,
                      root: classes.fontFamily,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      contained: classes.leftMarginZero,
                      root: classes.fontFamily,
                    },
                  }}
                  error={
                    !this.state.isValidFirstName ||
                    this.state.isFirstNameRequired
                  }
                  helperText={
                    this.state.isFirstNameRequired ? (
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )
                    ) : this.state.isValidFirstName ? (
                      ""
                    ) : baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Validation_Messages.Field_Invalid"
                        placement="left-end"
                      >
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Invalid"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className={classes.fontSize12px}
                      >
                        <ReactTranslation
                          contentKey={"Validation_Messages.Field_Invalid"}
                        />
                      </Typography>
                    )
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  autoComplete={false}
                  id="lastName"
                  name="lastName"
                  fullWidth
                  label={
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Step_1.Form.Last_Name"
                        placement="left-end"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_1.Form.Last_Name"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Basic_Information_Step_1.Form.Last_Name"}
                        />
                      </Typography>
                    )
                  }
                  placeholder={`${translationEN.Placeholder.Basic_Info_Page.Last_Name_Placeholder}`}
                  variant="standard"
                  required
                  value={lastName}
                  onChange={this.handleInputChange("lastName")}
                  InputProps={{
                    classes: {
                      notchedOutline: classes.textField,
                      input: classes.notchedInput,
                      root: classes.fontFamily,
                    },
                  }}
                  FormHelperTextProps={{
                    classes: {
                      contained: classes.leftMarginZero,
                      root: classes.fontFamily,
                    },
                  }}
                  error={
                    !this.state.isValidLastName || this.state.isLastNameRequired
                  }
                  helperText={
                    this.state.isLastNameRequired ? (
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )
                    ) : this.state.isValidLastName ? (
                      ""
                    ) : baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Validation_Messages.Field_Invalid"
                        placement="left-end"
                      >
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Invalid"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className={classes.fontSize12px}
                      >
                        <ReactTranslation
                          contentKey={"Validation_Messages.Field_Invalid"}
                        />
                      </Typography>
                    )
                  }
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  autoComplete={false}
                  id="email"
                  name="email"
                  fullWidth
                  label={
                    baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Step_1.Form.Email"
                        placement="left-end"
                      >
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Basic_Information_Step_1.Form.Email"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography component={"span"}>
                        <ReactTranslation
                          contentKey={"Basic_Information_Step_1.Form.Email"}
                        />
                      </Typography>
                    )
                  }
                  placeholder={`${translationEN.Placeholder.Basic_Info_Page.Email_Placeholder}`}
                  variant="standard"
                  required
                  value={email}
                  onChange={this.handleInputChange("email")}
                  InputProps={{
                    readOnly: true,
                    classes: {
                      notchedOutline: classes.textField,
                      input: classes.notchedInput,
                      root: classes.fontFamily,
                    },
                  }}
                  error={!this.state.isValidEmail || this.state.isEmailRequired}
                  helperText={
                    this.state.isEmailRequired ? (
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Validation_Messages.Field_Required"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={"Validation_Messages.Field_Required"}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      )
                    ) : this.state.isValidEmail ? (
                      baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Basic_Information_Step_1.Form.non-editable_field"
                          placement="left-end"
                        >
                          <Typography
                            component={"span"}
                            className={classes.fontSize12px}
                          >
                            <ReactTranslation
                              contentKey={
                                "Basic_Information_Step_1.Form.non-editable_field"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_1.Form.non-editable_field"
                            }
                          />
                        </Typography>
                      )
                    ) : baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Validation_Messages.Field_Invalid"
                        placement="left-end"
                      >
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Invalid"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className={classes.fontSize12px}
                      >
                        <ReactTranslation
                          contentKey={"Validation_Messages.Field_Invalid"}
                        />
                      </Typography>
                    )
                  }
                ></TextField>
              </Grid>
              {!this.state.resumeFile && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <>
                    <Typography
                      className={classNames(
                        classes.mediumBottomMargin,
                        classes.successText,
                        this.state.isResumeRequired
                          ? classes.resumeRequiredTextColor
                          : classes.resumeNotRequiredTextColor
                      )}
                      align="left"
                      style={{
                        color: this.state.isResumeRequired
                          ? "rgb(220, 0, 78)"
                          : "unset",
                      }}
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Basic_Information_Step_1.RESUME_TITLE"
                          placement="left-end"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Basic_Information_Step_1.RESUME_TITLE"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={"Basic_Information_Step_1.RESUME_TITLE"}
                          />
                        </Typography>
                      )}
                      {this.props?.authentication?.jodDetailInfo?.type ===
                        "job" &&
                        !this.state.resumeFile && (
                          <span className={classes.resumeRequiredTextColor}>
                            *
                          </span>
                        )}
                    </Typography>
                    <input
                      required={
                        this.props?.authentication?.jodDetailInfo?.type ===
                          "job" && !this.state.resumeFile
                      }
                      hidden
                      type="file"
                      accept=".pdf, .doc, .docx"
                      id="resumeUpload"
                      name="resumeUpload"
                    />
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Basic_Information_Step_1.UPLOAD_RESUME"
                        placement="left-end"
                      >
                        <Button
                          fullWidth
                          className={classes.uploadButton}
                          startIcon={<PublishIcon />}
                          variant="outlined"
                          color="primary"
                          onClick={(event) => {
                            this.handleResumeUpload(event);
                          }}
                        >
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_1.UPLOAD_RESUME"
                            }
                          />
                        </Button>
                      </Tooltip>
                    ) : (
                      <Button
                        fullWidth
                        className={classes.uploadButton}
                        startIcon={<PublishIcon />}
                        variant="outlined"
                        color="primary"
                        onClick={(event) => {
                          this.handleResumeUpload(event);
                        }}
                      >
                        <ReactTranslation
                          contentKey={"Basic_Information_Step_1.UPLOAD_RESUME"}
                        />
                      </Button>
                    )}
                    <Typography
                      className={classes.mediumTopMargin}
                      color="textSecondary"
                      align="left"
                    >
                      {baseUrlDev ? (
                        <Tooltip
                          arrow
                          title="Basic_Information_Step_1.RESUME_FILE_ACCEPTES_TYPES"
                          placement="left-end"
                        >
                          <Typography component={"span"}>
                            <ReactTranslation
                              contentKey={
                                "Basic_Information_Step_1.RESUME_FILE_ACCEPTES_TYPES"
                              }
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography component={"span"}>
                          <ReactTranslation
                            contentKey={
                              "Basic_Information_Step_1.RESUME_FILE_ACCEPTES_TYPES"
                            }
                          />
                        </Typography>
                      )}
                    </Typography>
                  </>
                </Grid>
              )}
              {this.props?.authentication?.jodDetailInfo?.type === "job" &&
              !this.state.resumeFile &&
              this.state.isResumeRequired ? (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormHelperText className={classes.resumeRequiredTextColor}>
                    {baseUrlDev ? (
                      <Tooltip
                        arrow
                        title="Validation_Messages.Field_Required"
                        placement="left-end"
                      >
                        <Typography
                          component={"span"}
                          className={classes.fontSize12px}
                        >
                          <ReactTranslation
                            contentKey={"Validation_Messages.Field_Required"}
                          />
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography
                        component={"span"}
                        className={classes.fontSize12px}
                      >
                        <ReactTranslation
                          contentKey={"Validation_Messages.Field_Required"}
                        />
                      </Typography>
                    )}
                  </FormHelperText>
                </Grid>
              ) : null}
              {this.state.resumeFile && (
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <List component="nav" className={classes.backgroundColorGray}>
                    <ListItem>
                      <ListItemIcon>
                        <Avatar className={classes.backgroundColorWhite}>
                          <ResumeIcon />
                        </Avatar>
                      </ListItemIcon>
                      <a
                        href={this.state.resumeFile}
                        target="_blank"
                        rel="noreferrer"
                        className={classes.customLink}
                      >
                        <ListItemText primary={resumeName} />
                      </a>
                      <ListItemSecondaryAction>
                        <Tooltip title="Delete" arrow placement="right-end">
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={(event) => {
                              event.preventDefault();
                              this.handleDeleteResume();
                            }}
                          >
                            {/* isResumeRequired: true */}
                            {<CloseIcon />}
                          </IconButton>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3} className={classes.marginTop}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className={classes.mediumTopMargin}
              >
                <div
                  className={classNames(classes.TopMargin, classes.basicButton)}
                >
                  {baseUrlDev ? (
                    <Tooltip arrow title="Buttons.Next">
                      <Button
                        id="basicInfoPage1NextButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        onClick={() => this.handleSaveBasicInfo()}
                      >
                        {" "}
                        <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      id="basicInfoPage1NextButton"
                      variant="contained"
                      color="primary"
                      disableElevation
                      onClick={() => this.handleSaveBasicInfo()}
                    >
                      {" "}
                      <ReactTranslation contentKey={"Buttons.Next"} />{" "}
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

BasicInfoStep1.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return state;
}
const connectedBasicInfoPage1 = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withStyles(styles)(BasicInfoStep1))
);
export { connectedBasicInfoPage1 as BasicInfoStep1 };
