import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
// import secureLocalStorage from "react-secure-storage";

const useStyles = makeStyles(() => ({
  hidden: {
    display: "-webkit-box", //mandatory if using WebkitLineClamp
    WebkitLineClamp: 10,
    overflow: "hidden",
    WebkitBoxOrient: "vertical", //mandatory if using WebkitLineClamp
  },
}));

export function ViewMore(props, { length = props.length || 450 }) {
  const classes = useStyles();
  let [isHidden, setIsHidden] = useState(props.expanded ? false : true);
  /* -------------------------DO NOT DELETE------------------------- */
  // var isClampedText = false;
  // console.log(props.children.length)

  // function toggleClamp(val) {
  //   console.log(val);
  // }

  // const isClamped = el => {
  // isClampedText = el.scrollHeight > el.clientHeight;
  // toggleClamp(isClampedText);
  // const isTextClamped = elm => elm.scrollHeight > elm.clientHeight
  // const onMouseEnter = elm => console.log( isTextClamped(elm) )
  // const maxLines = parseInt(document.defaultView.getComputedStyle(el, null).getPropertyValue("-webkit-line-clamp"));
  // if(isNaN(maxLines)) return false;

  // const originalLineClamp = el.style['-webkit-line-clamp'];
  // el.style['-webkit-line-clamp'] = 'initial';

  // let lineHeight = document.defaultView.getComputedStyle(el, null).getPropertyValue("line-height");
  // if(lineHeight === 'normal'){
  //   lineHeight = parseInt(document.defaultView.getComputedStyle(el, null).getPropertyValue("font-size")) * 1.2;
  // }else{
  //   lineHeight = parseInt(lineHeight);
  // }

  // const clamped =  5 * lineHeight <= el.offsetHeight;
  // el.style['-webkit-line-clamp'] = originalLineClamp;
  // isClampedText = clamped;
  // return clamped;
  // };

  // if(document.getElementById(`viewMoreText${props.index}`)){
  // isClamped(document.getElementById(`viewMoreText${props.index}`))
  // ?
  // // setisClamped(true)
  // secureLocalStorage.setItem('isClampedText', true)
  // :
  // secureLocalStorage.setItem('isClampedText', false)
  // }

  return (
    <>
      {/* <div className={isHidden ? classes.hidden : null}>{children}</div> */}
      {/* -------------------------DO NOT DELETE------------------------- */}
      {/* <div id={`viewMoreText${props.index}`} className={props.children.length > length && isHidden ? classes.hidden : null} dangerouslySetInnerHTML={{
            __html: (props.children.length > length && isHidden && isClampedText) ? `${props.children.slice(0, length)}...` : props.children
      }}></div> */}
      <span
        id={`viewMoreText${props.index || Math.floor(Math.random() * 10)}`}
        className={
          props.children.length > length && isHidden ? classes.hidden : null
        }
        dangerouslySetInnerHTML={{
          __html:
            props.children.length > length && isHidden
              ? `${props.children.slice(0, length)}...`
              : props.children,
        }}
      ></span>
      {props.children.length > length ? (
        <div
          size="small"
          style={{
            cursor: "pointer",
            fontWeight: "bold",
            marginTop: "5px",
            marginBottom: "5px",
            color: "#048475",
            float: props.direction ? props.direction : "unset",
          }}
          onClick={() => setIsHidden(!isHidden)}
        >
          {/* {isHidden ? "⬇ more" : "⬆ less"} */}
          view {isHidden ? " more" : "less"}
        </div>
      ) : //   <Button size="small" style={{boxShadow: '0px 1px 1px 0px rgb(0 0 0 / 0%), 0px 1px 1px 0px rgb(0 0 0 / 5%), 0px 0px 1px 1px rgb(0 0 0 / 10%)', marginTop: '5px', marginBottom: '5px', color: '#048475'}} onClick={() => setIsHidden(!isHidden)}>
      //   {/* {isHidden ? "⬇ more" : "⬆ less"} */}
      //   View {isHidden ? " more" : "less"}
      // </Button>
      null}
    </>
  );
}

// export default ViewMore;
