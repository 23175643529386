const initialState = {
  memberDiversityData: {},
  preferencesPage: null,
  salaryData: {},
  workEnvData: {},
  jobRoleExpectationData: {},
};

export function preferences(state = initialState, action) {
  switch (action.type) {
    case "DIVERSITY_DATA":
      return {
        ...state,
        memberDiversityData: action.memberDiversity,
      };
    case "PREFERENCES_PAGE":
      return {
        ...state,
        preferencesPage: action.pageNo,
      };
    case "SAVE_SALARY":
      return {
        ...state,
        salaryData: action.salaryData,
      };
    case "SAVE_WORK_ENV":
      return {
        ...state,
        workEnvData: action.workEnvData,
      };
    case "JOB_ROLE_EXPECTATION":
      return {
        ...state,
        jobRoleExpectationData: action.jobRoleExpectationData,
      };
    default:
      return state;
  }
}
