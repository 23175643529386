import React from "react";
import { Route } from "react-router-dom";
// import secureLocalStorage from "react-secure-storage";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      // secureLocalStorage.getItem('auth') ? <Component {...props} /> : <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      <Component {...props} />
    )}
  />
);
