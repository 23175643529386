import config from "../config/config";

export const VERSION = "v2.3.2";
export const MESSAGE_FIELD_IS_REQUIRED = "This field is required";

export const MASTER_KEY = "gPcgG6jwzYdCJ0qE+abUhgI3rZSUDfSz27pLtl6p4ME=";

export const FIELD_INVALID = "Invalid value";

export const REMOVE_DASH_REGEX = new RegExp(/[()\\s-]+/g);
export const SCREENS = {
  DEFAULT_LOGIN_SCREEN: "Onboarding - Default Login Screen",
  JOB_LOGIN_SCREEN: "Onboarding - Job Login Screen",
  VIP_LOGIN_SCREEN: "Onboarding - VIP Login Screen",
  BASIC_INFO_STEP_1_SCREEN: "Onboarding - Basic Information Screen 1",
  BASIC_INFO_STEP_2_SCREEN: "Onboarding - Basic Information Screen 2",
  PROFESSIONAL_INFO_SCREEN: "Onboarding - Professional Information Screen",
  ACCOMPLISHMENTS_SCREEN: "Onboarding - Accomplishments Screen",
  COMPETENCIES_SCREEN: "Onboarding - Competencies Screen",
  COFFEE_QUE_SCREEN: "Onboarding - Coffee Questions Screen",
  VIDEO_SCREEN: "Onboarding - Video Screen",
  SALARY_EXPECTATIONS_SCREEN: "Onboarding - Salary Expectations Screen",
  WORK_ENVIROMENT_SCREEN: "Onboarding - Work Enviroment Screen",
  JOB_ROLE_PREFERENCES_SCREEN: "Onboarding - Job Role Preferences Screen",
  DIVERSITY_SCREEN: "Onboarding - Diversity Screen",
  ONBOARDING_COMPLETE_SCREEN: "Onboarding Complete Screen",
  BAD_REQUEST_SCREEN: "Error - Bad request screen",
};

export const EVENT_TITLES = {
  TERMS_AND_CONDITIONS_CHECKBOX_CLICK: "Terms and Conditions Checkbox Click",
  DEFAULT_LOGIN_PAGE_LOAD: "Default Login Page Load",
  JOB_LOGIN_PAGE_LOAD: "Job Login Page Load",
  VIP_LOGIN_PAGE_LOAD: "Vip Login Page Load",
  SIGN_IN_LINK_CLICK: "Sign In Link Click",
  SIGN_UP_BUTTON_CLICK: "Sign Up Link Click",
  BASIC_INFO_STEP_1_PAGE_LOAD: "Basic Information Step 1 Page Load",
  BASIC_INFO_STEP_1_UPLOAD_RESUME_CLICK: "Upload Resume",
  BASIC_INFO_STEP_1_DELETE_RESUME_CLICK: "Delete Resume",
  BASIC_INFO_STEP_1_NEXT_CLICK: "Save Step 1 Basic Information",
  BASIC_INFO_STEP_2_PAGE_LOAD: "Basic Information Step 2 Page Load",
  BASIC_INFO_STEP_2_NEXT_CLICK: "Save Step 2 Basic Information",
  BASIC_INFO_STEP_2_PREVIOUS_CLICK: "Basic Information Step 2 Previous Click",
  PROFESSIONAL_INFO_PAGE_LOAD: "Professional Information Page Load",
  PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICK: "Add Custom Job Title",
  PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICK:
    "Cancel Adding Custom Job Title",
  PROFESSIONAL_INFO_NEXT_CLICK: "Save Professional Information",
  PROFESSIONAL_INFO_PREVIOUS_CLICK: "Professional Information Previous Click",
  ACCOMPLISHMENTS_PAGE_LOAD: "Accomplishments Page Load",
  ACCOMPLISHMENTS_ADD_NEW_ACCOMPLISHMENT: "Add New Accomplsihment",
  ACCOMPLISHMENTS_NEXT_CLICK: "Accomplishments Next Click",
  ACCOMPLISHMENTS_PREVIOUS_CLICK: "Accomplishments Previous Click",
  COMPETENCIES_PAGE_LOAD: "Competencies Page Load",
  COMPETENCIES_ADD_NEW_COMPETENCY: "Add New Competency",
  COMPETENCIES_NEXT_CLICK: "Competencies Next Click",
  COMPETENCIES_PREVIOUS_CLICK: "Competencies Previous Click",
  COFFEE_QUE_PAGE_LOAD: "Coffee Question Page Load",
  COFFEE_QUE_SKIP_CLICK: "Coffee Question Skip Click",
  COFFEE_QUE_NEXT_CLICK: "Coffee Question Next Click",
  COFFEE_QUE_PREVIOUS_CLICK: "Coffee Question Previous Click",
  VIDEO_PAGE_LOAD: "Video Page Load",
  VIDEO_PAGE_UPLOAD_NEW_VIDEO: "Select Upload New Video",
  VIDEO_PAGE_SCHEDULE_MEETING: "Schedule A Meeting",
  VIDEO_PAGE_RECORD_NEW_VIDEO: "Select Record New Video",
  VIDEO_PAGE_SAVE_RECORDED_VIDEO: "Recorded Video Save",
  VIDEO_PAGE_SAVE_UPLOADED_VIDEO: "Uploaded Video Save",
  VIDEO_SKIP_CLICK: "Video Skip Click",
  VIDEO_NEXT_CLICK: "Video Next Click",
  VIDEO_PREVIOUS_CLICK: "Video Previous Click",
  SALARY_EXPECTATIONS_PAGE_LOAD: "Salary Expectations Page Load",
  SALARY_EXPECTATIONS_NEXT_CLICK: "Salary Expectations Next Click",
  SALARY_EXPECTATIONS_PREVIOUS_CLICK: "Salary Expectations Previous Click",
  WORK_ENVIROMENT_PAGE_LOAD: "Work Enviroment Page Load",
  WORK_ENVIROMENT_NEXT_CLICK: "Work Enviroment Next Click",
  WORK_ENVIROMENT_PREVIOUS_CLICK: "Work Enviroment Previous Click",
  JOB_ROLE_PREFERENCES_PAGE_LOAD: "Job Role Preferences Page Load",
  JOB_ROLE_PREFERENCES_NEXT_CLICK: "Job Role Preferences Next Click",
  JOB_ROLE_PREFERENCES_PREVIOUS_CLICK: "Job Role Preferences Previous Click",
  DIVERSITY_PAGE_LOAD: "Diversity Page Load",
  DIVERSITY_NEXT_CLICK: "Diversity Next Click",
  DIVERSITY_PREVIOUS_CLICK: "Diversity Previous Click",
  ONBOARDING_COMPLETE_PAGE_LOAD: "Onboarding Complete Page Load",
  ONBOARDING_COMPLETE_CONTINUE_CLICK: "Onboarding Complete Continue Click",
  ONBOARDING_COMPLETE_PREVIOUS_CLICK: "Onboarding Complete Previous Click",
  SIGN_OUT_CLICK: "Sign Out Click",
  BAD_RQUEST: "Bad request",
};

export const ACTIONS = {
  T_N_C_CHECKBOX_CHECKED: "Terms and Conditions Checkbox Checked",
  T_N_C_CHECKBOX_UNCHECKED: "Terms and Conditions Checkbox Unchecked",
  DEFAULT_LOGIN_PAGE_LOADED: "Default Login Page Loaded",
  JOB_LOGIN_PAGE_LOADED: "Job Login Page Loaded",
  VIP_LOGIN_PAGE_LOADED: "Vip Login Page Loaded",
  SIGN_IN_LINK_CLICKED: "Sign In Link Clicked",
  SIGN_UP_BUTTON_CLICKED: "Sign Up Link Clicked",
  BASIC_INFO_STEP_1_PAGE_LOADED: "Basic Information Step 1 Page Loaded",
  BASIC_INFO_STEP_1_UPLOAD_RESUME_CLICKED: "Uploaded Resume",
  BASIC_INFO_STEP_1_DELETE_RESUME_CLICKED: "Deleted Resume",
  BASIC_INFO_STEP_1_NEXT_CLICKED: "Saved Step 1 Basic Information",
  BASIC_INFO_STEP_2_PAGE_LOADED: "Basic Information Step 2 Page Loaded",
  BASIC_INFO_STEP_2_NEXT_CLICKED: "Saved Step 2 Basic Information",
  BASIC_INFO_STEP_2_PREVIOUS_CLICKED:
    "Basic Information Step 2 Previous Clicked",
  PROFESSIONAL_INFO_PAGE_LOADED: "Professional Information Page Loaded",
  PROFESSIONAL_INFO_ADD_CUSTOM_JOB_TITLE_CLICKED: "Added Custom Job Title",
  PROFESSIONAL_INFO_CANCEL_CUSTOM_JOB_TITLE_CLICKED:
    "Cancelled Adding Custom Job Title",
  PROFESSIONAL_INFO_NEXT_CLICKED: "Saved Professional Information",
  PROFESSIONAL_INFO_PREVIOUS_CLICKED:
    "Professional Information Previous Clicked",
  ACCOMPLISHMENTS_PAGE_LOADED: "Accomplishments Page Loaded",
  ACCOMPLISHMENTS_ADDED_NEW_ACCOMPLISHMENT: "Added New Accomplsihment",
  ACCOMPLISHMENTS_NEXT_CLICKED: "Accomplishments Next Clicked",
  ACCOMPLISHMENTS_PREVIOUS_CLICKED: "Accomplishments Previous Clicked",
  COMPETENCIES_PAGE_LOADED: "Competencies Page Loaded",
  COMPETENCIES_ADDED_NEW_COMPETENCY: "Added New Competency",
  COMPETENCIES_NEXT_CLICKED: "Competencies Next Clicked",
  COMPETENCIES_PREVIOUS_CLICKED: "Competencies Previous Clicked",
  COFFEE_QUE_PAGE_LOADED: "Coffee Question Page Loaded",
  COFFEE_QUE_SKIP_CLICKED: "Coffee Question Skip Clicked",
  COFFEE_QUE_NEXT_CLICKED: "Coffee Question Next Clicked",
  COFFEE_QUE_PREVIOUS_CLICKED: "Coffee Question Previous Clicked",
  VIDEO_PAGE_LOADED: "Video Page Loaded",
  VIDEO_PAGE_UPLOADED_NEW_VIDEO: "Selected Upload New Video",
  VIDEO_PAGE_SCHEDULED_MEETING: "Scheduled A Meeting",
  VIDEO_PAGE_RECORDED_NEW_VIDEO: "Selected Record New Video",
  VIDEO_PAGE_SAVED_RECORDED_VIDEO: "Recorded Video Saved",
  VIDEO_PAGE_SAVED_UPLOADED_VIDEO: "Uploaded Video Saved",
  VIDEO_SKIP_CLICKED: "Video Skip Clicked",
  VIDEO_NEXT_CLICKED: "Video Next Clicked",
  VIDEO_PREVIOUS_CLICKED: "Video Previous Clicked",
  SALARY_EXPECTATIONS_PAGE_LOADED: "Salary Expectations Page Loaded",
  SALARY_EXPECTATIONS_NEXT_CLICKED: "Salary Expectations Next Clicked",
  SALARY_EXPECTATIONS_PREVIOUS_CLICKED: "Salary Expectations Previous Clicked",
  WORK_ENVIROMENT_PAGE_LOADED: "Work Enviroment Page Loaded",
  WORK_ENVIROMENT_NEXT_CLICKED: "Work Enviroment Next Clicked",
  WORK_ENVIROMENT_PREVIOUS_CLICKED: "Work Enviroment Previous Clicked",
  JOB_ROLE_PREFERENCES_PAGE_LOADED: "Job Role Preferences Page Loaded",
  JOB_ROLE_PREFERENCES_NEXT_CLICKED: "Job Role Preferences Next Clicked",
  JOB_ROLE_PREFERENCES_PREVIOUS_CLICKED:
    "Job Role Preferences Previous Clicked",
  DIVERSITY_PAGE_LOADED: "Diversity Page Loaded",
  DIVERSITY_NEXT_CLICKED: "Diversity Next Clicked",
  DIVERSITY_PREVIOUS_CLICKED: "Diversity Previous Clicked",
  ONBOARDING_COMPLETE_PAGE_LOADED: "Onboarding Complete Page Loaded",
  ONBOARDING_COMPLETE_CONTINUE_CLICKED: "Onboarding Complete Continue Clicked",
  ONBOARDING_COMPLETE_PREVIOUS_CLICKED: "Onboarding Complete Previous Clicked",
  SIGN_OUT_CLICKED: "Sign Out Clicked",
};

export const CALNEDLY_SCHEDULE_LINK =
  config.baseUrl === `https://dev1-platform.succeedsmart.com/` ||
  config.baseUrl === `https://dev-platform.succeedsmart.com/`
    ? "https://calendly.com/rushinere/30min"
    : "https://calendly.com/succeedsmart/concierge";

export const CONSTANTS = {
  NOT_INTERESTED_IN_EITHER: "Not interested in either",
  NOT_INTERESTED_IN_ANY_BOARD_ROLE: "Not interested in any board role",
  INTERESTED_IN_BOARD_OF_ADVISOR_ROLES: "Interested in Board of Advisor Roles",
  INTERESTED_IN_DIRECTOR_ROLES: "Interested in Director Roles",
  INTERESTED_IN_A_FRACTIONAL_ROLE: "Interested in a Fractional Role",
  NO_PREDERENCES: "No preferences",
  REMOTE_ONLY: "Remote only",
  IN_OFFICE: "In-Office",
  HYBRID: "Hybrid",
};
